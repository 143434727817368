import { LoginContext } from "../../contexts/LoginContext";
import { MainContext } from "../../contexts/MainContext";
import { NewAgreementContext } from "../../contexts/NewAgreementContext";
import robot_img from "../../assets/robot.png";
import React, { useEffect, useRef, useContext, useState } from "react";
import refresh_ico from "../../assets/refresh.png"
import MessageBox from "./MessageBox";
import { ChatbotContext } from "../../contexts/ChatbotContext";
import "../../styles/Chatbot.css"

export default function Chatbot() {
  const [newCategoryName, setNewCategoryName] = useState("")
  const { langFile } = useContext(MainContext);
  const { user } = useContext(LoginContext)
  const {
    chatbotData,
    setChatbotData,
    nextQuestion,
    setNextQuestion,
    loopCounter,
    setLoopCounter,
    parentBotAnswerId,
    setParentBotAnswerId,
    RefreshChatbot,
    bottomRef,
    GetBotAnswer,
    AddNewCategory,
    lastGetBotAnswerBody
  } = useContext(ChatbotContext);

  const { token } = useContext(LoginContext)

  const { allChosen, setAllChosen } = useContext(NewAgreementContext)
  useEffect(() => {
    //for first fetch operation
    GetBotAnswer({
      includeParents: true,
      includeQuestions: true,
      getAll: false
    }
    )
  }, [loopCounter]);



  useEffect(() => {
    if (nextQuestion !== 0) {
      if (chatbotData[chatbotData.length - 1]?.botQuestionOptions.order === -1) {// when finished question
        if (chatbotData[chatbotData.length - 1].answer.value === "Evet") {
          let temp = [...allChosen];
          temp.push(
            {
              flow: [...chatbotData],
              kpiName: ""
            })
          setAllChosen(temp)
          setChatbotData([])
          setLoopCounter(loopCounter + 1)
        }
        else {
          setChatbotData([])
          setLoopCounter(loopCounter + 1)
        }
      }
      else {
        GetBotAnswer(
          {
            parentBotAnswerId: parentBotAnswerId,
            includeParents: true,
            includeQuestions: true,
            queryParameters: {
              ParentKpiCategoryId: "'" + chatbotData[chatbotData.length - 1]?.answer?.id + "'"
            }
          }
        )
      }

    }
  }, [nextQuestion])


  useEffect(() => {
    //scrool to bottom
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatbotData]);

  function HandleAddAnswerOnFocus(inputName) {
    const element = document.querySelector(`[name='${inputName}']`);
    if (element) {
      element.style.minWidth = "100%"
      element.style.marginLeft = "0%"
      element.style.marginRight = "0%"
    }
  }
  function HandleAddAnswerOnBlur(inputName) {
    const element = document.querySelector(`[name='${inputName}']`);
    if (element) {
      element.style.minWidth = "60%";
      element.style.marginLeft = "20%"
      element.style.marginRight = "20%"
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter" && newCategoryName !== "") {
      const parentKpiCategoryId = chatbotData[chatbotData.length-2].answer.id;
      AddNewCategory({
        name: newCategoryName,
        accountId: user.id,
        parentKpiCategoryId: parentKpiCategoryId
      },lastGetBotAnswerBody.length-2)
      setNewCategoryName("")
    }
  }
  return (
    <>
      <div className="navbox">
        <div
          style={{
            height: "8vh",
            width: "4.5vw",
            backgroundColor: "var(--backgroundColor2)",
            borderRadius: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
            marginLeft: "2vw",
          }}
        >
          <img
            alt="robot"
            src={robot_img}
            style={{
              height: "7vh",
              width: "3.5vw",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexFlow: "column wrap",
            marginLeft: "1vw",
          }}
        >
          <label>{langFile.pages.Chatbot.experibot}</label>
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              alignItems: "flex-start",
            }}
          >
            <label>{"."} </label>

            <label>{langFile.pages.Chatbot.status}</label>
          </div>
        </div>
        <img onClick={RefreshChatbot} alt="refresh" src={refresh_ico} className="refresh_ico">
        </img>
      </div>
      <div className="boxbox">
        {chatbotData.map((data, index) =>
          data.options === [] ? null : (
            <React.Fragment key={index}>
              {data.botQuestionOptions.order === 2 &&
              chatbotData[chatbotData.length-1].botQuestionOptions.order ===  2 &&
              <input
                onBlur={() => {
                  HandleAddAnswerOnBlur("new-category" + index);
                }}
                onFocus={() => {
                  HandleAddAnswerOnFocus("new-category" + index);
                }}
                name={"new-category" + index}
                id={"new-category" + index}
                type="text"
                className="input-new-category"
                value={newCategoryName}
                onChange={(event) => {
                  setNewCategoryName(event.target.value);
                }}
                onKeyDown={handleKeyPress}
                placeholder="+ New Category"
              ></input>
            }
              <MessageBox
                data={data}
                index={index}
              ></MessageBox>
            </React.Fragment>
          )
        )}
        <div ref={bottomRef} />
      </div>
    </>
  )
}
