import React, { useContext } from 'react'
import "./HistoricalDataUpdateCheck.css"
import right_arrow_ico from "../../assets/right-arrow-2.png"
import { KpiEditContext } from '../../contexts/KpiEditContext';

export default function HistoricalDataUpdateCheck() {
    const {
        historicalDataUpdateCheck,
        setHistoricalDataUpdateCheck,
        setUpdateConfirmActive
    } = useContext(KpiEditContext);
    return (
        historicalDataUpdateCheck && historicalDataUpdateCheck.length > 0 ?
            <div className='historical-data-update-check-container'>
                <div className='check-result'>
                    {historicalDataUpdateCheck.map((item, index) => (
                        <div key={index} className='result-item'>
                            <div className='content'>
                                <label>{item.content.startDate + "/" + item.content.endDate}</label>
                                <div className='changing-result-container'>
                                    {item.beforeStatus === 1 ? <div className="triangle-up"></div> : <div className="triangle-down"></div>}

                                    <img className='right-arrow-ico' alt='right_ico' src={right_arrow_ico}></img>
                                    {item.afterStatus === 1 ? <div className="triangle-up"></div> : <div className="triangle-down"></div>}
                                </div>
                            </div>
                            <hr></hr>
                        </div>

                    ))
                    }

                </div><div className='process-container'>
                    <label>KPI durum değişikliklerini onaylıyor musun?</label>
                    <div className='button-container'>
                        <button onClick={() => {
                            setHistoricalDataUpdateCheck(false)
                        }} className='close-button'>İptal</button>
                        <button onClick={() => { setHistoricalDataUpdateCheck(false); setUpdateConfirmActive(true) }} className='confirm-button'>Onayla</button>
                    </div>
                </div>

            </div>
            : null

    )
}
