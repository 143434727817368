import React from 'react'
import "./ProgressBar.css"
export default function ProgressBar({value_1, value_2}) {
  return (
    <div className='progress-bar-container'>
        <div style={{ width: value_1+value_2 === 0 ? "50%" : value_1/(value_1+value_2)*100+"%"}} className='part-1'>
          {value_1}
        </div>
        <div style={{width: value_1+value_2 === 0 ? "50%" :value_2/(value_1+value_2)*100+"%"}} className='part-2'>
          {value_2}
        </div>
    </div>
  )
}
