import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie, G2 } from '@ant-design/plots';

export default function OlaSlaPieChart () {
  const G = G2.getEngine('canvas');
  const data = [
    {
      type: 'SLA',
      sold: 0.45,
    },
    {
      type: 'OLA',
      sold: 0.55,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'sold',
    colorField: 'type',
    radius: 0.66,
    color: ['#1890ff', '#f04864'],
    label: {
      content: (obj) => {
        const group = new G.Group({});
        // group.addShape({
        //   type: 'image',
        //   attrs: {
        //     x: 0,
        //     y: 0,
        //     width: 40,
        //     height: 50,
        //     img:
        //       obj.type === 'SLA'
        //         ? 'https://gw.alipayobjects.com/zos/rmsportal/oeCxrAewtedMBYOETCln.png'
        //         : 'https://gw.alipayobjects.com/zos/rmsportal/mweUsJpBWucJRixSfWVP.png',
        //   },
        // });
        group.addShape({
          type: 'text',
          attrs: {
            x: obj.type === 'SLA' ? 0 : 27,
            y: obj.type === 'SLA' ? -20 : 20,
            text: obj.type,
            textAlign: 'center',
            textBaseline: 'top',
            fill: obj.type === 'SLA' ? '#1890ff' : '#f04864',
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie style={{width:"100%", height:"100%"}} {...config} />;
};

