import React, { useContext } from 'react'
import { KpiEditContext } from '../../contexts/KpiEditContext';
import "./ForecastSelection.css"
import ai_ico from "../../assets/robot.png";
import question_ico from "../../assets/question_ico.png"

export default function ForecastSelection() {
  const {
    median,
    average,
    selectedEngine,
    setSelectedEngine,
    targetsForRecommendation,
    currentDate,
    beginDateForEngine,
    setBeginDateForEngine,
  } = useContext(KpiEditContext);
  return (
    <div className='forecast-selection-container'>
      <div className="recommendation-result-parent">
        <div style={{ width: "21%" }} className="recommendation-result-container">
          <label
            onClick={() => setSelectedEngine("median")}
            className={selectedEngine === "median" ? "active" : null}
          >
            {"Median: " + median}
          </label>
          <label
            className={selectedEngine === "average" ? "active" : null}
            onClick={() => setSelectedEngine("average")}
          >
            {"Average: " + average}
          </label>
        </div>
        <div style={{ width: "21%" }} className="recommendation-result-container">
          <label
            className={selectedEngine === "overMedian" ? "active" : null}
            onClick={() => setSelectedEngine("overMedian")}
          >
            Expect Improvement Over Median(20%)
          </label>
          <label
            className={selectedEngine === "overAverage" ? "active" : null}
            onClick={() => setSelectedEngine("overAverage")}
          >
            Expect Improvement Over Average(20%)
          </label>
        </div>
        <div
          className="recommendation-result-container"
          style={{ width: "55%" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", gap: "1vw" }}>
            <label
              style={{ width: "50%" }}
              className={
                selectedEngine === "sameFromLastYear" ? "active" : null
              }
              onClick={() => setSelectedEngine("sameFromLastYear")}
            >
              Take The Same Monthly Actuals From Last Year
            </label>
            <label
              style={{ width: "45%" }}
              className={
                selectedEngine === "manuel" ? "active" : null
              }
              onClick={() => setSelectedEngine("manuel")}
            >
              Manuel Entry
            </label>
          </div>

          <label
            className={
              selectedEngine === "ImprovementFromBeginning" ? "active" : null
            }
            onClick={() => setSelectedEngine("ImprovementFromBeginning")}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Continuous Improvement by Setting The Beginning(20%):
            <select
              name="beginDate"
              id="beginDate"
              value={beginDateForEngine}
              onChange={(event) => setBeginDateForEngine(event.target.value)}
            >
              {targetsForRecommendation.map((target, index) => {
                const startDate = new Date(target.item.startDate);
                const diffInMonths =
                  (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
                  (currentDate.getMonth() - startDate.getMonth());
                return diffInMonths <= -1 ? (
                  <option key={index} value={target.item.startDate}>
                    {target.item.startDate}
                  </option>
                ) : null;
              })}
            </select>
          </label>
        </div>
        <div style={{ width: "32%" }}
 className="recommendation-result-container">
          <label
            style={{ width: "100%" }}
            className={
              selectedEngine === "aiArima" ? "active" : null
            }
            onClick={() => setSelectedEngine("aiArima")}
          >
            <img
              alt="engine_ico"
              src={ai_ico}
              style={{
                width: "1.5vw",
                height: "fit-content",
                marginRight: "1vw",
              }}
            ></img>
            ARIMA MODEL
            <p className="arimaModel"
                data-tip="ARIMA, geçmişteki değerler arasındaki ilişkileri kullanarak
                gelecekteki değerleri tahmin eder. Eğer verilerde sürekli yukarı veya aşağı giden bir eğilim veya belirli 
               dönemlerde tekrarlanan bir desen görüyorsanız, ARIMA bunu yakalayabilir. En azından birkaç 
               düzensiz veri örneğiniz olmalıdır.">
                  <img
              alt='question_ico'
              src={question_ico}
              style={{
                width: "1vw",
                height:"fit-content",
                cursor: "pointer",
                marginLeft: "auto"
              }}
            ></img>
            </p>
          </label>
        </div>
        <div style={{ width: "32%" }} className="recommendation-result-container">
          <label
            style={{ width: "100%" }}
            className={
              selectedEngine === "aiProphet" ? "active" : null
            }
            onClick={() => setSelectedEngine("aiProphet")}
          >
            <img
              alt="engine_ico"
              src={ai_ico}
              style={{
                width: "1.5vw",
                height: "fit-content",
                marginRight: "1vw",
              }}
            ></img>
            PROPHET MODEL
            <p className="prophetModel"
                data-tip="Prophet, özellikle kullanıcı dostu bir tahmin modelidir. Manuel parametre ayarlamalarına 
                gerek kalmadan tatmin edici sonuçlar elde edebilirsiniz. Tatil günleri, özel etkinlikler gibi 
                öngörülemeyen etkileri de dikkate alabilir.">
                  <img
              alt='question_ico'
              src={question_ico}
              style={{
                width: "1vw",
                height:"fit-content",
                cursor: "pointer",
                marginLeft: "auto"
              }}
            ></img>
            </p>
          </label>
        </div>
        <div style={{ width: "32%" }} className="recommendation-result-container">
          <label
            style={{ width: "100%" }}
            className={
              selectedEngine === "aiExponentialSmoothing" ? "active" : null
            }
            onClick={() => setSelectedEngine("aiExponentialSmoothing")}
          >
            <img
              alt="engine_ico"
              src={ai_ico}
              style={{
                width: "1.5vw",
                height: "fit-content",
                marginRight: "1vw",
              }}
            ></img>
            EXPONENTIAL SMOOTHING MODEL
            <p className="exponentialModel"
                data-tip="Bu model, geçmiş verilerin ağırlığına dayalı olarak tahmin yapar. Eğer verilerinizde belirgin bir düzen 
                varsa ve bu düzeni geleceğe taşımak istiyorsanız, Exponential Smoothing işe yarar.">
                  <img
              alt='question_ico'
              src={question_ico}
              style={{
                width: "1vw",
                height:"fit-content",
                cursor: "pointer",
                marginLeft: "auto"
              }}
            ></img>
            </p>
          </label>
        </div>
      </div>

    </div>
  )
}