import React, { useContext, useEffect, useState } from "react";
import "../styles/NewUser.css";
import { MainContext } from "../contexts/MainContext";
import { NewUserContext } from "../contexts/NewUserContext";
import showIco from "../assets/eye.png";
import hiddenIco from "../assets/hidden.png";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { LoginContext } from "../contexts/LoginContext";

export default function NewUser() {
  const [showPassword, setShowPassword] = useState(false);
  const { langFile } = useContext(MainContext);
  const {
    accountTypeList,
    GetAllAccountGroup,
    formik,
    GetRoles,
    organizationGroups,
    GetAllOrganizationGroup,
    positions,
    GetAllPositions,
  } = useContext(NewUserContext);

  const {roles} = useContext(LoginContext)

  useEffect(() => {
    GetAllAccountGroup();
    GetAllOrganizationGroup();
    GetAllPositions();
  }, [])

  


  return (
    <div className="parent-new-user">
      <div className="formbold-form-wrapper">
        <form onSubmit={formik.handleSubmit}>
          <div className="formbold-form-title">
            <h2 style={{ textAlign: "left" }}>
              {langFile.pages.NewUser.newUser}
            </h2>
            <p>{langFile.pages.NewUser.slaInfo}</p>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="firstName" className="formbold-form-label">
                {langFile.pages.NewUser.firstName}
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && formik.touched.firstName && <label className="errorLabel">{formik.errors.firstName}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="lastName" className="formbold-form-label">
                {langFile.pages.NewUser.lastName}
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && formik.touched.lastName && <label className="errorLabel">{formik.errors.lastName}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="password" className="formbold-form-label">
                {langFile.pages.NewUser.password}
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  className="formbold-form-input"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  autoComplete="off"
                />
                <span className="show-password-span">
                  <img
                    style={{ width: "20px", height: "20px" }}
                    className="trend-button tooltip-trigger"
                    alt="showIco"
                    src={showPassword ? hiddenIco : showIco}
                    onClick={() => setShowPassword(!showPassword)} />
                </span>
              </div>
              {formik.errors.password && formik.touched.password && <label className="errorLabel">{formik.errors.password}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="username" className="formbold-form-label">
                {langFile.pages.NewUser.userName}
              </label>
              <input
                type="text"
                name="username"
                id="username"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.username}
                autoComplete="off"
              />
              {formik.errors.username && formik.touched.username && <label className="errorLabel">{formik.errors.username}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="email" className="formbold-form-label">
                {langFile.pages.NewUser.email}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && <label className="errorLabel">{formik.errors.email}</label>}
            </div>
          </div>
          <div className="formbold-input-flex" >
            <div>
              <label
                htmlFor="phoneNumber"
                className="formbold-form-label"
              >
                {langFile.pages.NewUser.phoneNumber}
              </label>
              <PhoneInput
                international
                defaultCountry="TR"
                countryCallingCodeEditable={false}
                name="phoneNumber"
                id="phoneNumber"
                className="formbold-form-input"
                value={formik.values.phoneNumber}
                onChange={(value) => { formik.setFieldValue('phoneNumber', value) }}
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && <label className="errorLabel">{formik.errors.phoneNumber}</label>}
            </div>
          </div>

          <div>
            <label htmlFor="organizationGroup" className="formbold-form-label">{langFile.pages.NewUser.organizationGroup}</label>
            <select
              className="formbold-form-input"
              name="organizationGroup"
              id="organizationGroup"
              value={formik.values.organizationGroup}
              onChange={formik.handleChange}
            >
              <option value="">{langFile.pages.NewUser.selectOrganizationGroup}</option>
              {
                organizationGroups?.map((organizationGroup) => (
                  <option key={organizationGroup.id} value={organizationGroup.id}>{organizationGroup.name}</option>
                ))
              }
            </select>
          </div>

          <div>
            <label htmlFor="organization" className="formbold-form-label">{langFile.pages.NewUser.organization}</label>
            <select
              className="formbold-form-input"
              name="organization"
              id="organization"
              value={formik.values.organization}
              onChange={formik.handleChange}
            >
              <option value="">{langFile.pages.NewUser.selectOrganization}</option>
              {
                (organizationGroups?.find(group => group.id === formik.values.organizationGroup)?.organizations || [])
                  .map((organization) => (
                    <option key={organization.id} value={organization.id}>{organization.name}</option>
                  ))
              }
            </select>
          </div>

          <div>
            <label htmlFor="position" className="formbold-form-label">{langFile.pages.NewUser.position}</label>
            <select
              className="formbold-form-input"
              name="position"
              id="position"
              value={formik.values.position}
              onChange={formik.handleChange}
            >
              <option value="">{langFile.pages.NewUser.selectPosition}</option>
              {
                (positions?.filter(position => position.organizationId === formik.values.organization) || [])
                  .map((position) => (
                    <option key={position.id} value={position.id}>{position.name}</option>
                  ))
              }
            </select>
          </div>
          <div>
            <label htmlFor="selectedAccountType" className="formbold-form-label">
              {langFile.pages.NewUser.accountType}
            </label>
            <select className="formbold-form-input"
              name="selectedAccountType"
              id="selectedAccountType"
              value={formik.values.selectedAccountType}
              onChange={formik.handleChange}>
              <option key={"default-value"} value={""} >{"Please Select An Account Type"}</option>
              {accountTypeList && accountTypeList.filter(accountType => !roles.some(role => role === "Admin") ? accountType.accountOrder<100 : true).map((type, index) =>
                type.name !== "Admin" && <option key={index} title={GetRoles(type)} value={type.id} >{type.name}</option>
              )}
            </select>
            {formik.errors.selectedAccountType && formik.touched.selectedAccountType && <label className="errorLabel">{formik.errors.selectedAccountType}</label>}
          </div>
          <div className="formbold-desc-container">
            {/* {selectedAccountType?.accountTypeName === "FullAccessUser" ?
              "READS SLA, EDITS SLA, CREATES SLA, READS KPI, EDITS KPI, CREATES KPI, READS USER, EDITS USER, CREATES USER, CREATES COMPANY, IMPORT, EXPORT" :
              selectedAccountType?.accountTypeName === "StandardAccessUser" ?
                "READS SLA, EDITS SLA, CREATES SLA, READS KPI, EDITS KPI, CREATES KPI, READS USER, IMPORT, EXPORT" :
                "READS SLA, READS KPI, READS USER, EXPORT"} */}
            {accountTypeList && GetRoles(accountTypeList.find(type => type.id === formik.values.selectedAccountType))}
          </div>
          <button type="submit" className="formbold-btn">
            {langFile.pages.NewCustomer.registerBtn}
          </button>
        </form>
      </div>
    </div>
  );
}
