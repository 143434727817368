import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Rose } from '@ant-design/plots';

export default function RoseStatistic () {
  const data = [
    {
      type: 'Critical',
      value: 27,
    },
    {
      type: 'Major',
      value: 25,
    },
    {
      type: 'Minor',
      value: 18,
    },
  ];
  const config = {
    data,
    xField: 'type',
    yField: 'value',
    seriesField: 'type',
    radius: 1,
    legend: {
      position: 'bottom',

    },
  };
  return <Rose style={{width:"100%", height:"80%"}} {...config} />;
};

