import React from 'react'
import "./PopUpAlert.css"
import alert_ico from "../../assets/alert.png";

export default function PopUpAlert({ text, func, setIsPopUp, body }) {
    return (
        <div className='pop-up-alert-parent'>
            <div className='pop-up-alert-container'>
                <div className='ico-part'>
                    <img alt='alert-ico' className='ico-part-img' src={alert_ico}></img>
                </div>
                <div className='explain-part'>
                    <label className='pop-up-text'>
                        {text}
                    </label>
                </div>
                <div className='button-part'>
                    <button onClick={() => { setIsPopUp(false) }}>
                        Cancel
                    </button>
                    <button onClick={() => { setIsPopUp(false); func(body) }}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    )
}
