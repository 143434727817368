import React, { useState, useRef } from "react";

export const NewAgreementContext = React.createContext();

const NewAgreementContextProvider = (props) => {

    const [allChosen, setAllChosen] = useState([]);
    const [metadataSide, setMetadataSide] = useState(true);
    const [basketSide, setBasketSide] = useState(true);
    const [metadataDone, setMetadataDone] = useState(false);
    const [editAlert, setEditAlert] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [agreementTypes, setAgreementTypes] = useState([])
    const [targetType, setTargetType] = useState([]);
    const [priority, setPriority] = useState([])
    const [emailComponents, setEmailComponents] = useState(true)
    return (
        <NewAgreementContext.Provider value={{
            allChosen,
            setAllChosen,
            metadataSide,
            setMetadataSide,
            basketSide,
            setBasketSide,
            metadataDone,
            setMetadataDone,
            editAlert,
            setEditAlert,
            deleteAlert,
            setDeleteAlert,
            agreementTypes,
            setAgreementTypes,
            targetType,
            setTargetType,
            priority,
            setPriority,
            emailComponents,
            setEmailComponents
        }}>
            {props.children}
        </NewAgreementContext.Provider>
    )
}
export default NewAgreementContextProvider;