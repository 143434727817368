import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Kpis.css";
import KpiEdit from "../components/KpiEdit";
import KpiDetail from "../components/KpiDetail";
import { CustomerDetailContext } from "../contexts/CustomerDetailContext";
import { CustomersContext } from "../contexts/CustomersContext";
import { MainContext } from "../contexts/MainContext";
import { KpisContext } from "../contexts/KpisContext";
import addKpiToSlaIco from "../assets/addToSla.png";
import plus_ico from "../assets/plus-2.png";
import deleteIco from "../assets/delete.png"
import showIco from "../assets/eye.png"
import { LoginContext } from "../contexts/LoginContext";
import PopUpAlert from "../components/PopUpAlert/PopUpAlert";
import right_arrow from "../assets/right-arrow-2.png"

export default function Kpis() {
  const navigate = useNavigate();
  const { selectedSla,
    setKpisSideTrigger, } = useContext(CustomerDetailContext);
  const { langFile, setAlertList, AddAlert } = useContext(MainContext);
  const { token, user, roles } = useContext(LoginContext)
  const { isDeletePopUp, setIsDeletePopUp } = useContext(CustomersContext);
  const { kpiEditSide,
    setKpiEditSide,
    iskpiDetail,
    setIskpiDetail,
    updateTrigger,
    setUpdateTrigger,
    addKpiBox,
    setAddKpiBox,
    kpis,
    setKpis,
    selectedKpi,
    setSelectedKpi,
    selectedKpiIndex,
    setSelectedKpiIndex,
    kpiIdToAdd,
    setKpiIdToAdd,
    kpiInfoToAdd,
    setKpiInfoToAdd,
    ResetContext } = useContext(KpisContext);

  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      ResetContext()
    };
  }, []);

  useEffect(() => {
    if (kpiIdToAdd !== "") {
      if (roles.some(role => role === "KpiRead")) {

        fetch(
          process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_KPI_BY_ID + "?KpiGuid=" + kpiIdToAdd,
          {
            method: "GET",
            headers: {
              "Accept": "application/json",
              "Authorization": "Bearer " + token,
            },
          }
        )
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            setKpiInfoToAdd(data.kpi[0])
            return data.kpi[0];
          })
          .catch((error) => {
            console.log(error);
          });
      }
      else {
        AddAlert("warning", "You do not have authorization for this operation.")
      }
    }

  }, [kpiIdToAdd])

  useEffect(() => {
    setIskpiDetail(false);
  }, [selectedSla]);

  function AddToSlaHandle() {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_ADD_KPI_TO_SLA, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        kpiId: kpiIdToAdd,
        slaId: selectedSla.agreementGuid,
        accountId: user.id
      }),
    })
      .then((res) => {
        if (res.ok) {
          AddAlert("success", "Adding KPI to SLA is Succesful")
          setUpdateTrigger(!updateTrigger)
          return res;
        }
        throw res;
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        AddAlert("fail", "Adding KPI to SLA is fail")
        console.log(error);
      });
  }

  function GetKpiAndTarget() {
    if (roles.some(role => role === "KpiRead") ) {

      fetch(
        process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_KPI_AND_TARGET + "?SlaId=" +
        selectedSla.agreementId + "&Deleted=false&PageSize=9999&Page=1",
        {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res.jon();
        })
        .then((data) => {
          setKpis(data.calculationResults.kpiList)
          selectedKpiIndex !== null
            ? setSelectedKpi(data.calculationResults.kpiList[selectedKpiIndex])
            : setSelectedKpi(null);
          return data.calculationResults;
        })
        .catch((error) => {
          setKpis([])
          setSelectedKpi(null)
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  useEffect(() => {
    GetKpiAndTarget()
  }, [updateTrigger, selectedSla]);

  function DeleteKpi(body) {
    if (roles.some(role => role === "KpiDelete")) {

      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_DELETE_KPI_FROM_SLA_BY_ID, {
        method: "PUT",
        headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify({
          guid: body.kpiGuid,
          accountGuid: body.accountGuid
        })
      })
        .then((res) => {
          GetKpiAndTarget()
          if (res.ok && res.status === 200) {
            return res;
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res.json();
        })
        .then((data) => {
          if (data.status === 200) {


            AddAlert("success", "Delete KPI is Successful")
            return data;
          }
          else {
            AddAlert("error", "Delete KPI is Failed")
          }
        })
        .catch((error) => {
          AddAlert("error", "Delete KPI is Failed")
          console.log(error)
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  return (
    <div className="parent-container-kpis">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          position: "relative",
          marginLeft: "1vw"
        }}
      >
        <img alt="close-contaienr-img" src={right_arrow}
          style={{ zIndex: "2", width: "1.5vw", height: "fit-content", position: "absolute", left: ".5vw", top: "1.25vh", cursor: "pointer" }}
          onClick={() => setKpisSideTrigger(false)}>

        </img>
        <div
          className="add-kpi-box-parent"
          style={{
            minHeight: (addKpiBox === false || kpiInfoToAdd === "") ? "4.2vh" : "10vh",
          }}
        >
          <div className="input-part">
            <input type="text" placeholder="Enter KPİ's Id" id="kpiIdToAdd"
              onChange={(event) => setKpiIdToAdd(event.target.value)}
              style={{ width: addKpiBox === false ? "0%" : "" }}
            />
          </div>
          {kpiInfoToAdd.name !== undefined ?
            <div
              className="info-part"
              style={{ height: (addKpiBox === false || kpiInfoToAdd === "") ? "0vh" : "5vh" }}
            >
              <label>
                {langFile.pages.Kpis.kpiName}
              </label>
              <label
              >
                {kpiInfoToAdd.name !== undefined ? kpiInfoToAdd.name : null}
              </label>
              <div style={{ display: "flex", flexDirection: "row", height: "100%", width: "10%", alignItems: "center" }}>
                <div className="tooltip-container">
                  <img
                    alt="add-kpi"
                    className="add-kpi-ok tooltip-trigger"
                    onClick={AddToSlaHandle}
                    src={addKpiToSlaIco}
                  ></img>
                  <div style={{
                    bottom: "-.5vh",
                    right: "2vw",
                    width: "4vw"
                  }} className="tooltip-content">Add KPI</div>
                </div>
              </div>
            </div>
            : null}
        </div>
        {roles.some(role => role === "KpiCreate") &&
        <button className="add-kpi-button"
          onClick={() => { setAddKpiBox(!addKpiBox); setIskpiDetail(false) }}
          style={{ borderRadius: addKpiBox ? "0px 5px 5px 0px " : "" }}
        >
          <img
            alt="add-kpi"
            className="add-kpi-img"
            src={plus_ico}
          ></img>
          {langFile.pages.Kpis.addNewKpi}
        </button>
      }
        <table
          className={
            (iskpiDetail === true)
              ? "container-kpis container-kpis-side"
              : (iskpiDetail === false && addKpiBox === true && kpiInfoToAdd !== "") ? "container-kpis container-kpis-side-2" : "container-kpis"
          }
        >
          <thead>

            <tr>
              <th>
                {langFile.pages.Kpis.kpiSetName}
              </th>
              <th>
                {langFile.pages.Kpis.kpiType}

              </th>
              <th>
                {langFile.pages.Kpis.kpiName}
              </th>
              <th></th>
              {/* <th>
                  {langFile.pages.Kpis.completedTargetValue}
                </th> */}
              {/* <th>
                  {langFile.pages.Kpis.value}
                </th> */}

            </tr>
          </thead>
          <tbody>
            {kpis?.map((kpi, index) => (
              <React.Fragment key={index}>
                <tr
                  style={{ backgroundColor: selectedKpi === kpi ? "var(--tableRowHover)" : "" }}
                  key={index}
                >
                  <td>{kpi?.name}</td>
                  <td>{kpi?.priority.name}</td>
                  <td>{kpi?.name}</td>
                  <td>
                    <div className="tooltip-container">
                      {roles.some(role => role === "KpiRead") &&
                        <img
                          className="trend-button tooltip-trigger"
                          alt="showIco"
                          src={showIco}
                          onClick={() => {
                            setSelectedKpi(kpi);
                            setSelectedKpiIndex(index);
                            setIskpiDetail(!iskpiDetail);
                            setAddKpiBox(false)
                          }}
                          style={{
                            filter: "invert(var(--filterRatio))",
                            WebkitFilter: "invert(var(--filterRatio))",
                          }} />
                      }
                      {roles.some(role => role === "KpiDelete") &&
                      <img
                        className="trend-button tooltip-trigger"
                        alt="deleteIco"
                        src={deleteIco}
                        onClick={() => {
                          setIsDeletePopUp(kpi.kpiId)
                        }}
                        style={{
                          filter: "invert(var(--filterRatio))",
                          WebkitFilter: "invert(var(--filterRatio))",
                        }} />
                      }
                    </div>

                  </td>
                  <td style={{ width: "0%" }}>
                    {isDeletePopUp === kpi.kpiId && <PopUpAlert key={"pop-up" + index} text={"Do you wanna delete " + kpi.kpiName + " ?"} func={DeleteKpi} setIsPopUp={setIsDeletePopUp} body={{ kpiGuid: kpi.kpiId, accountGuid: user.id }} ></PopUpAlert>}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div
          className={
            (iskpiDetail === true)
              ? "kpi-detail-parent"
              : "kpi-detail-parent kpi-detail-parent-side"
          }
        >
          {selectedKpi &&
            selectedKpi.targets.length !== 0 ? (
            <KpiDetail></KpiDetail>
          ) : null}
        </div>

      </div>
      {selectedKpi &&
        selectedKpi.targets.length !== 0 ? (
        <KpiEdit></KpiEdit>
      ) : null}
    </div>
  );
}
