import React, { useContext } from 'react'
import "./CompanyDetail.css"
import { CompanyGroupContext } from '../../contexts/CompanyGroupContext';
import { MainContext } from '../../contexts/MainContext';

export default function CompanyDetail() {
    const { langFile, setAlertList, AddAlert } = useContext(MainContext);
    const { selectedCompany } = useContext(CompanyGroupContext);
    const { isCompanyDetail } = useContext(CompanyGroupContext);
    return (
        <div className={"company-detail-parent"}>
            <table className="container-company-detail">
                <thead>
                    <tr>
                        <th>{langFile.pages.NewCompany.name}</th>
                        <td>{selectedCompany.name}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.email}</th>
                        <td>{selectedCompany.email}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.phoneNumber}</th>
                        <td>{selectedCompany.phoneNumber}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.address}</th>
                        <td>{selectedCompany.address}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.website}</th>
                        <td>{selectedCompany.website}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.contactName}</th>
                        <td>{selectedCompany.contactName}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.contactEmail}</th>
                        <td>{selectedCompany.contactEmail}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.contactPhoneNumber}</th>
                        <td>{selectedCompany.contactPhoneNumber}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.contactAddress}</th>
                        <td>{selectedCompany.contactAddress}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.contact2Name}</th>
                        <td>{selectedCompany.contact2Name}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.contact2Email}</th>
                        <td>{selectedCompany.contact2Email}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.contact2PhoneNumber}</th>
                        <td>{selectedCompany.contact2PhoneNumber}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.contact2Address}</th>
                        <td>{selectedCompany.contact2Address}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.taxCode}</th>
                        <td>{selectedCompany.taxCode}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.fax}</th>
                        <td>{selectedCompany.fax}</td>
                    </tr>
                    <tr>
                        <th>{langFile.pages.NewCompany.description}</th>
                        <td>{selectedCompany.description}</td>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

