import React, { useState } from "react";
import "../styles/Alert.css"
import succes_ico from "../assets/success.png"
import warning_ico from "../assets/warning.png"
import info_ico from "../assets/info.png"
import notice_ico from "../assets/notice.png"
import error_ico from "../assets/error.png"


export default function Alert(props) {
  const [close, setClose] = useState(false);

  setTimeout(function () {
    setClose(true);
  }, props.time);
  return (
    <div className={"alert-container " + props.status} style={{ borderWidth: (close === true ? "0vh" : null), marginBottom: (close === true ? "0vh" : null), height: (close === true ? "0vh" : null) }}>
      <div className="alert-container-header">
        <img alt="alert" src={props.status === "success" ? succes_ico : props.status === "warning" ? warning_ico :
          props.status === "info" ? info_ico : props.status === "notice" ? notice_ico : props.status === "error" ? error_ico : null} style={{ width: "1.5vw", marginRight: "1vw" }}></img>
        {props.text}
      </div>
    </div>
  );
}
