import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";
import { EditSlaContext } from "./EditSlaContext";
import { CustomerDetailContext } from "./CustomerDetailContext";

export const SignAgreementContext = React.createContext();

const SignAgreementContextProvider = (props) => {
    const navigate = useNavigate()
    
    const { token, user } = useContext(LoginContext)
    const { toEditSla, setToEditSla, users, owner } = useContext(EditSlaContext)
    const [selectedUsersForSign,setSelectedUsersForSign] = useState([])
    const [isSignPopUp, setIsSignPopUp] = useState(false)
    const {
      setIsOpenEditSla,
      getAllSlaByCompanyIdTrigger,
      setGetAllSlaByCompanyIdTrigger
    } = useContext(CustomerDetailContext);


      function VerifiedSla(body) {
        return new Promise((resolve) => {
          fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_VERIFIED_SLAS, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + token,
            },
            body: JSON.stringify(body),
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw res;
            })
            .then((data) => {
              setGetAllSlaByCompanyIdTrigger(!getAllSlaByCompanyIdTrigger)
              resolve(true);
              return data;
            })
            .catch((error) => {
              resolve(false);
              console.log(error.json());
            });
        });
      }

      function UpdateVerifiedSla(body) {
        return new Promise((resolve) => {
          fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_UPDATE_VERIFIED_SLA, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + token,
            },
            body: JSON.stringify(body),
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw res;
            })
            .then((data) => {
              setGetAllSlaByCompanyIdTrigger(!getAllSlaByCompanyIdTrigger)
              resolve(true);
              return data;
            })
            .catch((error) => {
              resolve(false);
              console.log(error.json());
            });
        });
      }

      function DeleteVerifiedSla(verifyId){
        return new Promise((resolve) => {
          fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_VERIFIED_SLA +"/"+verifyId, {
            method: "DELETE",
            headers: {
              "Accept": "application/json",
              "Authorization": "Bearer " + token,
            },
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw res;
            })
            .then((data) => {
              setGetAllSlaByCompanyIdTrigger(!getAllSlaByCompanyIdTrigger)
              resolve(true);
              return data;
            })
            .catch((error) => {
              resolve(false);
              console.log(error.json());
            });
        });
      }

    return (
        <SignAgreementContext.Provider value={{
            VerifiedSla,
            selectedUsersForSign,
            setSelectedUsersForSign,
            setIsSignPopUp,
            isSignPopUp,
            UpdateVerifiedSla,
            owner,
            DeleteVerifiedSla
           }}>
            { props.children }
        </SignAgreementContext.Provider>
    );
}

export default SignAgreementContextProvider;