import React, { useContext, useEffect, useState } from "react";
import "../styles/UserBar.css";
import userIco from "../assets/user-4.png";
import notification from "../assets/notification-bell.png";
import upArrow from "../assets/up-arrow.png";
import downArrow from "../assets/down-arrow.png"
import ThemaMode from "../components/themaToggle/ThemaMode.js"
import LangDropdown from "./LangDropdown.js"
import { MainContext } from "../contexts/MainContext";
import { LoginContext } from "../contexts/LoginContext";
import NotificationMain from "./NotificationComponents/NotificationMain";

export default function UserBar() {
  const {
    profileComp,
    setProfileComp } = useContext(MainContext);

  const { user } = useContext(LoginContext);
  return (
    <div className="user-bar-parent">
      <div className="page-style">
        <div className="icons-userbar">
          {/* <LangDropdown></LangDropdown> */}
          {/* <ThemaMode></ThemaMode> */}
        
          {/* <img className="logo-userbar" alt="user" src={notification} />  */}
          <NotificationMain></NotificationMain>
        </div>
        {/* <img className="logo-userbar" alt="user" src={userIco} /> */}

        <label style={{ color: "var(--textColor)", display: "flex", fontSize: "0.8vw", fontWeight: "normal" }}>{(user?.firstname)+" "+(user?.lastname)}</label>

        <button style={{ border: "none", background: "transparent", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() =>
          setProfileComp(!profileComp)
        }>
          <img className="arrow-ico-userbar" alt="arrow-up" src={profileComp === false ? downArrow : upArrow} />
        </button>
      </div>
    </div>
  );
}
