import React, { useContext, useEffect, useState } from 'react'
import "../styles/Settings.css"
import settings_ico from "../assets/settings-2.png";
import { MainContext } from '../contexts/MainContext';
import { SettingsContext } from '../contexts/SettingsContext';
import Navigation from '../components/Navigation';
import SettingsMain from '../components/SettingsComponents/SettingsMain';
import ThemaMode from '../components/themaToggle/ThemaMode';



export default function Settings() {

  const { langFile } = useContext(MainContext);
  const { GetSettingsType, 
     } = useContext(SettingsContext);
  
  useEffect(()=>{
    GetSettingsType()
  },[])

  return (
    <div className='settings-parent'>
      <div className="navigation-container">
        <Navigation img={settings_ico} text_1={langFile.pages.Settings.settings}
          text_2={langFile.pages.Settings.settingsPath}></Navigation>
      </div>
      <div className='settings-container'>
        <SettingsMain></SettingsMain>
      </div>
    </div>
  )
}
