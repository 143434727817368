import React, { useContext } from 'react'
import "./SettingsFrame.css"
import { SettingsContext } from '../../contexts/SettingsContext';
import ThemaMode from '../themaToggle/ThemaMode';
import LangDropdown from '../LangDropdown';
import { LoginContext } from '../../contexts/LoginContext';

export default function SettingsFrame() {
  const {
    setUserSettings,
    settingsTypeList,
    activeSettingsType,
    programSettings, userSettings, setProgramSettings, Save } = useContext(SettingsContext);

  const { roles } = useContext(LoginContext)
  const validTypes = ["range", "checkbox", "date", "number", "text"];

  function handleInputChange(settingId, newValue, settingType) {
    let temp = []
    if (settingType === "user") {
      temp = JSON.parse(JSON.stringify(userSettings))
    }
    else {
      temp = JSON.parse(JSON.stringify(programSettings))
    }
    for (let j = 0; j < temp.length; j++) {
      for (let i = 0; i < temp[j].programSettings.length; i++) {
        if (temp[j].programSettings[i].id === settingId) {
          temp[j].programSettings[i].value = newValue
          if (settingType === "user") {
            setUserSettings(temp)
          }
          else {
            setProgramSettings(temp)
          }
          return;
        }
      }
      for (let i = 0; i < temp[j].userSettings.length; i++) {
        if (temp[j].userSettings[i].id === settingId) {
          temp[j].userSettings[i].value = newValue
          if (settingType === "user") {
            setUserSettings(temp)
          }
          else {
            setProgramSettings(temp)
          }
          return
        }
      }
    }
  };


  return (
    <div className='settings-frame-parent'>
      <label className='active-settings-type-header'>
        {activeSettingsType.name}
      </label>
      <hr></hr>
      {userSettings.map((setting) => (
        setting.userSettings.map((userSettings, index, array) => (
          index === array.length - 1 && activeSettingsType.id === setting.settingsType.id && (
            <div key={userSettings.id} className='setting-container'>
              <label className='setting-header'>
                {setting.name}
              </label>
              <label className='description'>
                {setting.description}
              </label>
              {
                setting.name === "Theme" ?
                  <ThemaMode backgroundColor="var(--backgroundColor2)"></ThemaMode>
                  :
                  setting.name === "Language" ?
                    <LangDropdown backgroundColor="var(--backgroundColor2)"></LangDropdown>
                    :
                    setting.settingsDataType.name.split("-")[1]?.toLowerCase() === "radio" &&
                    setting.values.map((value, index_2) => (
                      <label
                        className='radio-label'
                        key={index_2}
                        htmlFor={value}>
                        <input
                          onChange={(e) => handleInputChange(userSettings.id, e.target.value, "user")}
                          checked={userSettings.value === value}
                          id={value}
                          type="radio"
                          name={"radio" + setting.id}
                          value={value} />
                        {value}
                      </label>
                    ))
              }
              {setting.settingsDataType.name.split("-")[1]?.toLowerCase() === "range" &&
                <label className='range-value'>{userSettings.value}</label>
              }
              {
                (validTypes.includes(setting.settingsDataType.name.split("-")[1]?.toLowerCase())) &&
                <input
                  name={setting.name}
                  id={setting.id}
                  type={setting.settingsDataType.name.split("-")[1]?.toLowerCase()}
                  max={setting.max}
                  min={setting.min}
                  defaultValue={userSettings.value}
                  onChange={(e) => handleInputChange(userSettings.id, e.target.value, "user")}
                >
                </input>
              }
            </div>)
        )))
      )}


      { // roles.some(role => role === "Admin") &&
        programSettings.map((setting) =>
          setting.programSettings.map((programSetting, index, array) =>
            index === array.length - 1 && activeSettingsType.id === setting.settingsType.id &&
            <React.Fragment key={programSetting.id}>
              <div className="setting-container">
                <label className="setting-header">
                  {setting.name}
                </label>
                <label className="description">
                  {setting.description}
                </label>
                {
                  setting.name === "thema" ? (
                    <ThemaMode backgroundColor="var(--backgroundColor2)"></ThemaMode>
                  ) : setting.name === "language" ? (
                    <LangDropdown></LangDropdown>
                  )
                    :

                    setting.settingsDataType.name.split("-")[1]?.toLowerCase() === "radio" &&
                    setting.values.map((value, index_2) => (
                      <label className="radio-label" key={index_2} htmlFor={value}>
                        <input
                          checked={programSetting.value === value}
                          onChange={(e) => {
                            handleInputChange(
                              programSetting.id,
                              e.target.value,
                              "program",
                            );
                          }}
                          id={value}
                          type="radio"
                          name={"radio" + setting.id}
                          value={value}
                        />
                        {value}
                      </label>
                    ))
                }

                {
                  setting.settingsDataType.name.split("-")[1]?.toLowerCase() === "range" && (
                    <label className="range-value">{programSetting.value}</label>

                  )}

                {validTypes.includes(
                  setting.settingsDataType.name.split("-")[1]?.toLowerCase(),
                ) && (
                    <input
                      name={setting.name}
                      id={setting.id}
                      type={setting.settingsDataType.name.split("-")[1]?.toLowerCase()}
                      max={setting.max}
                      min={setting.min}
                      defaultValue={programSetting.value}
                      onChange={(e) =>
                        handleInputChange(
                          programSetting.id,
                          e.target.value,
                          "program",
                        )
                      }
                    ></input>
                  )}
              </div>
              <button onClick={() => { Save(programSettings) }} className='save-button'>Save</button>
            </React.Fragment>
          )
        )}
    </div>
  )
}
