import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/plots';
import { DashboardContext } from '../contexts/DashboardContext';

export default function HorizontalBarChart (props) {
  const { selectedCompany,
    setSelectedCompany } = useContext(DashboardContext)

  const data = props.data;
  const config = {
    data,
    isGroup: true,
    xField: 'value',
    yField: 'label',

    /** 自定义颜色 */
    color: ['#263644',
    "#285cb0", '#cacedc'],
    seriesField: 'type',
    marginRatio: 0,
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        }, 
        {
          type: 'interval-hide-overlap',
        }, 
        {
          type: 'adjust-color',
        },
      ],
    },
    barStyle: {
      radius: [20, 20, 0, 0],
    },
    barWidthRatio: 0.4, // You can change this value to control bar width
  };
  
  return <Bar style={{width:"100%", height:"100%"}} {...config}
  onReady={(plot) => {
    plot.on('plot:click', (evt) => {
      const { x, y } = evt;
      const { xField } = plot.options;
      const tooltipData = plot.chart.getTooltipItems({ x, y });
      setSelectedCompany(tooltipData[0].title)
    });
  }} />;
};

