import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewAgreement from "./pages/NewAgreement";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Customers from "./pages/Customers";
import CustomerDetail from "./pages/CustomerDetail";
import NewCustomer from "./pages/NewCustomer";
import Dashboard from "./pages/Dashboard";
import DashboardOld from "./pages/DashboardOld";
import Import from "./pages/Import";
import Export from "./pages/Export";
import MyUsers from "./pages/MyUsers";
import NewCompany from "./pages/NewCompany";
import AllKpis from "./pages/AllKpis";
import { useContext, useEffect } from "react";

import MainContextProvider from "./contexts/MainContext";
import LoginContextProvider, { LoginContext } from "./contexts/LoginContext";
import CustomersContextProvider from "./contexts/CustomersContext";
import CustomerDetailContextProvider from "./contexts/CustomerDetailContext";
import KpisContextProvider from "./contexts/KpisContext";
import KpiEditContextProvider from "./contexts/KpiEditContext";
import AllKpisContextProvider from "./contexts/AllKpisContext";
import MyUsersContextProvider from "./contexts/MyUsersContext";
import NewUserContextProvider from "./contexts/NewUserContext";
import EditUserContextProvider from "./contexts/EditUserContext";
import NewAgreementContextProvider from "./contexts/NewAgreementContext";
import NewSlaMetaDataContextProvider from "./contexts/NewSlaMetaDataContext";
import ImportContextProvider from "./contexts/ImportContext";
import ImportTableContextProvider from "./contexts/ImportTableContext";
import NewCustomerContextProvider from "./contexts/NewCustomerContext";
import ProfileCardContextProvider from "./contexts/ProfileCardContext";
import CopySlaContextProvider from "./contexts/CopySlaContext";
import SettingsContextProvider from "./contexts/SettingsContext";
import SmallProfileCardContextProvider from "./contexts/SmallProfileCardContext"
import Settings from "./pages/Settings";
import DashboardContextProvider from "./contexts/DashboardContext";
import Organization from "./pages/Organization";
import Services from "./pages/Services"
import OrganizationContextProvider from "./contexts/OrganizationContext";
import EditSlaContextProvider from "./contexts/EditSlaContext";
import NotFound from "./pages/NotFound";
import UpdateKpiDateContextProvider from "./contexts/UpdateKpiDateContext";
import ServiceContextProvider from "./contexts/ServiceContext";
import NewService from "./pages/NewService";
import ForgotPassword from "./pages/ForgotPassword";
import ChatbotContextProvider from "./contexts/ChatbotContext";
import BasketContextProvider from "./contexts/BasketContext";
import NewCompanyContextProvider from "./contexts/NewCompanyContext";
import Companies from "./pages/Companies";
import CompanyGroupContextProvider from "./contexts/CompanyGroupContext";
import EditCompanyContextProvider from "./contexts/EditCompanyContext";
import ThemaContextProvider, { ThemaContext } from "./contexts/ThemaContext";
import SignAgreementContextProvider from "./contexts/SignAgreementContext";
import MailContextProvider from "./contexts/MailContext";
import ForgotPasswordContextProvider from "./contexts/ForgotPasswordContext";
import NotificationContextProvider from "./contexts/NotificationContext";


function App() {

  return (
    <BrowserRouter >
      <MainContextProvider>
        <ThemaContextProvider>
          <LoginContextProvider>
            <MailContextProvider>
              <SettingsContextProvider>
                <ForgotPasswordContextProvider>
                  <ProfileCardContextProvider>
                    <NotificationContextProvider>
                      <DashboardContextProvider>
                        <OrganizationContextProvider>
                          <ServiceContextProvider>
                            <NewAgreementContextProvider>
                              <NewSlaMetaDataContextProvider>
                                <ChatbotContextProvider>
                                  <BasketContextProvider>
                                    <CustomersContextProvider>
                                      <NewCompanyContextProvider>
                                        <EditCompanyContextProvider>
                                          <CompanyGroupContextProvider>
                                            <KpisContextProvider>
                                              <KpiEditContextProvider>
                                                <CopySlaContextProvider>
                                                  <UpdateKpiDateContextProvider>
                                                    <CustomerDetailContextProvider>
                                                      <EditSlaContextProvider>
                                                        <NewUserContextProvider>
                                                          <EditUserContextProvider>
                                                            <SmallProfileCardContextProvider>
                                                              <MyUsersContextProvider>
                                                                <ImportContextProvider>
                                                                  <ImportTableContextProvider>
                                                                    <AllKpisContextProvider>
                                                                      <NewCustomerContextProvider>
                                                                        <SignAgreementContextProvider>
                                                                          <Routes>
                                                                            <Route path="" element={<Login />} />
                                                                            <Route path="/login" element={<Login />} />
                                                                            <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
                                                                            <Route path="/forgotpassword/:resetcode" element={<ForgotPassword />}></Route>
                                                                            <Route path="/main" element={<Main />}>
                                                                              <Route path="dashboard" element={
                                                                                <Dashboard />
                                                                              }></Route>
                                                                              <Route path="dashboard-old" element={<DashboardOld />}></Route>
                                                                              <Route path="organization" element={
                                                                                <Organization />
                                                                              }></Route>
                                                                              <Route path="services" element={
                                                                                <Services />
                                                                              }></Route>
                                                                              <Route path="newservice" element={
                                                                                <NewService />
                                                                              }></Route>
                                                                              <Route path="newAgreement" element={
                                                                                <NewAgreement></NewAgreement>
                                                                              }></Route>
                                                                              <Route path="customers" element={
                                                                                <Customers />
                                                                              }></Route>
                                                                              <Route path="newcompany" element={
                                                                                <NewCompany />
                                                                              }></Route>
                                                                              <Route path="companies" element={
                                                                                <Companies></Companies>
                                                                              }></Route>
                                                                              <Route path="customer-detail" element={
                                                                                <CustomerDetail />
                                                                              }></Route>
                                                                              <Route path="myUsers" element={
                                                                                <MyUsers></MyUsers>
                                                                              }></Route>
                                                                              <Route path="import" element={
                                                                                <Import />
                                                                              }></Route>
                                                                              <Route path="export" element={<Export />}></Route>
                                                                              <Route path="all-kpis" element={
                                                                                <AllKpis />
                                                                              }></Route>
                                                                              <Route path="newcustomer" element={
                                                                                <NewCustomer />
                                                                              }></Route>
                                                                              <Route path="settings" element={
                                                                                <Settings />
                                                                              }></Route>
                                                                            </Route>
                                                                            <Route path="*" element={<NotFound></NotFound>} />
                                                                          </Routes>
                                                                        </SignAgreementContextProvider>
                                                                      </NewCustomerContextProvider>
                                                                    </AllKpisContextProvider>
                                                                  </ImportTableContextProvider>
                                                                </ImportContextProvider>
                                                              </MyUsersContextProvider>
                                                            </SmallProfileCardContextProvider>
                                                          </EditUserContextProvider>
                                                        </NewUserContextProvider>
                                                      </EditSlaContextProvider>
                                                    </CustomerDetailContextProvider>
                                                  </UpdateKpiDateContextProvider>
                                                </CopySlaContextProvider>
                                              </KpiEditContextProvider>
                                            </KpisContextProvider>
                                          </CompanyGroupContextProvider>
                                        </EditCompanyContextProvider>
                                      </NewCompanyContextProvider>
                                    </CustomersContextProvider>
                                  </BasketContextProvider>
                                </ChatbotContextProvider>
                              </NewSlaMetaDataContextProvider>
                            </NewAgreementContextProvider>
                          </ServiceContextProvider>
                        </OrganizationContextProvider>
                      </DashboardContextProvider>
                    </NotificationContextProvider>
                  </ProfileCardContextProvider>
                </ForgotPasswordContextProvider>
              </SettingsContextProvider>
            </MailContextProvider>
          </LoginContextProvider>
        </ThemaContextProvider>
      </MainContextProvider>
    </BrowserRouter>
  );
}
export default App;
