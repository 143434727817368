import React, { useContext, useEffect, useState } from "react";
import "../../styles/AllKpisComponent/AllKpisDetail.css";
import { AllKpisContext } from "../../contexts/AllKpisContext";
import { MainContext } from "../../contexts/MainContext";
import down_arrow_ico from "../../assets/down-arrow.png"

export default function AllKpisDetail() {
  const {
    selectedKpi,
    filters,
    setIskpiDetail
  } = useContext(AllKpisContext);
  const { langFile, AddAlert } = useContext(MainContext);


  function getDate(startDate, mounthNumber) {
    const start = startDate.split("-");
    let yearCounter = parseInt(
      (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) / 12
    );
    let mounthCounter = parseInt(
      (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) % 12
    );
    start[1] = mounthCounter;
    start[0] = yearCounter;
    if (mounthCounter === 0) {
      start[1] += 12;
      start[0] -= 1;
    }
    if (start[1] < 10) {
      return String(start[0]) + "-0" + String(start[1]);
    } else {
      return String(start[0]) + "-" + String(start[1]);
    }
  }
  return (
    <div className="all-kpis-detail-container">
      <div className="all-kpis-detail-container-part1">
        <div className="all-kpis-detail-container-part1-column-item">
          {langFile.pages.AllKpis.AllKpisDetail.kpiName}
        </div>
        <div className="all-kpis-detail-container-part1-column">
          <div className="all-kpis-detail-container-part1-column-item">
            {selectedKpi ? selectedKpi.name : null}
          </div>
        </div>
        <img onClick={()=>setIskpiDetail(false)} alt="close" className="close-kpi-detail-ico" src={down_arrow_ico}></img>
      </div>
      <div className="all-kpis-detail-container-part2">
        <table >
          <thead >
            <tr>
              <th >
                {langFile.pages.AllKpis.AllKpisDetail.startDate}
              </th>
              <th >
                {langFile.pages.AllKpis.AllKpisDetail.endDate}
              </th>
              <th >
                {langFile.pages.AllKpis.AllKpisDetail.kpiType}
              </th>
              <th >
                {langFile.pages.AllKpis.AllKpisDetail.kpiWeight}
              </th>
              <th>
                {langFile.pages.AllKpis.AllKpisDetail.targetType}
              </th>
              <th >
                {langFile.pages.AllKpis.AllKpisDetail.targetValue}
              </th>
              <th >
                {langFile.pages.AllKpis.AllKpisDetail.completedTargetValue}
              </th>
              <th >
                {langFile.pages.AllKpis.AllKpisDetail.range}
              </th>
            </tr>
          </thead>
          <tbody className="all-kpis-detail-tbody">

            {selectedKpi?.targets.map((target, index) => {

              if (
                ((filters.Numeric && target.isPercentage === false) ||
                  (filters.Percentage && target.isPercentage === true))
                // &&
                // ((filters.Successful && target.isCompleted === true) || 
                // (filters.Fail && target.isCompleted === false) )
              ) {
                return (
                  <tr key={index}>
                    {/* <td className="all-kpis-detail-td">
                  {target.isCompleted ===true ? (
                    <div className="triangle-up"></div>
                  ) : (
                    <div className="triangle-down"></div>
                  )
                  }
                </td> */}
                    <td >
                      {target.targetDate.substring(0, 7)}
                    </td>
                    <td >
                      {getDate(target.targetDate.substring(0, 7), 1)}
                    </td>
                    <td >
                      {selectedKpi?.priority.name}
                    </td>
                    <td >
                      {target.weight}</td>
                    <td >
                      {target.isPercentage === true ? "Percentage" : "Numeric"}
                    </td>
                    <td >
                      {target.value}</td>
                    <td >
                      {target.completedValue}
                    </td>
                    <td >
                      {target.targetType.name}
                    </td>
                  </tr>)
              } else {
                return null;
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
