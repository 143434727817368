import React, { useContext } from 'react'
import "./SettingsMenuBar.css"
import { SettingsContext } from '../../contexts/SettingsContext';

export default function SettingsMenuBar() {
  const {activeSettingsType,
    setActiveSettingsType,
    settingsTypeList } = useContext(SettingsContext);
    
  
  return (
    <div className='settings-menu-parent'>
        {settingsTypeList.map((settingsType, index) => (
          <button 
          key={index}
          onClick={()=>{setActiveSettingsType(settingsType)}}
          className={`settings-menu-button${activeSettingsType.id === settingsType.id ? " active" : ""}`}
          >
          {settingsType.name}</button>
        ))}
   
    </div>
  )
}
