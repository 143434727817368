import React, { useContext, useState } from "react";
import DashboardInfoBox from "../components/DashboardInfoBox";

import "../styles/DashboardOld.css";

import HorizontalBarChart from "../components/HorizontalBarChar";
import StackedAreaPlot from "../components/StackedAreaPlot";
import FailedSlaList from "../components/FailedSlaList";
import warning_ico from "../assets/warning.png";
import error_ico from "../assets/error.png";
import FailedKpiList from "../components/FailedKpiList";
import ChartSelect from "../components/ChartSelect";
import RecentTransactions from "../components/RecentTransactions";
import OlaSlaPieChart from "../components/OlaSlaPieChart";
import GaugeStatistic from "../components/GaugeStatistic";
import { MainContext } from "../contexts/MainContext";

export default function Dashboard() {
  const [data, setData] = useState([
    { type: "SLA", date: "01.2023", value: 1390 },
    { type: "SLA", date: "02.2023", value: 1469 },
    { type: "SLA", date: "03.2023", value: 1521 },
    { type: "SLA", date: "04.2023", value: 1615 },
    { type: "SLA", date: "05.2023", value: 1703 },
    { type: "SLA", date: "06.2023", value: 1767 },
    { type: "SLA", date: "07.2023", value: 1806 },
    { type: "SLA", date: "08.2023", value: 1903 },
    { type: "SLA", date: "09.2023", value: 1986 },
    { type: "SLA", date: "10.2023", value: 1952 },
    { type: "SLA", date: "11.2023", value: 1910 },
    { type: "SLA", date: "12.2023", value: 2018 },
    { type: "OLA", date: "01.2023", value: 109 },
    { type: "OLA", date: "02.2023", value: 115 },
    { type: "OLA", date: "03.2023", value: 120 },
    { type: "OLA", date: "04.2023", value: 128 },
    { type: "OLA", date: "05.2023", value: 134 },
    { type: "OLA", date: "06.2023", value: 142 },
    { type: "OLA", date: "07.2023", value: 157 },
    { type: "OLA", date: "08.2023", value: 169 },
    { type: "OLA", date: "09.2023", value: 186 },
    { type: "OLA", date: "10.2023", value: 195 },
    { type: "OLA", date: "11.2023", value: 198 },
    { type: "OLA", date: "12.2023", value: 211 },

    { type: "KPI", date: "01.2023", value: 1090 },
    { type: "KPI", date: "02.2023", value: 1150 },
    { type: "KPI", date: "03.2023", value: 1200 },
    { type: "KPI", date: "04.2023", value: 1280 },
    { type: "KPI", date: "05.2023", value: 1340 },
    { type: "KPI", date: "06.2023", value: 1420 },
    { type: "KPI", date: "07.2023", value: 1570 },
    { type: "KPI", date: "08.2023", value: 1690 },
    { type: "KPI", date: "09.2023", value: 1860 },
    { type: "KPI", date: "10.2023", value: 1950 },
    { type: "KPI", date: "11.2023", value: 1980 },
    { type: "KPI", date: "12.2023", value: 2100 },
  ]);
  const [data2, setData2] = useState([
    {
      label: "A Şirketi",
      type: "sla",
      value: 28,
    },
    {
      label: "A Şirketi",
      type: "ola",
      value: 22,
    },
    {
      label: "B Şirketi",
      type: "sla",
      value: 18,
    },
    {
      label: "B Şirketi",
      type: "ola",
      value: 13,
    },
    {
      label: "C Şirketi",
      type: "sla",
      value: 9,
    },
    {
      label: "C Şirketi",
      type: "ola",
      value: 9,
    },
    {
      label: "D Şirketi",
      type: "sla",
      value: 5,
    },
    {
      label: "D Şirketi",
      type: "ola",
      value: 39,
    }
  ]);
  const data3 = {
    id: "A0",
    value: {
      title: "Experilabs",
      items: [
        {
          text: "Admin",
        },
      ],
    },
    children: [
      {
        id: "A1",
        value: {
          title: "e-ticaret Firması",
          items: [
            {
              text: "1152万",
            },
            {
              text: "占比",
              value: "30%",
            },
          ],
        },
        children: [
          {
            id: "A11",
            value: {
              title: "Ericson",
              items: [
                {
                  text: "1152万",
                },
                {
                  text: "占比",
                  value: "30%",
                },
              ],
            },
          },
          {
            id: "A12",
            value: {
              title: "Coffy",
              items: [
                {
                  text: "1152万",
                },
                {
                  text: "占比",
                  value: "30%",
                },
              ],
            },
          },
          {
            id: "A13",
            value: {
              title: "海南",
              items: [
                {
                  text: "1152万",
                },
                {
                  text: "占比",
                  value: "30%",
                },
              ],
            },
          },
        ],
      },
      {
        id: "A2",
        value: {
          title: "Ericson",
          items: [
            {
              text: "Customer",
            },
            {
              text: "Customer",
              value: "30%",
              icon: "https://gw.alipayobjects.com/zos/antfincdn/iFh9X011qd/7797962c-04b6-4d67-9143-e9d05f9778bf.png",
            },
          ],
        },
      },
      {
        id: "A3",
        value: {
          title: "Bosch",
          items: [
            {
              text: "Customer",
              value: "30%",
              icon: "https://gw.alipayobjects.com/zos/antfincdn/iFh9X011qd/7797962c-04b6-4d67-9143-e9d05f9778bf.png",
            },
          ],
        },
      },
      {
        id: "A4",
        value: {
          title: "Vodafone",
          items: [
            {
              text: "Customer",
              value: "30%",
              icon: "https://gw.alipayobjects.com/zos/antfincdn/iFh9X011qd/7797962c-04b6-4d67-9143-e9d05f9778bf.png",
            },
          ],
        },
      },
      {
        id: "A5",
        value: {
          title: "Google",
          items: [
            {
              text: "Customer",
              value: "30%",
              icon: "https://gw.alipayobjects.com/zos/antfincdn/iFh9X011qd/7797962c-04b6-4d67-9143-e9d05f9778bf.png",
            },
          ],
        },
      },
    ],
  };
  const [data4, setData4] = useState([
    { type: "CUSTOMER NUMBER", date: "01.2023", value: 10 },
    { type: "CUSTOMER NUMBER", date: "02.2023", value: 11 },
    { type: "CUSTOMER NUMBER", date: "03.2023", value: 12 },
    { type: "CUSTOMER NUMBER", date: "04.2023", value: 12 },
    { type: "CUSTOMER NUMBER", date: "05.2023", value: 13 },
    { type: "CUSTOMER NUMBER", date: "06.2023", value: 14 },
    { type: "CUSTOMER NUMBER", date: "07.2023", value: 15 },
    { type: "CUSTOMER NUMBER", date: "08.2023", value: 16 },
    { type: "CUSTOMER NUMBER", date: "09.2023", value: 18 },
    { type: "CUSTOMER NUMBER", date: "10.2023", value: 19 },
    { type: "CUSTOMER NUMBER", date: "11.2023", value: 19 },
    { type: "CUSTOMER NUMBER", date: "12.2023", value: 21 },
  ]);
  const { langFile } = useContext(MainContext);
  return (
    <div className="dashboard-container">
      <div className="chart-select-tab">
        <ChartSelect></ChartSelect>
      </div>
      <div style={{ display: "flex", flexDirection: "row", height:"95%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
          }}
        >
          <div className="dashboard-component-1">
            <DashboardInfoBox
              info={[
                {
                  number: 35,
                  text: langFile.pages.Dashboard.totalCustomer,
                },
              ]}
              white={false}
              className="center-flex"
            ></DashboardInfoBox>
            <div className="dashboard-component-1-2">
              {/* <DashedLineChart data={data}></DashedLineChart> */}
              <StackedAreaPlot dataForRecommendation={false} data={data4}></StackedAreaPlot>
            </div>
          </div>
          <div className="dashboard-component-1">
            <DashboardInfoBox
              info={[
                {
                  number: 120,
                  text: langFile.pages.Dashboard.chart2TotalSla,
                },
                {
                  number: 120,
                  text: langFile.pages.Dashboard.chart2TotalOla,
                },
                {
                  number: 120,
                  text: langFile.pages.Dashboard.chart2TotalKpi,
                },
              ]}
              white={true}
              className="center-flex"
            ></DashboardInfoBox>
            <div className="dashboard-component-1-2">
              <StackedAreaPlot dataForRecommendation={false} data={data}></StackedAreaPlot>
            </div>
          </div>
          <div className="dashboard-component-1">
            <h5
              style={{
                textAlign: "center",
                color: "var(--textColor)",
                fontSize: "0.8vw",
                fontWeight:"bold",
                padding: "1vw",
              }}
            >
              {langFile.pages.Dashboard.slaCountByCompanies}
            </h5>
            <div className="dashboard-component-2-1">
              <HorizontalBarChart data={data2}></HorizontalBarChart>
            </div>
          </div>
          <div></div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "43%" }}>
        <div className="dashboard-component-6">
            <div className="dashboard-component-6-1">
              <div className="dashboard-component-6-1-1">
              <h5
              style={{
                textAlign: "center",
                color: "var(--textColor)",
                fontSize: "0.8vw",
                fontWeight:"bold",
                alignItems:"center",
                justifyContent:"center",
                display:"flex",
                height:"10%"
              }}
            >
              {langFile.pages.Dashboard.slaOlaRation}
            </h5>
              <OlaSlaPieChart></OlaSlaPieChart>
              </div>
              <div className="dashboard-component-6-1-2">
              <h5
              style={{
                textAlign: "center",
                color: "var(--textColor)",
                fontSize: "0.8vw",
                fontWeight:"bold",
                alignItems:"center",
                justifyContent:"center",
                display:"flex",
                height:"10%"

              }}
            >
            
              {langFile.pages.Dashboard.slaSuccesRatio}

            </h5>
              <GaugeStatistic value={0.75}></GaugeStatistic>
              </div>
            </div>
          </div>
          <div className="dashboard-component-5">
            <h5
              style={{
                textAlign: "center",
                color: "var(--textColor)",
                fontSize: "0.8vw",
                fontWeight:"bold",
                height:"5%",
                alignItems:"center",
                justifyContent:"center",
                display:"flex"
              }}
            >
              {langFile.pages.Dashboard.recentTransactions}
            </h5>
            <div className="dashboard-component-5-1">
                <RecentTransactions></RecentTransactions>
            </div>
          </div>
          <div></div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "16%" }}>
          <div className="dashboard-component-4">
            <h5
              style={{
                textAlign: "center",
                color: "var(--textColor)",
                fontSize: "0.8vw",
                fontWeight:"bold",
              }}
            >
              <img
                alt="warning"
                src={warning_ico}
                style={{ width: "1.2vw", marginRight: ".5vw" }}
              ></img>
              {langFile.pages.Dashboard.failedSlaList}
              <img
                alt="error"
                src={error_ico}
                style={{ width: "1.2vw", marginLeft: ".5vw" }}
              ></img>
            </h5>
            <div className="dashboard-component-4-1">
              {/* <SimpleBarChart data={data2}></SimpleBarChart> */}
              <FailedSlaList></FailedSlaList>
            </div>
          </div>
          <div></div>
          <div className="dashboard-component-4">
            <h5
              style={{
                textAlign: "center",
                color: "var(--textColor)",
                fontSize: "0.8vw",
                fontWeight:"bold",
              }}
            >
              <img
                alt="warning"
                src={warning_ico}
                style={{ width: "1.2vw", marginRight: ".5vw" }}
              ></img>
              {langFile.pages.Dashboard.failedKpiList}
              <img
                alt="error"
                src={error_ico}
                style={{ width: "1.2vw", marginLeft: ".5vw" }}
              ></img>
            </h5>
            <div className="dashboard-component-4-1">
              {/* <SimpleBarChart data={data2}></SimpleBarChart> */}
              <FailedKpiList></FailedKpiList>
            </div>
          </div>
          <div></div>
          
        </div>
      </div>
    </div>
  );
}
