import React, { useContext, useEffect, useState } from "react";
import "../styles/ImportTable.css";
import { MainContext } from "../contexts/MainContext";
import { ImportTableContext } from "../contexts/ImportTableContext";
import { ImportContext } from "../contexts/ImportContext";
import { LoginContext } from "../contexts/LoginContext";


export default function ImportTable() {
  const { AddAlert } = useContext(MainContext);
  const {
    selectedSla,
    setExcelData,
    updateTableTrigger,
    setUpdateTableTrigger,
    slas } = useContext(ImportContext);
  const { token, roles } = useContext(LoginContext)
  const { tableInitalData,
    setTableInitalData,
    tableData,
    setTableData,
    clickedCordinat,
    setClickedCordinat,
    ids,
    setIds,
    month,
    messageIntoTable,
    setMessageIntoTable,
    ResetContext } = useContext(ImportTableContext);


  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      ResetContext()
    };
  }, []);
  let id = 0;

  function UpdateValueOfTarget(arr) {
    if (arr.targets.length === 0) {
      AddAlert("warning", "There is No Change to Update")
      setMessageIntoTable("There is no change to update")
      return
    }
    if (roles.some(role => role === "KpiEdit")) {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_UPDATE_TARGETS, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(arr),
      })
        .then((res) => {
          if (res.ok) {
            AddAlert("success", "Update Values Successful")
            setUpdateTableTrigger(!updateTableTrigger)
            return res.json();
          }
          throw res;
        })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          AddAlert("error", "Update Values Failed")
          console.log(error.json());
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }



  function UpdateTargetsFromUi() {
    let arr = [];
    for (let i = 0; i < tableData.length; i++) {
      for (let j = 0; j < tableData[i].length; j++) {
        if (tableData[i][j] !== tableInitalData[i][j]) {
          let obj = { id: ids[i][j], value: tableData[i][j] }
          arr.push(obj);
        }
      }
    }
    let obj = { targets: arr }
    UpdateValueOfTarget(obj)
  }

  function Sort(kpis) {
    let arr = kpis;
    for (let i = 0; i < arr.length; i++) {
      arr[i].targetResults.sort(
        (date1, date2) =>
          parseInt(date1.referenceDate.substring(5, 7)) +
          parseInt(date1.referenceDate.substring(0, 4)) * 12 -
          (parseInt(date2.referenceDate.substring(5, 7)) +
            parseInt(date2.referenceDate.substring(0, 4)) * 12)
      );
    }
    return arr;
  }


  useEffect(() => {
    if (parseInt(selectedSla) !== -1) {
      if (roles.some(role => role === "KpiRead")) {
        setTableInitalData([], setTableData([]))
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_KPI_AND_TARGET + "?SlaId=" + (slas[selectedSla].guid) + "&Deleted=false&PageSize=9999&Page=1", {
          method: "GET",
          headers: {
            "Accept": 'application/json',
            "Authorization": "Bearer " + token,
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            let arrSla = [];
            let idSla = []
            data.calculationResults.kpiList.map((kpi) => {
              let arrKpi = [];
              let idKpi = [];
              arrKpi.push(kpi.name);
              idKpi.push(kpi.kpiId)
              kpi.targets.map((target) => {
                arrKpi.push(target.value);
                idKpi.push(target.id)
              })

              arrSla.push(arrKpi);
              idSla.push(idKpi);
            })

            let temp = JSON.parse(JSON.stringify(arrSla));
            setTableInitalData(temp, setTableData(arrSla))
            setIds(idSla)

            data.calculationResults.kpiList[0]?.targets?.map((target) =>
              month.push(target.referenceDate)
            )
            return data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      else {
        AddAlert("warning", "You do not have authorization for this operation.")
      }
    }
    else {
      setTableData([])
      setTableInitalData([])
    }
  }, [selectedSla, updateTableTrigger])


  function inputHandleClicked(index1, index2, value) {
    let arr = [...tableData];
    arr[index1][index2] = parseFloat(value);
    setTableData(arr);
  }

  function CreateJsonFile() {
    let arr = [];
    for (let i = 0; i < tableData.length; i++) {
      let obj = { "KPI/MONTH": tableData[i][0] }
      for (let j = 1; j < tableData[i].length; j++) {
        obj[month[j - 1]] = tableData[i][j];
      }
      arr.push(obj);
    }
    for (let i = 0; i < ids.length; i++) {
      let obj = { "KPI/MONTH": ids[i][0] }
      for (let j = 1; j < ids[i].length; j++) {
        obj[month[j - 1]] = ids[i][j];
      }
      arr.push(obj);
    }

    setExcelData(arr);
  }

  useEffect(() => {
    CreateJsonFile();
  }, [tableData]);

  return (
    <div className="import-table-parent">
      <div className="import-table-container">
        <div className="import-table-column-container">
          <div className="import-table-row-container">{/*for first line (months)*/}
            {tableData.length !== 0 ? tableData[0].map((row, index) => (
              <div key={id++} className="import-table-header-cell">
                <input
                  className="header-label-cell-table"
                  type="text"
                  id="month"
                  defaultValue={
                    // index === 0 ? "KPI/Month" : month[(index - 1) % 12]
                    index === 0 ? "KPI/Month" : month[index - 1]
                  }
                  readOnly
                  style={{ pointerEvents: index === 0 ? "none" : null }}
                  onClick={() => setClickedCordinat([-1, index])}
                />
              </div>
            )) : <label style={{ backgroundColor: "var(--backgroundColor2)", width: "max-content" }}>{messageIntoTable}</label>}
          </div>

          {tableData.map((dataKpi, index1) => (
            <div key={id++} className="import-table-row-container">
              {dataKpi.map((data, index2) => (
                <div key={id++} className="import-table-cell">
                  <input
                    title="Please use '.' for decimals."
                    onChange={(event) =>
                      inputHandleClicked(index1, index2, event.target.value)
                    }
                    className="input-cell-table"
                    type={index2 === 0 ? "text" : "number"}
                    id="table-number-data"
                    defaultValue={data?.toString()}
                    readOnly={index2 === 0 ? true : false}
                    min={0}
                    style={{
                      // borderColor:
                      //   tableInitalData[index1][index2] !==
                      //   tableData[index1][index2]
                      //     ? "red"
                      //     : "black",
                      // borderWidth:
                      //   tableInitalData[index1][index2] !==
                      //   tableData[index1][index2]
                      //     ? "4px"
                      //     : "1px",
                      backgroundColor:
                        tableInitalData[index1][index2] !==
                          tableData[index1][index2]
                          ? "#d9534f"
                          : index2 === 0 ||
                            index1 === clickedCordinat[0] ||
                            index2 === clickedCordinat[1]
                            ? "var(--importTableColor2)"
                            : null,
                    }}
                    onClick={() => setClickedCordinat([index1, index2])}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {roles.some(role => role === "KpiEdit") &&
        <div className="import-table-parent-submit-button-parent">
          <button onClick={UpdateTargetsFromUi} className="import-table-parent-submit-button">
            Submit
          </button>
        </div>
      }
    </div>
  );
}
