import { useFormik } from "formik";
import React, { useContext, useState, useEffect } from "react";
import { MainContext } from "./MainContext";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "./LoginContext";
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { NewUserContext } from "./NewUserContext";

export const EditUserContext = React.createContext();

const EditUserContextProvider = (props) => {
  const navigate = useNavigate
  const { AddAlert, setAlertList } = useContext(MainContext);
  const { token, user, roles } = useContext(LoginContext)
  const [toEditUser, setToEditUser] = useState(false);

  const [accountTypeList, setAccountTypeList] = useState()
  const editUserValidationSchema = yup.object().shape({
    firstName: yup.string().required("'First Name' must not be empty.Provide a valid first name"),
    lastName: yup.string().required("'Last Name' must not be empty.Provide a valid last name"),
    password: yup.string().min(8, "Password must be at least 8 characters"),
    username: yup.string().min(3, "Provide a valid username with at least 3 characters").required("'Username' must not be empty."),
    email: yup.string().email("Provide a valid email address").required("'Email' must not be empty."),
    phoneNumber: yup.string()
      .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', function (value) {
        if (value && typeof value === 'string') {
          return isValidPhoneNumber(value);
        }
        return true;
      }),
    selectedAccountType: yup.string().required("'Account Type' must not be empty."),
  });

  const formik = useFormik({
    initialValues: {
      accountId: "",
      firstName: "",
      lastName: "",
      password: "",
      username: "",
      email: "",
      phoneNumber: "",
      countryCode: "",
      selectedAccountType: "",
      organizationGroup: "",
      organization: "",
      oldPosition: "",
      position: "",
    },
    validationSchema: editUserValidationSchema,
    onSubmit: (values) => { EditUser(values) },
  });

  const [getAccountTrigger, setGetAccountTrigger] = useState(false)

  function GetRoles(arr) {
    if (arr) {
      const roles = arr.roleDtos.map(roleDto => roleDto.roleName).join(', ');
      return roles;
    }
    return ""
  }

  function EditUser(values) {
    let accountPositions = values.position ? values.oldPosition ? { [values.oldPosition]: values.position } : { [values.position]: null } : null

    if (roles.some(role => role === "UserEdit")) {

      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_UPDATE_ACCOUNT, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm"

        },
        body: JSON.stringify({
          craetedBy: user.id,
          accountId: values.accountId,
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password === "" ? null : values.password,
          confirmPassword: values.password,
          phoneNumber: values.phoneNumber ? values.phoneNumber : null,
          countryCode: values.phoneNumber ? parsePhoneNumber(values.phoneNumber).country : null,
          accountTypeId: values.selectedAccountType,
          accountPositions: accountPositions
        }),
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }

          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          if (data.statusCode === 200) {
            AddAlert("success", "User Updating successful")
            setToEditUser(false)
            formik.resetForm();
            setGetAccountTrigger(!getAccountTrigger)
          }
          else {
            AddAlert("error", data.error.message)
          }
          return data;
        })
        .catch((error) => {
          error.json().then((err) => {
            const errorObject = err.errors
            Object.keys(errorObject).forEach(key => {
              const errors = errorObject[key];
              let errorText = ""
              errors.forEach(error => {
                errorText += (error + ". ")
              });
              formik.setFieldError(key, errorText)
            });
          })
          AddAlert("error", "User Updating Failed")
          setGetAccountTrigger(!getAccountTrigger)
          console.log(error.json());
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }
  function GetAllAccountGroup() {
    fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_PRIVILEGES_ACCOUNT_GROUP + "?PageNumber=1&PageSize=999", {
      method: "Get",
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
        "TenantId": "slm",
      }
    })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
        else if (res.status === 401) {
          setAlertList([])
          localStorage.clear();
          navigate("/login");
        }
        throw res;
      })
      .then((data) => {
        const otherAccountGroup = data.accountGroups.find(function (group) {
          return group.accountGroupName === "main";
        });
        setAccountTypeList(otherAccountGroup?.accountTypes);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <EditUserContext.Provider value={{
      toEditUser,
      setToEditUser,
      getAccountTrigger,
      setGetAccountTrigger,
      accountTypeList,
      setAccountTypeList,
      formik,
      EditUser,
      GetRoles,
      GetAllAccountGroup,
    }}>
      {props.children}
    </EditUserContext.Provider>
  )
}
export default EditUserContextProvider;