import React, { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../contexts/LoginContext';
import { MainContext } from '../contexts/MainContext';
import { useNavigate } from 'react-router-dom';
import "../styles/SelectCompany.css"

export default function SelectCompany() {
    const navigate = useNavigate();
    const { langFile, } = useContext(MainContext);
    const { roles, token, user, setUser } = useContext(LoginContext)
    const { setAlertList, AddAlert } = useContext(MainContext);
    const [companyList, setCompanyList] = useState([])

    useEffect(() => {
        if (roles.some(role => role === "CompanyRead")) {
            fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token,
                    "TenantId": "slm",
                },
                body: JSON.stringify({
                    includeCompany: true,
                    page: 1,
                    pageSize: 999
                })
            })
                .then((res) => {
                    if (res.ok && res.status === 200) {
                        return res.json();
                    }
                    else if (res.status === 401) {
                        setAlertList([])
                        localStorage.clear();
                        navigate("/login");
                    }
                    throw res;
                })
                .then((data) => {
                    setCompanyList(data.companies);
                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            AddAlert("warning", "You do not have authorization for this operation.")
        }
    }, [])


    function HandleChangeCompany(id) {
        const tempUser = JSON.parse(JSON.stringify(user));

        // Check if tempUser.company is null before trying to access its properties
        if (tempUser.company) {
            tempUser.company.id = id;
            setUser(tempUser);
        } else {
            // If tempUser.company is null, you may need to initialize it
            tempUser.company = { id };
            setUser(tempUser);
        }
    }
    return (
        <div className="select-company-group-container">
            {roles.some(role => role === "Admin") &&
                <React.Fragment>
                    <label className="select-comp-label">
                        {langFile.pages.NewCompany.selectedCompany}
                    </label>
                    <select
                        name="companyGroup"
                        id="companyGroup"
                        onChange={(event) => { HandleChangeCompany(event.target.value) }}
                        value={user?.company?.id ? user.company.id : "admin"}
                    >
                        {companyList?.map((item, index_2) => (
                            <option key={index_2} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                        <option key={"admin"} value={"admin"}>
                            admin
                        </option>
                    </select>
                </React.Fragment>}
        </div>

    )
}
