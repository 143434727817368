import React, { useContext, useEffect, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import { MainContext } from "../contexts/MainContext";
import { EditCompanyContext } from "../contexts/EditCompanyContext";

export default function EditCompany() {
    const { formik, handleKeyPress, toEditCompany } = useContext(EditCompanyContext);
    const { langFile } = useContext(MainContext);

    return (
        <div className="new-company-container">
          <div className="parent-new-company">
            <div className="formbold-main-wrapper">
              <div className="formbold-form-wrapper">
                <form onSubmit={formik.handleSubmit}>
                  <div className="formbold-form-title">
                    <h2 style={{ textAlign: "left" }}>
                      {langFile.pages.EditCompany.editServiceProvider}
                    </h2>
                    <p>{langFile.pages.EditCompany.whenAddCompany}</p>
                  </div>
                  <div className="formbold-input-flex">
                    <div className="formbold-mb-3">
                      <label htmlFor="name" className="formbold-form-label">
                        {langFile.pages.EditCompany.name}
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="formbold-form-input"
                        value={formik.values.name}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.name && formik.touched.name && <label className="errorLabel">{formik.errors.name}</label>}
                    </div>
                    <div className="formbold-mb-3">
                      <label htmlFor="email" className="formbold-form-label">
                        {langFile.pages.EditCompany.email}
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="formbold-form-input"
                        value={formik.values.email}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.email && formik.touched.email && <label className="errorLabel">{formik.errors.email}</label>}
                    </div>
                  </div>
                  <div className="formbold-input-flex">
                    <div className="formbold-mb-3">
                      <label htmlFor="taxCode" className="formbold-form-label">
                        {langFile.pages.EditCompany.taxCode}
                      </label>
                      <input
                        type="text"
                        name="taxCode"
                        id="taxCode"
                        className="formbold-form-input"
                        value={formik.values.taxCode}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.taxCode && formik.touched.taxCode && <label className="errorLabel">{formik.errors.taxCode}</label>}
                    </div>
                    <div className="formbold-mb-3">
                      <label htmlFor="fax" className="formbold-form-label">
                        {langFile.pages.EditCompany.fax}
                      </label>
                      <input
                        type="text"
                        name="fax"
                        id="fax"
                        className="formbold-form-input"
                        value={formik.values.fax}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.fax && formik.touched.fax && <label className="errorLabel">{formik.errors.fax}</label>}
                    </div>
                  </div>
                  <div className="formbold-input-flex">
                    <div>
                      <label
                        htmlFor="phoneNumber"
                        className="formbold-form-label"
                      >
                        {langFile.pages.EditCompany.phoneNumber}
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="TR"
                        countryCallingCodeEditable={false}
                        name="phoneNumber"
                        id="phoneNumber"
                        className="formbold-form-input"
                        value={formik.values.phoneNumber}
                        onChange={(value) => { formik.setFieldValue('phoneNumber', value) }}
                      />
                      {formik.errors.phoneNumber && formik.touched.phoneNumber && <label className="errorLabel">{formik.errors.phoneNumber}</label>}
                    </div>
                    <div className="formbold-mb-3">
                      <label htmlFor="website" className="formbold-form-label">
                        {langFile.pages.EditCompany.website}
                      </label>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        className="formbold-form-input"
                        value={formik.values.website}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.website && formik.touched.website && <label className="errorLabel">{formik.errors.website}</label>}
                    </div>
                  </div>
                  <div className="formbold-input-flex">
                    <div className="formbold-mb-3">
                      <label htmlFor="contactName" className="formbold-form-label">
                        {langFile.pages.EditCompany.contactName}
                      </label>
                      <input
                        type="text"
                        name="contactName"
                        id="contactName"
                        className="formbold-form-input"
                        value={formik.values.contactName}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.contactName && formik.touched.contactName && <label className="errorLabel">{formik.errors.contactName}</label>}
                    </div>
                    <div className="formbold-mb-3">
                      <label htmlFor="contactEmail" className="formbold-form-label">
                        {langFile.pages.EditCompany.contactEmail}
                      </label>
                      <input
                        type="text"
                        name="contactEmail"
                        id="contactEmail"
                        className="formbold-form-input"
                        value={formik.values.contactEmail}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.contactEmail && formik.touched.contactEmail && <label className="errorLabel">{formik.errors.contactEmail}</label>}
                    </div>
                  </div>
                  <div className="formbold-input-flex">
                    <div>
                      <label
                        htmlFor="contactPhoneNumber"
                        className="formbold-form-label">
                        {langFile.pages.EditCompany.contactPhoneNumber}
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="TR"
                        countryCallingCodeEditable={false}
                        name="contactPhoneNumber"
                        id="contactPhoneNumber"
                        className="formbold-form-input"
                        value={formik.values.contactPhoneNumber}
                        onChange={(value) => { formik.setFieldValue('contactPhoneNumber', value) }}
                      />
                      {formik.errors.contactPhoneNumber && formik.touched.contactPhoneNumber && <label className="errorLabel">{formik.errors.contactPhoneNumber}</label>}
                    </div>
                    <div>
                      <label
                        htmlFor="contactAddress"
                        className="formbold-form-label">
                        {langFile.pages.EditCompany.contactAddress}
                      </label>
                      <input
                        type="text"
                        name="contactAddress"
                        id="contactAddress"
                        className="formbold-form-input"
                        value={formik.values.contactAddress}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.contactAddress && formik.touched.contactAddress && <label className="errorLabel">{formik.errors.contactAddress}</label>}
                    </div>
    
                  </div>
                  <div className="formbold-input-flex">
                    <div className="formbold-mb-3">
                      <label htmlFor="contact2Name" className="formbold-form-label">
                        {langFile.pages.EditCompany.contact2Name}
                      </label>
                      <input
                        type="text"
                        name="contact2Name"
                        id="contact2Name"
                        className="formbold-form-input"
                        value={formik.values.contact2Name}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.contact2Name && formik.touched.contact2Name && <label className="errorLabel">{formik.errors.contact2Name}</label>}
                    </div>
                    <div className="formbold-mb-3">
                      <label htmlFor="contact2Email" className="formbold-form-label">
                        {langFile.pages.EditCompany.contact2Email}
                      </label>
                      <input
                        type="text"
                        name="contact2Email"
                        id="contact2Email"
                        className="formbold-form-input"
                        value={formik.values.contact2Email}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.contact2Email && formik.touched.contact2Email && <label className="errorLabel">{formik.errors.contact2Email}</label>}
                    </div>
                  </div>
                  <div className="formbold-input-flex">
                    <div>
                      <label
                        htmlFor="contact2PhoneNumber"
                        className="formbold-form-label">
                        {langFile.pages.EditCompany.contact2PhoneNumber}
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="TR"
                        countryCallingCodeEditable={false}
                        name="contact2PhoneNumber"
                        id="contact2PhoneNumber"
                        className="formbold-form-input"
                        value={formik.values.contact2PhoneNumber}
                        onChange={(value) => { formik.setFieldValue('contact2PhoneNumber', value) }}
                      />
                      {formik.errors.contact2PhoneNumber && formik.touched.contact2PhoneNumber && <label className="errorLabel">{formik.errors.contact2PhoneNumber}</label>}
                    </div>
                    <div>
                      <label htmlFor="contact2Address" className="formbold-form-label">
                        {langFile.pages.EditCompany.contact2Address}
                      </label>
                      <input
                        type="text"
                        name="contact2Address"
                        id="contact2Address"
                        className="formbold-form-input"
                        value={formik.values.contact2Address}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.contact2Address && formik.touched.contact2Address && <label className="errorLabel">{formik.errors.contact2Address}</label>}
                    </div>
                  </div>
                  <div className="formbold-input-flex">
                    <div className="formbold-mb-3">
                      <label htmlFor="address" className="formbold-form-label">
                        {langFile.pages.EditCompany.address}
                      </label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        className="formbold-form-input"
                        value={formik.values.address}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.address && formik.touched.address && <label className="errorLabel">{formik.errors.address}</label>}
                    </div>
                    <div className="formbold-mb-3">
                      <label htmlFor="description" className="formbold-form-label">
                        {langFile.pages.EditCompany.description}
                      </label>
                      <input
                        type="text"
                        name="description"
                        id="description"
                        className="formbold-form-input"
                        value={formik.values.description}
                        placeholder=""
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.errors.description && formik.touched.description && <label className="errorLabel">{formik.errors.description}</label>}
                    </div>
                  </div>
                  <button
                    className="formbold-btn"
                    type="submit">
                    {langFile.pages.EditCompany.editProviderBtn}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
    
      );
}

