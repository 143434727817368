import React, { useContext, useEffect } from 'react'
import "../../styles/NewSlaMetaData.css";
import { NewAgreementContext } from '../../contexts/NewAgreementContext';
import { NewSlaMetaDataContext } from '../../contexts/NewSlaMetaDataContext';
import { LoginContext } from '../../contexts/LoginContext';
import { MainContext } from '../../contexts/MainContext';
import Multiselect from 'multiselect-react-dropdown';

export default function NewSla() {
  const { langFile } = useContext(MainContext);
  const { metadataDone } = useContext(NewAgreementContext);
  const { user } = useContext(LoginContext)
  const {
    contractType,
    companyGroupList,
    GetAllCompanyGroup,
    formik,
    okrTo,
    setOkrTo,
    organizations,
    GetAllOrganization,
    GetAllUser,
    users,
    setUsers,
    setUsersOfCompany,
    usersOfCompany
  } = useContext(NewSlaMetaDataContext)

  useEffect(() => {
    if (okrTo === "organization" && formik.values.selectedCompany) {
      GetAllOrganization({
        companyId: formik.values.selectedCompany,
        pageNumber: 1,
        pageSize: 999
      })
    }
    else if (okrTo === "user") {
      GetAllUser({
        companyId: formik.values.selectedCompany,
        pageIndex: 1,
        pageSize: 999,
        includeAccountType: true,
        includeCompany: true,
        includeOrganizations: true,
        includePositions: true
      },setUsers)
      }
  }, [user, formik.values.selectedCompany, okrTo]) 

  useEffect(()=>{
    GetAllUser({
      companyId: user?.company?.id,
      pageIndex: 1,
      pageSize: 999,
      includeAccountType: true,
      includeCompany: true,
      includeOrganizations: true,
      includePositions: true
    },setUsersOfCompany)
  },[user?.company?.id])

  useEffect(() => {
    if (user?.company?.id) {
      GetAllCompanyGroup(user.company.id)
    }
  }, [user?.company?.id])


  function onSelect(selectedList, selectedItem) {
    formik.setFieldValue('selectedAgreementOwner', selectedItem)    
  } 

  function onRemove(selectedList, selectedItem) {
    formik.setFieldValue('selectedAgreementOwner', {  })    
  } 

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form-container"
      style={{
        pointerEvents: !metadataDone ? "all" : "none",
        opacity: !metadataDone ? "1" : "0.5"
      }}
    >
      <label htmlFor='slaName' className="label-metadata-2">
        {langFile.pages.NewSlaMetaData[contractType].name}
      </label>
      <input
        type="text"
        placeholder={langFile.pages.NewSlaMetaData[contractType].name}
        name="slaName"
        id="slaName"
        onChange={formik.handleChange}
        autoComplete="on"
        value={formik.values.slaName}
      />
      {formik.errors.slaName && formik.touched.slaName && <label className="errorLabel">{formik.errors.slaName}</label>}


      <label className="label-metadata-2" htmlFor='selectedAgreementOwner'>
        {langFile.pages.NewSlaMetaData.agreementOwner}
      </label>
      <Multiselect
        name="selectedAgreementOwner"
        id="selectedAgreementOwner"
        options={usersOfCompany} // Options to display in the dropdown
        onSelect={onSelect}
        onRemove={onRemove}
        displayValue="username" // Property name to display in the dropdown options
        selectionLimit={1}
        placeholder={formik.values.selectedAgreementOwner.username}
        closeIcon={"cancel"}
      />
      {formik.errors.selectedAgreementOwner && formik.touched.selectedAgreementOwner && <label className="errorLabel">{formik.errors.selectedAgreementOwner}</label>}

      <label className="label-metadata-2" htmlFor='selectedCompanyGroup'>
        {langFile.pages.NewSlaMetaData.selectCompanyGroup}
      </label>
      <select
        name="selectedCompanyGroup"
        id="selectedCompanyGroup"
        onChange={formik.handleChange}
        value={formik.values.selectedCompanyGroup}
      >
        <option value="">Select Company Group</option>
        {companyGroupList?.map((companyGroup, index) => (
          <option key={index} value={companyGroup.companyGroupId}>
            {companyGroup.name}
          </option>
        ))}
      </select>
      {formik.errors.selectedCompanyGroup && formik.touched.selectedCompanyGroup && <label className="errorLabel">{formik.errors.selectedCompanyGroup}</label>}
      <label className="label-metadata-2" htmlFor='selectedCompany'>
        {langFile.pages.NewSlaMetaData.selectCompany}
      </label>
      <select
        name="selectedCompany"
        id="selectedCompany"
        onChange={formik.handleChange}
        value={formik.values.selectedCompany}
      >
        <option value="">Select Company</option>
        {
          companyGroupList.find(companyGroup => { if (companyGroup.companyGroupId === formik.values.selectedCompanyGroup) return companyGroup })?.companies.map((company, index) => (
            <option key={index} value={company.id}>
              {company.name}
            </option>
          ))
        }
      </select>
      {formik.errors.selectedCompany && formik.touched.selectedCompany && <label className="errorLabel">{formik.errors.selectedCompany}</label>}

      {contractType === "OKR" &&
        <>
          <label className="label-metadata-2" htmlFor='selectedOrganizationGroup'>
            {langFile.pages.NewSlaMetaData.OKR.selectOkrType}
          </label>
          <div className="radio-button-parent">
            <div
              title="UC"
              className="checkboxParent"
            >
              <label htmlFor='okrToOrganization' style={{ color: "black", width: "auto", marginRight: "1vw", fontSize: "0.8vw" }}>
                OKR to Organization
              </label>
              <input
                type="radio"
                id="okrToOrganization"
                name="okrTo"
                value={"organization"}
                checked={okrTo === "organization"}
                onChange={(event) => setOkrTo(event.target.value)}
              />
            </div>
            <div
              title="UC"
              className="checkboxParent"
            >
              <label htmlFor='okrToUser' style={{ color: "black", width: "auto", marginRight: "1vw", fontSize: "0.8vw" }}>
                OKR to User
              </label>
              <input
                type="radio"
                id="okrToUser"
                name="okrTo"
                value={"user"}
                checked={okrTo === "user"}
                onChange={(event) => setOkrTo(event.target.value)}
              />
            </div>
          </div>
          {okrTo === "organization" &&
            <>
              <label className="label-metadata-2" htmlFor='selectedOrganizationGroup'>
                {langFile.pages.NewSlaMetaData.OKR.selectOrganizationGroup}
              </label>
              <select
                name="selectedOrganizationGroup"
                id="selectedOrganizationGroup"
                onChange={(e) => {
                  formik.handleChange(e)
                }}
                value={formik.values.selectedOrganizationGroup}
              >
                <option value="">Select Organization Group</option>
                {organizations?.map((organizationGroup, index) => (
                  <option key={index} value={organizationGroup.id}>
                    {organizationGroup.name}
                  </option>
                ))}
              </select>
              {formik.errors.selectedOrganizationGroup && formik.touched.selectedOrganizationGroup && <label className="errorLabel">{formik.errors.selectedOrganizationGroup}</label>}
              <label className="label-metadata-2" htmlFor='selectedOrganizationOkrTarget'>
                {langFile.pages.NewSlaMetaData.OKR.selectOrganization}
              </label>
              <select
                name="selectedOrganizationOkrTarget"
                id="selectedOrganizationOkrTarget"
                onChange={(e) => {
                  const selectedOrgId = e.target.value;
                  const selectedOrganization = organizations.find(organization => organization.id === selectedOrgId);
                  formik.handleChange(e);
                }}
                value={formik.values.selectedOrganizationOkrTarget}
              >
                <option value="">Select Organization</option>
                {organizations
                  .filter(organizationGroup => organizationGroup.id === formik.values.selectedOrganizationGroup)
                  .map((organizationGroup) => (
                    organizationGroup.organizations.map((organization, index) => (
                      <option key={index} value={organization.id}>
                        {organization.name}
                      </option>
                    ))
                  ))}
              </select>
              {formik.errors.selectedOrganizationOkrTarget && formik.touched.selectedOrganizationOkrTarget && <label className="errorLabel">{formik.errors.selectedOrganizationOkrTarget}</label>}
            </>
          }
          {okrTo === "user" &&
            <>
              <label className="label-metadata-2" htmlFor='selectedUserOkrTarget'>
                {langFile.pages.NewSlaMetaData.selectUser}
              </label>
              <select
                name="selectedUserOkrTarget"
                id="selectedUserOkrTarget"
                onChange={(e) => {
                  formik.handleChange(e); // Call the handleChange function with the event object
                }}
                value={formik.values.selectedUserOkrTarget}
              >
                <option value="">Select User</option>
                {users?.map((user, index) => (
                  <option key={index} value={user.id}>
                    {user.username}
                  </option>
                ))}
              </select>
              {formik.errors.selectedOrganizationOkrTarget && formik.touched.selectedOrganizationOkrTarget && <label className="errorLabel">{formik.errors.selectedOrganizationOkrTarget}</label>}
            </>
          }
        </>
      }
      <label className="label-metadata-2" htmlFor='slaStatus'>
        {langFile.pages.NewSlaMetaData[contractType].status}
      </label>
      <select
        name="slaStatus"
        id="slaStatus"
        onChange={formik.handleChange}
        value={formik.values.slaStatus}
      >
        <option value={"active"}>active</option>
        <option value={"passive"}>passive</option>
      </select>
      {formik.errors.slaStatus && formik.touched.slaStatus && <label className="errorLabel">{formik.errors.slaStatus}</label>}
      <label className="label-metadata-2" htmlFor='startDate'>
        {langFile.pages.NewSlaMetaData.startDate}
      </label>
      <input
        type="month"
        id="startDate"
        name="startDate"
        max={formik.values.endDate}
        value={formik.values.startDate}
        onChange={formik.handleChange}
        autoComplete="on"
      />
      <label className="label-metadata-2">
        {langFile.pages.NewSlaMetaData.endDate}
      </label>
      {formik.errors.startDate && formik.touched.startDate && <label className="errorLabel">{formik.errors.startDate}</label>}
      <input
        type="month"
        id="endDate"
        name="endDate"
        min={formik.values.startDate}
        value={formik.values.endDate}
        onChange={formik.handleChange}
        autoComplete="on"
      />
      {formik.errors.endDate && formik.touched.endDate && <label className="errorLabel">{formik.errors.endDate}</label>}
      <button type="submit" >
        {langFile.pages.NewSlaMetaData.done}
      </button>
    </form>
  )
}
