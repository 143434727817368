import React, { useState, useRef, useEffect, useContext } from "react";
import "../styles/ProfileCard.css";
import user_photo from "../assets/user.png";
import email_ico from "../assets/email.png";
import phone_ico from "../assets/phone.png";
import logout_ico from "../assets/logout.png";
import LangDropdown from "./LangDropdown";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import { ProfileCardContext } from "../contexts/ProfileCardContext";
import { LoginContext } from "../contexts/LoginContext";

export default function ProfileCard() {
  const { langFile, profileComp, setProfileComp, setAlertList } = useContext(MainContext);
  const { user, ResetContext } = useContext(LoginContext);

  const navigate = useNavigate();

  function LogOut() {
    setAlertList([])
    setProfileComp(false)
    localStorage.clear();
    navigate("/login");
    ResetContext()
  }
  return (
    <div
      className={
        profileComp === true
          ? "container-profile-card"
          : "container-profile-card-close"
      }
    >
      <div className="parent-profile-card">
        <div
          className="profile-photo-parent"
        >
          <img
            alt="profile_photo"
            src={user_photo}
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          ></img>
        </div>
        <div className="info-container">
          <label>
          {user.firstname + " " + user.lastname}
          </label>
        </div>
        <div className="info-container">
          <label>
          {user.company?.name +
            " / " +
            user.username}
            </label>
        </div>
        <label className="user-status">{langFile.pages.ProfileCard.status}</label>
        <div className="info-container-img">
          <img
            className="info-ico-profile-card"
            alt="email"
            src={email_ico}
          ></img>
          <label>
            {user.email}
          </label>
        </div>
        <div className="info-container-img">
          <img
            className="info-ico-profile-card"
            alt="phone"
            src={phone_ico}
          ></img>
          <label>
            {user.phoneNumber}
          </label>
        </div>
            
        <div className="logout-button-parent-profile-card">
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={LogOut}
          >
            <img
              className="logout-ico-profile-card"
              alt="edit"
              src={logout_ico}
            ></img>
            <label>Log Out</label>
          </button>
        </div>
      </div>
    </div>
  );
}
