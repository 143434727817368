import React, { useContext, useState } from "react";
import { MainContext } from "./MainContext";
import { LoginContext } from "./LoginContext";
import { useNavigate } from "react-router-dom";

export const AllKpisContext = React.createContext();

const AllKpisContextProvider = (props) => {
    const navigate = useNavigate;
    const { token, user,roles } = useContext(LoginContext)
    const [iskpiDetail, setIskpiDetail] = useState(false);
    const { AddAlert, setAlertList } = useContext(MainContext);
    const [slas, setSlas] = useState([]);
    const [selectedSla, setSelectedSla] = useState(null)
    const [kpis, setKpis] = useState([]);
    const [selectedKpi, setSelectedKpi] = useState(null);
    const [sortTrigger, setSortTrigger] = useState(false);
    const [isActiveDropdown_2, setIsActiveDropdown_2] = useState(false);
    const [isActiveDropdown_3, setIsActiveDropdown_3] = useState(false);
    const [sideAddKpi, setSideAddKpi] = useState(false);
    const [filteredKpis, setFilteredKpis] = useState([]);
    const [filters, setFilters] = useState({
        Successful: true,
        Fail: true,
        Critical: true,
        Major: true,
        Minor: true,
        Numeric: true,
        Percentage: true,
    });

    const [companyGroupList, setCompanyGroupList] = useState([])
    const [selectedCompanyGroup, setSelectedCompanyGroup] = useState()
    
    function ResetContext(){
        setIskpiDetail(false)
        setSideAddKpi(false)
        setSlas([])
        setSelectedSla(null)
        setKpis([])
        setSelectedKpi(false)
        setIsActiveDropdown_2(false)
        setIsActiveDropdown_3(false)
        setFilteredKpis([])
        setFilters({
            Successful: true,
            Fail: true,
            Critical: true,
            Major: true,
            Minor: true,
            Numeric: true,
            Percentage: true,
        })
        
    }
    function AddToSlaHandle(kpiGuid, slaGuid) {
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_ADD_KPI_TO_SLA, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
                "Authorization": "Bearer " + token,
            },
            body: JSON.stringify({
                kpiId: kpiGuid,
                slaId: slaGuid,
                accountId: user.id
            }),
        })
            .then((res) => {
                if (res.ok) {
                    AddAlert("success", "Adding KPI to SLA is Succesful")
                    return res;
                }
                throw res;
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                AddAlert("error", "Adding KPI to SLA is fail")
                console.log(error);
            });
    }
    function Sort() {
        let arr = [...kpis];
        for (let i = 0; i < arr.length; i++) {
            arr[i].targets.sort(
                (date1, date2) =>
                    parseInt(date1.targetDate.substring(5, 7)) +
                    parseInt(date1.targetDate.substring(0, 4)) * 12 -
                    (parseInt(date2.targetDate.substring(5, 7)) +
                        parseInt(date2.targetDate.substring(0, 4)) * 12)
            );
        }
        setKpis(arr);
        setSortTrigger(false);
    }

    function handleCheckboxChangeSla(index) {
        const updateSlas = slas.map((sla, i) => {
            if (index === i) {
                return { ...sla, check: !sla.check };
            }
            return sla;
        })
        setSlas(updateSlas)
    };

    function handleCopyKpiToSlas(kpiGuid) {
        const selectedSlas = slas.filter(sla => sla.check);
        selectedSlas.forEach(selectedSla => {
            AddToSlaHandle(kpiGuid, selectedSla.guid);
        });
    };

    function handleCheckboxChange(event) {
        const { value, checked } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [value]: checked,
        }));

        if (!checked) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [value]: false,
            }));
        }
    };

    function GetKpiByCompanyGroup(companyGroupId) {

        if (roles.some(role => role === "KpiRead")) {

        fetch(
            process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_KPI_BY_COMPANY_GROUP_ID + "?CompanyGroupId=" +
            companyGroupId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                },
            }
        )
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                throw res;
            })
            .then((data) => {
                setKpis(data.kpis);
                setSortTrigger(true);
                return data;
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function GetAllSlaByAccountId(accountId) {
        if (roles.some(role => role === "SlaRead")) {
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_SLA_BY_ACCOUNT_ID+ "?AccountId=" + accountId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + token,
            }
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                throw res;
            })
            .then((data) => {
                setSlas(data.slas);
                return data;
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }
    function GetAllCompanyGroup(ownerCompanyId) {
        if (roles.some(role => role === "CompanyGroupRead")) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY_GROUP, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(
                {
                    ownerCompanyId: ownerCompanyId,
                    pageNumber: 1,
                    pageSize: 9999,
                }
            ),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res;
            })
            .then((data) => {
                if (data?.companyGroups.length > 0) {
                    setSelectedCompanyGroup(data?.companyGroups[0])
                    setCompanyGroupList(data?.companyGroups);
                }
                else {
                    setSelectedCompanyGroup()
                    setCompanyGroupList([])
                }
            })
            .catch((error) => {
                setCompanyGroupList([])
                setSelectedCompanyGroup()
                console.log(error);
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }
    return (
        <AllKpisContext.Provider value={{
            ResetContext,
            iskpiDetail,
            setIskpiDetail,
            slas,
            setSlas,
            selectedSla,
            setSelectedSla,
            kpis,
            setKpis,
            selectedKpi,
            setSelectedKpi,
            sortTrigger,
            setSortTrigger,
            isActiveDropdown_2,
            setIsActiveDropdown_2,
            isActiveDropdown_3,
            setIsActiveDropdown_3,
            sideAddKpi,
            setSideAddKpi,
            filteredKpis,
            setFilteredKpis,
            filters,
            setFilters,
            AddToSlaHandle,
            Sort,
            handleCheckboxChangeSla,
            handleCopyKpiToSlas,
            handleCheckboxChange,
            GetAllSlaByAccountId,
            GetKpiByCompanyGroup,
            companyGroupList,
            setCompanyGroupList,
            selectedCompanyGroup,
            setSelectedCompanyGroup,
            GetAllCompanyGroup
        }}>
            {props.children}
        </AllKpisContext.Provider>
    );
}

export default AllKpisContextProvider;