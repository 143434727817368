import React, { useContext, useState } from "react";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";
import { useNavigate } from "react-router-dom";
import { SmallProfileCardContext } from "./SmallProfileCardContext";

export const MyUsersContext = React.createContext();

const MyUsersContextProvider = (props) =>{
    const { setMyUsers } = useContext(SmallProfileCardContext);
    const navigate = useNavigate();
    const { token, user, roles } = useContext(LoginContext)
    const { setAlertList,AddAlert } = useContext(MainContext);
    const [selectedCompany, setSelectedCompany] = useState()
    const [companyList, setCompanyList] = useState([])
    
  function SortAccounts(accounts, loggedUserId){
    accounts.sort(function(a, b) {
      if (a.id === loggedUserId) {
        return -1; // a, loggedUserId'ye eşitse, a önce gelmeli
      } else if (b.id === loggedUserId) {
        return 1; // b, loggedUserId'ye eşitse, b önce gelmeli
      } else {
        return 0; // Diğer durumlarda sıralamada değişiklik yapma
      }
    });
    return accounts
  }

    function GetAccounts(companyId) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_ACCOUNTS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
            "TenantId": "slm",
          },
          body: JSON.stringify(
            {
              companyId: companyId,
              pageIndex: 1,
              pageSize: 999,
              includeAccountType : true,
              includeCompany: true,
              includeOrganizations: true,
              includePositions: true,
            }
          ),
        })
          .then((res) => {
            if (res.ok && res.status === 200) {
              return res.json();
            }
    
            else if (res.status === 401) {
              setAlertList([])
              localStorage.clear();
              navigate("/login");
            }
            throw res;
          })
          .then((data) => {
            setMyUsers(SortAccounts(data.accounts, user.id))
 
            return data;
          })
          .catch((error) => {
            setMyUsers([]);
            console.log(error);
          });
      }

      function GetAllCompany(){
        if (roles.some(role => role === "CompanyRead")) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
            "TenantId": "slm",
          },
          body: JSON.stringify(
            {
              page: 1,
              pageSize: 9999,
            }
          ),
        })
          .then((res) => {
            if (res.ok && res.status === 200) {
              return res.json();
            }
    
            else if (res.status === 401) {
              setAlertList([])
              localStorage.clear();
              navigate("/login");
            }
            throw res;
          })
          .then((data) => {
            setCompanyList(data?.companies);
            return data;
          })
          .catch((error) => {
            console.log(error);
          });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
      }

    return(
        <MyUsersContext.Provider value={{
            selectedCompany,
            setSelectedCompany,
            companyList,
            setCompanyList,
            GetAccounts,
            GetAllCompany
        }}>
            {props.children}
        </MyUsersContext.Provider>
    )
}
export default MyUsersContextProvider;