import React, { useState } from "react";

export const ImportContext = React.createContext();

const ImportContextProvider = (props) => {

    const [slas, setSlas] = useState([]);
    const [selectedSla, setSelectedSla] = useState(-1);
    const [excelData, setExcelData] = useState([{}]);
    const [file, setFile] = useState();
    const [importLoader, setImportLoader] = useState(false);
    const [updateTableTrigger, setUpdateTableTrigger] = useState(false);


    function ResetContext(){
        setSlas([])
        setSelectedSla(-1)
        setExcelData([{}])
        setFile()
        setImportLoader(false)
        setUpdateTableTrigger(false)
    }

    return (
        <ImportContext.Provider value={{
            ResetContext,
            slas,
            setSlas,
            selectedSla,
            setSelectedSla,
            excelData,
            setExcelData,
            file,
            setFile,
            importLoader,
            setImportLoader,
            updateTableTrigger,
            setUpdateTableTrigger
        }}>
            {props.children}
        </ImportContext.Provider>
    )
}
export default ImportContextProvider;
