import React, { useContext } from 'react'
import "./SettingsMain.css"
import SettingsMenuBar from './SettingsMenuBar'
import SettingsFrame from './SettingsFrame'
import ThemaMode from '../themaToggle/ThemaMode'

export default function SettingsMain() {
  return (
    <div className='settings-main-parent'>
        <div className='settings-menu-container'>
            <SettingsMenuBar></SettingsMenuBar>
        </div>
        <div className='settings-frame-container'>
            <SettingsFrame></SettingsFrame>
        </div>
    </div>
  )
}
