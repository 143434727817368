import React, { useContext, useState } from "react";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";
import { useNavigate } from "react-router-dom";

export const NotificationContext = React.createContext();

const NotificationContextProvider = (props) => {
  const { token, user, roles } = useContext(LoginContext)
  const { AddAlert, setAlertList } = useContext(MainContext)
  const [sideNotification, setSideNotification] = useState(false)
  const [notifications, setNotifications] = useState([])
  const navigate = useNavigate()


  async function GetVerifyNotification() {
    let verifies = await GetVerifiedSla(user.id);

    async function fetchSla(verify) {
      const sla = await GetSlaById(verify.slaId);
      return { ...verify, sla };
    }

    async function fetchComp(verify) {
      const companyGroup = await GetCompanyGroup({
        id: verify.sla.companyGroupId,
        includeOwnerCompany: true,
        includeCompanies: true,
        pageNumber: 1,
        pageSize: 1
      });
      return { ...verify, companyGroup };
    }

    const verifiesWithSla = await Promise.all(verifies.map(fetchSla));

    const verifiesWithCompanyGroup = await Promise.all(verifiesWithSla.map(fetchComp));



    let temp = []
    verifiesWithCompanyGroup.forEach(verify => {
      const serviceRecipientObject = verify.companyGroup[0].companies.find(company => company.id === verify.sla.companyId);
      const serviceRecipient = serviceRecipientObject ? serviceRecipientObject.name : 'Unknown Company';

      if (verify.isOwner && !verify.isSigned) {
        temp.push(
          {
            text:
              "Sorumlu kişisi olduğunuz, " +
              serviceRecipient + " servis sağlayıcısı ile " +
              verify.companyGroup[0].ownerCompany.name + " servis alıcısı arasında" +
              " imzaya açılan " +
              verify.sla.name + " kontraktı için imzanız bekleniyor.",
            data: verify
          }
        );
      } else if (!verify.isOwner && !verify.isSigned) {
        temp.push(
          {
            text:
              serviceRecipient + " servis sağlayıcısı ile " +
              verify.companyGroup[0].ownerCompany.name + " servis alıcısı arasında" +
              " imzaya açılan " +
              verify.sla.name + " kontraktı için imzanız bekleniyor.",
            data: verify
          }
        );
      }
    });
    setNotifications(temp)
  }


  function GetSlaById(slaId) {
    if (roles.some(role => role === "SlaRead")) {
      return new Promise((resolve) => {
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_SLA_BY_ID + "?Id=" + slaId, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            resolve(data.sla);
            return data;
          })
          .catch((error) => {
            resolve();
            console.log(error.json());
          });
      });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }


  function GetVerifiedSla(AccountId) {
    if (roles.some(role => role === "SlaRead")) {

      return new Promise((resolve) => {
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_VERIFIED_SLA + "?AccountId=" + AccountId + "&IsSigned=false&PageSize=999&PageNumber=1", {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            resolve(data.verifiedSlas);
            return data;
          })
          .catch((error) => {
            resolve([]);
            console.log(error.json());
          });
      });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetCompanyGroup(body) {
    return new Promise((resolve) => {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY_GROUP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(
          body
        ),
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          resolve(data?.companyGroups);
        })
        .catch((error) => {
          resolve([])
          console.log(error);
        });
    })
  }


  return (
    <NotificationContext.Provider value={{
      sideNotification,
      setSideNotification,
      notifications,
      setNotifications,
      GetVerifiedSla,
      GetVerifyNotification
    }}>
      {props.children}
    </NotificationContext.Provider>
  );
}

export default NotificationContextProvider;