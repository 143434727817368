import React, { useContext } from 'react'
import "./CompaniesMain.css"
import CompanyGroupBox from "./CompanyGroupBox"
import CompanyDetail from './CompanyDetail'
import { CompanyGroupContext } from '../../contexts/CompanyGroupContext'

export default function CompaniesMain({ companyGroups, GetCompanies }) {

  const { isCompanyDetail } = useContext(CompanyGroupContext);


  return (
    <div className='companies-main-container'>
      <div className='company-group-box-parent' style={{width: isCompanyDetail ? '60%' : ''}}>
        {companyGroups?.map((companyGroup) => (
          <React.Fragment key={companyGroup.companyGroupId}>
            <CompanyGroupBox companyGroup={companyGroup} GetCompanies={GetCompanies}></CompanyGroupBox>
          </React.Fragment>
        ))}
      </div>
      <div className='company-detail-container' style={{width: isCompanyDetail ? '40%' : ''}}>
        {isCompanyDetail && <CompanyDetail></CompanyDetail>}
      </div>
    </div>
  )
}
