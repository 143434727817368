import React, { useContext, useEffect, useState } from "react";
import "../styles/CustomerDetail.css";
import logo from "../assets/sla-2.png";
import trend_ico from "../assets/trend.png";
import add_ico from "../assets/add.png";
import Kpis from "./Kpis";
import StackedAreaPlot from "../components/StackedAreaPlot";
import { MainContext } from "../contexts/MainContext";
import { CustomerDetailContext } from "../contexts/CustomerDetailContext";
import { CustomersContext } from "../contexts/CustomersContext";
import { useNavigate } from "react-router-dom";
import { CopySlaContext } from "../contexts/CopySlaContext";
import up_arrow_ico from "../assets/up-arrow.png"
import mail_ico from "../assets/mail_2.png"
import copy_ico from "../assets/copy-3.png"
import { LoginContext } from "../contexts/LoginContext";
import editIco from "../assets/pencil.png"
import EditSla from "../components/EditSla";
import deleteIco from "../assets/delete.png"
import { EditSlaContext } from "../contexts/EditSlaContext";
import PopUpAlert from "../components/PopUpAlert/PopUpAlert";
import EmailSenderMain from "../components/EmailSender/EmailSenderMain"
import sign_ico from "../assets/sign.png"
import { MailContext } from "../contexts/MailContext";

export default function CustomerDetail() {
  const [searchSla, setSearchSla] = useState("");
  const navigate = useNavigate();
  const { langFile } = useContext(MainContext);
  const { token, user, roles } = useContext(LoginContext)
  const { mailTemplates } = useContext(MailContext)
  const { trendChartSide,
    setTrendChartSide,
    slas,
    setSlas,
    selectedSla,
    setSelectedSla,
    data,
    setData,
    sideAddSla,
    setSideAddSla,
    kpisSideTrigger,
    setKpisSideTrigger,
    customerList,
    isOpenEditSla,
    setIsOpenEditSla,
    getAllSlaByCompanyIdTrigger,
    handleCheckboxChange,
    CompanyGroupGetAll,
    getAllSla,
    GetAllSlaType,
    DeleteSla,
    CopySlaHandle,
    ResetContext,
    organizations,
    users,
    GetAllOrganization,
    GetAllUser,
    informationEmail,
    setInformationEmail,
    setSignSide,
    signSide,
    selectedServiceRecipientCompany
  } = useContext(CustomerDetailContext);
  const { setToEditSla, } = useContext(EditSlaContext);
  const { isDeletePopUp, setIsDeletePopUp, selectedCompanyGroup } = useContext(CustomersContext);
  const mailTemplate = mailTemplates.find(mailTemplate => mailTemplate?.name === "Contract Information")


  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      ResetContext()
    };
  }, []);
  useEffect(() => {
    if (user?.company?.id) {
      GetAllOrganization({
        pageNumber: 1,
        pageSize: 999
      })
      GetAllUser({
        companyId: user.company.id,
        pageIndex: 1,
        pageSize: 999,
        includeAccountType: true,
        includeCompany: true,
        includeOrganizations: true,
        includePositions: true
      })
    }
  }, [user])

  useEffect(() => {
    if (!selectedServiceRecipientCompany) {
      navigate("/main/customers");
    }
  }, [selectedServiceRecipientCompany])

  useEffect(() => {
    if (user?.company?.id) {
      CompanyGroupGetAll(user.company.id)
    }
  }, [user?.company?.id])

  useEffect(() => {
    if (selectedSla !== false) {
      setData([]);
      let Arr = [];
      selectedSla.calculatedResults.map((item, index) => (
        Arr.push({
          type: selectedSla?.name,
          date: item.date,
          value: item.isSucceed === true ? 1 : 0,
        })
      )
      );
      setData([...Arr]);
    }
  }, [selectedSla])

  useEffect(() => {
    if (selectedServiceRecipientCompany) {
      getAllSla()
    }
  }, [getAllSlaByCompanyIdTrigger]);

  useEffect(() => {
    GetAllSlaType()
  }, []);

  function HandleEditButton(sla) {
    setToEditSla(sla)
    setIsOpenEditSla(true)
  }

  return (
    <div className="parent-container-customer-detail">
      {isOpenEditSla ? <EditSla></EditSla> : null}
      <div className="nav-root">
        <div className="info-box">
          <div className="logo-box">
            <img
              className="logo-box-ico"
              alt="customers"
              src={logo}
            ></img>
          </div>
          <div className="text-box">
            <label
              style={{
                color: "var(--textColor)",
                fontSize: "0.8vw",
                fontWeight: "500",
              }}
            >
              {langFile.pages.CustomerDetail.customersSla}
            </label>
            <label
              style={{
                color: "var(--textColor)",
                fontSize: "0.6vw",
              }}
            >
              {langFile.pages.CustomerDetail.header2}
            </label>
          </div>
        </div>
        <div className="customer-name-parent">
          <label className="customer-name-label">
            {selectedCompanyGroup?.ownerCompany?.name +
              " > " +
              selectedServiceRecipientCompany?.name +
              (selectedSla !== false ? " > " + selectedSla.name : "")}
          </label>
        </div>
        <input
          className="search-item"
          id="search"
          type="search"
          placeholder={langFile.pages.CustomerDetail.searchPlaceholder}
          onChange={(e) => setSearchSla(e.target.value)}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "84vh",
          width: "100%",
          padding: "0 5%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: selectedSla !== false && kpisSideTrigger === true ? "35%" : "100%",
            WebkitTransition: "width .5s",
            transition: "width .5s",
          }}
        >
          <table
            className="container-customer-detail"
          >
            <thead>
              <tr>
                <th>
                  {langFile.pages.CustomerDetail.Sla}
                </th>
                <th>
                  {langFile.pages.CustomerDetail.contractType}
                </th>
                <th style={{ width: kpisSideTrigger ? "50%" : "" }}>
                  {langFile.pages.CustomerDetail.serviceProvider}
                </th>
                {!kpisSideTrigger && <th></th>}
              </tr>
            </thead>
            <tbody>
              {slas.filter(filterSla => {
                if (searchSla === "") {
                  return filterSla;
                }
                else if (filterSla.name.toLowerCase().includes(searchSla.toLocaleLowerCase())) {
                  return filterSla;
                }
              }).map((sla, index) => (
                <React.Fragment key={index}>
                  <tr style={{
                    borderRadius: sideAddSla && selectedSla === sla ? "5px 5px 0px 0px" : "5px 5px 5px 5px",
                    backgroundColor: selectedSla === sla ? "var(--tableRowHover)" : "",
                    // padding: 1vh .5vw!important;
                  }}>
                    <td
                      onClick={() => {
                        if(roles.some(role => role === "KpiRead") ){
                          setSelectedSla(sla);
                          setKpisSideTrigger(true);
                        }

                      }}
                    >
                      {sla.name}
                    </td>
                    <td
                      onClick={() => {
                        if(roles.some(role => role === "KpiRead") ){
                          setSelectedSla(sla);
                          setKpisSideTrigger(true);
                        }
                      }}
                    >
                      {sla.slaType}
                    </td>
                    <td
                      style={{ width: kpisSideTrigger ? "50%" : "" }}
                      onClick={() => {
                        if(roles.some(role => role === "KpiRead") ){
                          setSelectedSla(sla);
                          setKpisSideTrigger(true);
                        }
                      }}
                    >
                      {sla.slaType === "OKR" && sla?.ownerAccountId ?
                        "ACCOUNT: " + users.find(user => user.id === sla?.ownerAccountId)?.username :
                        sla.slaType === "OKR" && sla?.ownerOrganizationId ?
                          "ORGANIZATION: " + organizations.find(organization => organization?.id === sla?.ownerOrganizationId)?.name
                          :
                          "COMPANY: " + selectedServiceRecipientCompany.name}

                    </td>
                    {!kpisSideTrigger &&
                      <>
                        <td>
                          {roles.some(role => role === "SlaDelete") &&
                            <div className="tooltip-container">
                              <img
                                className="trend-button tooltip-trigger"
                                alt="delete_ico"
                                src={deleteIco}
                                onClick={() => {
                                  setIsDeletePopUp(sla.agreementId)
                                }}
                                style={{
                                  filter: "invert(var(--filterRatio))",
                                  WebkitFilter: "invert(var(--filterRatio))",
                                }}
                              ></img>
                              <div style={{
                                top: "0vh",
                                right: "3vw",
                                width: "6vw"
                              }}
                                className="tooltip-content">DELETE SLA</div>
                            </div>
                          }
                           {roles.some(role => role === "SlaEdit") &&
                            <div className="tooltip-container">
                              <img
                                className="trend-button tooltip-trigger"
                                alt="edit_ico"
                                src={editIco}
                                onClick={() => {
                                  HandleEditButton(sla)
                                }}
                                style={{
                                  filter: "invert(var(--filterRatio))",
                                  WebkitFilter: "invert(var(--filterRatio))",
                                }}
                              ></img>
                              <div style={{
                                top: "0vh",
                                right: "3vw",
                                width: "6vw"
                              }}
                                className="tooltip-content">EDIT SLA</div>
                            </div>
                          } 
                          { sla?.slaType !== "OKR" &&
                          roles.some(role => role === "SlaCreate") &&
                            <div className="tooltip-container">
                              <img
                                className="trend-button tooltip-trigger"
                                alt="add_ico"
                                src={copy_ico}
                                onClick={() => {
                                  setSideAddSla(true);
                                  setSelectedSla(sla);
                                }}
                                style={{
                                  filter: "invert(var(--filterRatio))",
                                  WebkitFilter: "invert(var(--filterRatio))",
                                }}
                              ></img>
                              <div style={{
                                top: "0vh",
                                right: "3vw",
                                width: "6vw"
                              }}
                                className="tooltip-content">Copy Contract</div>
                            </div>
                          }
                          <div className="tooltip-container">
                            <img
                              className="trend-button tooltip-trigger"
                              alt="trend_ico"
                              src={trend_ico}
                              onClick={() => {
                                setTrendChartSide(!trendChartSide);
                                setSelectedSla(sla);
                              }}
                              style={{
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                              }}
                            ></img>
                            <div style={{
                              top: "0vh",
                              right: "3vw",
                              width: "5vw"
                            }}
                              className="tooltip-content">Trend of Sla</div>
                          </div>
                          <div className="tooltip-container">
                            <img
                              className="trend-button tooltip-trigger"
                              alt="mail_ico"
                              src={mail_ico}
                              onClick={() => {
                                setInformationEmail(sla)
                              }}
                              style={{
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                              }}
                            ></img>
                            <div style={{
                              top: "0vh",
                              right: "3vw",
                              width: "10vw"
                            }}
                              className="tooltip-content">Send Information Email</div>
                          </div>
                          {/* <div className="tooltip-container">
                            <img
                              className="trend-button tooltip-trigger"
                              alt="sign_ico"
                              src={sign_ico}
                              onClick={() => {
                                setSelectedSla(sla);
                                setSignSide(true)
                              }}
                              style={{
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                              }}
                            ></img>
                            <div style={{
                              top: "0vh",
                              right: "3vw",
                              width: "3vw"
                            }}
                              className="tooltip-content">Sign</div>
                          </div> */}
                        </td>
                        <td style={{ width: "0%" }}>
                          {isDeletePopUp === sla.agreementId && <PopUpAlert key={"pop-up" + index} text={"Do you wanna delete " + sla.name + " ?"} func={DeleteSla} setIsPopUp={setIsDeletePopUp} body={{ agreementId: sla.agreementId, accountId: sla.accountId }} ></PopUpAlert>}
                          {mailTemplate && informationEmail
                            && <EmailSenderMain
                              mailTemplateId={mailTemplate.id}
                              subjectParameters={mailTemplate.subjectParameters}
                              bodyParameters={`[${informationEmail.slaType}],[${informationEmail.name}],[${selectedServiceRecipientCompany.name}],[${selectedCompanyGroup?.ownerCompany?.name}],[${informationEmail.startDate}],[${informationEmail.endDate}],[...]`}
                              setState={setInformationEmail}></EmailSenderMain>
                          }
                        </td>
                      </>
                    }
                  </tr>
                  <tr
                    style={{
                      margin: "0",
                      padding: "0",
                      width: "100%",
                      height: sideAddSla && selectedSla === sla ? "7vh" : "0vh",
                    }}
                    className={
                      kpisSideTrigger === false
                        ? "add-sla-parent"
                        : "add-sla-parent add-sla-parent-side"
                    }
                  >
                    <td style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>

                      <label className="companyNameTitle-italic">Copy</label>
                      <label className="companyNameTitle-bold">{(selectedSla !== false ? selectedSla.name : null)}</label>
                      <label className="companyNameTitle-italic">from</label>
                      <label className="companyNameTitle-bold">{selectedServiceRecipientCompany?.name}</label>
                      <label className="companyNameTitle-italic">to</label>
                      <div className="add-sla-parent-content">
                        {customerList.map((item, index) =>
                          <React.Fragment key={index}>
                            <input
                              style={{ width: ".8vw" }}
                              id={`customer-${index}`}
                              type="checkbox"
                              checked={item?.check || false}
                              onChange={() => handleCheckboxChange(index)}
                            />
                            <label style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis"
                            }} htmlFor={`customer-${index}`}>{item.name}</label>

                          </React.Fragment>
                        )}
                      </div>
                      <div className="copy-sla-button-container">
                        <button onClick={() => CopySlaHandle(sla)} className="copy-sla-button">
                          <img
                            alt="copy_ico"
                            className="copy-sla"
                            src={copy_ico}
                          ></img>
                          Copy Contract
                        </button>
                        <button onClick={() => {
                          setSideAddSla(!sideAddSla);
                        }} className="up-arrow-button">
                          <img
                            alt="up-arrow"
                            className="up-arrow"
                          ></img>
                        </button>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {!trendChartSide && data &&
            <div
              className="chart-component-sla-kpi"
            >
              <div className="chart-component-sla-kpi-container">
                <StackedAreaPlot dataForRecommendation={false} data={data}></StackedAreaPlot>
              </div>
            </div>
          }
        </div>
        {selectedSla !== false && kpisSideTrigger === true && (
          <Kpis></Kpis>
        )}
      </div>
    </div>
  );
}
