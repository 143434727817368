import React, { useContext, useEffect, useState } from 'react'
import "../styles/Dashboard.css"
import CounterBoxes from '../components/DashboardComponents/CounterBoxes/CounterBoxes'
import sla_ico from "../assets/sla.png"
import ola_ico from "../assets/ola.png"
import uc_ico from "../assets/uc.png"
import customer_ico from "../assets/people-2.png"
import CounterBoxesThreePart from '../components/DashboardComponents/CounterBoxesThreePart/CounterBoxesThreePart'
import ChartSelect from "../components/ChartSelect";
import HorizontalBarChart from '../components/HorizontalBarChar'
import { MainContext } from '../contexts/MainContext'
import VerticalBarChart from '../components/DashboardComponents/VerticalBarChart/VerticalBarChart'
import { DashboardContext } from '../contexts/DashboardContext'
import WarningList from '../components/DashboardComponents/WarningList/WarningList'
import { LoginContext } from '../contexts/LoginContext'
import { useNavigate } from 'react-router-dom'
import SelectCompany from "../components/SelectCompany"

export default function Dashboard() {
  const { langFile, setAlertList } = useContext(MainContext);
  const { token, user } = useContext(LoginContext)
  const { selectedCompany,
    setSelectedCompany,
    statistics,
    setStatistics,
    setServiceProviderList,
    dataForHorizontalChart,
    setDataForHorizontalChart,
    dataForVerticalChart,
    setDataForVerticalChart,
    filteredDataVerticalChart,
    setFilteredDataVerticalChart,
    targetStatistics,
    setTargetStatistics,
    selectedCompanyGroup,
    setSelectedCompanyGroup,
    GetSlaStatisticsByStartAndFinishDate,
    GetTargetStatistics,
    GroupedData,
    CompanyGroupGetAll,
    CalculateChartData,
    companyGroupList,
    ResetContext
  } = useContext(DashboardContext)


  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      ResetContext()
    };
  }, []);

  useEffect(() => {
    if (user?.company?.id) {
      CompanyGroupGetAll(user.company.id)
    }
  }, [user?.company?.id])

  useEffect(() => {
    setSelectedCompany("All")
    if(selectedCompanyGroup?.companyGroupId){
      GetSlaStatisticsByStartAndFinishDate()
    }
  }, [selectedCompanyGroup])

  
  useEffect(() => {//to get target statistic
    // const selectedCompanyId = companyGroupList?.find(companyGroup => companyGroup.companyGroupId === selectedCompanyGroup.companyGroupId)?.companies.find(item => item.name === selectedCompany)?.id//to find company id
    const selectedCompanyId =   selectedCompanyGroup?.companies.find(item => item.name === selectedCompany)?.id//to find company id
    const serviceProviderId = selectedCompany !== "All" ? "CompanyId=" + selectedCompanyId + "&" : ""
    GetTargetStatistics(serviceProviderId)
  }, [selectedCompany, selectedCompanyGroup, companyGroupList])

  useEffect(() => {
      const result = Object.values(GroupedData(statistics));
      CalculateChartData(result)
  }, [statistics, selectedCompanyGroup])

  useEffect(() => {//when selected a company from left chart we must filter
    const filteredData = dataForVerticalChart.filter((item) => {
      return item.label === selectedCompany;
    });
    setFilteredDataVerticalChart(filteredData)
  }, [selectedCompany, dataForHorizontalChart,dataForVerticalChart])

  return (
    <div className='dashboard-parent'>
      <div className='chart-select-container'>
        {/* <ChartSelect></ChartSelect> */}
        <SelectCompany></SelectCompany>
        <label className="selected-comp-label">
          {langFile.pages.NewCompany.selectedCompanyGroup}
        </label>
        <select
          name="companyGroup"
          id="companyGroup"
          onChange={(event) => {
            setSelectedCompanyGroup(
              companyGroupList.find(companyGroup => companyGroup.companyGroupId === event.target.value)
            )
          }}
          value={selectedCompanyGroup?.companyGroupId}
        >
          {companyGroupList?.map((companyGroup, index) => (
            <option key={index} value={companyGroup.companyGroupId}>
              {companyGroup.name}
            </option>
          ))}
        </select>
      </div>
      
      <div className='counter-boxes-container'>
        <CounterBoxesThreePart
          text_1={langFile.pages.Dashboard.chart2TotalSla}
          count_1={dataForHorizontalChart.find(item => item.label === "All" && item.type === "SLA")?.value + dataForHorizontalChart.find(item => item.label === "All" && item.type === "SLA")?.deactiveValue}
          text_2={"Active"}
          count_2={dataForHorizontalChart.find(item => item.label === "All" && item.type === "SLA")?.value}
          text_3={"Deactive"}
          count_3={dataForHorizontalChart.find(item => item.label === "All" && item.type === "SLA")?.deactiveValue}
          backgroundColor={"#AEC8D7"}
          src={sla_ico}
        ></CounterBoxesThreePart>
        <CounterBoxesThreePart
          text_1={langFile.pages.Dashboard.chart2TotalOla}
          count_1={dataForHorizontalChart.find(item => item.label === "All" && item.type === "OLA")?.value + dataForHorizontalChart.find(item => item.label === "All" && item.type === "OLA")?.deactiveValue}
          text_2={"Active"}
          count_2={dataForHorizontalChart.find(item => item.label === "All" && item.type === "OLA")?.value}
          text_3={"Deactive"}
          count_3={dataForHorizontalChart.find(item => item.label === "All" && item.type === "OLA")?.deactiveValue}
          backgroundColor={"#E6CDD8"}
          src={ola_ico}
        ></CounterBoxesThreePart>
        <CounterBoxesThreePart
          text_1={langFile.pages.Dashboard.chart2TotalUc}
          count_1={dataForHorizontalChart.find(item => item.label === "All" && item.type === "UC")?.value + dataForHorizontalChart.find(item => item.label === "All" && item.type === "UC")?.deactiveValue}
          text_2={"Active"}
          count_2={dataForHorizontalChart.find(item => item.label === "All" && item.type === "UC")?.value}
          text_3={"Deactive"}
          count_3={dataForHorizontalChart.find(item => item.label === "All" && item.type === "UC")?.deactiveValue}
          backgroundColor={"#CCE3D0"}
          src={uc_ico}
        ></CounterBoxesThreePart>
                <CounterBoxesThreePart
          text_1={langFile.pages.Dashboard.chart2TotalOkr}
          count_1={dataForHorizontalChart.find(item => item.label === "All" && item.type === "OKR")?.value + dataForHorizontalChart.find(item => item.label === "All" && item.type === "OKR")?.deactiveValue}
          text_2={"Active"}
          count_2={dataForHorizontalChart.find(item => item.label === "All" && item.type === "OKR")?.value}
          text_3={"Deactive"}
          count_3={dataForHorizontalChart.find(item => item.label === "All" && item.type === "OKR")?.deactiveValue}
          backgroundColor={"#CCE3D0"} 
          src={uc_ico}
        ></CounterBoxesThreePart>
        <CounterBoxes
          text={langFile.pages.Dashboard.totalServiceProvider}
          count={companyGroupList?.find(companyGroup => companyGroup.companyGroupId === selectedCompanyGroup.companyGroupId)?.companies?.length}
          backgroundColor={"#F4E8D5"}
          src={customer_ico}
        ></CounterBoxes>
      </div>
      <div className='charts-container'>
        <div className='aggrement-statics-container'>
          <label
            className='title'
          >
            {langFile.pages.Dashboard.slaCountByCompanies}
          </label>
          <div className='horizontalBarChart-container'>
            <HorizontalBarChart data={dataForHorizontalChart}></HorizontalBarChart>
          </div>
        </div>
        <div className='statistics-by-company-container'>
          <div className='line-chart-container'>
            <label
              className='title'
            >
              {selectedCompany ? langFile.pages.Dashboard.statisticsByCompanies + " (" + selectedCompany + ")" : langFile.pages.Dashboard.statisticsByCompanies + " (ALL COMPANIES)"}
            </label>
            <div className='bar-chart-container'>
              <VerticalBarChart data={filteredDataVerticalChart}></VerticalBarChart>
            </div>
          </div>
          <div className='warnings-list-container'>
            <label
              className='title'
            >
              {langFile.pages.Dashboard.warningAgreementList}
            </label>
            <div className='warning-table-container'>
              <WarningList data={targetStatistics}></WarningList>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
