import React, { useState } from "react";

export const ServiceContext = React.createContext();

const ServiceContextProvider = (props) => {

    const [services, setServices] = useState([]);
    const [isDeletePopUp, setIsDeletePopUp] = useState(false);
    const [toEditService, setToEditService] = useState(false);



    return (
        <ServiceContext.Provider value={{
            services,
            setServices,
            isDeletePopUp,
            setIsDeletePopUp,
            toEditService,
            setToEditService
        }}>
            {props.children}
        </ServiceContext.Provider>
    );
}

export default ServiceContextProvider;