import React from "react";
import "../styles/DashboardInfoBox.css";

export default function DashboardInfoBox(props) {
  return (
    <div className="container-dashboard-info-box">
      {props.info.map((data,index)=>
        <div className="parent-dib" key={index}>
        <div className="infobox-dashboard" >{data.number}</div>
        <h5>{data.text}</h5>
      </div>
      )}
      
    </div>
  );
}
