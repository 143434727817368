import React, { useState, useContext, useEffect } from 'react'

import '../styles/ForgotPassword.css'
import { useNavigate, useParams } from 'react-router-dom';
import { MainContext } from "../contexts/MainContext";
import showIco from "../assets/eye.png"
import hiddenIco from "../assets/hidden.png"
import { ForgotPasswordContext } from '../contexts/ForgotPasswordContext';
import ReactCodeInput from 'react-code-input';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'



export default function ForgotPassword() {

  const { langFile } = useContext(MainContext);
  const { showPassword,
    setShowPassword,
    resetFormik,
    mailFormik,
    verifyCodeFormik,
    codeRemainingTime,
    setCodeRemainingTime,
    forgetPasswordCompleted,
    setForgetPasswordCompleted,
    ResetContext,
    disableEmailInput
  } = useContext(ForgotPasswordContext)
  const { resetcode } = useParams();

  const props = {
    className: ReactCodeInput,
    inputStyle: {
      fontFamily: 'monospace',
      margin: '1vh .5vw',
      MozAppearance: 'textfield',
      width: '2vw',
      borderRadius: '3px',
      fontSize: '1vw',
      height: '4vh',
      textAlign: "center",
      color: '#000',
      border: '1px solid #000'
    }
  }

  const navigate = useNavigate()

  const handleChange = (value) => {
    verifyCodeFormik.setFieldValue('code', value.toString())
  };

  const handleComplete = (totalElapsedTime) => {
    console.log(`Geri sayım tamamlandı!`);
    setCodeRemainingTime(0)
  };

  useEffect(() => {
    if (forgetPasswordCompleted) {
      const timeoutId = setTimeout(() => {
        navigate("/login");
      }, 3000);
      
      return () => clearTimeout(timeoutId);
    }
  }, [forgetPasswordCompleted]);

  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      ResetContext()
    };
  }, []);

  if (resetcode && !forgetPasswordCompleted) {
    return (
      <div className='forgot-password-parent'>
        <div className='form-container'>
          <div className='formbold-form-wrapper'>
            <form onSubmit={resetFormik.handleSubmit}>
              <div className="formbold-form-title">
                <h2 style={{ textAlign: "left" }}>
                  {langFile.pages.ForgotPassword.resetPass}
                </h2>
                <p> {langFile.pages.ForgotPassword.resetInfo}</p>
              </div>
              <div className="formbold-input-flex">
                <div>
                  <label htmlFor="password" className="formbold-form-label">
                    {langFile.pages.ForgotPassword.password}
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      className="formbold-form-input"
                      onChange={resetFormik.handleChange}
                      value={resetFormik.values.password}
                      autoComplete="off"
                    />
                    <span className="show-password-span">
                      <img
                        style={{ width: "20px", height: "20px" }}
                        className="trend-button tooltip-trigger"
                        alt="showIco"
                        src={showPassword ? hiddenIco : showIco}
                        onClick={() => setShowPassword(!showPassword)} />
                    </span>
                  </div>
                  {resetFormik.errors.password && resetFormik.touched.password && <label className="errorLabel">{resetFormik.errors.password}</label>}
                </div>
              </div>
              <div className="formbold-input-flex">
                <div>
                  <label htmlFor="confirmPassword" className="formbold-form-label">
                    {langFile.pages.ForgotPassword.confirmPassword}
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      className="formbold-form-input"
                      onChange={resetFormik.handleChange}
                      value={resetFormik.values.confirmPassword}
                      autoComplete="off"
                    />
                    <span className="show-password-span">
                      <img
                        style={{ width: "20px", height: "20px" }}
                        className="trend-button tooltip-trigger"
                        alt="showIco"
                        src={showPassword ? hiddenIco : showIco}
                        onClick={() => setShowPassword(!showPassword)} />
                    </span>
                  </div>
                  {resetFormik.errors.confirmPassword && resetFormik.touched.confirmPassword && <label className="errorLabel">{resetFormik.errors.confirmPassword}</label>}
                </div>
              </div>
              <button className='formbold-btn' type='submit'>{langFile.pages.ForgotPassword.changePasswordBtn}</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
  else if (resetcode && forgetPasswordCompleted) {
    return (
      <div className='forgot-password-parent'>
        <div className='form-container'>
          <div className='formbold-form-wrapper'>
            <div className="formbold-form-title">
              <h2 style={{ textAlign: "left" }}>
                {langFile.pages.ForgotPassword.forgotPasswordCompleted}
              </h2>
              <p> {langFile.pages.ForgotPassword.forgotPasswordCompletedInfo}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className='forgot-password-parent'>
        <div className='form-container'>
          <div className='formbold-form-wrapper'>
            <form onSubmit={codeRemainingTime !== 0 ? verifyCodeFormik.handleSubmit : mailFormik.handleSubmit}>
              <div className="formbold-form-title">
                <h2 style={{ textAlign: "left" }}>
                  {langFile.pages.ForgotPassword.forgotPassword}
                </h2>
                <p>{codeRemainingTime === 0 ?  langFile.pages.ForgotPassword.forgotInfo : langFile.pages.ForgotPassword.sentEmailInfo}</p>
              </div>
              <div className='formbold-input-flex'>
                <div>
                  <label className='formbold-form-label' htmlFor='email'>{langFile.pages.ForgotPassword.email}</label>
                  <input
                    disabled={disableEmailInput }
                    className="formbold-form-input"
                    type="text"
                    name='email'
                    id="email"
                    value={mailFormik.values.email}
                    onChange={mailFormik.handleChange}
                  />
                  {mailFormik.errors.email && mailFormik.touched.email && <label className="errorLabel">{mailFormik.errors.email}</label>}
                </div>
              </div>
              {codeRemainingTime !== 0 &&
                <div className='formbold-input-flex'>
                  <div>
                    <label style={{ marginBottom: "0" }} className='formbold-form-label' htmlFor='code'>{langFile.pages.ForgotPassword.code}</label>
                    <ReactCodeInput
                      type='number'
                      fields={6}
                      value={verifyCodeFormik.values.code}
                      onChange={handleChange}
                      name='code'
                      id="code"
                      {...props} />
                    {verifyCodeFormik.errors.code && verifyCodeFormik.touched.code && <label className="errorLabel">{verifyCodeFormik.errors.code}</label>}
                    <div className='count-down-container'>
                      <CountdownCircleTimer
                        isPlaying
                        duration={codeRemainingTime}
                        colors={['#feb64c']}
                        strokeWidth={"4"}
                        trailStrokeWidth={"4"}
                        size={50}
                        onComplete={handleComplete}
                      >
                        {({ remainingTime }) => (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span style={{ fontSize: '1vw', fontWeight: 'bold', color: "#feb64c" }}>{remainingTime}</span>
                          </div>
                        )}
                      </CountdownCircleTimer>
                    </div>
                  </div>
                </div>
              }
              <button className='formbold-btn' type='submit'>{codeRemainingTime === 0 ? langFile.pages.ForgotPassword.sendMailBtn: langFile.pages.ForgotPassword.submitCode}</button>
            </form>
          </div>
        </div>
      </div>
    )
  }

}

