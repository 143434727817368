import React, { useContext, useEffect, useState } from "react";
import "../styles/Import.css";
import import_ico from "../assets/import.png";
import ImportTable from "../components/ImportTable.js";
import Excelexport from "../components/Excelexport";
import * as xlsx from "xlsx";
import { MainContext } from "../contexts/MainContext";
import { ImportContext } from "../contexts/ImportContext";
import { LoginContext } from "../contexts/LoginContext";
import Navigation from "../components/Navigation";

export default function Import() {
  const { langFile, AddAlert } = useContext(MainContext);
  const { slas,
    setSlas,
    selectedSla,
    setSelectedSla,
    excelData,
    setExcelData,
    file,
    setFile,
    importLoader,
    setImportLoader,
    updateTableTrigger,
    setUpdateTableTrigger,
    ResetContext } = useContext(ImportContext);
  const { token, roles, user } = useContext(LoginContext)

  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      ResetContext()
    };
  }, []);

  useEffect(() => {
    if (roles.some(role => role === "SlaRead")) {

      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_SLA_BY_ACCOUNT_ID + "?AccountId=" + user.id, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          "Authorization": "Bearer " + token,
        }
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((data) => {
          setSlas(data.slas);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }, [])

  function ReadUploadFile(e) {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  function handleUploadClick() {
    if (!file) {
      return;
    }
    setImportLoader(true)
    const formData = new FormData();
    formData.append("excelFile", file);
    formData.append("accountId", user.id);
    if (roles.some(role => role === "Import")) {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_UPLOAD_SLA_DATA, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          'Accept': 'application/json',
          "Authorization": "Bearer " + token,
        },
      })
        .then((res) => {
          if (res.ok) {
            setImportLoader(false)
            setUpdateTableTrigger(!updateTableTrigger);
            AddAlert("success", "Import Successful")
            res.json();
            return res;
          }
          throw res;
        })
        .catch((err) => {
          setImportLoader(false)
          setUpdateTableTrigger(!updateTableTrigger);
          AddAlert("error", "Import Failed")
          console.error(err)
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  return (
    <div className="import-parent">

      <div className="navigation-container">
        <Navigation img={import_ico} text_1={langFile.pages.Import.import}
          text_2={langFile.pages.Import.shareData}></Navigation>

      </div>
      <div className="import-container">
        <div className="import-column-1">

          {roles.some(role => role === "Import") &&
            <div className="box-parent">
              <label className="header-1">{langFile.pages.Import.import}</label>
              <label className="explain">
                {langFile.pages.Import.uploadFileExplain}
              </label>
              <input
                type="file"
                id="file"
                accept=".xlsx"
                required="required"
                // onChange={handleFileChange}
                onChange={ReadUploadFile}
              />

              <div>{file && file.name + " " + file.type}</div>

              <button className="upload-btn" onClick={handleUploadClick} style={{ pointerEvents: importLoader === true ? "none" : "all" }}>

                {importLoader === true ? <div className="loader"></div> : "Upload"}

              </button>
            </div>}


          <div className="box-parent">
            <label className="header-1">{langFile.pages.Import.export}</label>
            <label className="explain">
              {langFile.pages.Import.exportExplain}
            </label>


            <Excelexport
              excelData={excelData}
              fileName={"SLA EXPORT FILE"}
            ></Excelexport>
          </div>

          <div className="select-sla-container">
            <label className="label-import-1">
              {langFile.pages.Import.slaName}
            </label>
            <select
              name="selectSla"
              id="selectSla"
              onChange={event => setSelectedSla(event.target.value)}
            >
              <option value="-1">
                {"SELECT SLA / OLA / UC"}
              </option>
              {slas.map((sla, index) => (
                <option key={index} value={index}>
                  {sla.name}
                </option>
              ))}
            </select>
          </div>
        </div>


        <div className="table-container">
          <ImportTable
            setUpdateTableTrigger={setUpdateTableTrigger}
            updateTableTrigger={updateTableTrigger}
            excelData={excelData}
            setExcelData={setExcelData}
            selectedSla={parseInt(selectedSla) !== -1 ? slas[selectedSla] : "notSelected"}></ImportTable>
        </div>
      </div>
    </div>
  );
}
