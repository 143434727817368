import React, { useContext } from 'react'
import "./NotificationItem.css"
import notification_ico from "../../assets/notification-bell.png";
import { CustomerDetailContext } from '../../contexts/CustomerDetailContext';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';

export default function NotificationItem({notification}) {
  const { selectedServiceRecipientCompany,
    setSelectedServiceRecipientCompany } = useContext(CustomerDetailContext)
    const {setSideNotification} = useContext(NotificationContext)
    const navigate = useNavigate()
    
  return (
    <div className='notification-item-container' title={notification} 
    onClick={() => { 
      const serviceRecipientObject = notification.data.companyGroup[0].companies.find(company => company.id === notification.data.sla.companyId);
      if(serviceRecipientObject){
        setSelectedServiceRecipientCompany(serviceRecipientObject);
        navigate("/main/customer-detail");
        setSideNotification(false)
      }            
    }}>
        <div className='notifications-ico-container'>
            <img className="notifications-ico" alt="notifications" src={notification_ico} />
        </div>
        <label className='desc'>
        {notification.text}
        </label>
    </div>
  )
}
