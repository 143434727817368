import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "../styles/CreateService.css";
import { MainContext } from "../contexts/MainContext";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";

const newServiceValidationSchema = yup.object().shape({
  key: yup.string().required("'Key' must not be empty.Provide a valid name"),
  name: yup.string().required("'Name' must not be empty.Provide a valid name"),
  platform: yup.string(),
  serviceOwner: yup.string(),
  serviceProcessManager: yup.string(),
  businessOwner: yup.string(),
  team: yup.string(),
  description: yup.string().required("'Description' must not be empty.Provide a valid name"),
  relatedTechnicalService: yup.string(),
  customers: yup.string(),
  supportCompany: yup.string()
});


export default function CreateService() {
  const navigate = useNavigate();
  const { token, user,roles } = useContext(LoginContext)
  const { langFile, AddAlert, setAlertList } = useContext(MainContext);

  const formik = useFormik({
    initialValues: {
      key: '',
      name: '',
      platform: '',
      serviceOwner: '',
      serviceProcessManager: '',
      businessOwner: '',
      relatedTechnicalService: '',
      customers: '',
      supportCompany: '',
      team: '',
      description: '',
    },
    validationSchema: newServiceValidationSchema,
    onSubmit: (values, { resetForm }) => {
      createNewService(values)
    },
  });

  function createNewService(values) {
    if (roles.some(role => role === "ServiceCreate")) {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_CREATE_SERVICE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify({
          key: values.key,
          name: values.name,
          platform: values.platform,
          serviceOwner: values.serviceOwner,
          serviceProcessManager: values.serviceProcessManager,
          businessOwner: values.businessOwner,
          team: values.team,
          description: values.description,
          supportCompany: values.supportCompany,
          customers: values.customers.split(",").map(customer => customer.trim()),
          relatedTechnicalService: values.relatedTechnicalService,
        }),
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }

          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          if (data.okResult.statusCode === 200) {
            formik.resetForm()
            AddAlert("success", "Create Service successful")
            return data;
          }
          else if (data.statusCode === 400) {
            AddAlert("error", data.error.message)
            return data;
          }
        })
        .catch((error) => {
          error.json().then((err) => {
            const errorObject = err.errors
            Object.keys(errorObject).forEach(key => {
              const errors = errorObject[key];

              let errorText = ""
              errors.forEach(error => {
                errorText += (error + ". ")
              });
              formik.setFieldError(key, errorText)
            });
          })
          AddAlert("error", "Create Service failed")
          error.json().then()
          console.log(error)
        });
    }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }


  return (
    <div className="parent-new-service">
      <div className="formbold-form-wrapper">
        <form onSubmit={formik.handleSubmit}>
          <div className="formbold-form-title">
            <h2 style={{ textAlign: "left" }}>
              {langFile.pages.NewService.newService}
            </h2>
            <p>{langFile.pages.NewService.serviceInfo}</p>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="key" className="formbold-form-label">
                {langFile.pages.NewService.key}
              </label>
              <input
                type="text"
                name="key"
                id="key"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.key}
              />
              {formik.errors.key && formik.touched.key && <label className="errorLabel">{formik.errors.key}</label>}
            </div>
            <div>
              <label htmlFor="name" className="formbold-form-label">
                {langFile.pages.NewService.name}
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && <label className="errorLabel">{formik.errors.name}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="platform" className="formbold-form-label">
                {langFile.pages.NewService.platform}
              </label>
              <input
                type="text"
                name="platform"
                id="platform"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.platform}
              />
              {formik.errors.platform && formik.touched.platform && <label className="errorLabel">{formik.errors.platform}</label>}
            </div>


            <div>
              <label htmlFor="serviceOwner" className="formbold-form-label">
                {langFile.pages.NewService.serviceOwner}
              </label>
              <input
                type="text"
                name="serviceOwner"
                id="serviceOwner"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.serviceOwner}
              />
              {formik.errors.serviceOwner && formik.touched.serviceOwner && <label className="errorLabel">{formik.errors.serviceOwner}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="serviceProcessManager" className="formbold-form-label">
                {langFile.pages.NewService.serviceProcessManager}
              </label>
              <input
                type="text"
                name="serviceProcessManager"
                id="serviceProcessManager"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.serviceProcessManager}
              />
              {formik.errors.serviceProcessManager && formik.touched.serviceProcessManager && <label className="errorLabel">{formik.errors.serviceProcessManager}</label>}
            </div>


            <div>
              <label
                htmlFor="businessOwner"
                className="formbold-form-label"
              >
                {langFile.pages.NewService.businessOwner}
              </label>
              <input
                type="text"
                name="businessOwner"
                id="businessOwner"
                className="formbold-form-input"
                value={formik.values.businessOwner}
                onChange={formik.handleChange}
              />
              {formik.errors.businessOwner && formik.touched.businessOwner && <label className="errorLabel">{formik.errors.businessOwner}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="team" className="formbold-form-label">
                {langFile.pages.NewService.team}
              </label>
              <input
                type="text"
                name="team"
                id="team"
                className="formbold-form-input"
                value={formik.values.team}
                onChange={formik.handleChange}
              />
              {formik.errors.team && formik.touched.team && <label className="errorLabel">{formik.errors.team}</label>}
            </div>
            <div>
              <label htmlFor="relatedTechnicalService" className="formbold-form-label">
                {langFile.pages.NewService.relatedTechnicalService}
              </label>
              <input
                type="text"
                name="relatedTechnicalService"
                id="relatedTechnicalService"
                className="formbold-form-input"
                value={formik.values.relatedTechnicalService}
                onChange={formik.handleChange}
              />
              {formik.errors.relatedTechnicalService && formik.touched.relatedTechnicalService && <label className="errorLabel">{formik.errors.relatedTechnicalService}</label>}
            </div>
          </div>


          <div className="formbold-input-flex">

            <div>
              <label htmlFor="customers" className="formbold-form-label">
                {langFile.pages.NewService.customers}
              </label>
              <input
                type="text"
                name="customers"
                id="customers"
                className="formbold-form-input"
                value={formik.values.customers}
                onChange={formik.handleChange}
              />
              {formik.errors.customers && formik.touched.customers && <label className="errorLabel">{formik.errors.customers}</label>}
            </div>
            <div>
              <label htmlFor="supportCompany" className="formbold-form-label">
                {langFile.pages.NewService.supportCompany}
              </label>
              <input
                type="text"
                name="supportCompany"
                id="supportCompany"
                className="formbold-form-input"
                value={formik.values.supportCompany}
                onChange={formik.handleChange}
              />
              {formik.errors.supportCompany && formik.touched.supportCompany && <label className="errorLabel">{formik.errors.supportCompany}</label>}
            </div>
          </div>

          <div>
            <label htmlFor="description" className="formbold-form-label">
              {langFile.pages.NewService.description}
            </label>
            <textarea
              type="text"
              rows={10}
              name="description"
              id="description"
              className="formbold-form-textarea"
              value={formik.values.description}
              onChange={formik.handleChange}
            ></textarea>
            {formik.errors.description && formik.touched.description && <label className="errorLabel">{formik.errors.description}</label>}
          </div>

          <button type="submit" className="formbold-btn">
            {langFile.pages.NewService.addService}
          </button>
        </form>
      </div>
    </div>
  );
}
