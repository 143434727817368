import React, { useContext, useEffect } from 'react';
import "../styles/EditSla.css";
import { CustomerDetailContext } from '../contexts/CustomerDetailContext';
import { EditSlaContext } from '../contexts/EditSlaContext';
import { LoginContext } from '../contexts/LoginContext';
import { MainContext } from '../contexts/MainContext';
import EditSlaServices from './EditSlaComponents/EditSlaServices';
import EditSlaFile from './EditSlaComponents/EditSlaFile';
import EditSlaMetaData from './EditSlaComponents/EditSlaMetaData';
import SignAgreementMain from './SignAgreementComponents/SignAgreementMain';


export default function EditSla() {
  const {
    setIsOpenEditSla,
    slas
  } = useContext(CustomerDetailContext);

  const {
    setFile,
    ConfirmEditSlaHandle,
    setToEditSla,
    toEditSla
  } = useContext(EditSlaContext);




  function CancelButtonHandle() {
    setFile();
    setIsOpenEditSla(false)
  }
  function ConfirmButtonHandle() {
    ConfirmEditSlaHandle()

  }


  useEffect(() => {
    if (toEditSla) {
      slas.forEach(element => {
        if (element.agreementId === toEditSla.agreementId) {
          setToEditSla(element)
          return
        }
      });
    }
  }, [slas])

  return (
    <div className='edit-sla-parent'>
      <div className='edit-sla-container'>
        <div className='form-container'>
          <div className='edit-info'>
            <EditSlaMetaData></EditSlaMetaData>
          </div>

          {/* <div className='edit-service-file'>

            <div className='edit-services'>
              <EditSlaServices></EditSlaServices>
            </div>
            <div className='edit-file'>
              <EditSlaFile></EditSlaFile>
            </div>
          </div> */}

          <div className='edit-services'>
            <EditSlaServices></EditSlaServices>
          </div>
          <div className='edit-file'>
            <EditSlaFile></EditSlaFile>
          </div>


        </div>
        <div className='sign-container'>
          <SignAgreementMain></SignAgreementMain>

        </div>
        <div className='button-container'>
          {/* <button onClick={ConfirmButtonHandle} className='confirm-button'>
            Save the Changes
          </button> */}
          <button onClick={CancelButtonHandle} className='close-button'>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
