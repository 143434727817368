import React, { useContext, useEffect, useState} from "react";
import "../styles/KpiEdit.css";
import logo from "../assets/settings.png";
import StackedAreaPlot from "./StackedAreaPlot";
import { MainContext } from "../contexts/MainContext";
import { KpiEditContext } from "../contexts/KpiEditContext";
import { KpisContext } from "../contexts/KpisContext";
import MainEngine from "./EditKpiEngine/MainEngine";
import HistoricalDataUpdateCheck from "./EditKpiEngine/HistoricalDataUpdateCheck";
import BasicKpiInfo from "./EditKpiEngine/BasicKpiInfo";

export default function KpiEdit() {

  const [data, setData] = useState([])
  const { kpiEditSide,
  } = useContext(KpisContext);

  const { langFile } = useContext(MainContext);
  const { ResetContext } = useContext(KpiEditContext)

  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      ResetContext()
    };
  }, []);

  return (
    <React.Fragment>
      <HistoricalDataUpdateCheck></HistoricalDataUpdateCheck>
      <div
        className={
          kpiEditSide
            ? "kpi-edit-parent kpi-edit-parent-side-open"
            : "kpi-edit-parent kpi-edit-parent-side-close"
        }
      >
        <div className="navbar-root">
          <div className="info-box">
            <div className="page-logo">
              <img
                alt="customers"
                src={logo}
              ></img>
            </div>

            <div className="page-info">
              <label>
                {langFile.pages.KpiEdit.pageTitle}
              </label>
              <label>
                {langFile.pages.KpiEdit.pagePath}
              </label>
            </div>
          </div>
            <BasicKpiInfo></BasicKpiInfo>
        </div>
        <div className="chart-part">
          <StackedAreaPlot dataForRecommendation={true} data={data}></StackedAreaPlot>
        </div>
        <div className="edit-kpi-engine-container">
          <MainEngine data = {data} setData = {setData}></MainEngine>
        </div>
      </div>
    </React.Fragment>
  );
}
