import React, { useContext, useEffect, useState } from 'react'
import "./EditSlaMetaData.css"
import { EditSlaContext } from '../../contexts/EditSlaContext';
import { LoginContext } from '../../contexts/LoginContext';
import { UpdateKpiDateContext } from '../../contexts/UpdateKpiDateContext';
import { MainContext } from '../../contexts/MainContext';
import Multiselect from 'multiselect-react-dropdown';
import { SignAgreementContext } from '../../contexts/SignAgreementContext';
import { CustomerDetailContext } from '../../contexts/CustomerDetailContext';

export default function EditSlaMetaData() {
  const {
    agreementType,
    setAgreementType,
    isActive,
    setIsActive,
    slaName,
    setSlaName,
    agreementTypesList,
    setAgreementTypesList,
    toEditSla,
    setSlaId,
    setCompanyId,
    slaStartDate,
    setSlaStartDate,
    slaEndDate,
    setSlaEndDate,
    users,
    selectedOwner,
    setSelectedOwner,
    owner,
    setFile,
    ConfirmEditSlaHandle,
    setToEditSla,
    GetAllSlaType
  } = useContext(EditSlaContext);
  const { langFile } = useContext(MainContext);
  const {
    setIsOpenEditSla,
    slas
  } = useContext(CustomerDetailContext);
  const { user } = useContext(LoginContext)
  const { DeleteVerifiedSla, VerifiedSla, UpdateVerifiedSla } = useContext(SignAgreementContext)

  useEffect(() => {
    setSlaId(toEditSla?.agreementId)
    setAgreementType(toEditSla?.slaType)
    setIsActive(toEditSla?.isActive)
    setSlaName(toEditSla?.name)
    setCompanyId(toEditSla?.companyId)
    setSlaStartDate(toEditSla?.startDate)
    setSlaEndDate(toEditSla?.endDate)
  }, [toEditSla])

  function CancelButtonHandle() {
    setFile();
    setIsOpenEditSla(false)
  }
  function ConfirmButtonHandle() {
    ConfirmEditSlaHandle()

    const willBeOwnerVerify = toEditSla.verifiedSlas.find(verify => verify.accountId === selectedOwner?.id)

    if(selectedOwner){
      if(owner){
        UpdateVerifiedSla(
          {
            id: owner.guid,
            isAuthorized: owner.isAuthorized,
            isSigned: owner.isSigned,
            isOwner: false,
            updatedBy: user.id
          }
        )    
        if(willBeOwnerVerify){
          UpdateVerifiedSla(
            {
              id: willBeOwnerVerify.guid,
              isAuthorized: willBeOwnerVerify.isAuthorized,
              isSigned: willBeOwnerVerify.isSigned,
              isOwner: true,
              updatedBy: user.id
            }
          )     
        }
        else{
          VerifiedSla({
            createVerifiedSlaList: [
              {
                slaId: toEditSla.agreementId,
                accountId: selectedOwner.id,
                createdBy: user.id,
                isOwner: true
              }
            ]
          })
        }
      }
      else{
        if(willBeOwnerVerify){
          UpdateVerifiedSla(
            {
              id: willBeOwnerVerify.guid,
              isAuthorized: willBeOwnerVerify.isAuthorized,
              isSigned: willBeOwnerVerify.isSigned,
              isOwner: true,
              updatedBy: user.id
            }
          )     
        }
        else{
          VerifiedSla({
            createVerifiedSlaList: [
              {
                slaId: toEditSla.agreementId,
                accountId: selectedOwner.id,
                createdBy: user.id,
                isOwner: true
              }
            ]
          })
        }
      }
    }
    else{
      if(owner){
        UpdateVerifiedSla(
          {
            id: owner.guid,
            isAuthorized: owner.isAuthorized,
            isSigned: owner.isSigned,
            isOwner: false,
            updatedBy: user.id
          }
        )   
      }
    }
    
 

  }

  useEffect(() => {
    GetAllSlaType()
  }, []);

  function onSelect(selectedList, selectedItem) {
    setSelectedOwner(selectedItem)
  }

  function onRemove(selectedList, selectedItem) {
    setSelectedOwner()
  }

  return (
    <div className='edit-sla-metadata-container'>
      <label className='table-header'>Agreement Metadata</label>
      <div className='edit-item'>
        <label>Agreement Name</label>
        <input value={slaName} onChange={(e) => setSlaName(e.target.value)} name="agreement-name" id="agreement-name" type='text'></input>
      </div>
      <div className='edit-item'>
        <label>Agreement Type</label>
        <select
          name="agreement-type"
          id="agreement-type"
          value={agreementType}
          onChange={(e) => setAgreementType(e.target.value)}
        >
          {agreementTypesList.map((agreementType, index) =>
            <option key={index} value={agreementType.type}>{agreementType.type}</option>
          )}
        </select>
      </div>
      <div className='edit-item'>
        <label>Activity</label>
        <select
          name="agreement-activity"
          id="agreement-activity"
          value={isActive ? "Active" : "Deactive"}
          onChange={(e) => setIsActive(e.target.value === "Active" ? true : false)}
        >
          <option value={"Active"}>Active</option>
          <option value={"Deactive"}>Deactive</option>
        </select>
      </div>
      <div className='edit-item date-selection'>
        <div className='date-input-container'>
          <label className="label-metadata-2">
            {langFile.pages.NewSlaMetaData.startDate}
          </label>
          <input
            type="month"
            id="startDate"
            name="trip-start"
            // min="2023-04"
            max={slaEndDate}
            value={slaStartDate}
            onChange={(event) => setSlaStartDate(event.target.value)}
            autoComplete="on"
          />
        </div>
        <div className='date-input-container'>
          <label className="label-metadata-2">
            {langFile.pages.NewSlaMetaData.endDate}
          </label>
          <input
            type="month"
            id="endDate"
            name="trip-end"
            min={slaStartDate}
            // max="2033-04"
            value={slaEndDate}
            onChange={(event) => setSlaEndDate(event.target.value)}
            autoComplete="on"
          />
        </div>
      </div>
      <div className='edit-item'>
        <label className="label-metadata-2" htmlFor='selectedAgreementOwner'>
          {langFile.pages.NewSlaMetaData.agreementOwner}
        </label>
        <Multiselect
          name="selectedAgreementOwner"
          id="selectedAgreementOwner"
          options={users}
          onSelect={onSelect}
          onRemove={onRemove}
          displayValue="username"
          selectionLimit={1}
          closeIcon="cancel"
          selectedValues={selectedOwner ? [selectedOwner] : []}
        />
      </div>
      <div className='button-container'>
        <button onClick={ConfirmButtonHandle} className='confirm-button'>
          Save the Changes
        </button>
        {/* <button onClick={CancelButtonHandle} className='close-button'>
            Cancel
          </button> */}
      </div>
    </div>
  )
}
