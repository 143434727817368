import React, { useContext, useState } from 'react'
import "./BasicKpiInfo.css"
import { KpiEditContext } from '../../contexts/KpiEditContext';
import { MainContext } from '../../contexts/MainContext';

export default function BasicKpiInfo() {
    const {
        setInputWeight,
        inputKpiName,
        setInputKpiName,
        inputKpiType,
        setInputKpiType,
        kpiStartDate,   
        kpiEndDate,
        setKpiStartDate,
        setKpiEndDate
    } = useContext(KpiEditContext);
    
    const { langFile } = useContext(MainContext);


    function handleKpiTypeOnChange(event) {
        setInputKpiType(event.target.value);
        if (event.target.value === "Critical") {
            setInputWeight(100);
        } else if (event.target.value === "Minor") {
            setInputWeight(0);
        }
    }


    return (
        <div className='basicKpiInfo-parent'>
            <div className="edit-form-part">
                <label>{langFile.pages.KpiEdit.kpiName}</label>
                <input
                    type="text"
                    id="slaName"
                    value={inputKpiName}
                    required="required"
                    onChange={(event) => setInputKpiName(event.target.value)}
                />
            </div>
            <div className="edit-form-part">
                <label>{langFile.pages.KpiEdit.kpiType}</label>
                <select
                    name="kpiType"
                    id="kpiType"
                    value={inputKpiType}
                    onChange={(event) => handleKpiTypeOnChange(event)}
                >
                    <option value="Critical">{langFile.pages.KpiEdit.critical}</option>
                    <option value="Major">{langFile.pages.KpiEdit.major}</option>
                    <option value="Minor">{langFile.pages.KpiEdit.minor}</option>
                </select>
            </div>
            <div className="edit-form-part">
                <label >
                    {langFile.pages.NewSlaMetaData.startDate}
                </label>
                <input
                    type="month"
                    id="startDate"
                    name="trip-start"
                    max={kpiEndDate}
                    value={kpiStartDate}
                    onChange={(event) => setKpiStartDate(event.target.value)}
                    autoComplete="on"
                />
            </div>
            <div className="edit-form-part">
                <label >
                    {langFile.pages.NewSlaMetaData.startDate}
                </label>    
                <input
                    type="month"
                    id="endDate"
                    name="trip-end"
                    min={kpiStartDate}
                    value={kpiEndDate}
                    onChange={(event) => setKpiEndDate(event.target.value)}
                    autoComplete="on"
                />
            </div>
        </div>
    )
}
