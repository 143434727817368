import React, { useContext, useEffect, useRef, useState } from "react";
import { LoginContext } from "./LoginContext";
import { useNavigate } from "react-router-dom";
import { MainContext } from "./MainContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { MailContext } from "./MailContext";

export const NewUserContext = React.createContext();

const NewUserContextProvider = (props) => {

  const { SendEmail, mailTemplates } = useContext(MailContext)
  const mailTemplate = mailTemplates.find(mailTemplate => mailTemplate?.name === "Account Register Information")

  const newUserValidationSchema = yup.object().shape({
    firstName: yup.string().required("'First Name' must not be empty.Provide a valid first name"),
    lastName: yup.string().required("'Last Name' must not be empty.Provide a valid last name"),
    password: yup.string().min(8, "Password must be at least 8 characters").required("'Password' must not be empty."),
    username: yup.string().min(3, "Provide a valid username with at least 3 characters").required("'Username' must not be empty."),
    email: yup.string().email("Provide a valid email address").required("'Email' must not be empty."),
    phoneNumber: yup.string()
      .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', function (value) {
        if (value && typeof value === 'string') {
          return isValidPhoneNumber(value);
        }
        return true; // Geçerli olmayan bir değerse doğrulamayı geç
      }),
    selectedAccountType: yup.string().required("'Account Type' must not be empty.")
  });
  const { token, user, roles } = useContext(LoginContext)
  const navigate = useNavigate();
  const { langFile, AddAlert, setAlertList } = useContext(MainContext);

  const passwordRef = useRef(null)

  const [accountTypeList, setAccountTypeList] = useState();
  const [organizationGroups, setOrganizationGroups] = useState();
  const [positions, setPositions] = useState();
  const [checkedRoles, setRole] = useState([]);
  const [getAccountTrigger, setGetAccountTrigger] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      username: '',
      email: '',
      phoneNumber: '',
      countryCode: '',
      selectedAccountType: "",
      organizationGroup: "",
      organization: "",
      position: "",
    },
    validationSchema: newUserValidationSchema,
    onSubmit: (values, { resetForm }) => {
      AddUser(values);
      //   resetForm();
    },
  });

  function GetAllOrganizationGroup() {
    if (roles.some(role => role === "OrganizationRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_GROUP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify({
          pageNumber: 1,
          pageSize: 999,
          companyId: user?.company?.id
        })
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          setOrganizationGroups(data.organizationGroups);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetAllPositions() {
    if (roles.some(role => role === "PositionRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_POSITION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify({
          pageNumber: 1,
          pageSize: 999
        })
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          setPositions(data.positionDtos);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }


  function GetAllAccountGroup() {
    fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_PRIVILEGES_ACCOUNT_GROUP + "?PageNumber=1&PageSize=999", {
      method: "Get",
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
        "TenantId": "slm",
      }
    })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
        else if (res.status === 401) {
          setAlertList([])
          localStorage.clear();
          navigate("/login");
        }
        throw res;
      })
      .then((data) => {
        const otherAccountGroup = data.accountGroups.find(function (group) {
          return group.accountGroupName === "main";
        });
        if (otherAccountGroup?.accountTypes?.length > 0) {
          const initialAccountType = otherAccountGroup.accountTypes.find(function (type) {
            return type.name === "FullAccessUser";
          });
          // formik.setValues({selectedAccountType:initialAccountType.id});
        }
        setAccountTypeList(otherAccountGroup?.accountTypes);

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddUser(values) {
    if (roles.some(role => role === "UserCreate")) {
    fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_AUTH_REGISTER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
        "TenantId": "slm",
      },
      body: JSON.stringify({
        companyId: user.company.id,
        accountTypeId: values.selectedAccountType,
        username: values.username,
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        bio: "",
        phoneNumber: values.phoneNumber !== "" ? values.phoneNumber : null,
        countryCode: values.phoneNumber ? parsePhoneNumber(values.phoneNumber).country : null,
        positionId: values.position !== "" ? values.position : null,
      }),
    })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
        else if (res.status === 401) {
          setAlertList([])
          localStorage.clear();
          navigate("/login");
        }
        AddAlert("error", "Add user failed")
        throw res;
      })
      .then((data) => {
        if (data.statusCode === 400) {
          AddAlert("error", data.error.message)
          setGetAccountTrigger(!getAccountTrigger)
          return data;
        }
        else if (data.statusCode === 200) {
          formik.resetForm()
          AddAlert("success", "Add user successful")
          setGetAccountTrigger(!getAccountTrigger)

          SendEmail(
            {
              mailTemplateId: mailTemplate.id,
              subjectParameters: mailTemplate.subjectParameters,
              bodyParameters: `[${values.firstName}],[${values.lastName}],[${values.username}],[${values.password}]`,
              to: values.email,
              cc: "",
              bcc: ""
            }
          )
          return data;
        }
      })
      .catch((error) => {
        error.json().then((err) => {
          const errorObject = err.errors
          Object.keys(errorObject).forEach(key => {
            const errors = errorObject[key];
            let errorText = ""
            errors.forEach(error => {
              errorText += (error + ". ")
            });
            formik.setFieldError(key, errorText)
            // console.log(key + " : " + errorText)
          });
        })

        setGetAccountTrigger(!getAccountTrigger)
      });
    }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetRoles(arr) {
    if (arr) {
      const roles = arr.roleDtos.map(roleDto => roleDto.roleName).join(', ');
      return roles;
    }
    return ""
  }

  return (
    <NewUserContext.Provider value={{
      accountTypeList,
      setAccountTypeList,
      checkedRoles,
      setRole,
      getAccountTrigger,
      setGetAccountTrigger,
      newUserValidationSchema,
      formik,
      GetRoles,
      GetAllAccountGroup,
      organizationGroups,
      setOrganizationGroups,
      GetAllOrganizationGroup,
      positions,
      setPositions,
      GetAllPositions
    }}>
      {props.children}
    </NewUserContext.Provider>
  )
}
export default NewUserContextProvider;