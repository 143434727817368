import tr from "../assets/flags/tr.png";

import eng from "../assets/flags/gb.png";

import "../styles/LangDropdown.css";

import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../contexts/MainContext";
import { LoginContext } from "../contexts/LoginContext";
import { SettingsContext } from "../contexts/SettingsContext";

export default function LangDropdown({backgroundColor, display}) {
  const { setLocal, local} = useContext(MainContext);
  const { user } = useContext(LoginContext)
  const {CreateUserSettings, UpdateUserSettings, settingLanguage} = useContext(SettingsContext)
  
  const [isActive, setIsActive] = useState(false);
  
  function refreshPage() {
    // window.location.reload();
  }
  const languages = [
    {
      lang: "turkish",
      flag: tr,
    },

    {
      lang: "english",
      flag: eng,
    },
  ];

  function ChangeHandle(option){
    setLocal(option.lang);
    setIsActive(false);
    if(settingLanguage && user?.id){
      if(settingLanguage.isDefault){
        CreateUserSettings(
          {
            name: settingLanguage.name,
            settingsId: settingLanguage.settingsId,
            accountId: user.id,
            value: option.lang.toLowerCase()
          }
        )
      }
      else{
        UpdateUserSettings({
          userSettingsId: settingLanguage.id,
          value: option.lang.toLowerCase()
        })
      }
    }

  }

  return (
    <div className="menu-container" style={{display:display}}>
      <div
        className="menu-trigger"
        onClick={() => {
          setIsActive(!isActive);
        }}
        style={{background: backgroundColor}}
      >
        <img src={local === "english" ? eng : tr} alt="flag" />
        {local}
      </div>
      {isActive && (
        <div className="dropdown-menu">
          <ul>
            {languages.map((option, index) => (
              <div key={index}>
                <li
                  value={option}
                  className="dropdownItem"
                  onClick={() => {
                    ChangeHandle(option)
                  }}
                >
                  <img src={option.flag} alt="flag" />
                  <button>{`${option.lang}`}</button>
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
