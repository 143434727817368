import React, { useContext, useEffect } from 'react'
import "../styles/Organization.css"
import { MainContext } from '../contexts/MainContext';
import logo from "../assets/organization.png"
import { OrganizationContext } from '../contexts/OrganizationContext';
import Navigation from '../components/Navigation';
import OrganizationTree from '../components/OrganizationTree/OrganizationTree';
import plus_ico from "../assets/plus.png"
import add_ico from "../assets/plus.png"
import confirm_ico from "../assets/confirm.png"
import cancel_ico from "../assets/cancel.png"
import { LoginContext } from '../contexts/LoginContext';

export default function Organization() {
  const { langFile } = useContext(MainContext);
  const { user , roles} = useContext(LoginContext)
  const { treeData, GetOrganizationGroup,
    selectedOrganizationGroup,
    setSelectedOrganizationGroup,
    organizationGroupsList,
    setOrganizationGroupList,
    setTreeData,
    ConvertToTree,
    newOrganizationGroup,
    setNewOrganizationGroup,
    organizationGroupName,
    setOrganizationGroupName,
    CreateOrganizationGroup,
    organizationNameToCreate,
    setOrganizationNameToCreate,
    CreateOrganization,
    setShowPosition,
    setIdOfClickedDepartment,
    setParentPositionIdToAdd,
    setParentOrganizationIdToAddPosition,
    setParentOrganizationIdToAddDepartment,
    ResetContext
  } = useContext(OrganizationContext)


  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      ResetContext()
    };
  }, []);

  useEffect(() => {
      GetOrganizationGroup({
        pageNumber: 1,
        pageSize: 999,
        companyId: user?.company?.id
      })       
  }, [user])

  useEffect(() => {
    if (selectedOrganizationGroup) {
      setShowPosition(false)
      setIdOfClickedDepartment()
      setParentPositionIdToAdd(false)
      setParentOrganizationIdToAddPosition(false)
      setParentOrganizationIdToAddDepartment(false)
      const organization = organizationGroupsList?.find(org => org.id === selectedOrganizationGroup)
      if (organization) {
        setTreeData(ConvertToTree(organization.organizations))
      }

    }
  }, [selectedOrganizationGroup, organizationGroupsList])


  return (
    <div className='organization-parent'>
      <div className="navigation-container">
        <Navigation img={logo} text_1={langFile.pages.Organization.organization}
          text_2={langFile.pages.Organization.servicesOrganization}
        ></Navigation>
        {newOrganizationGroup === false ?
          <React.Fragment>
            <label className="selectedOrganizationGroup-label">
            {langFile.pages.Organization.selectOrganizationGroup}
            </label>
            <select
              className='selectedOrganizationGroup-select'
              name="organizationGroup"
              id="organizationGroup"
              onChange={(e) => setSelectedOrganizationGroup(e.target.value)}
              value={selectedOrganizationGroup}
            >
              {organizationGroupsList && organizationGroupsList.map((orgGroup, index) =>
                <option key={index} value={orgGroup.id} >
                  {orgGroup.name}
                </option>
              )}
            </select>
            {roles.some(role => role === "OrganizationCreate") &&
            <button onClick={() => { setNewOrganizationGroup("") }} className="newOrganizationGroup" >
              <img alt="newOrganizationGroup" src={add_ico}>
              </img>
              {langFile.pages.Organization.addNewOrganizationGroup}
            </button>
            }
          </React.Fragment>
          :
          <React.Fragment>
            <input autoFocus placeholder={langFile.pages.Organization.enterNewOrganizationGroupName} className="new-company-group-input" type="text" value={organizationGroupName} onChange={(e) => { setOrganizationGroupName(e.target.value) }}>
            </input>
            <img alt="confirmCompanyGroup" className="confirmCompanyGroup" onClick={() => { CreateOrganizationGroup({ name: organizationGroupName, companyId:user.company.id }) }} src={confirm_ico}>
            </img>
            <img alt="cancelCompanyGroup" className="cancelCompanyGroup" src={cancel_ico} onClick={() => { setNewOrganizationGroup(false) }}>
            </img>
          </React.Fragment>}
      </div>
      {treeData ?        
          <OrganizationTree width={"100%"} height={"86vh"} treeData={treeData}></OrganizationTree>
        :
        <div className='add-initial-organization'>
          {selectedOrganizationGroup && treeData === null &&
            <React.Fragment>
              <input className='organization-name-input' placeholder={langFile.pages.Organization.enterNameFirstDepartment} value={organizationNameToCreate}
                onChange={(e) => { setOrganizationNameToCreate(e.target.value) }}>
              </input>
              <img alt="confirmCompanyGroup" className="confirmCompanyGroup" onClick={() => {
                CreateOrganization({
                  "accountId": user.id,
                  "code": "0",
                  "name": organizationNameToCreate,
                  "organizationGroupId": selectedOrganizationGroup,
                  "organizationLevelId": null,
                  "parentOrganizationId": null
                })
              }} src={confirm_ico}>
              </img>
            </React.Fragment>
          }
        </div>
      }
    </div>
  )
}
