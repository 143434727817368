import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "../styles/EditUser.css";
import { MainContext } from "../contexts/MainContext";
import { EditUserContext } from "../contexts/EditUserContext";
import close_ico from "../assets/close.png"
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import InputMask from 'react-input-mask';
import showIco from "../assets/eye.png"
import hiddenIco from "../assets/hidden.png"
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input'
import { NewUserContext } from "../contexts/NewUserContext";


export default function EditUser() {
  const [showPassword, setShowPassword] = useState(false);
  const { langFile } = useContext(MainContext);
  const { roles } = useContext(LoginContext);
  const {
    accountTypeList,
    setToEditUser,
    toEditUser,
    formik,
    GetAllAccountGroup,
    GetRoles } = useContext(EditUserContext);

  const { organizationGroups, positions, GetAllOrganizationGroup } = useContext(NewUserContext);

  useEffect(() => { GetAllOrganizationGroup() }, [])

  useEffect(() => {
    if (toEditUser) {
      formik.setValues({
        accountId: toEditUser?.id,
        firstName: toEditUser?.firstname,
        lastName: toEditUser?.lastname,
        password: "",
        username: toEditUser?.username,
        email: toEditUser?.email,
        phoneNumber: toEditUser?.phoneNumber ? toEditUser?.phoneNumber : "",
        selectedAccountType: toEditUser?.accountType?.id,
        organizationGroup: toEditUser?.positions?.map((position) => { return position?.organization?.organizationGroup?.id })[0],
        organization: toEditUser?.positions?.map((position) => { return position?.organization?.id })[0],
        position: toEditUser?.positions?.map((position) => { return position.id })[0],
        oldPosition: toEditUser?.positions?.map((position) => { return position.id })[0],
      });
    }
  }, [toEditUser]);

  useEffect(() => {
    GetAllAccountGroup()
  }, [])

  return (
    <div className="parent-edit-user">
      <div className="formbold-form-wrapper">
        <button className="to-new-user-button" onClick={() => { setToEditUser(false) }}>
          <img alt="close" className="close-button-ico" src={close_ico}>
          </img>
        </button>
        <form onSubmit={formik.handleSubmit}>
          <div className="formbold-form-title">
            <h2 style={{ textAlign: "left" }}>
              {langFile.pages.EditUser.editUser}
            </h2>
            <p>{langFile.pages.EditUser.editInfo}</p>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="firstName" className="formbold-form-label">
                {langFile.pages.EditUser.firstName}
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && formik.touched.firstName && <label className="errorLabel">{formik.errors.firstName}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="lastName" className="formbold-form-label">
                {langFile.pages.EditUser.lastName}
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && formik.touched.lastName && <label className="errorLabel">{formik.errors.lastName}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="password" className="formbold-form-label">
                {langFile.pages.EditUser.password}
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  className="formbold-form-input"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <span className="show-password-span">
                  <img
                    style={{ width: "20px", height: "20px" }}
                    className="trend-button tooltip-trigger"
                    alt="showIco"
                    src={showPassword ? hiddenIco : showIco}
                    onClick={() => setShowPassword(!showPassword)} />
                </span>
              </div>
              {formik.errors.password && formik.touched.password && <label className="errorLabel">{formik.errors.password}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="username" className="formbold-form-label">
                {langFile.pages.EditUser.userName}
              </label>
              <input
                type="text"
                name="username"
                id="username"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.username}
                disabled={true}
              />
              {formik.errors.username && formik.touched.username && <label className="errorLabel">{formik.errors.username}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="email" className="formbold-form-label">
                {langFile.pages.EditUser.email}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="formbold-form-input"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && <label className="errorLabel">{formik.errors.email}</label>}
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="phoneNumber" className="formbold-form-label">
                {langFile.pages.EditUser.phoneNumber}
              </label>
              <PhoneInput
                international
                defaultCountry="TR"
                countryCallingCodeEditable={false}
                name="phoneNumber"
                id="phoneNumber"
                className="formbold-form-input"
                value={formik.values.phoneNumber}
                onChange={(value) => { formik.setFieldValue('phoneNumber', value) }}
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && <label className="errorLabel">{formik.errors.phoneNumber}</label>}
            </div>
          </div>
            <>
              <div>
                <label htmlFor="organizationGroup" className="formbold-form-label">{langFile.pages.NewUser.organizationGroup}</label>
                <select
                  className="formbold-form-input"
                  name="organizationGroup"
                  id="organizationGroup"
                  value={formik.values.organizationGroup}
                  onChange={formik.handleChange}
                >
                  <option value="">{langFile.pages.NewUser.selectOrganizationGroup}</option>
                  {
                    organizationGroups?.map((organizationGroup) => (
                      <option key={organizationGroup.id} value={organizationGroup.id}>{organizationGroup.name}</option>
                    ))
                  }
                </select>
              </div>

              <div>
                <label htmlFor="organization" className="formbold-form-label">{langFile.pages.NewUser.organization}</label>
                <select
                  className="formbold-form-input"
                  name="organization"
                  id="organization"
                  value={formik.values.organization}
                  onChange={formik.handleChange}
                >
                  <option value="">{langFile.pages.NewUser.selectOrganization}</option>
                  {
                    (organizationGroups?.find(group => group.id === formik.values.organizationGroup)?.organizations || [])
                      .map((organization) => (
                        <option key={organization.id} value={organization.id}>{organization.name}</option>
                      ))
                  }
                </select>
              </div>

              <div>
                <label htmlFor="position" className="formbold-form-label">{langFile.pages.NewUser.position}</label>
                <select
                  className="formbold-form-input"
                  name="position"
                  id="position"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                >
                  <option value="">{langFile.pages.NewUser.selectPosition}</option>
                  {
                    (positions?.filter(position => position.organizationId === formik.values.organization) || [])
                      .map((position) => (
                        <option key={position.id} value={position.id}>{position.name}</option>
                      ))
                  }
                </select>
              </div>

              <div>
                <label htmlFor="selectedAccountType" className="formbold-form-label">
                  {langFile.pages.EditUser.accountType}
                </label>
                <select
                  className="formbold-form-input"
                  name="selectedAccountType"
                  id="selectedAccountType"
                  value={formik.values.selectedAccountType}
                  onChange={formik.handleChange}
                >
                  {accountTypeList && accountTypeList.map((type, index) =>
                    type.name !== "Admin" &&
                    <option key={index} title={GetRoles(type)} value={type.id} >
                      {type.name}
                    </option>
                  )}
                </select>
              </div>
            </>
          
          <button type="submit" className="formbold-btn">
            {langFile.pages.EditUser.editBtn}
          </button>
        </form>
      </div>
    </div>
  );
}
