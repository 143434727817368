import React, { useState } from "react";

export const ProfileCardContext = React.createContext();

const ProfileCardContextProvider = (props) => {
    return (
        <ProfileCardContext.Provider value={{ 

           }}>
            { props.children }
        </ProfileCardContext.Provider>
    );
}

export default ProfileCardContextProvider;