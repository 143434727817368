import React from 'react'
import "../styles/RecentTransactions.css"

export default function RecentTransactions() {
  return (
    <div className="parent-container-RecentTransactions">
    <table className="container-RecentTransactions">
      <thead>
        <tr>
          <th>
            <h1 className="header-1">Date</h1>
          </th>
          <th>
            <h1 className="header-1">User</h1>
          </th>
          <th>
            <h1 className="header-1">Description</h1>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>05.02.2023/10.23</td>
          <td>Cihan Özata</td>
          <td>SLA named sla 14 created.</td>
        </tr>
        <tr>
          <td>05.02.2023/10.45</td>
          <td>Cihan Özata</td>
          <td>SLA named sla 15 created.</td>
        </tr><tr>
          <td>05.02.2023/10.46</td>
          <td>Cihan Özata</td>
          <td>SLA named sla 18 created.</td>
        </tr><tr>
          <td>05.02.2023/10.47</td>
          <td>Cihan Özata</td>
          <td>KPI named kpi 14 created.</td>
        </tr><tr>
          <td>05.02.2023/11.00</td>
          <td>Cihan Özata</td>
          <td>SLA named sla 14 created.</td>
        </tr><tr>
          <td>05.02.2023/11.30</td>
          <td>Cihan Özata</td>
          <td>Company named Coffy created.</td>
        </tr>
        <tr>
          <td>05.02.2023/11.30</td>
          <td>Kağan Ulu</td>
          <td>Export Data</td>
        </tr>
      </tbody>
    </table>
  </div>
    )
}
