import React, { useContext, useState } from "react";
import { KpisContext } from "./KpisContext";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";
import { CustomersContext } from "./CustomersContext";

export const KpiEditContext = React.createContext();

const KpiEditContextProvider = (props) => {

  const { selectedKpi } = useContext(KpisContext);
  const { token, user, roles } = useContext(LoginContext)
  const { AddAlert } = useContext(MainContext);
  const { selectedCompanyGroup } = useContext(CustomersContext);
  const [median, setMedian] = useState(0);
  const [average, setAverage] = useState(0);
  const [selectedEngine, setSelectedEngine] = useState("manuel");
  const [historicalDataUpdateCheck, setHistoricalDataUpdateCheck] = useState(false)
  const [inputTargetType, setInputTargetType] = useState("percentage");
  const [inputRange, setInputRange] = useState("Minimum");
  const [inputPercentageTarget, setInputPercentageTarget] = useState(0); //percentage target
  const [inputNumberTarget, setInputNumberTarget] = useState(0); //number target
  const [targetsForRecommendation, setTargetsForRecommendation] = useState([]);
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}`;
  const [beginDateForEngine, setBeginDateForEngine] = useState(formattedDate);
  const [rangeValues, setRangeValues] = useState();
  const [priorityValues, setPriorityValues] = useState();
  const [kpiStartDate, setKpiStartDate] = useState("");
  const [kpiEndDate, setKpiEndDate] = useState("");
  const [kpiName, setKpiName] = useState("");
  const [kpiType, setKpiType] = useState("");
  const [inputWeight, setInputWeight] = useState("");
  const [inputKpiName, setInputKpiName] = useState("");
  const [inputKpiType, setInputKpiType] = useState("");
  const [selectionTargetsForConfiguration, setSelectionTargetsForConfiguration] = useState("none"); //for select target to calculate target recommendation
  const [selectionTargetsForEdit, setSelectionTargetsForEdit] = useState("none"); //for select target to calculate target recommendation
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [kpiJson, setKpiJson] = useState([{ months: [], },]);
  const [updateConfirmActive, setUpdateConfirmActive] = useState(false)

  function ResetContext() {

  }


  function GetDate_2(startDate, mounthNumber) {
    const start = startDate.split("-");
    let yearCounter = parseInt(
      (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) / 12
    );
    let mounthCounter = parseInt(
      (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) % 12
    );
    start[1] = mounthCounter;
    start[0] = yearCounter;
    if (mounthCounter === 0) {
      start[1] += 12;
      start[0] -= 1;
    }
    if (start[1] < 10) {
      return "01-0" + String(start[1]) + "-" + String(start[0]);
    } else {
      return "01-" + String(start[1]) + "-" + String(start[0]);
    }
  }

  function activeMonth(index) {
    let temp = [...selectedMonths];
    if (selectedEngine === "manuel" || selectedEngine === "average" || selectedEngine === "overAverage" || selectedEngine === "median" || selectedEngine === "overMedian") {
      temp[index] = !temp[index];
    }
    else {
      const startDate = new Date(selectedKpi?.targets[index].referenceDate)
      const diffInMonths =
        (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
        (currentDate.getMonth() - startDate.getMonth());
      if (diffInMonths < 0) {
        temp[index] = !temp[index];
      }
    }
    setSelectedMonths(temp);
  }

  function UpdateDb() {
    UpdateKpi();//to update KPI
    let targetListToUpdate = []
    kpiJson[0].months.map((month, index) =>
      selectedMonths[index] ?
        targetListToUpdate.push({
          id: month.guid,
          accountId: user.id,
          targetTypeId: rangeValues[month.range],
          value: parseInt(month.value),
          completedValue: parseInt(month.target),
          weight: parseInt(month.weight),
          // targetDate: month.targetDate,
          isPercentage: month.isPercentage
        })
        : null
    )
    targetListToUpdate && UpdateTargetAsList(targetListToUpdate)//to update targets
  }

  function UpdateKpi() {
    if (roles.some(role => role === "KpiEdit")) {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_UPDATE_KPI_BY_ID, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        id: selectedKpi.kpiId,
        priorityId: priorityValues[inputKpiType],
        accountId: user.id,
        companyId: selectedCompanyGroup.companyGroupId,
        name: inputKpiName,
        isActive: true,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error.json());
      });
    }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function UpdateTargetAsList(
    targets
  ) {
    if (roles.some(role => role === "KpiEdit")) {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_UPDATE_TARGETS, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        accountId: user.id,
        targets: targets
      }),
    })
      .then((res) => {
        if (res.ok) {
          AddAlert("success", "Add Target Successful")
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        AddAlert("error", "Add Target Failed")
        console.log(error.json());
      });
    }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function UpdateTarget(
    guid,
    targetTypeGuid,
    value,
    completedValue,
    weight,
    targetDate,
    isPercentage
  ) {
    if (roles.some(role => role === "KpiEdit")) {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_UPDATE_TARGET_BY_ID, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        id: guid,
        accountId: user.id,
        targetTypeId: targetTypeGuid,
        value: value,
        completedValue: completedValue,
        weight: weight,
        targetDate: GetDate_2(targetDate, 0),
        isPercentage: isPercentage,
      }),
    })
      .then((res) => {
        if (res.ok) {
          AddAlert("success", "Add Target Successful")
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        AddAlert("error", "Add Target Failed")
        console.log(error.json());
      });
    }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }
  function UpdateJson(data) {
    let jsonTemp = [...kpiJson];
    for (let i = 0; i < selectedKpi?.targets.length; i++) {
      if (selectedMonths[i]) {
        if (inputTargetType === "percentage") {
          jsonTemp[0].months[i].weight = inputWeight;
          jsonTemp[0].months[i].range = inputRange;
          jsonTemp[0].months[i].isPercentage = true;
          if (selectedEngine === "manuel") {
            jsonTemp[0].months[i].target = parseInt(inputPercentageTarget);
          }
          else if (selectedEngine === "median") {
            let targetValue = data.find(item => item.type === "median" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "average") {
            let targetValue = data.find(item => item.type === "average" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "overMedian") {
            let targetValue = data.find(item => item.type === "over median" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "overAverage") {
            let targetValue = data.find(item => item.type === "over average" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "ImprovementFromBeginning") {
            let targetValue = data.find(item => item.type === "continuous improvement" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "aiArima") {
            let targetValue = data.find(item => item.type === "Arima AI" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "aiProphet") {
            let targetValue = data.find(item => item.type === "Prophet AI" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "aiExponentialSmoothing") {
            let targetValue = data.find(item => item.type === "Exponential Smoothing AI" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
        } else {
          jsonTemp[0].months[i].weight = inputWeight;
          jsonTemp[0].months[i].range = inputRange;
          jsonTemp[0].months[i].isPercentage = false;
          if (selectedEngine === "manuel") {
            jsonTemp[0].months[i].target = parseInt(inputNumberTarget);
          }
          else if (selectedEngine === "median") {
            let targetValue = data.find(item => item.type === "median" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "average") {
            let targetValue = data.find(item => item.type === "average" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "overMedian") {
            let targetValue = data.find(item => item.type === "over median" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "overAverage") {
            let targetValue = data.find(item => item.type === "over average" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "ImprovementFromBeginning") {
            let targetValue = data.find(item => item.type === "continuous improvement" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "aiArima") {
            let targetValue = data.find(item => item.type === "Arima AI" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "aiProphet") {
            let targetValue = data.find(item => item.type === "Prophet AI" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
          else if (selectedEngine === "aiExponentialSmoothing") {
            let targetValue = data.find(item => item.type === "Exponential Smoothing AI" && item.date === selectedKpi.targets[i].referenceDate)
            jsonTemp[0].months[i].target = parseInt(targetValue?.value);
          }
        }
      }
    }
    setKpiJson(jsonTemp);
  }
  return (
    <KpiEditContext.Provider value={{
      median,
      setMedian,
      average,
      setAverage,
      selectedEngine,
      setSelectedEngine,
      historicalDataUpdateCheck,
      setHistoricalDataUpdateCheck,
      inputWeight,
      setInputWeight,
      inputTargetType,
      setInputTargetType,
      inputRange,
      setInputRange,
      inputPercentageTarget,
      setInputPercentageTarget,
      inputNumberTarget,
      setInputNumberTarget,
      targetsForRecommendation,
      setTargetsForRecommendation,
      currentDate,
      formattedDate,
      beginDateForEngine,
      setBeginDateForEngine,
      rangeValues,
      setRangeValues,
      priorityValues,
      setPriorityValues,
      kpiStartDate,
      setKpiStartDate,
      kpiEndDate,
      setKpiEndDate,
      kpiName,
      setKpiName,
      kpiType,
      setKpiType,
      inputKpiName,
      setInputKpiName,
      inputKpiType,
      setInputKpiType,
      selectedMonths,
      setSelectedMonths,
      kpiJson,
      setKpiJson,
      updateConfirmActive,
      setUpdateConfirmActive,
      selectionTargetsForConfiguration,
      setSelectionTargetsForConfiguration,
      selectionTargetsForEdit,
      setSelectionTargetsForEdit,
      UpdateJson,
      UpdateDb,
      activeMonth,
      ResetContext
    }}>
      {props.children}
    </KpiEditContext.Provider>
  );
}

export default KpiEditContextProvider;