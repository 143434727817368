import React, { useContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { MainContext } from "./MainContext";
import { ThemaContext } from "./ThemaContext";


export const LoginContext = React.createContext();

const LoginContextProvider = (props) => {
    const navigate = useNavigate();
    const { AddAlert, setAlertList,setLocal} = useContext(MainContext);
    const {Dark, Light} = useContext(ThemaContext)
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [importLoader, setImportLoader] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [token, setToken] = useState(JSON.parse(localStorage.getItem("userData"))?.accessToken)
    const [roles, setRoles] = useState(() => {
        if (typeof (JSON.parse(localStorage.getItem("decodedToken"))?.permissions) === "string") {
            return [JSON.parse(localStorage.getItem("decodedToken"))?.permissions] }
        else { return JSON.parse(localStorage.getItem("decodedToken"))?.permissions }
    })
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userData"))?.user)

    function ResetContext(){
        setLoginEmail("")
        setLoginPassword("")
        setImportLoader("")
        setLoginError(false)
        setToken()
        setRoles([])
        setUser()
    }

    function SignInConfirm() {
        setImportLoader(true);
        fetch(process.env.REACT_APP_BASE_URL_AUTH+process.env.REACT_APP_AUTH_LOGIN, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'TenantId': 'slm',
          },
          body: JSON.stringify({
            emailOrUserName: loginEmail,
            password: loginPassword,
            isAndroidDevice: true,
            fcmToken: null,
            isLdap: false,
            includePositions: true,
            includeMembership: true,
            includeCompany: true,
            includeAccountType: true,
            includeOrganizations: true
          }),
          mode: 'cors'
        })
          .then((res) => {
            if (res.ok && res.status===200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const decodedToken = jwt_decode(data.accessToken);
            if(decodedToken){
              localStorage.setItem('userData', JSON.stringify(data));
              localStorage.setItem('decodedToken', JSON.stringify(decodedToken));
              let typeOfPermission = typeof(decodedToken.permissions)
              if(typeOfPermission==="string"){
                setRoles([decodedToken.permissions])
              }
              else{
                setRoles(decodedToken.permissions)
              }
              setToken(data.accessToken)
              setUser(data.user)
              setImportLoader(false);
            }
          })
          .then(() => {
            navigate("/main/dashboard");
          })
          .catch((error) => {
            setLoginError(true);
            setImportLoader(false);
            console.log(error);
          });
    }
    
    return (
        <LoginContext.Provider value={{
            loginEmail,
            setLoginEmail,
            loginPassword,
            setLoginPassword,
            importLoader,
            setImportLoader,
            loginError,
            setLoginError,
            token,
            setToken,
            roles,
            setRoles,
            user,
            setUser,
            SignInConfirm,
            ResetContext
        }}>
            {props.children}
        </LoginContext.Provider>
    );
}

export default LoginContextProvider;