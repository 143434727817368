import React, { useContext } from 'react'
import "./ReleatedAgreements.css"
import right_ico from "../../assets/right-arrow-2.png"
import { MainContext } from '../../contexts/MainContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { CustomerDetailContext } from '../../contexts/CustomerDetailContext';
import { useNavigate } from 'react-router-dom';

export default function ReleatedAgreements({ agreementsList, department, setShow }) {
    const { langFile } = useContext(MainContext);
    const {GetCompany} = useContext(OrganizationContext)
    const {setSelectedServiceRecipientCompany } = useContext(CustomerDetailContext)
    const navigate = useNavigate()
    return (
        <div className='releated-agreements-container'>
            <div className='table-information'>
                <label> {department + " " + langFile.pages.Organization.agreementList}</label>
                <img onClick={() => setShow(false)} alt='close-ico' src={right_ico}></img>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>
                            {langFile.pages.Organization.name}
                        </th>
                        <th>
                            {langFile.pages.Organization.agreementType}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {agreementsList && agreementsList.map((agreement, index) =>
                        <tr key={index}
                        onClick={async ()=>{
                            const serviceRecipientObject = await GetCompany({
                                id:agreement.companyId,
                                page: 1,
                                pageSize: 999
                              })
                            if(serviceRecipientObject.length>0){
                                setSelectedServiceRecipientCompany(serviceRecipientObject[0]);
                                navigate("/main/customer-detail");
                            }       
                        }}>
                            <td>
                                {agreement.name}
                            </td>
                            <td>
                                {agreement.slaType.type}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>


        </div>
    )
}
