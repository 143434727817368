import React, { useContext, useState } from "react";
import { LoginContext } from "./LoginContext";

export const ThemaContext = React.createContext();

const ThemaContextProvider = (props) => {
    function Dark() {
        document.documentElement.style.setProperty('--textColor', '#fff');
        document.documentElement.style.setProperty('--textColor2', '#000');
        document.documentElement.style.setProperty('--backgroundColor', '#343541');
        document.documentElement.style.setProperty('--backgroundColor2', '#40414f');
        document.documentElement.style.setProperty('--backgroundColor3', '#77919a');
        document.documentElement.style.setProperty('--backgroundColor4', '#343541');
        document.documentElement.style.setProperty('--sidebarBackgroundColor', '#202123');
        document.documentElement.style.setProperty('--sidebarHoverBackgroundColor', '#40414f');
        document.documentElement.style.setProperty('--scroolbarTrackColor', '#000');
        document.documentElement.style.setProperty('--scroolbarThumbColor', '#263644');
        document.documentElement.style.setProperty('--boxShadowColor', '#263644');
        document.documentElement.style.setProperty('--filterRatio', '90%');
        document.documentElement.style.setProperty('--tableRowHover', '#202123');
        document.documentElement.style.setProperty('--importTableColor', '#707070');
        document.documentElement.style.setProperty('--importTableColor2', '#202123');
        document.documentElement.style.setProperty('--langBackgroundColor', '#242424');
      }
      function Light() {
        document.documentElement.style.setProperty('--textColor', '#000');
        document.documentElement.style.setProperty('--textColor2', '#fff');
        document.documentElement.style.setProperty('--backgroundColor', '#e9eeff');
        document.documentElement.style.setProperty('--backgroundColor2', '#fff');
        document.documentElement.style.setProperty('--backgroundColor3', '#263644');
        document.documentElement.style.setProperty('--backgroundColor4', '#ededed');
        document.documentElement.style.setProperty('--sidebarBackgroundColor', '#263644');
        document.documentElement.style.setProperty('--sidebarHoverBackgroundColor', '#1f2938');
        document.documentElement.style.setProperty('--scroolbarTrackColor', '#e4e4e4');
        document.documentElement.style.setProperty('--scroolbarThumbColor', '#4d4d4f');
        document.documentElement.style.setProperty('--boxShadowColor', '#d6d6d6');
        document.documentElement.style.setProperty('--filterRatio', '0%');
        document.documentElement.style.setProperty('--tableRowHover', '#c4d2ff');
        document.documentElement.style.setProperty('--importTableColor', '#e4e4e4');
        document.documentElement.style.setProperty('--importTableColor2', '#e9eeff');
        document.documentElement.style.setProperty('--langBackgroundColor', '#ebebeb');
      }

    return (
        <ThemaContext.Provider value={{
            Dark,
            Light
        }}>
            {props.children}
        </ThemaContext.Provider>
    );
}

export default ThemaContextProvider;