import React from 'react'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import "../styles/Excelexport.css"

export default function Excelexport(props) {


const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
const fileExtension = ".xlsx";

async function exportToExcel(){
    const ws = XLSX.utils.json_to_sheet(props.excelData);
    const wb = { Sheets: {'data' : ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType : 'xlsx', type : 'array' });
    const data = new Blob([excelBuffer], {type : fileType});
    FileSaver.saveAs(data, props.fileName + fileExtension);
}


  return (


    <>
       <button 
       className='export-btn'
       onClick={(e)=>
               exportToExcel()
           }>
               Excel Export
        </button>
    </>
    
  )
}
