import React, { useContext, useEffect, useState } from 'react'
import "../styles/KpiDetail.css"
import edit_ico from "../assets/pencil.png"

import { KpisContext } from '../contexts/KpisContext';
import { MainContext } from '../contexts/MainContext';
import { CustomersContext } from '../contexts/CustomersContext';
import { LoginContext } from '../contexts/LoginContext';
import { CustomerDetailContext } from "../contexts/CustomerDetailContext"

export default function KpiDetail() {

  const { selectedKpi, setKpiEditSide } = useContext(KpisContext);
  const { langFile } = useContext(MainContext);
  const { selectedCompanyGroup } = useContext(CustomersContext);
  const { roles, user } = useContext(LoginContext)
  const {selectedServiceRecipientCompany} = useContext(CustomerDetailContext)

  function getDate(startDate, mounthNumber) {
    const start = startDate.split("-");
    let yearCounter = parseInt(
      (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) / 12
    );
    let mounthCounter = parseInt(
      (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) % 12
    );
    start[1] = mounthCounter;
    start[0] = yearCounter;
    if (mounthCounter === 0) {
      start[1] += 12;
      start[0] -= 1;
    }
    if (start[1] < 10) {
      return String(start[0]) + "-0" + String(start[1]);
    } else {
      return String(start[0]) + "-" + String(start[1]);
    }
  }
  return (
    <div className='kpi-detail-container'>
      <div className='kpi-detail-container-part1'>
        <div className='kpi-detail-container-part1-column'>
          <div className='kpi-detail-container-part1-column-item'>

            {langFile.pages.KpiDetail.kpiName}

          </div>
          <div className='kpi-detail-container-part1-column-item'>

            {selectedKpi !== null ? selectedKpi.name : null}

          </div>
        </div>
        <div className='kpi-detail-container-part1-column'>
          <div className='kpi-detail-container-part1-column-item'>

            {langFile.pages.KpiDetail.generalStartDate}

          </div>
          <div className='kpi-detail-container-part1-column-item'>

            {selectedKpi ? selectedKpi.targets[0].referenceDate : null}


          </div>
        </div>
        <div className='kpi-detail-container-part1-column'>
          <div className='kpi-detail-container-part1-column-item'>

            {langFile.pages.KpiDetail.generalEndDate}

          </div>
          <div className='kpi-detail-container-part1-column-item'>

            {selectedKpi ? selectedKpi.targets[selectedKpi.targets.length - 1].referenceDate : null}

          </div>
        </div>
        <div className='kpi-detail-container-part1-column'>

          <div className='kpi-detail-container-part1-column-item'>

            {langFile.pages.KpiDetail.serviceRecipient}

          </div>
          <div className='kpi-detail-container-part1-column-item'>

          {selectedCompanyGroup.ownerCompany.name}
          </div>
        </div>
        <div className='kpi-detail-container-part1-column'>
          <div className='kpi-detail-container-part1-column-item'>

            {langFile.pages.KpiDetail.serviceProvider}

          </div>
          <div className='kpi-detail-container-part1-column-item'>

            {selectedServiceRecipientCompany.name}

          </div>
        </div>
        {roles.some(role => role === "KpiEdit") &&

          <div className='kpi-detail-container-part1-column'>
            <div className='kpi-detail-container-part1-column-edit-button-container'>
              <div className="tooltip-container">
                <button className='tooltip-trigger kpi-detail-container-part1-column-edit-button' onClick={() => {
                   setKpiEditSide(true)
                    }}>
                  <img alt='edit' src={edit_ico}></img>
                </button>
                <div style={{
                  top: "0vh",
                  right: "2vw"
                }} className="tooltip-content">Edit KPI</div>
              </div>
            </div>
          </div>
}
      </div>
      <div className='kpi-detail-container-part2'>

        <table>
          <thead>
            <tr >
              <th>
                {langFile.pages.KpiDetail.status}
              </th>
              <th >
                {langFile.pages.KpiDetail.startDate}
              </th>
              <th >
                {langFile.pages.KpiDetail.endDate}
              </th>
              <th >
                {langFile.pages.KpiDetail.kpiType}
              </th>
              <th >
                {langFile.pages.KpiDetail.kpiWeight}
              </th>
              <th >
                {langFile.pages.KpiDetail.targetType}
              </th>
              <th >
                {langFile.pages.KpiDetail.targetValue}
              </th>
              <th >
                {langFile.pages.KpiDetail.completedTargetValue}
              </th>
              <th >
                {langFile.pages.KpiDetail.range}
              </th>

            </tr>
          </thead>
          <tbody >
            {selectedKpi !== null ? selectedKpi.targets.map((target, index) =>
              <tr key={index} >
                <td >
                  {target.isSucceed === true ? <div className="triangle-up"></div> : <div className="triangle-down"></div>}
                </td>
                <td >
                  {target.referenceDate}
                </td>
                <td >
                  {getDate(target.referenceDate, 1)}
                </td>
                <td >
                  {selectedKpi.priority.name}
                </td>
                <td >
                  {target.weight}
                </td>
                <td >
                  {target.isPercentage === true ? "Percentage" : "Numeric"}
                </td>
                <td >
                  {target.value}
                </td>
                <td >
                  {target.completedValue}
                </td>
                <td >
                  {target.targetType.name}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>

      </div>

    </div>
  )
}
