import React from "react";
import "./CounterBoxes.css";

export default function CounterBoxes({ text, count, backgroundColor, src }) {
  return (
    <div
      style={{ backgroundColor: "var(--backgroundColor2)" }}
      className="counter-box-container"
    >
      <img alt="ico" src={src} id="counter-ico"></img>

      <div className="ico-text-container">
        <label className="count-number">{count ?count:0}</label>
        <label className="count-text">{text}</label>
      </div>
    </div>
  );
}
