import React, { useContext } from "react";
import { MainContext } from "./MainContext";
import { LoginContext } from "./LoginContext";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

export const NewCustomerContext = React.createContext();

const NewCustomerContextProvider = (props) => {

    const { AddAlert, setAlertList } = useContext(MainContext);
    const { token, roles } = useContext(LoginContext)

    const navigate = useNavigate();
    const newServiceProviderValidationSchema = yup.object().shape({
        name: yup.string().min(2,"'Company Name' must  be least 2 character."),
        taxCode: yup.string(),
        fax: yup.string(),
        email: yup.string().email("Provide a valid email address"),
        phoneNumber: yup.string()
        .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', function(value) {
          if (value && typeof value === 'string') {
            return isValidPhoneNumber(value);
          }
          return true;
        }),
        address: yup.string(),
        contactName: yup.string(),
        contactPhoneNumber: yup.string()
        .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', function(value) {
          if (value && typeof value === 'string') {
            return isValidPhoneNumber(value);
          }
          return true;
        }),
        contactEmail: yup.string().email("Provide a valid email address"),
        contactAddress: yup.string(),
        contact2Name: yup.string(),
        contact2PhoneNumber: yup.string()
        .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', function(value) {
          if (value && typeof value === 'string') {
            return isValidPhoneNumber(value);
          }
          return true;
        }),
        contact2Email: yup.string().email("Provide a valid email address"),
        contact2Address: yup.string(),
        website: yup.string(),
        description: yup.string(),
      });
    const formik = useFormik({
      initialValues: {
        name: '',
        email: '',
        phoneNumber: '',
        address: '',
        taxCode: '',
        fax: '',
        description: '',
        contactName: '',
        contactEmail: '',
        contactPhoneNumber: '',
        contactAddress: '',
        contact2Name: '',
        contact2Email: '',
        contact2PhoneNumber: '',
        contact2Address: '',
        website: ''
      },
      validationSchema: newServiceProviderValidationSchema,
      onSubmit: (values) => {
        CreateCompany(values);
      },
    });

  
    function CreateCompany(values) {
      if (roles.some(role => role === "CompanyCreate")) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_CREATE_ORGANIZATION_COMPANY, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
            "TenantId": "slm",
          },
          body: JSON.stringify({
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            address: values.address,
            taxCode: values.taxCode,
            countryCode: values.phoneNumber ?? parsePhoneNumber(values.phoneNumber).country,
            fax: values.fax,
            website: values.website,
            description: values.description,
            contactName: values.contactName,
            contactEmail: values.contactEmail,
            contactCountryCode: values.contactPhoneNumber?? parsePhoneNumber(values.contactPhoneNumber).country,
            contactPhoneNumber: values.contactPhoneNumber,
            contactAddress: values.contactAddress,
            contact2Name: values.contact2Name,
            contact2Email: values.contact2Email,
            contact2CountryCode: values.contact2PhoneNumber ?? parsePhoneNumber(values.contact2PhoneNumber).country,
            contact2PhoneNumber: values.contact2PhoneNumber,
            contact2Address: values.contact2Address,
          }),
        })
          .then((res) => {
            if (res.ok && res.status === 200) {
              AddAlert("success", "Add company successful")
              return res.json();
            }
            else if (res.status === 401) {
              setAlertList([])
              localStorage.clear();
              navigate("/login");
            }
            throw res
          })
          .then((data) => {
            formik.resetForm()
            return data;
          })
          .catch((error) => {
            error.json().then((err)=>{
              const errorObject = err.errors
              Object.keys(errorObject).forEach(key => {
                const errors = errorObject[key];
    
                let errorText = ""
                 errors.forEach(error => {
                     errorText+=(error+". ")
                });
                formik.setFieldError(key, errorText )
            });
            })
            AddAlert("error", "Add company Failed")
          });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
      }
      function handleKeyPress(event) {
        if (event.key === "Enter" && Object.keys(formik.errors).length === 0) {
          CreateCompany(formik.values);
        }
      }

    return (
        <NewCustomerContext.Provider value={{
            CreateCompany,
            formik,
            handleKeyPress
           }}>
            { props.children }
        </NewCustomerContext.Provider>
    );
}

export default NewCustomerContextProvider;