import React, { useContext, useState } from "react";
import { MainContext } from "./MainContext";
import { LoginContext } from "./LoginContext";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

export const NewCompanyContext = React.createContext();

const NewCompanyContextProvider = (props) => {
    const [newCompanyGroup, setNewCompanyGroup] = useState(false)
    const [newCompanyGroupName, setNewCompanyGroupName] = useState("")
    const [selectedCompanyGroup, setSelectedCompanyGroup] = useState()
    const [companyGroupList, setCompanyGroupList] = useState([])
    const { AddAlert, setAlertList } = useContext(MainContext);
    const { token, user, roles } = useContext(LoginContext)
    const navigate = useNavigate();

    const newServiceProviderValidationSchema = yup.object().shape({
        name: yup.string().min(2, "'Company Name' must be least 2 character."),
        taxCode: yup.string(),
        fax: yup.string(),
        email: yup.string().email("Provide a valid email address"),
        phoneNumber: yup.string()
            .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', value => checkPhoneNumber(value)),
        address: yup.string(),
        contactName: yup.string(),
        contactPhoneNumber: yup.string()
            .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', value => checkPhoneNumber(value)),
        contactEmail: yup.string().email("Provide a valid email address"),
        contactAddress: yup.string(),
        contact2Name: yup.string(),
        contact2PhoneNumber: yup.string()
            .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', value => checkPhoneNumber(value)),
        contact2Email: yup.string().email("Provide a valid email address"),
        contact2Address: yup.string(),
        website: yup.string(),
        description: yup.string(),
    });

    function checkPhoneNumber(value) {
        if (value && typeof value === 'string') {
            return isValidPhoneNumber(value);
        }
        return true;
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            address: '',
            taxCode: '',
            fax: '',
            description: '',
            contactName: '',
            contactEmail: '',
            contactPhoneNumber: '',
            contactAddress: '',
            contact2Name: '',
            contact2Email: '',
            contact2PhoneNumber: '',
            contact2Address: '',
            website: ''
        },
        validationSchema: newServiceProviderValidationSchema,
        onSubmit: (values) => {
            CreateCompany(values);
        },
    });

    function CreateCompanyGroup() {
        if (roles.some(role => role === "CompanyGroupCreate")) {
            fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_CREATE_ORGANIZATION_COMPANY_GROUP, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                    "TenantId": "slm",
                },
                body: JSON.stringify(
                    {
                        ownerCompanyId: user.company.id,
                        name: newCompanyGroupName
                    }
                ),
            })
                .then((res) => {
                    if (res.ok && res.status === 200) {
                        return res.json();
                    }

                    else if (res.status === 401) {
                        setAlertList([])
                        localStorage.clear();
                        navigate("/login");
                    }
                    throw res;
                })
                .then((data) => {
                    AddAlert("success", "Add company group successful")
                    setNewCompanyGroup(false)
                    setNewCompanyGroupName("")
                    GetAllCompanyGroup(user.company.id)
                    return data;
                })
                .catch((error) => {
                    AddAlert("error", "Add company group fail")
                    console.log(error);
                });
        }
        else {
            AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function GetAllCompanyGroup(ownerCompanyId) {
        if (roles.some(role => role === "CompanyGroupRead")) {
            fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY_GROUP, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                    "TenantId": "slm",
                },
                body: JSON.stringify(
                    {
                        ownerCompanyId: ownerCompanyId,
                        pageNumber: 1,
                        pageSize: 9999,
                    }
                ),
            })
                .then((res) => {
                    if (res.ok && res.status === 200) {
                        return res.json();
                    }

                    else if (res.status === 401) {
                        setAlertList([])
                        localStorage.clear();
                        navigate("/login");
                    }
                    throw res;
                })
                .then((data) => {
                    if (data?.companyGroups.length > 0) {
                        setSelectedCompanyGroup(data?.companyGroups[0])
                        setCompanyGroupList(data?.companyGroups);
                    }
                    else {
                        setSelectedCompanyGroup()
                        setCompanyGroupList([])
                    }
                })
                .catch((error) => {
                    setCompanyGroupList([])
                    setSelectedCompanyGroup()
                    console.log(error);
                });
        }
        else {
            AddAlert("warning", "You do not have authorization for this operation.")
        }
    }


    // function AddCompanyToCompanyGroup(companyId) {
    //     fetch(process.env.REACT_APP_BASE_URL_AUTH + "api/organization/company-group/add-company", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Accept": "application/json",
    //             "Authorization": "Bearer " + token,
    //             "TenantId": "slm",
    //         },
    //         body: JSON.stringify({
    //             companyGroupId: selectedCompanyGroup.companyGroupId,
    //             companyId: companyId
    //         }),
    //     })
    //         .then((res) => {
    //             if (res.ok && res.status === 200) {
    //                 AddAlert("success", "Add to company group successful")
    //                 return res.json();
    //             }
    //             else if (res.status === 401) {
    //                 setAlertList([])
    //                 localStorage.clear();
    //                 navigate("/login");
    //             }
    //         })
    //         .then((data) => {
    //             return data;
    //         })
    //         .catch((error) => {
    //             AddAlert("error", "Add to company group Failed")
    //             console.log(error.json());
    //         });
    // }
    function CreateCompany(values) {
        if (selectedCompanyGroup?.companyGroupId) {
            if (roles.some(role => role === "CompanyCreate")) {
            fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_CREATE_ORGANIZATION_COMPANY, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                    "TenantId": "slm",
                },
                body: JSON.stringify({
                    name: values.name,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    address: values.address,
                    taxCode: values.taxCode,
                    countryCode: values.phoneNumber ?? parsePhoneNumber(values.phoneNumber).country,
                    fax: values.fax,
                    website: values.website,
                    description: values.description,
                    contactName: values.contactName,
                    contactEmail: values.contactEmail,
                    contactCountryCode: values.contactPhoneNumber ?? parsePhoneNumber(values.contactPhoneNumber).country,
                    contactPhoneNumber: values.contactPhoneNumber,
                    contactAddress: values.contactAddress,
                    contact2Name: values.contact2Name,
                    contact2Email: values.contact2Email,
                    contact2CountryCode: values.contact2PhoneNumber ?? parsePhoneNumber(values.contact2PhoneNumber).country,
                    contact2PhoneNumber: values.contact2PhoneNumber,
                    contact2Address: values.contact2Address,
                    companyGroupId: selectedCompanyGroup.companyGroupId,
                }),
            })
                .then((res) => {
                    if (res.ok && res.status === 200) {
                        AddAlert("success", "Add company successful")
                        return res.json();
                    }
                    else if (res.status === 401) {
                        setAlertList([])
                        localStorage.clear();
                        navigate("/login");
                    }
                    throw res
                })
                .then((data) => {
                    // AddCompanyToCompanyGroup(data.result.data)
                    formik.resetForm()
                    return data;
                })
                .catch((error) => {
                    error.json().then((err) => {
                        const errorObject = err.errors
                        Object.keys(errorObject).forEach(key => {
                            const errors = errorObject[key];

                            let errorText = ""
                            errors.forEach(error => {
                                errorText += (error + ". ")
                            });
                            formik.setFieldError(key, errorText)
                        });
                    })
                    AddAlert("error", "Add company Failed")
                });
            }
            else{
              AddAlert("warning", "You do not have authorization for this operation.")
            }
        }
        else {
            AddAlert("warning", "Please Select or Create an Company Group")
        }
    }
    function handleKeyPress(event) {
        if (event.key === "Enter" && Object.keys(formik.errors).length === 0) {
            CreateCompany(formik.values);
        }
    }
    return (
        <NewCompanyContext.Provider value={{
            newCompanyGroup,
            setNewCompanyGroup,
            newCompanyGroupName,
            setNewCompanyGroupName,
            selectedCompanyGroup,
            setSelectedCompanyGroup,
            companyGroupList,
            setCompanyGroupList,
            CreateCompany,
            GetAllCompanyGroup,
            CreateCompanyGroup,
            newServiceProviderValidationSchema,
            formik,
            handleKeyPress
        }}>
            {props.children}
        </NewCompanyContext.Provider>
    )
}
export default NewCompanyContextProvider;