import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";
import { ThemaContext } from "./ThemaContext";

export const SettingsContext = React.createContext();

const SettingsContextProvider = (props) => {
    const [activeSettingsType, setActiveSettingsType] = useState("")
    const [settingsTypeList, setSettingsTypeList] = useState([])
    const navigate = useNavigate
    const { token, user } = useContext(LoginContext)
    const { AddAlert, setAlertList, setLocal } = useContext(MainContext);
    const { Dark, Light } = useContext(ThemaContext)
    const [userSettings, setUserSettings] = useState([])
    const [programSettings, setProgramSettings] = useState([])
    const [settingThema, setSettingThema] = useState()
    const [settingLanguage, setSettingLanguage] = useState()



    useEffect(() => {
        if (user?.id) {
            GetUserSettings()
            GetProgramSettings()
        }
    }, [user?.id])


    function Save(settings) {
        if (user?.id) {
            settings.forEach(programSetting => {
                programSetting.programSettings.forEach(setting => {
                    UpdateProgramSettings(
                        {
                            id: setting.id,
                            settingsId: setting.settingsId,
                            value: setting.value
                        }
                    )
                    // if (setting?.isDefault) {
                    //     CreateUserSettings(
                    //         {
                    //             name: setting.name,
                    //             settingsId: setting.settingsId,
                    //             accountId: user.id,
                    //             value: setting.value
                    //         }
                    //     )
                    // }
                    // else {
                    //     UpdateUserSettings({
                    //         userSettingsId: setting.id,
                    //         value: setting.value
                    //     })
                    // }
                })


            })
        }
    }

    function ParseSettings(settings) {
        settings.map((setting) => { 
            if (setting.name === "Theme" && setting?.userSettings[0]?.value) {
                // setting.userSettings.map((userSetting,index)=>{
                //     if(index === setting.userSettings.length-1){
                //         setSettingThema(userSetting)
                //         userSetting.value === "dark" ? Dark() : Light()
                //     }
                // })
                setSettingThema(setting.userSettings[0])
                setting.userSettings[0].value === "dark" ? Dark() : Light()
            }
            else if (setting.name === "Language" && setting?.userSettings[0]?.value) {
                // setting.userSettings.map((userSetting,index)=>{
                //     if(index === setting.userSettings.length-1){
                //         setSettingLanguage(userSetting)
                //         setLocal(userSetting.value==="english"?"English":"Turkish")
                //     }
                // })

                setSettingLanguage(setting.userSettings[0])
                setLocal(setting.userSettings[0].value === "en" ? "english" : "turkish")
            }
        })
    }

    function GetSettingsType() {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_SETTINGS_TYPE + "?PageSize=999&PageNumber=1", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            }
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res;
            })
            .then((data) => {
                if (data?.settingsTypes.length > 0) {
                    setSettingsTypeList(data.settingsTypes)
                    setActiveSettingsType(data.settingsTypes[0])
                }
                else {
                    setSettingsTypeList([])
                }
            })
            .catch((error) => {
                setSettingsTypeList([])
                console.log(error);
            });
    }

    function GetUserSettings() {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_SETTINGS, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(
                {
                    accountId: user.id,
                    includeSettingsType: true,
                    includeSettingsDataType: true,
                    includeProgramSettings: true,
                    includeAccountSettings: true,
                    includeAccount: false,
                    pageNumber: 1,
                    pageSize: 9999
                }
            ),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res;
            })
            .then((data) => {
                if (data?.settings?.length > 0) {
                    let temp = data.settings
                    temp.forEach((setting, index) => {
                         let item = false
                         if (setting.userSettings.length > 0) {
                             item = setting.userSettings.find(setting => setting.accountId === user.id)
                             if (item) {
                                 setting.userSettings = [item]
                             }
                             else {
                                 item = setting.userSettings.find(setting => setting.isDefault)
                                 setting.userSettings = [item]
                             }
                         }
                     });
                    setUserSettings(temp)
                    setProgramSettings(temp)
                    ParseSettings(temp)
                }
                else {
                    setUserSettings([])
                    setProgramSettings([])
                }
            })
            .catch((error) => {
                setUserSettings([])
                setProgramSettings([])
                console.log(error);
            });
    }

    function GetProgramSettings() {
        // fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_SETTINGS, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Accept": "application/json",
        //         "Authorization": "Bearer " + token,
        //         "TenantId": "slm",
        //     },
        //     body: JSON.stringify(
        //         {    
        //              accountId: user.id,
        //              includeSettingsType: true,
        //              includeSettingsDataType: true,
        //              includeProgramSettings: true,
        //              includeAccountSettings: false,
        //              includeAccount: false,
        //              pageNumber: 1,
        //              pageSize: 9999
        //         }

        //     ),
        // })
        //     .then((res) => {
        //         if (res.ok && res.status === 200) {
        //             return res.json();
        //         }
        //         else if (res.status === 401) {
        //             setAlertList([])
        //             localStorage.clear();
        //             navigate("/login");
        //         }
        //         throw res;
        //     })
        //     .then((data) => {
        //         if (data?.settings.length > 0) {
        //             setProgramSettings(data.settings)
        //         }
        //         else {
        //             setUserSettings([])
        //         }
        //     })
        //     .catch((error) => {
        //         setUserSettings([])
        //         console.log(error);
        //     });
    }


    function CreateUserSettings(body) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_CREATE_SETTINGS_USER, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(
                body
            ),
        })
            .then((res) => {
                GetProgramSettings()
                GetUserSettings()
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res;
            })
            .catch((error) => {                
                AddAlert("error", "Setting Change Error")
                console.log(error);
            });
    }

    function UpdateUserSettings(body) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_UPDATE_SETTINGS_USER, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(
                body
            ),
        })
            .then((res) => {
                GetProgramSettings()
                GetUserSettings()
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res;
            })
            .catch((error) => {
                AddAlert("error", "Setting Change Error")
                console.log(error);
            });
    }

    function UpdateProgramSettings(body) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + "api/settings/program/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(
                body
            ),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return;
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res;
            })
            .catch((error) => {
                AddAlert("error", "Setting Change Error")
                console.log(error);
            });
    }

    return (
        <SettingsContext.Provider value={{
            activeSettingsType,
            setActiveSettingsType,
            GetUserSettings,
            GetSettingsType,
            userSettings,
            setUserSettings,
            settingsTypeList,
            setSettingsTypeList,
            CreateUserSettings,
            UpdateUserSettings,
            userSettings,
            setUserSettings,
            programSettings,
            setProgramSettings,
            settingThema,
            setSettingThema,
            settingLanguage,
            setSettingLanguage,
            Save
        }}>
            {props.children}
        </SettingsContext.Provider>
    );
}

export default SettingsContextProvider;