import React, { useContext, useEffect, useState } from 'react'
import "../../../styles/WarningList.css"
import { LoginContext } from '../../../contexts/LoginContext';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../../contexts/MainContext';
import { DashboardContext } from '../../../contexts/DashboardContext';

export default function WarningList({ data }) {
    const { token, user } = useContext(LoginContext)
    const [agreementTypesList, setAgreementTypesList] = useState([])
    const [customers, setCustomers] = useState([])
    const { setAlertList, langFile } = useContext(MainContext);
    const { selectedCompanyGroup, companyGroupList } = useContext(DashboardContext);
    const navigate = useNavigate();
    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL_SLM+process.env.REACT_APP_GET_SLA_TYPE,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "accept": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    take: 999,
                    skip: 0
                }),
            })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
            })
            .then((data) => {
                setAgreementTypesList(data.slaTypes)
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div className='warnings-table-parent'>
            <table>
                <thead>
                    <tr>
                        <th>
                            {langFile.pages.WarningList.name}
                        </th>
                        <th>
                        {langFile.pages.WarningList.type}
                        </th>
                        <th>
                        {langFile.pages.WarningList.serviceProvider}
                        </th>
                        <th>
                        {langFile.pages.WarningList.serviceRecipient}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>
                                {item[0].slaName}
                            </td>
                            <td>
                                {agreementTypesList?.find(agreementTypeItem => agreementTypeItem.id === item[0].slaTypeId)?.type}
                            </td>
                            <td>
                                {selectedCompanyGroup?.name}
                            </td>
                            <td>
                                {companyGroupList?.find(companyGroup => companyGroup.companyGroupId === selectedCompanyGroup.companyGroupId)?.
                                companies.find(company => company.companyId === item[0].companyId)?.name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
