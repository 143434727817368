import React from "react";
import "../styles/FailedKpiList.css";

export default function FailedKpiList() {
  return (
    <div className="parent-container-failedKpiList">
      <table className="container-failedKpiList">
        <thead>
          <tr>
            <th>
              <h1 className="header-1">NAME</h1>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Satış Geliri</td>
          </tr>
          <tr className="error">
            <td>Müşteri Sadakati Endeksi</td>
          </tr>
          <tr>
            <td>Müşteri Memnuniyeti Puanı</td>
          </tr>
          <tr>
            <td>Envater Dönüş Hızı</td>
          </tr>
          <tr>
            <td>Personel Devir Oranı</td>
          </tr>
          <tr className="error">
            <td>Müşteri Hizmetleri Yanıt Süresi</td>
          </tr>
         
        </tbody>
      </table>
    </div>
  );
}
