import React, { useContext, useEffect, useState } from "react";
import "../../styles/ProfileCardListComponentMyUsers/Card.css";
import user_photo from "../../assets/user.png";
import upArrow from "../../assets/up-arrow.png";
import downArrow from "../../assets/down-arrow.png";
import edit_ico from "../../assets/edit.png";
import delete_ico from "../../assets/delete.png"
import email_ico from "../../assets/email.png";
import phone_ico from "../../assets/phone.png";
import point_ico from "../../assets/point.png";
import company_ico from "../../assets/company.png";
import logout_ico from "../../assets/logout.png";
import user_ico from "../../assets/user-4.png";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../contexts/LoginContext";
import { SmallProfileCardContext } from "../../contexts/SmallProfileCardContext";

export default function Card({ customer, setToEditUser, online }) {
  const [wide, setWide] = useState(false);
  const navigate = useNavigate();

  function handleClickEditButton() {
    setToEditUser(customer);
  }
  const { roles, user } = useContext(LoginContext)
  const { setIsDeletePopUp } = useContext(SmallProfileCardContext)

  return (
    <div style={{ border: customer.id === user.id && "1px solid grey" }} className={wide === false ? "card-parent" : "card-parent-side"}>
      {wide === false ? (
        <div className="card-container">
          <div
            className="profile-photo-parent"
          >
            <img
              alt="profile_photo"
              src={user_photo}
            ></img>
          </div>
          <div className="text-area">
            {customer.firstname + " " + customer.lastname}
          </div>
          <label
            className={online === true ? "online-color" : "offline-color"}
          >
            Online
          </label>
        </div>
      ) : (
        <div className="card-container">
          <div className="left-part">
            <div className="profile-photo-parent">
              <img
                alt="profile_photo"
                src={user_photo}
              ></img>
            </div>
            <label
              className={
                online === true ? "online-color" : "offline-color"
              }
            >
              Online
            </label>
          </div>

          <div className="right-part">
            <div className="text-area">
              <label>
                {customer.firstname + " " + customer.lastname}
              </label>
            </div>
            <div className="text-area">
              <img className="icon" alt="point" src={user_ico}></img>
              <label>{customer.username}</label>
            </div>
            <div className="text-area">
              <img className="icon" alt="point" src={company_ico}></img>
              <label>{customer.company.name}</label>
            </div>
            <div className="text-area">
              <img className="icon" alt="point" src={email_ico}></img>
              <label>{customer.email}</label>
            </div>
            <div className="text-area">
              <img className="icon" alt="point" src={phone_ico}></img>
              <label>{customer.phoneNumber}</label>
            </div>
          </div>

          <div style={{ alignSelf: "flex-start" }} className="right-part">
            <React.Fragment >
              {(roles.some(role => role === "UserEdit") || customer.id === user.id) &&
                <button onClick={handleClickEditButton}>
                  <img className="icon" alt="point" src={edit_ico}></img>
                </button>
              }
              {(roles.some(role => role === "UserDelete")) &&
                <button onClick={() => setIsDeletePopUp(customer.id)}>
                  <img className="icon" alt="point" src={delete_ico}></img>
                </button>
              }
            </React.Fragment>
          </div>
        </div>
      )}

      <div className="arrow-area">
        <button
          onClick={() => {
            setWide(!wide);
          }}
        >
          <img
            className="arrow-ico-userbar"
            alt="arrow-up"
            src={wide === false ? downArrow : upArrow}
          />
        </button>
      </div>
    </div>
  );
}
