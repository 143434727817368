import React, { useEffect, useContext, useState } from "react";
import "../styles/Main.css";
import { Outlet, useNavigate } from "react-router-dom";
import UserBar from "../components/UserBar";
import logo from "../assets/logo2.png";
import ex from "../assets/ex2.png";
import cordlynx_ico from "../assets/cordlynx.png"
import dashboard_ico from "../assets/home.png";
import kpi_ico from "../assets/kpi.png";
import customers_ico from "../assets/people.png";
import companies_ico from "../assets/company-group.png";
import ProfileCard from "../components/ProfileCard";
import leftArrow from "../assets/left-arrow-sidebar.png";
import rightArrow from "../assets/right-arrow-sidebar.png";
import import_ico from "../assets/import.png";
import myUsers from "../assets/my-user.png";
import settings_ico from "../assets/settings-2.png"
import organization_ico from "../assets/organization.png"
import services_ico from "../assets/services.png"
import Alert from "../components/Alert";
import { MainContext } from "../contexts/MainContext";
import { LoginContext } from "../contexts/LoginContext";
import { getMenuData } from "../json/JsonService";

export default function Main() {
  const { langFile,
    tempArr,
    sideBar,
    setSideBar,
    activePage,
    setActivePage } = useContext(MainContext);

  const { roles, user } = useContext(LoginContext);

  const navigate = useNavigate();
  const menuData = getMenuData()

  useEffect(() => {
    if (sideBar) {
      setActivePage(
        activePage.substring(0, activePage.length - 5),
      );
    } else {
      setActivePage(activePage.concat("-side"));
    }
  }, [sideBar]);


  function hasRoleForPage(roles, requiredRoles) {
    if(requiredRoles.length===0) return true
    for (let value of requiredRoles) {
        if (roles?.includes(value)) {
            return true; 
        }
    }
    return false;
}

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexdirection: "row",
        }}
      >
        <div
          className={
            sideBar === true
              ? "main-page-style-2"
              : "main-page-style-2 main-page-style-2-side"
          }
        >
          <div className="logo-main">
            <img
              alt="experilabs"
              src={cordlynx_ico}
              className="experilabs-ico-wide"
            ></img>
          </div>
          {menuData.menu.map((menu, index_1) => (
            <React.Fragment key={index_1}>
              <h6 className="h7-style">{langFile.pages.Main[menu.title]}</h6>
              {menu.submenu.map((item, index_2) => 
                item.buttonType === "type1" &&
                // item.roles.every(requiredRole => roles.includes(requiredRole))
                hasRoleForPage(roles, item.roles)
                ?
                  <button
                  style={item.style}
                  key={index_2}
                    className={"button-main-1".concat(
                      activePage === item.activePage ? " button-main-1-active" : ""
                    )}
                    onClick={() => {
                      setActivePage(item.activePage);
                      navigate(item.navigate);
                    }}
                  >
                    <img
                      alt={item.navigate}
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5vw",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={require(`../assets/${item.ico}`)}
                    ></img>
                    {langFile.pages.Main[item.labelName]}
                  </button>
                : 
                item.buttonType === "type2" &&
                // item.roles.every(requiredRole => roles.includes(requiredRole)) &&
                hasRoleForPage(roles, item.roles) &&
                <button
                style={item.style}
                key={index_2}
                  className={"new-sla-button".concat(
                    activePage === item.activePage ? " new-sla-button-active" : ""
                  )}
                  onClick={() => {
                    setActivePage(item.activePage);
                    navigate(item.navigate);
                  }}
                >
                  {langFile.pages.Main[item.labelName]}
                </button>
              
              )}
            </React.Fragment>
          ))}
          
         
          <button
            className={
              sideBar === true ? "arrow-button-left" : "arrow-button-right"
            }
            onClick={() => {
              setSideBar(!sideBar);
              if (sideBar) {
                document.documentElement.style.setProperty(
                  "--frameWidth",
                  "96vw"
                );
                document.documentElement.style.setProperty("--leftFrame", "4%");
              } else {
                document.documentElement.style.setProperty(
                  "--frameWidth",
                  "84vw"
                );
                document.documentElement.style.setProperty(
                  "--leftFrame",
                  "16%"
                );
              }
            }}
          >
            <img
              alt="dashboard"
              style={{
                width: "1.7vw",
                height: "fit-content",
                filter: "invert(15%)",
                WebkitFilter: "invert(15%)",
              }}
              src={sideBar === true ? leftArrow : rightArrow}
            ></img>
          </button>
        </div>
        <div
          className={
            sideBar === false
              ? "main-page-style-2-close"
              : "main-page-style-2-close-side"
          }
        >
          <div className="logo-main">
            <img
              alt="experilabs"
              src={cordlynx_ico}
              className="experilabs-ico-small"
            ></img>
          </div>

          {menuData.menu.map((menu, index_1) => (
            <React.Fragment key={index_1}>
              {menu.submenu.map((item, index_2) => 
                item.buttonType === "type1" &&
                // item.roles.every(requiredRole => roles.includes(requiredRole))
                hasRoleForPage(roles, item.roles)
                ?
                <button
                key={index_2}
                style={item.style}
                  className={"button-main-1".concat(
                    activePage === item.activePage ? " button-main-1-active" : ""
                  )}
                  onClick={() => {
                    setActivePage(item.activePage);
                    navigate(item.navigate);
                  }}
                >
                  <img
                    alt="dashboard"
                    className="small-ico"
                    src={require(`../assets/${item.ico}`)}
                    ></img>
                </button>
                : 
                item.buttonType === "type2" &&
                // item.roles.every(requiredRole => roles.includes(requiredRole))
                hasRoleForPage(roles, item.roles)&&
                <button
                style={item.style}
                key={index_2}
                className={"new-sla-button".concat(
                  activePage === item.activePage ? " new-sla-button-active" : ""
                )}
                onClick={() => {
                  setActivePage(item.activePage);
                    navigate(item.navigate);
                }}
              >
                +
              </button>
              )}
            </React.Fragment>
          ))}

          <button
            className={
              sideBar === true ? "arrow-button-left" : "arrow-button-right"
            }
            onClick={() => {
              setSideBar(!sideBar);
              if (sideBar) {
                document.documentElement.style.setProperty(
                  "--frameWidth",
                  "96vw"
                );
                document.documentElement.style.setProperty("--leftFrame", "4%");
              } else {
                document.documentElement.style.setProperty(
                  "--frameWidth",
                  "84vw"
                );
                document.documentElement.style.setProperty(
                  "--leftFrame",
                  "16%"
                );
              }
            }}
          >
            <img
              alt="dashboard"
              style={{
                width: "1.7vw",
                filter: "invert(15%)",
                WebkitFilter: "invert(15%)",
              }}
              src={sideBar === true ? leftArrow : rightArrow}
            ></img>
          </button>
        </div>

        <div
          className={
            sideBar === true
              ? "main-page-style main-page-style-side"
              : "main-page-style"
          }
        >
          <UserBar></UserBar>
          <Outlet></Outlet>
          <ProfileCard></ProfileCard>
        </div>
      </div>
    </div>
  );
}
