import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";
import { CustomerDetailContext } from "./CustomerDetailContext";
import { useNavigate } from "react-router-dom";

export const EditSlaContext = React.createContext();

const EditSlaContextProvider = (props) => {
  const [toEditSla, setToEditSla] = useState()
  const [slaStartDate, setSlaStartDate] = useState("")
  const [slaEndDate, setSlaEndDate] = useState("")
  const [slaId, setSlaId] = useState("")
  const [slaName, setSlaName] = useState("")
  const [agreementType, setAgreementType] = useState("SLA")
  const [isActive, setIsActive] = useState(true)
  const [companyId, setCompanyId] = useState("")
  const [agreementTypesList, setAgreementTypesList] = useState([])
  const [allServicesList, setAllServices] = useState([])
  const [slasServices, setSlasServices] = useState([])
  const [getSlasServicesTrigger, setGetSlasServicesTrigger] = useState(false)
  const [documents, setDocuments] = useState([])
  const [getDocumentsTrigger, setGetDocumentsTrigger] = useState(false)
  const [file, setFile] = useState()
  const [importLoader, setImportLoader] = useState(false)
  const [users, setUsers] = useState([])

  const { token, user, roles } = useContext(LoginContext)
  const { AddAlert, setAlertList } = useContext(MainContext);
  const {
    setIsOpenEditSla,
    getAllSlaByCompanyIdTrigger,
    setGetAllSlaByCompanyIdTrigger
  } = useContext(CustomerDetailContext);

  const navigate = useNavigate()

  const owner = toEditSla?.verifiedSlas.find(verify => verify.isOwner)
  const [selectedOwner, setSelectedOwner] = useState();

  useEffect(() => {
    setSelectedOwner(users.find(user => user.id === owner?.accountId))
  }, [users, owner])

  function GetAllUser(body) {
    if (roles.some(role => role === "UserRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_ACCOUNTS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(body)
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          setUsers(data.accounts)
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetDays(date) {
    const splittedDays = date.split("-")
    const year = splittedDays[0]
    const month = splittedDays[1]
    return new Date(year, month, 0).getDate();
  };

  function ConfirmEditSlaHandle() {
    const endDateDayNumber = GetDays(slaEndDate)
    if (roles.some(role => role === "SlaEdit")) {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_UPDATE_SLA, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({
          id: slaId,
          name: slaName,
          accountId: user.id,
          isActive: isActive,
          companyId: companyId,
          slaTypeId: agreementTypesList.find(item => item.type === agreementType)?.guid,
          startDate: slaStartDate + "-01T00:00:00.000Z",
          endDate: slaEndDate + "-" + endDateDayNumber + "T00:00:00.000Z"
        }),
      })
        .then((res) => {
          setFile();
          if (res.ok && res.status === 200) {
            return res;
          }
          throw res;
        })
        .then((data) => {
          AddAlert("success", "Update Agreement Successful")
          setGetAllSlaByCompanyIdTrigger(!getAllSlaByCompanyIdTrigger)
          return data;
        })
        .catch((error) => {
          AddAlert("error", "Update Agreement Failed")
          console.log(error.json());
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetAllSlaType() {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_SLA_TYPE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          take: 999,
          skip: 0
        }),
      })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setAgreementTypesList(data.slaTypes)
      })
      .catch((err) => console.log(err));
  }

  return (
    <EditSlaContext.Provider value={{
      toEditSla,
      setToEditSla,
      slaStartDate,
      setSlaStartDate,
      slaEndDate,
      setSlaEndDate,
      slaId,
      setSlaId,
      agreementType,
      setAgreementType,
      isActive,
      setIsActive,
      slaName,
      setSlaName,
      companyId,
      setCompanyId,
      agreementTypesList,
      setAgreementTypesList,
      allServicesList,
      setAllServices,
      slasServices,
      setSlasServices,
      getSlasServicesTrigger,
      setGetSlasServicesTrigger,
      documents,
      setDocuments,
      getDocumentsTrigger,
      setGetDocumentsTrigger,
      file,
      setFile,
      importLoader,
      setImportLoader,
      ConfirmEditSlaHandle,
      GetAllUser,
      users,
      setUsers,
      owner,
      selectedOwner,
      setSelectedOwner,
      GetAllSlaType
    }}>
      {props.children}
    </EditSlaContext.Provider>
  );
}

export default EditSlaContextProvider;