import React, { useContext } from "react";
import "../styles/Export.css";
import export_ico from "../assets/export.png";
import { MainContext } from "../contexts/MainContext";


export default function Export() {
  const { langFile } = useContext(MainContext);

  return (
    <div className="export-parent">
        <div className="info-box">
          <div className="logo-box">
            <img
              className="logo-box-ico"
              alt="customers"
              src={export_ico}
            ></img>
          </div>

          <div className="text-box">
            <label
              style={{
                color: "var(--textColor)",
                fontSize: "large",
                fontWeight: "500",
              }}
            >
              {langFile.pages.Export.header1}
            </label>
            <label
              style={{
                color: "var(--textColor)",
                fontSize: "small",
              }}
            >
              {langFile.pages.Export.header2}
            </label>
          </div>
        </div>
              




      </div>
  );
}
