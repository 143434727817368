import React, { useContext, useEffect } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import "./WillSign.css"
import { SignAgreementContext } from '../../contexts/SignAgreementContext'
import sign_ico from "../../assets/sign.png"
import { EditSlaContext } from '../../contexts/EditSlaContext'
import { LoginContext } from '../../contexts/LoginContext'
import PopUpAlert from "../../components/PopUpAlert/PopUpAlert"
import { CustomersContext } from '../../contexts/CustomersContext'
import { MainContext } from '../../contexts/MainContext'
import { MailContext } from '../../contexts/MailContext'
import { CustomerDetailContext } from '../../contexts/CustomerDetailContext'

export default function WillSign() {
    const { selectedUsersForSign, setSelectedUsersForSign,  VerifiedSla, setIsSignPopUp, isSignPopUp} = useContext(SignAgreementContext)
    const {SendEmail} = useContext(MailContext)
    const {toEditSla, users,owner} = useContext(EditSlaContext)
    const {user} = useContext(LoginContext)
    const {mailTemplates} = useContext(MailContext)
    const {selectedCompanyGroup} = useContext(CustomersContext)
    const {getAllSlaByCompanyIdTrigger , setGetAllSlaByCompanyIdTrigger ,selectedServiceRecipientCompany} = useContext(CustomerDetailContext)
    const { setAlertList,AddAlert, AddMultiAlert } = useContext(MainContext);
    const mailTemplate = mailTemplates.find(mailTemplate => mailTemplate?.name === "Contract Alert")


    function onSelect(selectedList, selectedItem) {
        setSelectedUsersForSign([...selectedList]) 
    }
    
    function onRemove(selectedList, removedItem) {
        setSelectedUsersForSign([...selectedList]) 
    } 
    async function HandleOpenForSignature(){
        let body = {
            createVerifiedSlaList: []
        }
        selectedUsersForSign.forEach(userForSign => {
            body.createVerifiedSlaList.push({
                slaId: toEditSla?.agreementId,
                accountId: userForSign.id,
                createdBy: user.id,
                isOwner: false
              })
        });
        const response = await VerifiedSla(body)
        

        if(response){
            const promisesToAwait = [];
            selectedUsersForSign.forEach(userForSign => {
                promisesToAwait.push(SendEmail(
                    {
                        mailTemplateId: mailTemplate.id,
                        subjectParameters: mailTemplate.subjectParameters,
                        bodyParameters: `[${userForSign.firstname}],[${userForSign.lastname}],[${toEditSla?.slaType}],[${toEditSla?.name}],[${selectedServiceRecipientCompany.name}],[${selectedCompanyGroup?.ownerCompany?.name}],[${toEditSla.startDate}],[${toEditSla?.endDate}],[...]`,
                        to:userForSign.email,
                        cc: "",
                        bcc: ""
                    }
                ))
            }) 
            const responses = await Promise.all(promisesToAwait);

            let alertList = [];
            for(let i = 0; i<responses.length; i++){
            alertList.push({status:responses[i][0],text:responses[i][1]})
            }
            AddMultiAlert(alertList)
            setGetAllSlaByCompanyIdTrigger(!getAllSlaByCompanyIdTrigger)
        }   
    }
    return (
        <div className='will-sign-container'>
             {isSignPopUp && <PopUpAlert text={`Would you like to open ${toEditSla?.name} for signature to the users you choose?`} func={HandleOpenForSignature} setIsPopUp={setIsSignPopUp} ></PopUpAlert>}
            <label className='multiselect-tip'>Select the accounts you want to open for signature:</label>
            <div className='select-users-part'>
                <Multiselect
                    name="selectedAgreementOwner"
                    id="selectedAgreementOwner"
                    options = {users.filter(item => item.id !== owner?.accountId)}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="username" // Property name to display in the dropdown options
                    closeIcon={"cancel"}
                />
            </div>
            <button className='open-to-sign-button' onClick={()=>{selectedUsersForSign.length > 0 && setIsSignPopUp(true)}}>
                <img src={sign_ico} alt='sign' className='sign-ico'>
                </img>
                Open for Signature                
            </button>
        </div>)
}
