import React, { useContext } from 'react'
import { MainContext } from '../../contexts/MainContext';
import { NewSlaMetaDataContext } from '../../contexts/NewSlaMetaDataContext';
import { NewAgreementContext } from '../../contexts/NewAgreementContext';
import "../../styles/NewSlaMetaData.css"
import { LoginContext } from '../../contexts/LoginContext';

export default function AgreementTypeEntry() {
  const { langFile } = useContext(MainContext);
  const {
    metaDataType,
    setMetaDataType,
    contractType,
    setContractType,
  } = useContext(NewSlaMetaDataContext)
  const { roles } = useContext(LoginContext)

  const {
    metadataDone,
  } = useContext(NewAgreementContext);

  return (
    <div className='agreement-type-entry-parent'
      style={{
        pointerEvents: !metadataDone ? "all" : "none",
        opacity: !metadataDone ? "1" : "0.5"
      }}>
      <label className="label-metadata-1">
        {langFile.pages.NewSlaMetaData.metaData}
      </label>
      <div className="radio-button-parent-tab-type">
        {
          // roles.some(role => role === "SlaCreate") &&
          <div
            title="SLA"
            className={contractType == "SLA" ? "checkboxParent active" : "checkboxParent"}
          >
            <label htmlFor='sla' style={{ color: "var(--textColor)", marginRight: "1vw", fontSize: "0.8vw" }}>
              SLA
            </label>
            <input
              type="radio"
              id="sla"
              name="sla"
              defaultValue="SLA"
              checked={contractType === "SLA"}
              onChange={(event) => setContractType(event.target.value)}
            />
          </div>}
        {
          // roles.some(role => role === "UcCreate") &&
          <div
            title="UC"
            className={contractType == "UC" ? "checkboxParent active" : "checkboxParent"}

          >
            <label htmlFor='uc' style={{ color: "var(--textColor)", fontSize: "0.8vw" }}>
              UC
            </label>
            <input
              type="radio"
              id="uc"
              name="uc"
              defaultValue="UC"
              checked={contractType === "UC"}
              onChange={(event) => setContractType(event.target.value)}
            />
          </div>
        }
        {
          // roles.some(role => role === "OlaCreate") &&
          <div
            title="OLA"
            className={contractType == "OLA" ? "checkboxParent active" : "checkboxParent"}

          >
            <label htmlFor='ola' style={{ color: "var(--textColor)", fontSize: "0.8vw" }}>
              OLA
            </label>
            <input
              type="radio"
              id="ola"
              name="ola"
              defaultValue="OLA"
              checked={contractType === "OLA"}
              onChange={(event) => setContractType(event.target.value)}
            />
          </div>
        }
        {
          // roles.some(role => role === "OkrCreate") &&
          <div
            title="OKR"
            className={contractType == "OKR" ? "checkboxParent active" : "checkboxParent"}

          >
            <label htmlFor='okr' style={{ color: "var(--textColor)", fontSize: "0.8vw" }}>
              OKR
            </label>
            <input
              type="radio"
              id="okr"
              name="okr"
              defaultValue="OKR"
              checked={contractType === "OKR"}
              onChange={(event) => setContractType(event.target.value)}
            />
          </div>
        }
      </div>
      <div className="radio-button-parent-tab-type">
        {
          roles.some(role => role === "SlaCreate") &&
          <div
            title="A new SLA will be created and the KPIs you create will be assigned to this SLA."
            className={metaDataType == "new" ? "checkboxParent active" : "checkboxParent"}

          >
            <label htmlFor='newSla' style={{ color: "var(--textColor)", fontSize: "0.8vw" }}>
              {langFile.pages.NewSlaMetaData[contractType].new}
            </label>
            <input
              type="radio"
              id="newSla"
              name="slaType"
              defaultValue="new"
              checked={metaDataType === "new"}
              onChange={(event) => setMetaDataType(event.target.value)}
            />
          </div>
        }
        {
          roles.some(role => role === "KpiCreate") &&
          <div
            title="After selecting an SLA that you have created before, the KPIs you have created will be added for this SLA."
            className={metaDataType == "old" ? "checkboxParent active" : "checkboxParent"}

          >
            <label htmlFor='oldSla' style={{ color: "var(--textColor)", fontSize: "0.8vw" }}>
              {langFile.pages.NewSlaMetaData[contractType].select}
            </label>

            <input
              type="radio"
              id="oldSla"
              name="slaType"
              defaultValue="old"
              checked={metaDataType === "old"}
              onChange={(event) => setMetaDataType(event.target.value)}
            />
          </div>
        }
        {
          roles.some(role => role === "KpiCreate") &&
          <div
            title="KPIs you create will not be added to any SLA. Then you can use this KPI in any SLAs."
            className={metaDataType == "no" ? "checkboxParent active" : "checkboxParent"}

          >
            <label htmlFor='noSla' style={{ color: "var(--textColor)", fontSize: "0.8vw" }}>
              {langFile.pages.NewSlaMetaData[contractType].without}
            </label>
            <input
              type="radio"
              id="noSla"
              name="slaType"
              defaultValue="no"
              checked={metaDataType === "no"}
              onChange={(event) => setMetaDataType(event.target.value)}
            />
          </div>
        }
      </div>
    </div>
  )
}
