import React, { useContext, useEffect, useState } from 'react'
import "./NotificationMain.css"
import notification from "../../assets/notification-bell.png";
import NotificationItem from './NotificationItem';
import { NotificationContext } from '../../contexts/NotificationContext';
import { LoginContext } from '../../contexts/LoginContext';

export default function NotificationMain() {

    const {sideNotification,
        setSideNotification,
        notifications,
    setNotifications,
    GetVerifiedSla,
    GetVerifyNotification} = useContext(NotificationContext)



    useEffect(()=>{
        GetVerifyNotification()

    },[])
    

    return (
        <div className='notifications-parent'>
            <div className='notification-counter'>
                {notifications.length}
            </div>
            <img onClick={() => { setSideNotification(!sideNotification) }} className="notifications-ico" alt="notifications" src={notification} />
            {sideNotification &&
                <div className='notifications-list'>
                    {notifications.map((notification, index) => (
                        <NotificationItem key={index} notification={notification}></NotificationItem>
                    ))}
                </div>
            }
        </div>
    )
}
