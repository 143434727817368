import React, { useContext, useState } from "react";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";
import { useNavigate } from "react-router-dom";
import { CopySlaContext } from "./CopySlaContext";
import { CustomersContext } from "./CustomersContext";

export const CustomerDetailContext = React.createContext();

const CustomerDetailContextProvider = (props) => {
  const { selectedCompanyGroup } = useContext(CustomersContext);
  const { CopySla } = useContext(CopySlaContext);
  const { token, user, roles } = useContext(LoginContext)
  const { setAlertList, AddAlert } = useContext(MainContext);
  const navigate = useNavigate();
  const [slas, setSlas] = useState([]);
  const [selectedSla, setSelectedSla] = useState(false);
  const [data, setData] = useState([]);
  const [trendChartSide, setTrendChartSide] = useState(true);
  const [sideAddSla, setSideAddSla] = useState(false);
  const [kpisSideTrigger, setKpisSideTrigger] = useState(false);
  const [signSide, setSignSide] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [agreementTypes, setAgreementTypes] = useState([])
  const [isOpenEditSla, setIsOpenEditSla] = useState(false)
  const [getAllSlaByCompanyIdTrigger, setGetAllSlaByCompanyIdTrigger] = useState(false)
  const [users, setUsers] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [informationEmail, setInformationEmail] = useState(false)
  const [selectedServiceRecipientCompany, setSelectedServiceRecipientCompany] = useState(
  //   {
  //     "id": "6874f256-538d-4d15-b361-39cbf0432426",
  //     "name": "ExperiLabs",
  //     "description": null,
  //     "phoneNumber": null,
  //     "address": null,
  //     "email": null,
  //     "website": null,
  //     "taxCode": null,
  //     "fax": null,
  //     "contactName": null,
  //     "contactPhoneNumber": null,
  //     "contactEmail": null,
  //     "contactAddress": null,
  //     "contact2Name": null,
  //     "contact2PhoneNumber": null,
  //     "contact2Email": null,
  //     "contact2Address": null
  // }
  );


  function ResetContext() {
    setTrendChartSide(true)
    setSlas([])
    setSelectedSla(false)
    setData([])
    setSideAddSla(false)
    setKpisSideTrigger(false)
    setCustomerList([])
    setIsOpenEditSla(false)
  }

  function handleCheckboxChange(index) {
    const updatedCustomerList = customerList.map((customer, i) => {
      if (index === i) {
        return { ...customer, check: !customer.check };
      }
      return customer;
    });

    setCustomerList(updatedCustomerList);
  };
  // function Sort(willSortedArr) {
  //   let arr = willSortedArr;
  //   for (let i = 0; i < arr.length; i++) {
  //     arr[i].calculatedResults.sort(
  //       (date1, date2) =>
  //         parseInt(date1.date.substring(5, 7)) +
  //         parseInt(date1.date.substring(0, 4)) * 12 -
  //         (parseInt(date2.date.substring(5, 7)) +
  //           parseInt(date2.date.substring(0, 4)) * 12)
  //     );
  //   }
  //   return arr;
  // }
  function CompanyGroupGetAll(ownerCompanyId) {
    if (roles.some(role => role === "CompanyGroupRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY_GROUP, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify({
          ownerCompanyId: ownerCompanyId,
          includeCompanies: true,
          pageNumber: 1,
          pageSize: 999
        })
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          let temp = []
          for (let i = 0; i < data.companyGroups.length; i++) {
            for (let j = 0; j < data.companyGroups[i]?.companies.length; j++) {
              let company = data.companyGroups[i]?.companies[j]
              const isCompanyExist = temp.some(item => item.id === company.id);
              if (!isCompanyExist) {
                temp.push(data.companyGroups[i]?.companies[j])
              }
            }
          }
          setCustomerList(temp);
          return data;

        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function SortVerifies(data) {
    return data.map((prevToEditSla) => {
      const sortedVerifiedSlas = [...prevToEditSla.verifiedSlas].sort((a, b) => {
        if (a.isOwner) {
          return -1;//when negative do not change a and b sequences
        }
        else if (b.isOwner) {
          return 1//when positive change a and b sequences
        }
        else if (b.accountId === user.id) {
          return 1;
        } else {
          return 0;
        }
      });

      return {
        ...prevToEditSla,
        verifiedSlas: sortedVerifiedSlas,
      };
    });
  }


  function getAllSla() {
    if (roles.some(role => role === "SlaRead")) {
      fetch(
        process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_ALL_SLA_BY_COMPANY_ID_AND_COMPANY_GROUP_ID + "?CompanyId=" +
        selectedServiceRecipientCompany?.id +
        "&Page=1&PageSize=9999",
        {
          method: "GET",
          headers: {
            "accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((data) => {
          // let sortedData = Sort(data.calculationResults);
          let sortedData = SortVerifies(data.calculationResults)
          setSlas(sortedData);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function countUniqueKpiGuids(dataArray) {
    const uniqueKpiGuids = [];

    dataArray.forEach(item => {
      const kpiId = item.kpiId;
      if (!uniqueKpiGuids.includes(kpiId)) {
        uniqueKpiGuids.push(kpiId);
      }
    });

    return uniqueKpiGuids;
  }

  function CopySlaHandle(sla) {
    let checkedCustomerList = []
    for (let i = 0; i < customerList.length; i++) {
      if (customerList[i]?.check) {
        checkedCustomerList.push(customerList[i])
      }
    }
    CopySla(sla.name, checkedCustomerList, agreementTypes.find(item =>
      item.type === sla.slaType
    )?.id, countUniqueKpiGuids(sla?.kpiList), selectedCompanyGroup, sla.startDate, sla.endDate)
  }

  function GetAllSlaType() {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_SLA_TYPE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          take: 999,
          skip: 0
        }),
      })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setAgreementTypes(data.slaTypes)
      })
      .catch((err) => console.log(err));
  }
  function DeleteSla(body) {
    if (roles.some(role => role === "SlaDelete")) {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_PURGE_SLA, {
        method: "PUT",
        headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify({
          id: body.agreementId,
        })
      })
        .then((res) => {
          getAllSla()
          if (res.ok && res.status === 200) {
            return res;
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res.json();
        })
        .then((data) => {
          if (data.status === 200) {
            AddAlert("success", "Delete SLA is Successful")
            return data;
          }
          else {
            AddAlert("error", "Delete SLA is Failed")
          }
        })
        .catch((error) => {
          AddAlert("error", "Delete SLA is Failed")
          console.log(error)
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetAllUser(body) {
    if (roles.some(role => role === "UserRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_ACCOUNTS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(body)
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          setUsers(data.accounts);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetAllOrganization(body) {
    if (roles.some(role => role === "OrganizationRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(body)
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          if(data.organizations){
            setOrganizations(data.organizations);
          }
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  return (
    <CustomerDetailContext.Provider value={{
      ResetContext,
      trendChartSide,
      setTrendChartSide,
      slas,
      setSlas,
      selectedSla,
      setSelectedSla,
      data,
      setData,
      sideAddSla,
      setSideAddSla,
      kpisSideTrigger,
      setKpisSideTrigger,
      customerList,
      setCustomerList,
      agreementTypes,
      setAgreementTypes,
      isOpenEditSla,
      setIsOpenEditSla,
      getAllSlaByCompanyIdTrigger,
      setGetAllSlaByCompanyIdTrigger,
      handleCheckboxChange,
      CompanyGroupGetAll,
      getAllSla,
      countUniqueKpiGuids,
      CopySlaHandle,
      GetAllSlaType,
      DeleteSla,
      organizations,
      users,
      GetAllOrganization,
      GetAllUser,
      informationEmail,
      setInformationEmail,
      setSignSide,
      signSide,
      selectedServiceRecipientCompany,
      setSelectedServiceRecipientCompany
    }}>
      {props.children}
    </CustomerDetailContext.Provider>
  );
}

export default CustomerDetailContextProvider;