import React, { useContext, useState } from "react";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";
import { useNavigate } from "react-router-dom";
import { SettingsContext } from "./SettingsContext";

export const DashboardContext = React.createContext();

const DashboardContextProvider = (props) => {
  const [selectedCompany, setSelectedCompany] = useState("All")
  const [statistics, setStatistics] = useState([])
  const [dataForHorizontalChart, setDataForHorizontalChart] = useState([]);
  const [dataForVerticalChart, setDataForVerticalChart] = useState([]);
  const [filteredDataVerticalChart, setFilteredDataVerticalChart] = useState([])
  const [targetStatistics, setTargetStatistics] = useState([])
  const [companyGroupList, setCompanyGroupList] = useState()
  const [selectedCompanyGroup, setSelectedCompanyGroup] = useState()
  const { token } = useContext(LoginContext)
  const { setAlertList } = useContext(MainContext);
  const { programSettings } = useContext(SettingsContext)
  const warningPercentage = programSettings.find(programSetting => programSetting.name === "percentile for contract alert")

  const navigate = useNavigate();

  function ResetContext() {
    setSelectedCompany("All")
    setStatistics([])
    setDataForHorizontalChart([])
    setDataForVerticalChart([])
    setFilteredDataVerticalChart([])
    setTargetStatistics([])
    setCompanyGroupList()
    setSelectedCompanyGroup()
  }

  function GetTargetStatistics(serviceProviderId) {
    fetch(
      process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_TARGET_STATISTICS + "?" + serviceProviderId + "CompanyGroupId=" + selectedCompanyGroup?.companyGroupId + "&Page=1&PageSize=999",
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
        },
      }
    )
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        setTargetStatistics(CalculateTargetData(data))
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function groupBySlaId(data) {
    const groupedData = {};
    data.forEach(item => {
      const slaId = item.slaId;
      if (!groupedData[slaId]) {
        groupedData[slaId] = [];
      }
      groupedData[slaId].push(item);
    });
    return groupedData;
  }

  function CalculateWarningOfAgreement(slaList) {
    let warningList = []
    for (const sla in slaList) {
      for (const kpi of slaList[sla]) {
        if (kpi.priority !== "Minor" && kpi.targetType === "Minimum" && kpi.completedValue + (kpi.completedValue * (warningPercentage ? (warningPercentage / 100) : 0.25)) >= kpi.value && kpi.value > kpi.completedValue) {
          warningList.push(slaList[sla]);
          break;
        } else if (kpi.priority !== "Minor" && kpi.targetType === "Maximum" && kpi.completedValue - (kpi.completedValue * (warningPercentage ? (warningPercentage / 100) : 0.25)) <= kpi.value && kpi.value < kpi.completedValue) {
          warningList.push(slaList[sla]);
          break;
        }
      }
    }

    return warningList
  }

  function CalculateTargetData(data) {
    let filteredData = data.filter((target) => {
      const date = new Date();
      const currentYear = date.getFullYear();
      const currentMonth = date.getMonth() + 1;
      let formattedMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth.toString();
      let formattedYear = currentYear.toString();
      let formattedDate = formattedYear + "-" + formattedMonth;
      const parsedDate = target.targetDate.split("-");
      return formattedDate === parsedDate[0] + "-" + parsedDate[1];
    })
    let groupedData = groupBySlaId(filteredData)
    let result = CalculateWarningOfAgreement(groupedData)
    return result
  }
  function GetSlaStatisticsByStartAndFinishDate() {
    fetch(
      process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_SLA_STATISTICS_BY_START_AND_FINISH_DATE + "?CompanyGroupId=" + selectedCompanyGroup?.companyGroupId + "&StartDate=01-01-0000&FinishDate=01-01-9999",
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
        },
      }
    )
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        setStatistics(data)
        return;
      })
      .catch((error) => {
        setStatistics([])
        console.log(error);
      });
  }

  function CompanyGroupGetAll(ownerCompanyId) {
    fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY_GROUP, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
        "TenantId": "slm",
      },
      body: JSON.stringify(
        {
          ownerCompanyId: ownerCompanyId,
          pageNumber: 1,
          pageSize: 9999,
          includeCompanies: true,
        }
      ),
    })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }

        else if (res.status === 401) {
          setAlertList([])
          localStorage.clear();
          navigate("/login");
        }
        throw res;
      })
      .then((data) => {
        if (data?.companyGroups.length > 0) {
          setSelectedCompanyGroup(data?.companyGroups[0])
          setCompanyGroupList(data?.companyGroups);
        }
        else {
          setSelectedCompanyGroup()
          setCompanyGroupList([])
        }
      })
      .catch((error) => {
        setCompanyGroupList([])
        setSelectedCompanyGroup()
        console.log(error);
      });
  }

  function GroupedData(data) {
    const groupedData = data.reduce((acc, obj) => {
      const key = obj.companyId;

      if (!acc[key]) {
        acc[key] = {
          data: { sla: [], ola: [], uc: [], okr: [] }, calculations: {
            companyId: key,
            companyName: selectedCompanyGroup?.companies.find(item => item.id === key)?.name,
          }
        };
      }
      if (obj.type === "SLA") {
        acc[key].data.sla.push(obj);
      }
      else if (obj.type === "OLA") {
        acc[key].data.ola.push(obj);
      }
      else if (obj.type === "UC") {
        acc[key].data.uc.push(obj);
      }
      else if (obj.type === "OKR") {
        acc[key].data.okr.push(obj);
      }
      return acc;
    }, {});
    return groupedData
  }
  function CalculateChartData(data) {
    let date = new Date();
    let currentYear = date.getFullYear();
    let currentMonth = date.getMonth() + 1;
    let tempDataForHorizontalChart = [];
    let tempDataForVerticalChart = [];
    for (let i = 0; i < 12; i++) {
      let month = currentMonth - i;
      let year = currentYear;
      if (month <= 0) {
        month = 12 + month;
        year = currentYear - 1;
      }
      let formattedMonth = month < 10 ? "0" + month : month.toString();
      let formattedYear = year.toString();
      let formattedDate = formattedYear + "-" + formattedMonth;
      let totalCounts = {
        active: {
          sla: 0,
          ola: 0,
          uc: 0,
          okr: 0
        },
        deactive: {
          sla: 0,
          ola: 0,
          uc: 0,
          okr: 0
        }
      };
      const dataTypes = ['sla', 'ola', 'uc', 'okr'];
      data.forEach((serviceProvider) => {
        const companyName = serviceProvider?.calculations?.companyName;
        dataTypes.forEach((dataType) => {
          const obj = {
            date: "",
            label: companyName,
            type: dataType.toUpperCase(),
            value: 0,
            deactiveValue: 0
          };
          const relevantData = serviceProvider.data[dataType];
          const filteredData = relevantData.filter((item) => {
            const parsedDate = item.targetDate.split("-");
            return formattedDate === parsedDate[0] + "-" + parsedDate[1];
          });
          if (filteredData.length > 0) {
            obj.value = filteredData.reduce((acc, item) => item.isActive ? acc + item.count : acc, 0);
            obj.deactiveValue = filteredData.reduce((acc, item) => !item.isActive ? acc + item.count : acc, 0);
            obj.date = formattedDate
            tempDataForVerticalChart.unshift(obj)
            if (formattedDate === currentYear + "-" + (currentMonth < 10 ? ("0" + currentMonth.toString()) : currentMonth.toString())) {//anlık sayıyı bulma
              tempDataForHorizontalChart.push(obj);
            }
            totalCounts.active[dataType] += obj.value;
          }
          else {
            obj.value = 0;
            obj.deactiveValue = 0;
            obj.date = formattedDate
            tempDataForVerticalChart.unshift(obj)
            if (formattedDate === currentYear + "-" + (currentMonth < 10 ? ("0" + currentMonth.toString()) : currentMonth.toString())) {//anlık sayıyı bulma
              tempDataForHorizontalChart.push(obj);
            }
            totalCounts.deactive[dataType] += obj.value;
          }
        })
      })
      // console.log("tempDataForVerticalChart",tempDataForVerticalChart)
      // console.log("tempDataForHorizontalChart", tempDataForHorizontalChart)
      // console.log("totalCounts",totalCounts)
      tempDataForVerticalChart.unshift({
        label: 'All',
        type: 'SLA',
        value: totalCounts.active.sla,
        deactiveValue: totalCounts.deactive.sla,
        date: formattedDate
      }, {
        label: 'All',
        type: 'OLA',
        value: totalCounts.active.ola,
        deactiveValue: totalCounts.deactive.ola,
        date: formattedDate
      }, {
        label: 'All',
        type: 'UC',
        value: totalCounts.active.uc,
        deactiveValue: totalCounts.deactive.uc,
        date: formattedDate
      },
        {
          label: 'All',
          type: 'OKR',
          value: totalCounts.active.okr,
          deactiveValue: totalCounts.deactive.okr,
          date: formattedDate
        });
      if (formattedDate === currentYear + "-" + (currentMonth < 10 ? ("0" + currentMonth.toString()) : currentMonth.toString())) {//anlık sayıyı bulma
        tempDataForHorizontalChart.unshift({
          date: formattedDate,
          label: 'All',
          type: 'SLA',
          value: totalCounts.active.sla,
          deactiveValue: totalCounts.deactive.sla,
        }, {
          date: formattedDate,
          label: 'All',
          type: 'OLA',
          value: totalCounts.active.ola,
          deactiveValue: totalCounts.deactive.ola,
        }, {
          date: formattedDate,
          label: 'All',
          type: 'UC',
          value: totalCounts.active.uc,
          deactiveValue: totalCounts.deactive.uc,
        },
          {
            date: formattedDate,
            label: 'All',
            type: 'OKR',
            value: totalCounts.active.okr,
            deactiveValue: totalCounts.deactive.okr,
          });
      }
    }

    setDataForHorizontalChart(tempDataForHorizontalChart);
    setDataForVerticalChart(tempDataForVerticalChart)
  }

  return (
    <DashboardContext.Provider value={{
      selectedCompany,
      setSelectedCompany,
      statistics,
      setStatistics,
      dataForHorizontalChart,
      setDataForHorizontalChart,
      dataForVerticalChart,
      setDataForVerticalChart,
      filteredDataVerticalChart,
      setFilteredDataVerticalChart,
      targetStatistics,
      setTargetStatistics,
      selectedCompanyGroup,
      setSelectedCompanyGroup,
      GetSlaStatisticsByStartAndFinishDate,
      GetTargetStatistics,
      GroupedData,
      CompanyGroupGetAll,
      CalculateChartData,
      companyGroupList,
      companyGroupList,
      ResetContext
    }}>
      {props.children}
    </DashboardContext.Provider>
  );
}

export default DashboardContextProvider;