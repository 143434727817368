import React from 'react'
import "../styles/Navigation.css"


export default function Navigation({img, text_1, text_2}) {
    return (
        <div className="navigation-root">
            <div className="info-box">
                <div className="logo-box">
                    <img
                        className="logo-box-ico"
                        alt="customers"
                        src={img}
                    ></img>
                </div>

                <div className="text-box">
                    <label
                        style={{
                            color: "var(--textColor)",
                            fontSize: "0.8vw",
                            fontWeight: "500",
                        }}
                    >
                        {text_1}
                    </label>
                    <label
                        style={{
                            color: "var(--textColor)",
                            fontSize: "0.6vw",
                        }}
                    >
                        {text_2}
                    </label>
                </div>
            </div>
        </div>
        )
}
