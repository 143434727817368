import React, { useContext, useState } from "react";
import "../../styles/MessageBox.css";
import robot_img from "../../assets/robot.png";
import AnswerButton from "./AnswerButton";
import closeIco from "../../assets/close.png"
import { NewAgreementContext } from "../../contexts/NewAgreementContext";

export default function MessageBox({data, index}) {
  // const [newQuestion, setNewQuestion] = useState("");
  // function NewQuestion() {
  //   if (props.data.name && props.data.new) {
  //     props.data.name = newQuestion;
  //     props.data.new = false;
  //     setNewQuestion("")
  //   }
  // }
  // function handleKeyPress(event) {
  //   if (event.key === "Enter" && newQuestion !== "") {
  //     NewQuestion();
  //   }
  // }

  // function handleNextQuestion() {
  //   setNextQuestion(true);
  // }

  // function handleCloseButton() {
  //   setNewChatTrigger(false)
  //   let newArr = [...allData];
  //   newArr.pop();
  //   setAllData(newArr, handleNextQuestion());
  // }

  return (
    <div className="message-box-parent">
      <div style={{ display: "flex", flexFlow: "row wrap" }}>
        <div
          style={{
            height: "5vh",
            width: "2.5vw",
            backgroundColor: "#feb64c",
            borderRadius: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
          }}
        >
          <img
            alt="robot"
            src={robot_img}
            style={{
              height: "4vh",
              width: "2vw",
            }}
          />
        </div>
        {/* {props.data?.new ? (
          <>
          <div className="message-box">
            <input placeholder="+ New Question" type="text" value={newQuestion} onChange={(e)=>{ setNewQuestion(e.target.value) }} onKeyDown={handleKeyPress}>
            </input>
          </div>
          <button onClick={()=>{handleCloseButton()}} className="exit">
            <img src={closeIco} alt="close"></img>
          </button>
          </>
          
        ) : (
          <div className="message-box">{props.data.name}</div>
        )} */}
        <div className="message-box">{data?.botQuestionOptions.botQuestion?.question}</div>
      </div>
      <AnswerButton
        index={index}
        data={data}
      ></AnswerButton>
    </div>
  );
}
