import React, { useContext, useEffect } from 'react'
import { MainContext } from '../../contexts/MainContext';
import { LoginContext } from '../../contexts/LoginContext';
import { EditSlaContext } from '../../contexts/EditSlaContext';
import { CustomerDetailContext } from '../../contexts/CustomerDetailContext';
import "./EditSlaServices.css"

export default function EditSlaServices() {
  const {
    slaId,
    allServicesList,
    setAllServices,
    slasServices,
    setSlasServices,
    getSlasServicesTrigger,
    setGetSlasServicesTrigger,
  } = useContext(EditSlaContext);
  const { token, user, roles } = useContext(LoginContext)
  const { AddAlert } = useContext(MainContext);

  useEffect(() => {
    if (roles.some(role => role === "ServiceRead")) {

      fetch(
        process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_SERVICE_GET_SERVICES + "?Take=999&Skip=0",
        {
          method: "GET",
          headers: {
            "accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((data) => {
          setAllServices(data)
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }, []);

  useEffect(() => {
    if (slaId) {
      if (roles.some(role => role === "ServiceRead")) {
        fetch(
          process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_SERVICES_BY_SLA_ID + "?SlaId=" + slaId + "&PageNumber=1&PageSize=999",
          {
            method: "GET",
            headers: {
              "accept": "application/json",
              "Authorization": "Bearer " + token,
            },
          }
        )
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {

            setSlasServices(data.services)
            return data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      else {
        AddAlert("warning", "You do not have authorization for this operation.")
      }
    }
  }, [getSlasServicesTrigger, slaId]);
  function AddServiceToSla(serviceId) {
    if (roles.some(role => role === "ServiceCreate")) {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_SERVICES_ADD_TO_SLA,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "accept": "application/json",
            "Authorization": "Bearer " + token
          },
          body: JSON.stringify({
            serviceId: serviceId,
            slaId: slaId,
            accountId: user.id
          }),
        })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
        })
        .then((data) => {
          setGetSlasServicesTrigger(!getSlasServicesTrigger)
          AddAlert("success", "Adding Service Succesfull")
        })
        .catch((error) => {
          AddAlert("error", "Adding Service Fail")
          console.log(error.json());
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  };
  function RemoveServiceFromSla(serviceId) {
    if (roles.some(role => role === "ServiceDelete")) {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_SERVICES_REMOVE_FROM_SLA,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          slaId: slaId,
          serviceId: serviceId,
        }),
      })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setGetSlasServicesTrigger(!getSlasServicesTrigger)
        AddAlert("success", "Removing Service Succesfull")
      })
      .catch((error) => {
        AddAlert("error", "Removing Service Fail")
        console.log(error.json());
      });
    }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  return (
    <div className='edit-sla-services-container'>
      <div className='service-list-table-parent'>
        <label className='table-header'>All Services</label>
        <table>
          <thead>
            <tr>
              <th>
                Name
              </th>
              {/* <th>
                Key
              </th>
              <th>
                Description
              </th> */}
            </tr>
          </thead>
          <tbody>
            {allServicesList.map((item, index) => (
              <tr key={index}>
                <td>
                  {item.service.name}
                </td>
                {/* <td>
                  {item.service.key}
                </td>
                <td>
                  {item.service.description}
                </td> */}
                <td>
                  <button onClick={() => AddServiceToSla(item.service.id)} className='add-service-button'>
                    Add
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='service-list-table-parent'>
        <label className='table-header'>Agrement's Services</label>
        <table>
          <thead>
            <tr>
              <th>
                Name
              </th>
              {/* <th>
                Key
              </th>
              <th>
                Description
              </th> */}
            </tr>
          </thead>
          <tbody>
            {slasServices.map((item, index) => (
              <tr key={index}>
                <td>
                  {item.serviceName}
                </td>
                {/* <td>
                  {item.serviceKey}
                </td>
                <td>
                  {item.serviceDescription}
                </td> */}
                <td>
                  <button onClick={() => RemoveServiceFromSla(item.serviceId)} className='add-service-button'>
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


    </div>
  )
}
