import React from "react";
import "../../styles/UserAnswer.css";
import user_logo from "../../assets/user.png";

export default function UserAnswer({currentAnswer}){
    return (
      <div className="user-answer-box-parent">
        <div className="user-answer-box">
          {currentAnswer.value}
        </div>
        <div className="robot-img">
        <img
          alt="robot"
          src={user_logo}
          style={{
            height: "4vh",
            width: "2vw",
          }}
        />
        </div>
      </div>
    );
  }

