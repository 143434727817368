import React, { useContext, useEffect, useState } from 'react'
import { NewAgreementContext } from '../../contexts/NewAgreementContext';
import { NewSlaMetaDataContext } from '../../contexts/NewSlaMetaDataContext';
import { MainContext } from '../../contexts/MainContext';
import { LoginContext } from '../../contexts/LoginContext';
import { useNavigate } from 'react-router-dom';

export default function OldSla() {
    const { langFile, setAlertList, AddAlert } = useContext(MainContext);
    const { token, user, roles } = useContext(LoginContext)
    const navigate = useNavigate

    const {
        setSlas,
        metaDataType,
        oldSlaFormik,
        GetDate,
        Sort,
        slas,
        contractType } = useContext(NewSlaMetaDataContext)

    const {
        metadataDone,
        setMetadataDone,
    } = useContext(NewAgreementContext);

    useEffect(() => {
        if (metaDataType === "old" && oldSlaFormik.values.selectedSla) {
            if (roles.some(role => role === "SlaRead")) {
                fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_SLA_BY_ID + "?Id=" + oldSlaFormik.values.selectedSla + "&Deleted=false", {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token,
                    },
                })
                    .then((res) => {
                        if (res.ok && res.status === 200) {
                            return res.json();
                        }
                        throw res;
                    })
                    .then((data) => {
                        oldSlaFormik.setValues(
                            {
                                ...oldSlaFormik.values,
                                startDate: data.sla.startDate.substring(0, 7),
                                endDate: data.sla.endDate.substring(0, 7),
                                companyGroupOfSelectedSla: data.sla.companyGroupId
                            }
                        )
                        return data;
                    })
                    .catch((error) => {
                        setMetadataDone(false)
                        console.log(error);
                    });
            }
            else {
                AddAlert("warning", "You do not have authorization for this operation.")
            }
        }
        else {
            oldSlaFormik.resetForm()
        }
    }, [metadataDone, metaDataType, oldSlaFormik.values.selectedSla]);

    function GetAllSlaByCompanyIdAndCompanyGroupId(companyGroupId) {
        if (roles.some(role => role === "SlaRead")) {
            return new Promise((resolve) => {
                fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_ALL_SLA_BY_COMPANY_ID_AND_COMPANY_GROUP_ID + "?CompanyGroupId=" +
                    companyGroupId +
                    "&Deleted=false&Page=1&PageSize=9999", {
                    method: "GET",
                    headers: {
                        'Accept': 'application/json',
                        "Authorization": "Bearer " + token,
                    }
                })
                    .then((res) => {
                        if (res.ok && res.status === 200) {
                            return res.json();
                        }
                        throw res;
                    })
                    .then((data) => {
                        resolve(data.calculationResults);
                        return data;
                    })
                    .catch((error) => {
                        resolve([]);
                        console.log(error);
                    });
            })
        }
        else {
            AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function GetAllCompanyGroup(ownerCompanyId) {
        if (roles.some(role => role === "CompanyGroupRead")) {
        return new Promise((resolve) => {
            fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY_GROUP, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                    "TenantId": "slm",
                },
                body: JSON.stringify(
                    {
                        ownerCompanyId: ownerCompanyId,
                        pageNumber: 1,
                        pageSize: 9999,
                        includeCompanies: true
                    }
                ),
            })
                .then((res) => {
                    if (res.ok && res.status === 200) {
                        return res.json();
                    }
                    else if (res.status === 401) {
                        setAlertList([])
                        localStorage.clear();
                        navigate("/login");
                    }
                    throw res;
                })
                .then((data) => {
                    resolve(data?.companyGroups);
                })
                .catch((error) => {
                    resolve([])
                    console.log(error);
                });
        })
    }
        else{
        AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    async function GetAgreementsOfCompanyGroup() {
        const companyGroupList = await GetAllCompanyGroup(user.company.id)
        let agreements = []
        for (let i = 0; i < companyGroupList.length; i++) {
            let result = await GetAllSlaByCompanyIdAndCompanyGroupId(companyGroupList[i]?.companyGroupId)
            if (result) {
                agreements = [...agreements, ...result]
            }
        }
        setSlas(agreements)
    }

    useEffect(() => {
        GetAgreementsOfCompanyGroup()
    }, [user])

    return (
        <form
            onSubmit={oldSlaFormik.handleSubmit}
            className="form-container"
            style={{
                pointerEvents: !metadataDone ? "all" : "none",
                opacity: !metadataDone ? "1" : "0.5"
            }}
        >
            <label className="label-metadata-2" htmlFor='selectedSla'>
                {langFile.pages.NewSlaMetaData[contractType].name}
            </label>
            <select
                name="selectedSla"
                id="selectedSla"
                onChange={oldSlaFormik.handleChange}
                value={oldSlaFormik.values.selectedSla}
            >
                <option value="">
                    {langFile.pages.NewSlaMetaData[contractType].select}
                </option>
                {slas.filter(sla => {
                    if (sla.slaType === contractType) {
                        return sla;
                    }
                }).map((sla, index) => (
                    <option key={index} value={sla.agreementId}>
                        {sla.name}
                    </option>
                ))}
            </select>

            {oldSlaFormik.errors.selectedSla && oldSlaFormik.touched.selectedSla && <label className="errorLabel">{oldSlaFormik.errors.selectedSla}</label>}
            {oldSlaFormik.errors.startDate && oldSlaFormik.touched.startDate && <label className="errorLabel">{oldSlaFormik.errors.startDate}</label>}
            {oldSlaFormik.errors.endDate && oldSlaFormik.touched.endDate && <label className="errorLabel">{oldSlaFormik.errors.endDate}</label>}
            {<label style={{ color: "var(--tableHeaderColor)" }} className="errorLabel">{"Start Date:  " + oldSlaFormik.values.startDate}</label>}
            {<label style={{ color: "var(--tableHeaderColor)" }} className="errorLabel">{"End Date:    " + oldSlaFormik.values.endDate}</label>}
            <button type="submit">
                {langFile.pages.NewSlaMetaData.done}
            </button>
        </form>
    )
}
