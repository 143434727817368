import React, { useContext, useEffect } from 'react'
import "./Signing.css"
import { EditSlaContext } from '../../contexts/EditSlaContext'
import { SignAgreementContext } from '../../contexts/SignAgreementContext'
import cancel_ico from "../../assets/cancel.png"
import { LoginContext } from '../../contexts/LoginContext'
import sign_ico from "../../assets/sign.png"
import PopUpAlert from '../PopUpAlert/PopUpAlert'
import done_ico from "../../assets/done.png"

export default function Signing() {
    const { users, toEditSla, owner } = useContext(EditSlaContext)
    const { UpdateVerifiedSla, setIsSignPopUp, isSignPopUp } = useContext(SignAgreementContext)
    const { user } = useContext(LoginContext)


    function HandleSign(verifying) {
        UpdateVerifiedSla(
              {
                id: verifying.guid,
                isAuthorized: verifying.isAuthorized,
                isSigned: true,
                isOwner: verifying.isOwner,
                updatedBy: user.id
              }
        )
    }
    
    const completedSignatureCounter = toEditSla.verifiedSlas.reduce((count, element) => count + (element.isSigned === true ? 1 : 0), 0)

    return (
        <div className='signing-container'>
            <label className='counter-number'>
                {
                    toEditSla.verifiedSlas.length + "/" + completedSignatureCounter
                }
            </label>
            <div className={`users-table-container ${completedSignatureCounter === toEditSla.verifiedSlas.length && "completed-sign"}`}>
                {toEditSla.verifiedSlas
                    .map((verifying, index) => {
                        const verifyUser = users.find((user) => user.id === verifying.accountId);
                        const fullName = verifyUser ? `${verifyUser.firstname} ${verifyUser.lastname}` : '';
                        return (
                            <React.Fragment key={index}>
                                <div className='verifying-users-item'>
                                    {isSignPopUp === verifying.guid && <PopUpAlert text={`Would you like to sign ${toEditSla?.name} for signature to the users you choose?`}
                                        func={HandleSign} body={verifying} setIsPopUp={setIsSignPopUp} ></PopUpAlert>}
                                    {!verifying.isSigned && owner?.id === user.id?
                                        <div className='tooltip-container'>
                                            <img
                                                className='remove-verify tooltip-trigger'
                                                alt='remove'
                                                src={cancel_ico}
                                                onClick={() => {
                                                    // Remove Signature işlemleri buraya gelecek
                                                }}
                                                style={{
                                                    filter: 'invert(var(--filterRatio))',
                                                    WebkitFilter: 'invert(var(--filterRatio))',
                                                }}
                                            ></img>
                                            <div
                                                style={{
                                                    top: '2vh',
                                                    left: '2vw',
                                                    width: '6vw',
                                                    opacity: ".9"

                                                }}
                                                className='tooltip-content'
                                            >
                                                Remove Signature
                                            </div>
                                        </div>
                                        :
                                        owner?.id !== user.id ?
                                        null:
                                        <div style={{width:"1.4vw"}}></div>

                                    } 
                                    {!verifying.isSigned && verifying.accountId === user.id ? 
                                        <div className='tooltip-container'>
                                            <img
                                                className='remove-verify tooltip-trigger'
                                                alt='remove'
                                                src={sign_ico}
                                                onClick={() => {
                                                    setIsSignPopUp(verifying.guid)
                                                }}
                                                style={{
                                                    filter: 'invert(var(--filterRatio))',
                                                    WebkitFilter: 'invert(var(--filterRatio))',
                                                }}
                                            ></img>
                                            <div
                                                style={{
                                                    top: '2vh',
                                                    left: '2vw',
                                                    width: '3vw',
                                                    opacity: ".9"
                                                }}
                                                className='tooltip-content'
                                            >
                                                Sign
                                            </div>
                                        </div>
                                        :
                                        <div style={{width:"1.4vw"}}></div>
                                    }
                                    <label className='user-info' title={fullName}>
                                        {fullName}
                                    </label>
                                    {verifying.isSigned ? (
                                        <div className='box'>signed</div>
                                    ) : (
                                        <div className='box not'>unsigned</div>
                                    )}
                                    {verifying.isOwner && (
                                        <div className='box owner'>owner</div>
                                    )}
                                    {verifying.isSigned &&
                                        <>
                                            <label style={{ width: "auto", marginLeft:"auto" }} className='user-info' title={verifying.signedAt}>
                                                Signed at 
                                            </label>
                                            <label style={{ width: "auto" }} className='user-info' title={verifying.signedAt}>
                                                {verifying.signedAt}
                                            </label>
                                        </>
                                    }
                                </div>
                                <hr></hr>
                                
                            </React.Fragment>
                        );
                    })}
            </div>
            {completedSignatureCounter === toEditSla.verifiedSlas.length && 
            <label className='completed'>
                <img alt='done'  src={done_ico}>                    
                </img>
                DONE
            </label>
            }
        </div>
    )
}
