import React, { useContext, useEffect } from 'react'
import "./SignAgreementMain.css"
import { SignAgreementContext } from '../../contexts/SignAgreementContext'
import { LoginContext } from '../../contexts/LoginContext'
import WillSign from './WillSign'
import { EditSlaContext } from '../../contexts/EditSlaContext'
import Signing from './Signing'

export default function SignAgreementMain() {
  
  const { user } = useContext(LoginContext)
  const { toEditSla, GetAllUser } = useContext(EditSlaContext)

  useEffect(() => {
    GetAllUser({
      companyId: user?.company?.id,
      pageIndex: 1,
      pageSize: 999,
      includeAccountType: true,
      includeCompany: true,
      includeOrganizations: true,
      includePositions: true
    })
  }, [])

  return (
    <div className='sign-main-parent'>
      <label className='table-header'>
        Sign Agreement
      </label>
      <div className='signature-comp-container'>
      {toEditSla?.verifiedSlas?.length < 2 ? 
      <WillSign></WillSign>  
        :
        <Signing></Signing>
      }
      </div>
      
    </div>
  )
}
