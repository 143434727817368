import React, { useContext, useEffect, useState } from 'react';
import "./MainEngine.css";
import ForecastConfiguration from "./ForecastConfiguration"
import ForecastSelection from "./ForecastSelection"
import EditTarget from "./EditTarget"
import { KpiEditContext } from '../../contexts/KpiEditContext';
import { KpisContext } from '../../contexts/KpisContext';
import { MainContext } from '../../contexts/MainContext';
import { LoginContext } from '../../contexts/LoginContext';

export default function MainEngine({ data, setData }) {
    const [selectedSection, setSelectedSection] = useState("Configuration");
    const { token } = useContext(LoginContext)
    const { kpiEditSide,
        selectedKpi,
        setKpiEditSide,
        updateTrigger,
        setUpdateTrigger, } = useContext(KpisContext);
    const {
        kpiStartDate,
        setKpiStartDate,
        kpiEndDate,
        setKpiEndDate,
        setKpiName,
        kpiType,
        setKpiType,
        setSelectedMonths,
        setTargetsForRecommendation,
        setKpiJson,
        setHistoricalDataUpdateCheck,
        setInputWeight,
        inputTargetType,
        inputRange,
        inputPercentageTarget,
        inputNumberTarget,
        currentDate,
        kpiName,
        setInputKpiName,
        setInputKpiType,
        selectedMonths,
        kpiJson,
        setUpdateConfirmActive,
        setRangeValues,
        setPriorityValues,
    } = useContext(KpiEditContext);

    const { langFile } = useContext(MainContext);

    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_TARGET_TYPE+  "?Take=999&Skip=0",
            {
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": "Bearer " + token
                },
            })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
            })
            .then((data) => {
                let obj = {}
                data.map((item) => {
                    obj[item.name] = item.guid
                })
                setRangeValues(obj)
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_PRIORITY_GET_PRIORITY + "?Take=999&Skip=0",
            {
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": "Bearer " + token
                },
            })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
            })
            .then((data) => {
                let obj = {}
                data.map((item) => {
                    obj[item.name] = item.guid
                })
                setPriorityValues(obj)
            })
            .catch((err) => console.log(err));
    }, []);

    function createJson() {
        let json = [
            {
                months: [],
            },
        ];
        let months = [];
        let selectedMonthsTemp = [];
        for (let i = 0; i < selectedKpi?.targets.length; i++) {
            months.push({
                guid: selectedKpi.targets[i]?.id,
                targetDate: selectedKpi.targets[i]?.referenceDate,
                value: selectedKpi.targets[i]?.value,
                startDate: selectedKpi.targets[i]?.referenceDate,
                endDate: getDate(selectedKpi.targets[i]?.referenceDate, 1),
                target: selectedKpi.targets[i]?.completedValue,
                status: selectedKpi.targets[i]?.isSucceed ? 1 : -1,
                weight: selectedKpi.targets[i]?.weight,
                range: selectedKpi.targets[i]?.targetType.name,
                isPercentage: selectedKpi.targets[i]?.isPercentage,
            });
            selectedMonthsTemp.push(false);
        }
        setSelectedMonths(selectedMonthsTemp);
        json[0].months = months;
        setKpiJson(json);
    }
    
    function getDate(startDate, mounthNumber) {
        const start = startDate.split("-");
        let yearCounter = parseInt(
            (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) / 12
        );
        let mounthCounter = parseInt(
            (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) % 12
        );
        start[1] = mounthCounter;
        start[0] = yearCounter;
        if (mounthCounter === 0) {
            start[1] += 12;
            start[0] -= 1;
        }
        if (start[1] < 10) {
            return String(start[0]) + "-0" + String(start[1]);
        } else {
            return String(start[0]) + "-" + String(start[1]);
        }
    }

    useEffect(() => {
        let arr = [];
        kpiJson[0].months.map((item) => arr.push({ item: item, selected: false }));
        setTargetsForRecommendation(arr);
    }, [kpiJson]);

    useEffect(() => {
        setKpiName(selectedKpi?.name);
        setKpiType(selectedKpi?.priority.name);
        setInputKpiName(selectedKpi?.name);
        setInputKpiType(selectedKpi?.priority.name);
        setKpiStartDate(selectedKpi?.targets[0].referenceDate);
        setKpiEndDate(selectedKpi?.targets[selectedKpi?.targets.length - 1].referenceDate);
        setInputWeight(selectedKpi?.targets[0]?.weight)
        createJson();
    }, [selectedKpi]);
    
    function CheckHistoricalDataChange() {
        let jsonTemp = [...kpiJson];
        let arr = []
        for (let i = 0; i < selectedKpi?.targets.length; i++) {
            if (selectedMonths[i]) {
                let startDate = new Date(jsonTemp[0].months[i].startDate);
                let diffInMonths =
                    (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
                    (currentDate.getMonth() - startDate.getMonth());
                if (diffInMonths >= 0) {
                    let beforeStatus = jsonTemp[0].months[i].status
                    let afterStatus = jsonTemp[0].months[i].status
                    if ((parseInt(inputTargetType === "percentage" ? inputPercentageTarget : inputNumberTarget) <= parseInt(jsonTemp[0].months[i].value)) &&
                        inputRange === "Minimum") {
                        afterStatus = 1
                    }
                    else if ((parseInt(inputTargetType === "percentage" ? inputPercentageTarget : inputNumberTarget) > parseInt(jsonTemp[0].months[i].value)) &&
                        inputRange === "Minimum") {
                        afterStatus = -1
                    }
                    else if ((parseInt(inputTargetType === "percentage" ? inputPercentageTarget : inputNumberTarget) < parseInt(jsonTemp[0].months[i].value)) &&
                        inputRange === "Maximum") {
                        afterStatus = -1
                    }
                    else if ((parseInt(inputTargetType === "percentage" ? inputPercentageTarget : inputNumberTarget) >= parseInt(jsonTemp[0].months[i].value)) &&
                        inputRange === "Maximum") {
                        afterStatus = 1
                    }
                    if (beforeStatus !== afterStatus) {
                        let obj = {
                            "beforeStatus": beforeStatus,
                            "afterStatus": afterStatus,
                            "content": jsonTemp[0].months[i]
                        }
                        arr.push(obj)
                    }
                }
            }
        }
        if (arr.length === 0) { setUpdateConfirmActive(true); }
        setHistoricalDataUpdateCheck(arr)
    }

    async function Confirm() {
        await CheckHistoricalDataChange()
    }
    return (
        <div className='engine-parent'>
            <div className='engine-container'>
                <div className='section-container'>
                    <label htmlFor='Configuration' className={`section ${selectedSection === "Configuration" ? 'selected' : ''}`}>
                        <input
                            type="radio"
                            id="Configuration"
                            name="section"
                            value="Configuration"
                            checked={selectedSection === "Configuration"}
                            onChange={(event) => setSelectedSection(event.target.value)}
                        />
                        Forecast Configuration (1)
                    </label>
                    <label htmlFor='Selection' className={`section ${selectedSection === "Selection" ? 'selected' : ''}`}>
                        <input
                            type="radio"
                            id="Selection"
                            name="section"
                            value="Selection"
                            checked={selectedSection === "Selection"}
                            onChange={(event) => setSelectedSection(event.target.value)}
                        />
                        Forecast Selection (2)
                    </label>
                    <label htmlFor='Edit' className={`section ${selectedSection === "Edit" ? 'selected' : ''}`}>
                        <input
                            type="radio"
                            id="Edit"
                            name="section"
                            value="Edit"
                            checked={selectedSection === "Edit"}
                            onChange={(event) => setSelectedSection(event.target.value)}
                        />
                        Edit Target (3)
                    </label>
                </div>
                <div className='operation-frame-container'>
                    {selectedSection === "Configuration" ?
                        <ForecastConfiguration data={data} setData={setData}></ForecastConfiguration> :
                        selectedSection === "Selection" ?
                            <ForecastSelection></ForecastSelection> :
                            <EditTarget data={data} setData={setData}></EditTarget>}
                </div>
            </div>
            <div className="button-container">
                <button
                    onClick={() => {
                        setSelectedSection("Configuration")
                        setKpiEditSide(false);
                    }}
                    className="close-button"
                >
                    {langFile.pages.KpiEdit.cancel}
                </button>
                {selectedSection === "Edit" ?
                    <button onClick={Confirm} className="confirm-button">
                        {langFile.pages.KpiEdit.confirm}
                    </button>
                    : null}
            </div>
        </div>
    )
}
