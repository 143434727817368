import React, { useContext, useState } from "react";
import "../../styles/AnswerButton.css";
import UserAnswer from "./UserAnswer";
import { NewAgreementContext } from "../../contexts/NewAgreementContext";
import { ChatbotContext } from "../../contexts/ChatbotContext";
import { LoginContext } from "../../contexts/LoginContext";

export default function AnswerButton(props) {
  const [newAnswerName, setNewAnswerName] = useState("");
  const {
    chatbotData,
    setChatbotData,
    setNextQuestion,
    nextQuestion,
    setParentBotAnswerId,
    AddNewCategory,
    lastGetBotAnswerBody
  } = useContext(ChatbotContext);
  const { user } = useContext(LoginContext)

  function AddNewAnswer() {
    let parentKpiCategoryId = null
    if(props.index>0){
      parentKpiCategoryId = chatbotData[props.index-1].answer.id
    }
    AddNewCategory(
      {
        name: newAnswerName,
        accountId: user.id,
        parentKpiCategoryId: parentKpiCategoryId
      },
      lastGetBotAnswerBody.length-1
    )
    setNewAnswerName("")
  }
  function HandleAddAnswerOnFocus(inputName) {
    const element = document.querySelector(`[name='${inputName}']`);
    if (element) {
      element.style.minWidth = "12vw";
    }
  }
  function HandleAddAnswerOnBlur(inputName) {
    const element = document.querySelector(`[name='${inputName}']`);
    if (element) {
      element.style.minWidth = "6vw";
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter" && newAnswerName !== "") {
      AddNewAnswer();
    }
  }

  function HandleOptionClicked(option) {
    let temp = [...chatbotData]
    temp[temp.length - 1].answer = option
    setChatbotData(temp)
    setNextQuestion(nextQuestion + 1)
  }

  function HandlePastOptionClicked(option) {
    let temp = [...chatbotData.slice(0, props.index + 1)]
    temp[temp.length - 1].answer = option
    if (!temp[props.index].botQuestionOptions.isRecursive) {
      setParentBotAnswerId(temp[props.index].botQuestionOptions.id)
    }
    else {
      setParentBotAnswerId(temp[props.index].botQuestionOptions.parentBotAnswer.id)
    }
    setChatbotData(temp)
    setNextQuestion(nextQuestion + 1)
  }
  return (
    <div className="answer-button-parent">
      {props.data?.botQuestionOptions?.description?.values.map((option, index) => (
        <button
          key={index}
          className={
            (props.data?.answer?.id) ? 
            (option.id === props.data?.answer?.id//some answers has no id so we must check names in this times
              ? "answer-button answer-button-active"
              : "answer-button") 
            : (props.data?.answer?.value === option.value ?
              "answer-button answer-button-active"
              : "answer-button")
          }
          onClick={() => {
            if (props.index === chatbotData.length - 1) {//if last selection selected we not need to update other selection
              HandleOptionClicked(option)
            }
            else {
              HandlePastOptionClicked(option)
            }

          }}
        >
          {option.value}
        </button>
      ))}
      {(props.index === chatbotData.length - 1 && 
      (chatbotData[props.index].botQuestionOptions.order === 0 ||
      chatbotData[props.index].botQuestionOptions.order === 1)) &&
        <input
          onBlur={() => {
            HandleAddAnswerOnBlur("answer-button-input-" + props.index);
          }}
          onFocus={() => {
            HandleAddAnswerOnFocus("answer-button-input-" + props.index);
          }}
          name={"answer-button-input-" + props.index}
          id={props.index}
          type="text"
          className="input-add-answer"
          value={newAnswerName}
          onChange={(event) => {
            setNewAnswerName(event.target.value);
          }}
          onKeyDown={handleKeyPress}
          placeholder="+ New Answer"
        ></input>
      }
      {props.data?.answer && (
        <UserAnswer currentAnswer={props.data.answer}></UserAnswer>
      )}
    </div>
  );
}
