import React, { useEffect, useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { MainContext } from "./MainContext";
import { LoginContext } from "./LoginContext";
import {parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

export const EditCompanyContext = React.createContext();

const EditCompanyContextProvider = (props) => {
    const { AddAlert, setAlertList } = useContext(MainContext);
    const [toEditCompany, setToEditCompany] = useState(false);
    const { token, user, roles } = useContext(LoginContext)
    const navigate = useNavigate();

    const editServiceProviderValidationSchema = yup.object().shape({
        name: yup.string().min(2,"'Company Name' must be least 2 character."),
        taxCode: yup.string(),
        fax: yup.string(),
        email: yup.string().email("Provide a valid email address"),
        phoneNumber: yup.string()
        .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', value => checkPhoneNumber(value)),
        address: yup.string(),
        contactName: yup.string(),
        contactPhoneNumber: yup.string()
        .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', value => checkPhoneNumber(value)),
        contactEmail: yup.string().email("Provide a valid email address"),
        contactAddress: yup.string(),
        contact2Name: yup.string(),
        contact2PhoneNumber: yup.string()
        .test('isValidPhoneNumber', 'Geçerli bir telefon numarası giriniz', value => checkPhoneNumber(value)),
        contact2Email: yup.string().email("Provide a valid email address"),
        contact2Address: yup.string(),
        website: yup.string(),
        description: yup.string(),
    });

    function checkPhoneNumber(value){
            if (value && typeof value === 'string') {
              return isValidPhoneNumber(value);
            }
            return true;
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            address: '',
            taxCode: '',
            fax: '',
            description: '',
            contactName: '',
            contactEmail: '',
            contactPhoneNumber: '',
            contactAddress: '',
            contact2Name: '',
            contact2Email: '',
            contact2PhoneNumber: '',
            contact2Address: '',
            website: ''
        },
        validationSchema: editServiceProviderValidationSchema,
        onSubmit: (values) => {
            EditCompany(values);
        },
    });

    useEffect(() => {
        formik.setValues({
            name: toEditCompany?.name || '',
            email: toEditCompany?.email || '',
            phoneNumber: toEditCompany?.phoneNumber || '',
            address: toEditCompany?.address || '',
            taxCode: toEditCompany?.taxCode || '',
            fax: toEditCompany?.fax || '',
            description: toEditCompany?.description || '',
            contactName: toEditCompany?.contactName || '',
            contactEmail: toEditCompany?.contactEmail || '',
            contactPhoneNumber: toEditCompany?.contactPhoneNumber || '',
            contactAddress: toEditCompany?.contactAddress || '',
            contact2Name: toEditCompany?.contact2Name || '',
            contact2Email: toEditCompany?.contact2Email || '',
            contact2PhoneNumber: toEditCompany?.contact2PhoneNumber || '',
            contact2Address: toEditCompany?.contact2Address || '',
            website: toEditCompany?.website || ''
        });
      }, [toEditCompany])


    function EditCompany(values) {
        if (roles.some(role => role === "CompanyEdit")) {
            fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_ORGANIZATION_COMPANY_UPDATE, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                    "TenantId": "slm",
                },
                body: JSON.stringify({
                    id: toEditCompany.id,
                    name: values.name,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    address: values.address,
                    taxCode: values.taxCode,
                    countryCode: values.phoneNumber ?? parsePhoneNumber(values.phoneNumber).country,
                    fax: values.fax,
                    website: values.website,
                    description: values.description,
                    contactName: values.contactName,
                    contactEmail: values.contactEmail,
                    contactCountryCode: values.contactPhoneNumber ?? parsePhoneNumber(values.contactPhoneNumber).country,
                    contactPhoneNumber: values.contactPhoneNumber,
                    contactAddress: values.contactAddress,
                    contact2Name: values.contact2Name,
                    contact2Email: values.contact2Email,
                    contact2CountryCode: values.contact2PhoneNumber ?? parsePhoneNumber(values.contact2PhoneNumber).country,
                    contact2PhoneNumber: values.contact2PhoneNumber,
                    contact2Address: values.contact2Address,
                }),
            })
                .then((res) => {
                    if (res.ok && res.status === 200) {
                        AddAlert("success", "Update Company Successful")
                        return res.json();
                    }
                    else if (res.status === 401) {
                        setAlertList([])
                        localStorage.clear();
                        navigate("/login");
                    }
                    throw res
                })
                .then((data) => {
                    // AddCompanyToCompanyGroup(data.result.data)
                    formik.resetForm()
                    setToEditCompany(false)
                    return data;
                })
                .catch((error) => {
                    error.json().then((err) => {
                        const errorObject = err.errors
                        Object.keys(errorObject).forEach(key => {
                            const errors = errorObject[key];

                            let errorText = ""
                            errors.forEach(error => {
                                errorText += (error + ". ")
                            });
                            formik.setFieldError(key, errorText)
                        });
                    })
                    AddAlert("error", "Add company Failed")
                });
            }
            else{
              AddAlert("warning", "You do not have authorization for this operation.")
            }

    }
    function handleKeyPress(event) {
        if (event.key === "Enter" && Object.keys(formik.errors).length === 0) {
            EditCompany(formik.values);
        }
    }

    return (
        <EditCompanyContext.Provider value={{
            EditCompany,
            editServiceProviderValidationSchema,
            formik,
            handleKeyPress,
            setToEditCompany,
            toEditCompany
        }}>
            {props.children}
        </EditCompanyContext.Provider>
    );
}

export default EditCompanyContextProvider;