import React, { useEffect, useState, useContext } from 'react'
import "./CompanyGroupBox.css"
import remove_ico from "../../assets/delete.png"
import edit_ico from "../../assets/pencil.png"
import detail_ico from "../../assets/eye.png"
import { useNavigate } from 'react-router-dom'
import { LoginContext } from '../../contexts/LoginContext';
import { MainContext } from '../../contexts/MainContext';
import PopUpAlert from "../../components/PopUpAlert/PopUpAlert";
import { EditCompanyContext } from '../../contexts/EditCompanyContext'
import { CompanyGroupContext } from '../../contexts/CompanyGroupContext'


export default function CompanyGroupBox({ companyGroup, GetCompanies }) {

  const { token, user, roles } = useContext(LoginContext)
  const { langFile, setAlertList, AddAlert } = useContext(MainContext);
  const navigate = useNavigate();
  const { toEditCompany, setToEditCompany } = useContext(EditCompanyContext);
  const { isDeletePopUp,
    setIsDeletePopUp,
    selectedCompany,
    setSelectedCompany,
    isCompanyDetail,
    setIsCompanyDetail } = useContext(CompanyGroupContext);

  function removeCompany(body) {
    if (roles.some(role => role === "CompanyDelete")) {

      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_DELETE_ORGANIZATION_COMPANY_GROUP_COMPANY, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(body)
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res.json();
        })
        .then((data) => {
          if (data.result.statusCode === 200) {
            AddAlert("success", "Remove Company is Successful")
            GetCompanies()
            return data;
          }
          else {
            AddAlert("error", "Remove Company is Failed")
          }
        })
        .catch((error) => {
          AddAlert("error", "Remove Company is Failed")
          console.log(error)
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function deleteCompanyGroup(body) {
    if (roles.some(role => role === "CompanyGroupDelete")) {

      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_DELETE_ORGANIZATION_COMPANY_GROUP, {
        method: "DELETE",
        headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(body)
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res.json();
        })
        .then((data) => {
          if (data.result.statusCode === 200) {
            AddAlert("success", "Delete Company Group is Successful")
            GetCompanies()
            return data;
          }
          else {
            AddAlert("error", "Delete Company Group is Failed")
          }
        })
        .catch((error) => {
          AddAlert("error", "Delete Company Group is Failed")
          console.log(error)
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }


  function handleClickToEditCompanyButton(company) {
    setToEditCompany(company);
    navigate("/main/newcompany");
  }

  function IsPossibleDeleteCompany(companyId) {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_ALL_SLA_BY_COMPANY_ID_AND_COMPANY_GROUP_ID + "?CompanyId=" + companyId + "&Deleted=false&Page=1&PageSize=999", {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
        else if (res.status === 401) {
          setAlertList([])
          localStorage.clear();
          navigate("/login");
        }
        throw res.json();
      })
      .then((data) => {
        if (data?.totalSlaCount === 0) {
          setIsDeletePopUp(companyId)
          return data;
        }
        else {
          AddAlert("warning", "This company can not delete")
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  return (
    <div className='company-group-box-container'>
      <div className='group-companies-container'>
        <div className='group-owner-container'>
          <div className='group-info'>
            {companyGroup.name}
          </div>
          <div className='button-container'>
            {roles.some(role => role === "CompanyGroupDelete") &&
              <img alt='delete' src={remove_ico} onClick={() => setIsDeletePopUp(companyGroup.companyGroupId)} />
            }
          </div>
        </div>
        {companyGroup.companies?.map((company) => (
          <React.Fragment key={company.id}>
            {isDeletePopUp === companyGroup.companyGroupId && <PopUpAlert key={"pop-up" + companyGroup.companyGroupId} text={"Do you wanna delete " + companyGroup.name + " ?"} func={deleteCompanyGroup} setIsPopUp={setIsDeletePopUp} body={{ id: companyGroup.companyGroupId, name: companyGroup.name }} ></PopUpAlert>}
            {isDeletePopUp === company.id && <PopUpAlert key={"pop-up" + company.id} text={"Do you wanna remove " + company.name + " ?"} func={removeCompany} setIsPopUp={setIsDeletePopUp} body={{ companyGroupId: companyGroup.companyGroupId, companyId: company.id }} ></PopUpAlert>}
            <div className='company' key={company.id}>
              <label>{company.name}</label>
              <div className='button-container'>
                {roles.some(role => role === "CompanyEdit") &&
                  <img alt='edit' src={edit_ico} onClick={() => handleClickToEditCompanyButton(company)} />
                }
                {roles.some(role => role === "CompanyDelete") &&
                  <img alt='remove' src={remove_ico} onClick={() => IsPossibleDeleteCompany(company.id)} />
                }
                {roles.some(role => role === "CompanyRead") &&
                  <img alt='detail' src={detail_ico} onClick={() => { setSelectedCompany(company); setIsCompanyDetail(!isCompanyDetail) }} />
                }
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
