import React, { useContext, useEffect } from 'react'
import { MainContext } from '../../contexts/MainContext';
import { NewSlaMetaDataContext } from '../../contexts/NewSlaMetaDataContext';
import { NewAgreementContext } from '../../contexts/NewAgreementContext';
import { LoginContext } from '../../contexts/LoginContext';

export default function WithoutSla() {
    const { langFile } = useContext(MainContext);
    const { DoneButtonHandle, withoutSlaFormik, GetAllCompanyGroup, companyGroupList } = useContext(NewSlaMetaDataContext)
    const {
        metadataDone,
    } = useContext(NewAgreementContext);
    const { user } = useContext(LoginContext)
    useEffect(() => {
        if (user?.company?.id) {
            GetAllCompanyGroup(user.company.id)
        }
    }, [user?.company?.id])
    return (
        <form
            onSubmit={withoutSlaFormik.handleSubmit}
            className="form-container"
            style={{
                pointerEvents: !metadataDone ? "all" : "none",
                opacity: !metadataDone ? "1" : "0.5"
            }}>

            <label className="label-metadata-2" htmlFor='selectedCompanyGroup'>
                {langFile.pages.NewSlaMetaData.selectCompanyGroup}
            </label>
            <select
                name="selectedCompanyGroup"
                id="selectedCompanyGroup"
                onChange={withoutSlaFormik.handleChange}
                value={withoutSlaFormik.values.selectedCompanyGroup}
            >
                <option value="">Select Company Group</option>
                {companyGroupList?.map((companyGroup, index) => (
                    <option key={index} value={companyGroup.companyGroupId}>
                        {companyGroup.name}
                    </option>
                ))}
            </select>
            {withoutSlaFormik.errors.selectedCompanyGroup && withoutSlaFormik.touched.selectedCompanyGroup && <label className="errorLabel">{withoutSlaFormik.errors.selectedCompanyGroup}</label>}



            <label className="label-metadata-2" htmlFor='startDate'>
                {langFile.pages.NewSlaMetaData.startDate}
            </label>
            <input
                type="month"
                id="startDate"
                name="startDate"
                max={withoutSlaFormik.values.endDate}
                value={withoutSlaFormik.values.startDate}
                onChange={withoutSlaFormik.handleChange}
                autoComplete="on"
            />
            <label className="label-metadata-2">
                {langFile.pages.NewSlaMetaData.endDate}
            </label>
            {withoutSlaFormik.errors.startDate && withoutSlaFormik.touched.startDate && <label className="errorLabel">{withoutSlaFormik.errors.startDate}</label>}
            <input
                type="month"
                id="endDate"
                name="endDate"
                min={withoutSlaFormik.values.startDate}
                value={withoutSlaFormik.values.endDate}
                onChange={withoutSlaFormik.handleChange}
                autoComplete="on"
            />
            {withoutSlaFormik.errors.endDate && withoutSlaFormik.touched.endDate && <label className="errorLabel">{withoutSlaFormik.errors.endDate}</label>}

            <button type="submit">
                {langFile.pages.NewSlaMetaData.done}
            </button>

        </form>)
}
