import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "./MainContext";
import { useNavigate } from "react-router-dom";

export const MailContext = React.createContext();

const MailContextProvider = (props) => {
    const { setAlertList } = useContext(MainContext);
    const navigate = useNavigate
    const [mailTemplates, setMailTemplates] = useState([])

    

    function SendEmail(body){
        return new Promise((resolve) => {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_MAIL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            //   "Authorization": "Bearer " + token,
              "TenantId": "slm",
            },
            body: JSON.stringify(
              body
            ),
          })
            .then((res) => {
              if (res.ok && res.status === 200) {
                return res.json();
              }
              else if (res.status === 401) {
                setAlertList([])
                localStorage.clear();
                navigate("/login");
              }
              throw res;
            })
            .then((data) => {
              resolve(["success", `Mail is Sent to ${body.to} Succesfull`])
              return data;
            })
            .catch((error) => {
              resolve(["error", `Mail is not Sent to ${body.to}`])
              console.log(error);
            });
          });
      }

      function GetMailTemplates(){
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_MAIL_TEMPLATES + "?PageSize=999&PageNumber=1", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                // "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res;
            })
            .then((data) => {
                if (data?.mailTemplates.length > 0) {
                    setMailTemplates(data.mailTemplates)
                }
                else {
                    setMailTemplates([])
                }
            })
            .catch((error) => {
                setMailTemplates([])
                console.log(error);
            });
    }

    useEffect(()=>{
      GetMailTemplates()
    },[])

    return (
        <MailContext.Provider value={{ 
            SendEmail,
            mailTemplates,
            setMailTemplates
         }}>
            { props.children }
        </MailContext.Provider>
    );
}

export default MailContextProvider;