import React from "react";
import "./CounterBoxesThreePart.css";
import ProgressBar from "../../ProgressBar/ProgressBar.js";

export default function CounterBoxesThreePart({ text_1, count_1, text_2, count_2, text_3, count_3, backgroundColor, src }) {
    return (
        <div
            style={{ backgroundColor: "var(--backgroundColor2)" }}
            className="counter-box-three-part-container"
        >
            <div className="top-part">
                <div className="ico-text-container">
                    <img alt="ico" src={src} id="counter-ico"></img>
                    <label className="count-text">{text_1}</label>
                    <label className="count-number">{count_1 ? count_1 : 0}</label>
                </div>
                {/* <div className="count-info-container">
                    <label className="count-number">{count_1}</label>
                    <label className="count-text">{text_1}</label>
                </div> */}
            </div>
            <div className="bottom-part">
                <ProgressBar value_1={count_2} value_2={count_3}></ProgressBar>
                <div className="legend-container">
                    <div className="legend"> <div style={{ backgroundColor: "var(--backgroundColor3)" }} className="circle"></div>{"Active"}</div>
                    <div className="legend"> <div style={{ backgroundColor: "var(--backgroundColor)" }} className="circle"></div>{"Deactive"}</div>
                </div>

            </div>

        </div>
    );
}
