import React, { useState } from "react";

export const SmallProfileCardContext = React.createContext();

const SmallProfileCardContextProvider = (props) => {

    const[myUsers, setMyUsers] = useState([])
    const [isDeletePopUp, setIsDeletePopUp] = useState(false);

    return (
        <SmallProfileCardContext.Provider value={{ myUsers,
            setMyUsers,
            isDeletePopUp,
            setIsDeletePopUp
           }}>
            { props.children }
        </SmallProfileCardContext.Provider>
    );
}

export default SmallProfileCardContextProvider;