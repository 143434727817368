import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';
import { DashboardContext } from '../../../contexts/DashboardContext';

function VerticalBarChart(props){
  const [data, setData] = useState([
    props.data
  ]);
  useEffect(()=>{
    setData(props.data)
  },[props.data])

  const config = {
    color: ['#263644',"#285cb0",'#cacedc' ],
    data,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    columnWidthRatio: 0.3, // You can change this value to control bar width

  };

  return <Column style={{width:"100%", height:"100%"}} {...config} />;
};
export default VerticalBarChart
