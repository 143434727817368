import React, { useContext, useEffect, useState } from "react";

import "../styles/AllKpisComponent/AllKpis.css";
import AllKpisDetail from "../components/AllKpisComponent/AllKpisDetail";
import AllKpisSLAs from "../components/AllKpisComponent/AllKpisSLAs";
import add_ico from "../assets/add.png";
import { MainContext } from "../contexts/MainContext";
import { AllKpisContext } from "../contexts/AllKpisContext";
import logo from "../assets/kpi.png";
import copy_ico from "../assets/copy.png"
import copy_ico_2 from "../assets/copy-3.png"
import up_arrow_ico from "../assets/up-arrow.png"
import { LoginContext } from "../contexts/LoginContext";
import SelectCompany from "../components/SelectCompany";
import Navigation from "../components/Navigation";

export default function AllKpis() {
  const { token, user, roles } = useContext(LoginContext)
  const { langFile, AddAlert } = useContext(MainContext);
  const { iskpiDetail,
    setIskpiDetail,
    slas,
    setSlas,
    selectedSla,
    setSelectedSla,
    kpis,
    setKpis,
    selectedKpi,
    setSelectedKpi,
    sortTrigger,
    setSortTrigger,
    isActiveDropdown_2,
    setIsActiveDropdown_2,
    isActiveDropdown_3,
    setIsActiveDropdown_3,
    sideAddKpi,
    setSideAddKpi,
    filteredKpis,
    setFilteredKpis,
    filters,
    setFilters,
    Sort,
    handleCheckboxChangeSla,
    handleCopyKpiToSlas,
    handleCheckboxChange,
    GetAllSlaByAccountId,
    GetKpiByCompanyGroup,
    companyGroupList,
    setCompanyGroupList,
    selectedCompanyGroup,
    setSelectedCompanyGroup,
    GetAllCompanyGroup,
    ResetContext } = useContext(AllKpisContext);

  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      ResetContext()
    };
  }, []);

  useEffect(() => {
    const { Successful, Fail, Critical, Major, Minor, Numeric, Percentage } = filters;
    const filteredKpis = kpis.filter((kpi) => {
      if (
        (Critical && kpi.priority.name === "Critical") ||
        (Major && kpi.priority.name === "Major") ||
        (Minor && kpi.priority.name === "Minor")
      ) {
        return true;
      }
      return false;
    });
    setFilteredKpis(filteredKpis);
  }, [kpis, filters]);

  useEffect(() => {
    if (sortTrigger === true) {
      Sort();
    }
  }, [sortTrigger]);

  useEffect(() => {
    if (user?.company?.id) {
      GetAllCompanyGroup(user.company.id)
    }
  }, [user?.company?.id])

  useEffect(() => {
    if (selectedCompanyGroup?.companyGroupId) {
      GetKpiByCompanyGroup(selectedCompanyGroup.companyGroupId)
    }
  }, [selectedCompanyGroup])

  useEffect(() => {
    if (user?.id) {
      GetAllSlaByAccountId(user.id)
    }
  }, [user?.company?.id])


  return (
    <div className="parent-container-all-kpis">
      <div className='navigation-container'>
        <Navigation img={logo} text_1={langFile.pages.AllKpis.kpis}
          text_2={langFile.pages.AllKpis.servicesKpis}></Navigation>
        <SelectCompany></SelectCompany>
        <label className="selected-comp-label">
          {langFile.pages.NewCompany.selectedCompanyGroup}
        </label>
        <select
          name="companyGroup"
          id="companyGroup"
          onChange={(event) => {
            setSelectedCompanyGroup(
              companyGroupList.find(company => company.companyGroupId === event.target.value)
            )
          }}
          value={selectedCompanyGroup?.companyGroupId}
        >
          {companyGroupList?.map((company, index) => (
            <option key={index} value={company.companyGroupId}>
              {company.name}
            </option>
          ))}
        </select>
        <div className="filter-parent">
          <div className="filter-container">
            <div
              className={
                isActiveDropdown_2 === true
                  ? "checkbox-dropdown is-active"
                  : "checkbox-dropdown"
              }
              onClick={() => setIsActiveDropdown_2(!isActiveDropdown_2)}
            >
              {langFile.pages.AllKpis.filterKpiType}
              <ul className="checkbox-dropdown-list">
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="Critical"
                      name="kpiType"
                      onChange={handleCheckboxChange}
                      checked={filters.Critical}
                    />
                    Critical
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="Major"
                      name="kpiType"
                      onChange={handleCheckboxChange}
                      checked={filters.Major}
                    />
                    Major
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="Minor"
                      name="kpiType"
                      onChange={handleCheckboxChange}
                      checked={filters.Minor}
                    />
                    Minor
                  </label>
                </li>
              </ul>
            </div>
            <div
              className={
                isActiveDropdown_3 === true
                  ? "checkbox-dropdown is-active"
                  : "checkbox-dropdown"
              }
              onClick={() => setIsActiveDropdown_3(!isActiveDropdown_3)}
            >
              {langFile.pages.AllKpis.filterTargetType}
              <ul className="checkbox-dropdown-list">
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="Numeric"
                      name="targetType"
                      onChange={handleCheckboxChange}
                      checked={filters.Numeric}
                    />
                    Numeric
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      value="Percentage"
                      name="targetType"
                      onChange={handleCheckboxChange}
                      checked={filters.Percentage}
                    />
                    Percentage
                  </label>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <table
        className={
          iskpiDetail === true
            ? "container-all-kpis container-all-kpis-side"
            : "container-all-kpis"
        }
      >
        <thead>
          <tr>
            <th>
              {langFile.pages.AllKpis.kpiId}
            </th>
            <th>
              {langFile.pages.AllKpis.kpiSetName}
            </th>
            <th>
              {langFile.pages.AllKpis.kpiType}
            </th>
            <th>
              {langFile.pages.AllKpis.kpiName}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {kpis.map((kpi, index) => {
            if (
              (filters.Critical && kpi.priority.name === "Critical") ||
              (filters.Major && kpi.priority.name === "Major") ||
              (filters.Minor && kpi.priority.name === "Minor")
            ) {
              return (
                <React.Fragment key={index}>
                  <tr
                    style={{
                      borderRadius: sideAddKpi && selectedKpi === kpi ? "5px 5px 0px 0px" : "",
                      backgroundColor: selectedKpi === kpi ? "var(--tableRowHover)" : "",
                    }}>
                    <td
                      onClick={() => {
                        if (roles.some(role => role === "KpiRead")) {
                          setSelectedKpi(kpis[index]);
                          setIskpiDetail(!iskpiDetail);
                        }
                      }}
                    >
                      <label>
                        {kpi.guid}
                      </label>
                      <div className="tooltip-container">
                        <img
                          onClick={() => {
                            navigator.clipboard.writeText(kpi.guid);
                            AddAlert("success", "Copied")
                          }}
                          alt="copy"
                          className="copy-button tooltip-trigger"
                          src={copy_ico}
                        ></img>
                        <div style={{
                          top: "0vh",
                          right: "2vw",
                          width: "4vw"
                        }} className="tooltip-content">{langFile.pages.AllKpis.copyId}</div>
                      </div>
                    </td>
                    <td
                      onClick={() => {
                        if (roles.some(role => role === "KpiRead")) {
                          setSelectedKpi(kpis[index]);
                          setIskpiDetail(true);
                        }
                      }}
                    >
                      {kpi.kpiCategory.name}
                    </td>
                    <td
                      onClick={() => {
                        if (roles.some(role => role === "KpiRead")) {
                          setSelectedKpi(kpis[index]);
                          setIskpiDetail(true);
                        }
                      }}
                    >
                      {kpi.priority.name}
                    </td>
                    <td
                      onClick={() => {
                        if (roles.some(role => role === "KpiRead")) {
                          setSelectedKpi(kpis[index]);
                          setIskpiDetail(true);
                        }
                      }}
                    >
                      {kpi.name}
                    </td>

                    <td>
                      {roles.some(role => role === "KpiCreate") &&
                        <div className="tooltip-container">
                          <img
                            className="add-button tooltip-trigger"
                            alt="addIco"
                            src={copy_ico_2}
                            onClick={() => {
                              setSideAddKpi(!sideAddKpi);
                              setSelectedKpi(kpi);
                            }}
                            style={{
                              filter: "invert(var(--filterRatio))",
                              WebkitFilter: "invert(var(--filterRatio))",
                            }}
                          ></img>
                          <div style={{
                            top: "0vh",
                            right: "2vw",
                            width: "5vw"
                          }} className="tooltip-content">{langFile.pages.AllKpis.copyKpi}</div>
                        </div>
                      }
                    </td>
                  </tr>

                  <tr
                    style={{
                      height: sideAddKpi && selectedKpi === kpi ? "7vh" : "0vh",
                    }}
                    className="add-kpi-parent"
                  ><td>
                      <label className="companyNameTitle-italic">Copy</label>
                      <label className="companyNameTitle-bold">{(selectedKpi ? selectedKpi?.name : null)}</label>
                      <label className="companyNameTitle-italic">to</label>
                      <div className="add-kpi-parent-content">
                        {slas.map((sla, index) => (
                          <React.Fragment key={index}>
                            <input
                              style={{ width: ".8vw" }}
                              id={`sla-${index}`}
                              type="checkbox"
                              checked={sla?.check || false}
                              onChange={() => handleCheckboxChangeSla(index)}
                            />
                            <label style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis", color: "var(--textColor)", fontSize: ".7vw"
                            }}
                            >{sla.name}</label>
                          </React.Fragment>
                        ))}
                      </div>

                      <button className="copy-kpi-button"
                        onClick={() => handleCopyKpiToSlas(kpi.guid)}
                      >
                        <img
                          alt="copy"
                          className="copy-kpi"
                          src={copy_ico_2}
                        ></img>
                        {langFile.pages.AllKpis.copyKpi}
                      </button>

                      <button onClick={() => {
                        setSideAddKpi(!sideAddKpi)
                      }} className="up-arrow-button">
                        <img
                          alt="up-arrow"
                          className="up-arrow"
                          src={up_arrow_ico}
                        ></img>
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              )
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
      <div
        className={
          iskpiDetail === true
            ? "all-kpi-detail-parent"
            : "all-kpi-detail-parent all-kpi-detail-parent-side"
        }
      >
        {selectedKpi && selectedKpi.targets.length !== 0 ? (
          <div className="container-all-kpis-detail">
            {/* <AllKpisSLAs></AllKpisSLAs> */}
            <AllKpisDetail ></AllKpisDetail>
          </div>
        ) : null}
      </div>
    </div>
  );
}