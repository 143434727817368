import React, { useState } from "react";

export const KpisContext = React.createContext();

const KpisContextProvider = (props) => {
    const [kpiEditSide, setKpiEditSide] = useState(false);
    const [iskpiDetail, setIskpiDetail] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [addKpiBox, setAddKpiBox] = useState(false);
    const [kpis, setKpis] = useState([]);
    const [selectedKpi, setSelectedKpi] = useState(null);
    const [selectedKpiIndex, setSelectedKpiIndex] = useState(null);
    const [sortTrigger, setSortTrigger] = useState(false);
    const [kpiIdToAdd, setKpiIdToAdd] = useState("")
    const [kpiInfoToAdd, setKpiInfoToAdd] = useState("")

    function ResetContext(){
        setKpiEditSide(false)
        setIskpiDetail(false)
        setAddKpiBox(false)
        setKpis([])
        setSelectedKpi(null)
        setSelectedKpiIndex(null)
        setKpiIdToAdd("")
        setKpiInfoToAdd("")
    }

    return (
        <KpisContext.Provider value={{ 
            ResetContext,
            kpiEditSide,
            setKpiEditSide,
            iskpiDetail,
            setIskpiDetail,
            updateTrigger,
            setUpdateTrigger,
            addKpiBox,
            setAddKpiBox,
            kpis,
            setKpis,
            selectedKpi,
            setSelectedKpi,
            selectedKpiIndex,
            setSelectedKpiIndex,
            sortTrigger,
            setSortTrigger,
            kpiIdToAdd,
            setKpiIdToAdd,
            kpiInfoToAdd,
            setKpiInfoToAdd
         }}>
            { props.children }
        </KpisContext.Provider>
    );
}

export default KpisContextProvider;