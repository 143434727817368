import React, { useContext, useEffect, useRef, useState } from "react";
import "../styles/Basket.css";
import rightArrow from "../assets/right-arrow.png";
import alert from "../assets/alert.png";
import { MainContext } from "../contexts/MainContext";
import { NewAgreementContext } from "../contexts/NewAgreementContext";
import { NewSlaMetaDataContext } from "../contexts/NewSlaMetaDataContext";
import { LoginContext } from "../contexts/LoginContext";
import PopUpAlert from "./PopUpAlert/PopUpAlert";
import { ChatbotContext } from "../contexts/ChatbotContext";
import { BasketContext } from "../contexts/BasketContext";
import EmailSenderMain from "./EmailSender/EmailSenderMain";

export default function Basket() {
  const { langFile } = useContext(MainContext);
  const { CreateDate,
    Delete,
    Edit,
    CreateSla,
    AddKpi,
    HandleChangeKpiName,
    GetDays,
    VerifiedSla } = useContext(BasketContext);
  const {
    allChosen,
    basketSide,
    editAlert,
    setEditAlert,
    deleteAlert,
    setDeleteAlert,
    agreementTypes,
    setMetadataDone,
    metadataDone,
    emailComponents,
    setEmailComponents
  } = useContext(NewAgreementContext);
  const { token, user } = useContext(LoginContext)

  const {
    metaDataType,
    formik,
    contractType,
    oldSlaFormik,
    withoutSlaFormik
  } = useContext(NewSlaMetaDataContext)

  const bottomRef = useRef(null);
  const [indexToBeEdited, setIndexToBeEdited] = useState();

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allChosen]);

  function RefreshCreateOperation() {
    setMetadataDone(false)
  }

  async function CreateKpiWithouSla() {
    const promisesToAwait = [];
    for (let i = 0; i < allChosen.length; i++) {
      promisesToAwait.push(AddKpi(allChosen[i], false));
    }
    promisesToAwait.push(RefreshCreateOperation())
    const responses = await Promise.all(promisesToAwait);
  }

  async function CreateKpiToSla() {
    const promisesToAwait = [];
    for (let i = 0; i < allChosen.length; i++) {
      promisesToAwait.push(AddKpi(allChosen[i], oldSlaFormik.values.selectedSla));
    }
    promisesToAwait.push(RefreshCreateOperation())
    const responses = await Promise.all(promisesToAwait);
  }


  async function CreateSlaAndCreateKpi() {
    const slaTypeId = agreementTypes.find(item =>
      item.type === contractType
    )?.id

    let startDate = ""
    let endDate = ""
    if (metaDataType === "old") {
      startDate = oldSlaFormik.values.startDate
      endDate = oldSlaFormik.values.endDate
    }
    else if (metaDataType === "new") {
      startDate = formik.values.startDate
      endDate = formik.values.endDate
    }
    else {//if without sla
      startDate = withoutSlaFormik.values.startDate
      endDate = withoutSlaFormik.values.endDate
    }

    const endDateDayNumber = GetDays(endDate)

    let bodyOfCreateSla = contractType === "OKR" ?
      {
        name: formik.values.slaName !== "" ? formik.values.slaName : "sla-" + CreateDate(),
        accountId: user.id,
        companyGroupId: formik.values.selectedCompanyGroup,
        companyId: formik.values.selectedCompany,
        ownerAccountId: formik.values.selectedUserOkrTarget === "" ? null : formik.values.selectedUserOkrTarget,
        ownerOrganizationId: formik.values.selectedOrganizationOkrTarget === "" ? null : formik.values.selectedOrganizationOkrTarget,
        slaTypeId: slaTypeId,
        startDate: startDate + "-01T00:00:00.000Z",
        endDate: endDate + "-" + endDateDayNumber + "T00:00:00.000Z"
      }
      :
      {
        name: formik.values.slaName !== "" ? formik.values.slaName : "sla-" + CreateDate(),
        accountId: user.id,
        companyGroupId: formik.values.selectedCompanyGroup,
        companyId: formik.values.selectedCompany,
        slaTypeId: slaTypeId,
        startDate: startDate + "-01T00:00:00.000Z",
        endDate: endDate + "-" + endDateDayNumber + "T00:00:00.000Z"
      }

    const slaId = await CreateSla(bodyOfCreateSla);
    if(formik.values.selectedAgreementOwner?.id){
      await VerifiedSla({
        slaId: slaId,
        accountId: formik.values.selectedAgreementOwner.id,
        createdBy: user.id,
        isOwner: true
      })
    }
    const promisesToAwait = [];
    for (let i = 0; i < allChosen.length; i++) {
      promisesToAwait.push(AddKpi(allChosen[i], slaId));
    }
    promisesToAwait.push(RefreshCreateOperation())
    const responses = await Promise.all(promisesToAwait);
  }

  return (
    <div
      className={
        basketSide === true ? "parent-basket" : "parent-basket parent-basket-side"
      }
      style={{
        pointerEvents: metadataDone ? "auto" : "none",
        opacity: metadataDone ? "1" : "0.5",
      }}
    >
      {/* {emailComponents && <EmailSenderMain setState={setEmailComponents}></EmailSenderMain>} */}
      
      <div className="basket-box-parent">
        <label className="label-basket-1">
          {langFile.pages.Basket.yourChoices}
        </label>
        <div
          className="basket-box"
        >
          {allChosen.map((kpi, kpiIndex) => (
            <div key={kpiIndex}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "5vh"
                  }}
                >
                  <input
                    type="text"
                    placeholder={"KPI Name"}
                    id="kpiName"
                    value={kpi.kpiName}
                    onChange={(event) =>
                      HandleChangeKpiName(kpiIndex, event.target.value)
                    }
                    autoComplete="on"
                  />
                </div>

                {kpi.flow.map((selection, selectionIndex) => (
                  <div
                    key={selectionIndex}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="basket-box-box">{selection.answer.value}</div>
                    <div className="basket-box-box-right-arrow">
                      <img
                        className="right-arrow-basket-icon"
                        alt="right-arrow"
                        src={rightArrow}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {editAlert && <PopUpAlert text={langFile.pages.Basket.edit_alert} func={Edit} setIsPopUp={setEditAlert} body={indexToBeEdited} ></PopUpAlert>}
              {deleteAlert && <PopUpAlert text={langFile.pages.Basket.delete_alert} func={Delete} setIsPopUp={setDeleteAlert} body={indexToBeEdited} ></PopUpAlert>}
              <div style={{ display: "flex", flexDirection: "row", gap: "1vw" }}>
                <button
                  className="button-edit"
                  onClick={() => {
                    setIndexToBeEdited(kpiIndex);
                    setEditAlert(true);
                  }}
                >
                  {langFile.pages.Basket.editBtn}
                </button>

                <button
                  className="button-delete"
                  onClick={() => {
                    setIndexToBeEdited(kpiIndex);
                    setDeleteAlert(true);
                  }}
                >
                  {langFile.pages.Basket.deleteBtn}
                </button>
              </div>
              <hr></hr>
            </div>
          ))}
          <div ref={bottomRef} />
        </div>
        <button
          className="button-confirm-basket"
          // style={{ display: allChosen.length === 0 ? "none" : "flex" }}
          style={{ display: allChosen.length === 0 ? "flex" : "flex" }}
          onClick={() =>
            metaDataType === "new"
              ? CreateSlaAndCreateKpi()
              : metaDataType === "old"
                ? CreateKpiToSla()
                : CreateKpiWithouSla()
          }
        >
          {langFile.pages.Basket.confirmBtn}
        </button>
      </div>

    </div>
  );
}
