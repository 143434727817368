import React, { useState } from "react";
import { useEffect } from "react";

export const CompanyGroupContext = React.createContext();

const CompanyGroupContextProvider = (props) => {

    const [companyGroups, setCompanyGroups] = useState();
    const [isDeletePopUp, setIsDeletePopUp] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [isCompanyDetail, setIsCompanyDetail] = useState(false);

    return (
        <CompanyGroupContext.Provider value={{
            selectedCompany,
            setSelectedCompany,
            companyGroups,
            setCompanyGroups,
            isDeletePopUp,
            setIsDeletePopUp,
            isCompanyDetail,
            setIsCompanyDetail
        }}>
            {props.children}
        </CompanyGroupContext.Provider>
    );
}

export default CompanyGroupContextProvider;