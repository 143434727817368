import React, { useContext, useEffect, useState } from "react";
import { NewAgreementContext } from "./NewAgreementContext";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";

export const NewSlaMetaDataContext = React.createContext();

const NewSlaMetaDataContextProvider = (props) => {
  const [organizations, setOrganizations] = useState([])
  const [slas, setSlas] = useState([])
  const [contractType, setContractType] = useState("SLA");
  const [selectedSla, setSelectedSla] = useState("");
  const [metaDataType, setMetaDataType] = useState();
  const [companyGroupList, setCompanyGroupList] = useState([])
  const [users, setUsers] = useState([])
  const [usersOfCompany, setUsersOfCompany] = useState([])
  const [okrTo, setOkrTo] = useState("organization")
  const { token, user, roles } = useContext(LoginContext)
  const { setAlertList, AddAlert } = useContext(MainContext);

  const currentTime = new Date();
  const year = currentTime.getFullYear();
  const month = currentTime.getMonth() + 1;
  const formattedMonth = month < 10 ? "0" + month : month;
  const formattedCurrentDate = `${year}-${formattedMonth}`;

  useEffect(()=>{
    roles?.some(role => role === "SlaCreate") ? setMetaDataType("new") : roles?.some(role => role === "KpiCreate") ? setMetaDataType("old") : setMetaDataType()
  },[])

  const navigate = useNavigate()

  const newSlaValidationSchema = yup.object().shape({
    slaName: yup.string().required("'SLA Name' must not be empty."),
    selectedCompanyGroup: yup.string().when({
      is: () => contractType !== "OKR",
      then: () => yup.string().required("'Company Group ' must not be empty."),
      otherwise: () => yup.string().notRequired(),
    }),
    selectedCompany: yup.string().when({
      is: () => contractType !== "OKR",
      then: () => yup.string().required("'Company' must not be empty."),
      otherwise: () => yup.string().notRequired(),
    }),
    slaStatus: yup.string().required("'SLA Status' must not be empty."),
    startDate: yup.string().required("'Start Date' must not be empty."),
    endDate: yup.string().required("'End Date' must not be empty."),
    selectedUserOkrTarget: yup.string(),
    selectedOrganizationGroup: yup.string(),
    selectedOrganizationOkrTarget: yup.string().when('selectedUserOkrTarget', {
      is: (value) => (value === "" || !value) && contractType === "OKR",
      then: () => yup.string().required("'OKR Target' must not be empty."),
      otherwise: () => yup.string().notRequired(),
    }),
    selectedAgreementOwner: yup.object()
  });
  const oldSlaValidationSchema = yup.object().shape({
    selectedSla: yup.string().required("'SLA' must not be empty."),
    startDate: yup.string().required("'Start Date' must not be empty."),
    endDate: yup.string().required("'End Date' must not be empty."),
    companyGroupOfSelectedSla: yup.string().required("'Company Group' must not be empty."),
  });
  const withoutSlaValidationSchema = yup.object().shape({
    selectedCompanyGroup: yup.string().required("'Company Group ' must not be empty."),
    startDate: yup.string().required("'Start Date' must not be empty."),
    endDate: yup.string().required("'End Date' must not be empty."),
  });

  const withoutSlaFormik = useFormik({
    initialValues: {
      selectedCompanyGroup: "",
      startDate: formattedCurrentDate,
      endDate: formattedCurrentDate,
    },
    validationSchema: withoutSlaValidationSchema,
    onSubmit: (values, { resetForm }) => {
      DoneButtonHandle()
      // resetForm();
    },
  });

  const oldSlaFormik = useFormik({
    initialValues: {
      selectedSla: "",
      startDate: "",
      endDate: "",
      companyGroupOfSelectedSla: ""
    },
    validationSchema: oldSlaValidationSchema,
    onSubmit: (values, { resetForm }) => {
      DoneButtonHandle()
      // resetForm();
    },
  });

  const formik = useFormik({
    initialValues: {
      slaName: '',
      selectedCompanyGroup: '',
      selectedCompany: '',
      slaStatus: 'active',
      startDate: formattedCurrentDate,
      endDate: formattedCurrentDate,
      selectedSla: "",
      selectedUserOkrTarget: "",
      selectedOrganizationGroup: "",
      selectedOrganizationOkrTarget: "",
      selectedAgreementOwner: ""
    },
    validationSchema: newSlaValidationSchema,
    onSubmit: (values, { resetForm }) => {
      DoneButtonHandle()
      // resetForm();
    },
  });

  const {
    metadataSide,
    metadataDone,
    setMetadataDone,
  } = useContext(NewAgreementContext);



  function GetAllUser(body, setState) {
    if (roles.some(role => role === "UserRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_ACCOUNTS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(body)
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          // setUsers(data.accounts);
          setState(data.accounts)
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetAllOrganization(body) {
    if (roles.some(role => role === "OrganizationRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_GROUP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(body)
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          setOrganizations(data.organizationGroups);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetAllCompanyGroup(ownerCompanyId) {
    if (roles.some(role => role === "CompanyGroupRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY_GROUP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(
          {
            ownerCompanyId: ownerCompanyId,
            pageNumber: 1,
            pageSize: 9999,
            includeCompanies: true
          }
        ),
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          if (data?.companyGroups.length > 0) {
            setCompanyGroupList(data?.companyGroups);
          }
          else {
            setCompanyGroupList([])
          }
        })
        .catch((error) => {
          setCompanyGroupList([])
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function DoneButtonHandle() {
    setMetadataDone(true)
  }
  function GetDate(startDate, mounthNumber) {
    const start = startDate.split("-");
    let yearCounter = parseInt(
      (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) / 12
    );
    let mounthCounter = parseInt(
      (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) % 12
    );
    start[1] = mounthCounter;
    start[0] = yearCounter;
    if (mounthCounter === 0) {
      start[1] += 12;
      start[0] -= 1;
    }
    if (start[1] < 10) {
      return String(start[0]) + "-0" + String(start[1]);
    } else {
      return String(start[0]) + "-" + String(start[1]);
    }
  }
  function Sort(kpis) {
    let arr = [...kpis];
    arr.sort(
      (date1, date2) =>
        parseInt(date1.referenceDate.substring(5, 7)) +
        parseInt(date1.referenceDate.substring(0, 4)) * 12 -
        (parseInt(date2.referenceDate.substring(5, 7)) +
          parseInt(date2.referenceDate.substring(0, 4)) * 12)
    );
    return (arr);
  }

  return (
    <NewSlaMetaDataContext.Provider value={{
      DoneButtonHandle,
      GetDate,
      Sort,
      slas,
      setSlas,
      selectedSla,
      setSelectedSla,
      metaDataType,
      setMetaDataType,
      contractType,
      setContractType,
      companyGroupList,
      setCompanyGroupList,
      GetAllCompanyGroup,
      formik,
      oldSlaFormik,
      withoutSlaFormik,
      okrTo,
      setOkrTo,
      organizations,
      setOrganizations,
      GetAllOrganization,
      users,
      setUsers,
      GetAllUser,
      setUsersOfCompany,
      usersOfCompany
    }}>
      {props.children}
    </NewSlaMetaDataContext.Provider>
  )
}
export default NewSlaMetaDataContextProvider;