import React, { useContext, useEffect, useState } from "react";
import "../styles/ChartSelect.css";
import defacto_logo from "../assets/defacto-logo.svg"
import { LoginContext } from "../contexts/LoginContext";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import { DashboardContext } from "../contexts/DashboardContext";

export default function ChartSelect() {
  const { roles, token } = useContext(LoginContext)
  const { setAlertList } = useContext(MainContext);
  const [companyGroupList, setCompanyGroupList] = useState([])
  const {
    setSelectedCompany,
    setStatistics,
    setServiceProviderList,
    setDataForHorizontalChart,
    setDataForVerticalChart,
    setFilteredDataVerticalChart,
    setTargetStatistics,
    selectedCompanyGroupId,
    setSelectedCompanyGroupId } = useContext(DashboardContext)
  const navigate = useNavigate();

  const [menuItems, setMenuItems] = useState([
    {
      name: "Defacto",
    },
    {
      name: "Testinium",
    },
  ])
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMenuItemMouseLeave = () => {
    setDropdownOpen(false);
  };




  function HandleCompanyGroupChange(companyGroupGuid) {
    setSelectedCompany("All")
    setStatistics([])
    setServiceProviderList([])
    setDataForHorizontalChart([]);
    setDataForVerticalChart([]);
    setFilteredDataVerticalChart([])
    setTargetStatistics([])
    setSelectedCompanyGroupId(companyGroupGuid)
  }

  return (
    <div className="sliding-and-userdropdown-container">
     
      <div
        className="dropdown-user-selection-container"
        onMouseLeave={handleMenuItemMouseLeave}
      >
         {/* <div className="dashboard-customer-name">
        <img src={defacto_logo}>
        </img>
      </div> */}

          <button
            className="dropdown__button"
            onMouseEnter={() => setDropdownOpen(true)}
          >
            {companyGroupList.find(item => item.companyGroupGuid === selectedCompanyGroupId)?.name}
            <span className="dropdown__icon"></span>
          </button>
        

        <div className={`dropdown__menu ${dropdownOpen ? "show" : ""}`}>
          {menuItems.map((menuItem) => (
            <div
              key={menuItem.name}
              className={`dropdown__menu-item ${selectedCompanyGroupId === menuItem.companyGroupGuid ? "active" : ""
                }`}
              onClick={() => HandleCompanyGroupChange(menuItem.companyGroupGuid)}
            >
              {menuItem.name}
            </div>
          ))}
        </div>
      </div>


    </div>
  );
}
