import React, { useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

export const ForgotPasswordContext = React.createContext();

const ForgotPasswordContextProvider = (props) => {
    const navigate = useNavigate()
    const [codeRemainingTime, setCodeRemainingTime] = useState(0)
    const [account, setAccount] = useState()
    const [forgetPasswordCompleted, setForgetPasswordCompleted ] = useState(false)
    const [disableEmailInput, setDisableEmailInput] = useState(false)
    
    function ResetContext(){
        resetFormik.resetForm()
        mailFormik.resetForm()
        verifyCodeFormik.resetForm()
        setForgetPasswordCompleted(false)
        setCodeRemainingTime(0)
        setAccount()
        setDisableEmailInput(false)
      }

    const forgotPasswordValidationSchema = yup.object().shape({
        email: yup.string().email("Provide a valid email address").required("'Email' must not be empty.")
      });

      const verifyCodeValidationSchema = yup.object().shape({
        code: yup.string().required("'Code' must not be empty.").min(6, "'Code' must be at 6 characters long."),

      });
      
      const resetPasswordValidationSchema = yup.object().shape({
        password: yup.string().min(8, 'Şifre en az 8 karakterden oluşmalıdır.').required('Şifre alanı boş bırakılamaz.'),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Şifreler eşleşmiyor.').required('Şifre tekrarı alanı boş bırakılamaz.'),
      });

      const [showPassword, setShowPassword] = useState(false)

      const verifyCodeFormik = useFormik({
        initialValues: {
          code: '',
        },
        validationSchema: verifyCodeValidationSchema,
        onSubmit: (values, { resetForm }) => {
            VerifyCode(values)
        },
      });

      const resetFormik = useFormik({
        initialValues: {
          password: '',
          confirmPassword: '',
        },
        validationSchema: resetPasswordValidationSchema,
        onSubmit: (values, { resetForm }) => {
            UpdateAccount(values)
        },
      });
    
      const mailFormik = useFormik({
        initialValues: {
          email: '',
        },
        validationSchema: forgotPasswordValidationSchema,
        onSubmit: (values, { resetForm }) => {
            resetPassword(values)
        },
      });

    

      function resetPassword(values) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_AUTH_RESET_PASSWORD, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "TenantId": "slm",
          },
          body: JSON.stringify({
            emailOrUsername: values.email,
          }),
        })
          .then((res) => {
            if (res.ok && res.status === 200) {
              return res.json();
            }
            else if (res.status === 401) {
              localStorage.clear();
            }
            throw res;
          })
          .then((data) => {
            if (data.result.statusCode === 200) {
                setDisableEmailInput(true)
                setCodeRemainingTime(300)
                return data;
            }
            else if (data.result.statusCode === 400) {
                mailFormik.setFieldError("email", "Invalid Email " )
                setCodeRemainingTime(0)
              return data;
            }
          })
          .catch((error) => {
            console.log(error.json())
            setCodeRemainingTime(0)
            mailFormik.setFieldError("email", "Invalid Email " )

          });
      }
    
      function VerifyCode(values){
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_AUTH_VERIFY_CODE, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "TenantId": "slm",
            },
            body: JSON.stringify({
              emailOrUsername: mailFormik.values.email,
              code: values.code
            }),
          })
            .then((res) => {
              if (res.ok && res.status === 200) {
                return res.json();
              }
              else if (res.status === 401) {
                localStorage.clear();
              }
              throw res;
            })
            .then(async (data) => {
              if (data.result.statusCode === 200) {
                const result = await GetAccount({
                    email: mailFormik.values.email
                  })
                  if(result){
                      navigate(`/forgotpassword/${mailFormik.values.email}`)
                  }
                  else{
                    verifyCodeFormik.setFieldError("code", "Account Not Found" )
                  }
                  return data;
              }
              else if (data.result.statusCode === 400) {
                verifyCodeFormik.setFieldError("code", "Try Again" )
                return data;
              }
            })
            .catch((error) => {
              console.log(error.json())
              verifyCodeFormik.setFieldError("code", "Try Again" )

            });
      }

      function GetAccount(body) {
        return new Promise((resolve) => {
            fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_ACCOUNTS, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "TenantId": "slm",
            },
            body: JSON.stringify(
                body
            ),
            })
            .then((res) => {
                if (res.ok && res.status === 200) {
                return res.json();
                }
                throw res;
            })
            .then((data) => {
                setAccount(data.accounts)
                resolve(true);
                return data;
            })
            .catch((error) => {
                resolve(false);
                setAccount();
                console.log(error);
            });
        })

      }
      function UpdateAccount(values) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_UPDATE_ACCOUNT, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "TenantId": "slm"
          },
          body: JSON.stringify({
            username: mailFormik.values.email,
            password: values.password,
            confirmPassword: values.password,
          }),
        })
          .then((res) => {
            if (res.ok && res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            if (data.statusCode === 200) {
                setForgetPasswordCompleted(true)
            }
            else {

            }
            return data;
          })
          .catch((error) => {
            error.json().then((err) => {
              const errorObject = err.errors
              Object.keys(errorObject).forEach(key => {
                  const errors = errorObject[key];
                  let errorText = ""
                  errors.forEach(error => {
                      errorText += (error + ". ")
                  });
                  resetFormik.setFieldError(key, errorText)
              });
          })
            console.log(error.json());
          });
      }
 
    return (
        <ForgotPasswordContext.Provider value={{ 
            showPassword,
            setShowPassword,
            resetFormik,
            mailFormik,
            resetPassword,
            codeRemainingTime,
            setCodeRemainingTime,
            verifyCodeFormik,
            account,
            setAccount,
            forgetPasswordCompleted,
            setForgetPasswordCompleted,
            ResetContext,
            disableEmailInput,
            setDisableEmailInput

         }}>
            { props.children }
        </ForgotPasswordContext.Provider>
    );
}

export default ForgotPasswordContextProvider;