import React, { useContext, useEffect, useState } from "react";
import "../../styles/NewSlaMetaData.css";
import { MainContext } from "../../contexts/MainContext";
import { NewSlaMetaDataContext } from "../../contexts/NewSlaMetaDataContext";
import { NewAgreementContext } from "../../contexts/NewAgreementContext";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../contexts/LoginContext";
import NewSla from "./NewSla";
import OldSla from "./OldSla";
import AgreementTypeEntry from "./AgreementTypeEntry";
import WithoutSla from "./WithoutSla";
import edit_ico from "../../assets/pencil.png"

export default function NewSlaMetaData() {
  const navigate = useNavigate();
  const { setAlertList } = useContext(MainContext);
  const { token, user, roles } = useContext(LoginContext)

  const { metaDataType,} = useContext(NewSlaMetaDataContext)

  const {
    metadataSide,
    metadataDone,
    setMetadataDone,
  } = useContext(NewAgreementContext);

  return (
    <React.Fragment>
    <div
      className={
        metadataSide === true
          ? "new-sla-meta-data-parent"
          : "new-sla-meta-data-parent new-sla-meta-data-parent-side"
      } 
    >
      {metadataDone &&
      <div className="edit-button-container"
      onClick={()=>setMetadataDone(false)}>
        <img alt="edit" src={edit_ico}>
        </img>
      </div>
      } 
      
      <AgreementTypeEntry></AgreementTypeEntry>
      {metaDataType === "new"   ? (
        <NewSla></NewSla>
      ) :
        metaDataType === "old" ? (
          <OldSla></OldSla>
        ) :
        metaDataType === "no" ?
          <WithoutSla></WithoutSla>:
          <></>
      }
    </div>
    </React.Fragment>
  );
}
