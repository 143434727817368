import React, { useContext, useRef, useState } from "react";
import { LoginContext } from "./LoginContext";
import { MainContext } from "./MainContext";

export const ChatbotContext = React.createContext();

const ChatbotContextProvider = (props) => {
  const { token, roles } = useContext(LoginContext)
  const { AddAlert } = useContext(MainContext)

  const [chatbotData, setChatbotData] = useState([]);
  const [nextQuestion, setNextQuestion] = useState(0);
  const [loopCounter, setLoopCounter] = useState(0); //chatbotun kaçıncı turda olduğunu tutar
  const [parentBotAnswerId, setParentBotAnswerId] = useState()
  const [lastGetBotAnswerBody, setLastGetBotAnswerBody] = useState([])
  const bottomRef = useRef(null);

  function RefreshChatbot() {
    setChatbotData([]);
    setLoopCounter(loopCounter + 1);
    setNextQuestion(0)
  }
  function AskWhetherToSave() {//add "Seçimleriniz kayıt edilsin mi?" question
    let temp = [...chatbotData]
    temp.push(
      {
        "botQuestionOptions": {
          "id": null,
          "description": {
            "type": null,
            "query": null,
            "queryResult": {
              "id": null,
              "result": null
            },
            "values": [
              {
                "id": null,
                "value": "Evet"
              },
              {
                "id": null,
                "value": "Hayır"
              }
            ]
          },
          "parentBotAnswer": {
            "id": null,
            "description": {
              "type": null,
              "query": null,
              "queryResult": {
                "id": null,
                "result": null
              },
              "values": []
            },
            "parentBotAnswer": null,
            "botQuestion": null,
            "order": 3,
            "isRecursive": false
          },
          "botQuestion": {
            "id": null,
            "question": "Seçimleriniz kayıt edilsin mi?"
          },
          "order": -1,// if negative added this question and answer on frontend side
          "isRecursive": false
        },
        "answer": undefined
      }
    )
    setChatbotData(temp)
  }

  function AddNewCategory(body, refetchBodyIndex) {
    if (roles.some(role => role === "KpiCreate")) {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_CREATE_KPI_CATEGORY,
        {
          method: "POST",
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
          body: JSON.stringify(body)
        })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }
        })
        .then(() => {
          GetBotAnswer(lastGetBotAnswerBody[refetchBodyIndex], true)//after added answer to update chatbot
        })
        .catch((err) => console.log(err));
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }
  function GetBotAnswer(body, updateFlag) {//update flag for when add new answer as a category
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_BOT_ANSWER,
      {
        method: "POST",
        headers: {
          "accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(body)
      })
      .then((res) => {
        if (res.ok && res.status === 200) {
          let temp = lastGetBotAnswerBody;
          temp.push(body)
          setLastGetBotAnswerBody([...temp])
          return res.json();
        }
      })
      .then((data) => {
        if (data.botAnswers === null) {
          AskWhetherToSave()
        }
        else if (data.botAnswers[0]?.description?.values.length === 0) {//if values empty
          setParentBotAnswerId(data.botAnswers[0].id)
          setNextQuestion(nextQuestion + 1)
        }
        else {
          if (updateFlag) {
            let temp = [...chatbotData]
            temp[temp.length - 1] =
              ({
                botQuestionOptions: data.botAnswers[0],
                answer: undefined,
              })
            setChatbotData(temp)
          }
          else {
            let temp = [...chatbotData]
            temp.push({
              botQuestionOptions: data.botAnswers[0],
              answer: undefined,
            })
            setChatbotData(temp)
          }
          if (!data.botAnswers[0].isRecursive) {
            setParentBotAnswerId(data.botAnswers[0].id)
          }
          else {
            setParentBotAnswerId(data.botAnswers[0].parentBotAnswer.id)
          }
        }
      })
      .catch((err) => console.log(err));
  }
  return (
    <ChatbotContext.Provider value={{
      chatbotData,
      setChatbotData,
      nextQuestion,
      setNextQuestion,
      loopCounter,
      setLoopCounter,
      parentBotAnswerId,
      setParentBotAnswerId,
      RefreshChatbot,
      bottomRef,
      GetBotAnswer,
      AddNewCategory,
      lastGetBotAnswerBody,
      setLastGetBotAnswerBody
    }}>
      {props.children}
    </ChatbotContext.Provider>
  );
}

export default ChatbotContextProvider;