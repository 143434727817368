import React, { useContext, useEffect, useState } from 'react'
import "./EditTarget.css"
import upArrow from "../../assets/up-arrow.png";
import downArrow from "../../assets/down-arrow.png";
import { KpiEditContext } from '../../contexts/KpiEditContext';
import { MainContext } from '../../contexts/MainContext';
import { KpisContext } from '../../contexts/KpisContext';
import { LoginContext } from '../../contexts/LoginContext';
import { UpdateKpiDateContext } from '../../contexts/UpdateKpiDateContext';

export default function EditTarget({ data }) {
  
  const {
    inputWeight,
    setInputWeight,
    inputTargetType,
    setInputTargetType,
    inputRange,
    setInputRange,
    inputPercentageTarget,
    setInputPercentageTarget,
    inputNumberTarget,
    setInputNumberTarget,
    inputKpiType,
    selectedMonths,
    setSelectedMonths,
    kpiJson,
    selectedEngine,
    selectionTargetsForEdit,
    setSelectionTargetsForEdit,
    currentDate,
    rangeValues,
    priorityValues,
    updateConfirmActive,
    setUpdateConfirmActive,
    inputKpiName,
    setKpiJson,
    kpiStartDate,
    kpiEndDate,
    UpdateJson,
    UpdateDb,
    activeMonth
  } = useContext(KpiEditContext);
  const { UpdateKpiDate, GetDate_2 } = useContext(UpdateKpiDateContext)

  const { langFile, AddAlert } = useContext(MainContext);

  const { selectedKpi, setUpdateTrigger,
    updateTrigger } = useContext(KpisContext);

  useEffect(() => {
    let temp = [...selectedMonths];
    for (let i = 0; i < temp.length; i++) {
      temp[i] = false
    }
    let arr = [...selectedKpi?.targets];
    if (selectionTargetsForEdit === "selectAll") {
      arr.map((target, index) => {
        const startDate = new Date(target.referenceDate);
        const diffInMonths =
          (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
          (currentDate.getMonth() - startDate.getMonth());
        if (selectedEngine === "manuel") {
          temp[index] = true
        }
        else if (diffInMonths < 0) {
          temp[index] = true
        }
      });
    } else if (selectionTargetsForEdit === "nextOneYear") {
      arr.map((target, index) => {
        const startDate = new Date(target.referenceDate);
        const diffInMonths =
          (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
          (currentDate.getMonth() - startDate.getMonth());
        if ((currentDate.getFullYear() - startDate.getFullYear()) === 0 && diffInMonths < 0) {
          temp[index] = true
        }
      });
    } else if (selectionTargetsForEdit === "nextSixMonth") {
      arr.map((target, index) => {
        const startDate = new Date(target.referenceDate);
        const diffInMonths =
          (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
          (currentDate.getMonth() - startDate.getMonth());
        if (diffInMonths >= -6 && diffInMonths < 0) {
          temp[index] = true
        }
      });
    } else {
      arr.map((target, index) => {
        temp[index] = false
      });
    }
    setSelectedMonths(temp);
  }, [selectionTargetsForEdit]);


  useEffect(() => {
    async function updateData() {
      if (updateConfirmActive) {
        await setUpdateConfirmActive(false);
        await UpdateJson(data);
        await UpdateDb();
        await UpdateKpiDate( kpiStartDate, kpiEndDate, selectedKpi)
        await setUpdateTrigger(!updateTrigger);
      }
    }
    updateData();
  }, [updateConfirmActive]);
  
  return (
    <div className='edit-target-container'>
      <div className='auto-selection-tab'>
        <input
          type="radio"
          name="targetFilter"
          id="selectAll"
          value={"selectAll"}
          checked={selectionTargetsForEdit === "selectAll"}
          onChange={(e) => setSelectionTargetsForEdit(e.target.value)}
        />
        <label className={selectionTargetsForEdit === "selectAll" ? "selected" : ""} htmlFor="selectAll">Select All</label>
        <input
          type="radio"
          name="targetFilter"
          id="nextOneYear"
          value={"nextOneYear"}
          checked={selectionTargetsForEdit === "nextOneYear"}
          onChange={(e) => setSelectionTargetsForEdit(e.target.value)}
        />
        <label className={selectionTargetsForEdit === "nextOneYear" ? "selected" : ""} htmlFor="nextOneYear">Next 1 Year</label>
        <input
          type="radio"
          name="targetFilter"
          id="nextSixMonth"
          value={"nextSixMonth"}
          checked={selectionTargetsForEdit === "nextSixMonth"}
          onChange={(e) => setSelectionTargetsForEdit(e.target.value)}
        />
        <label className={selectionTargetsForEdit === "nextSixMonth" ? "selected" : ""} htmlFor="nextSixMonth">Next 6 Month</label>
        <input
          type="radio"
          name="targetFilter"
          id="none"
          value={"none"}
          checked={selectionTargetsForEdit === "none"}
          onChange={(e) => setSelectionTargetsForEdit(e.target.value)}
        />
        <label className={selectionTargetsForEdit === "none" ? "selected" : ""} htmlFor="none">None</label>
      </div>
      <div className="select-targets-tab">
        {kpiJson[0].months.map((month, index) => (
          <div
            key={index}
            className={
              selectedMonths[index] === true
                ? "month-status month-status-selected"
                : "month-status"
            }
            onClick={() => {
              activeMonth(index);
            }}
          >
            <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div
                className={
                  month.status === -1
                    ? "triangle-down"
                    : month.status === 0
                      ? "square"
                      : "triangle-up"
                }
              ></div>
            </div>

            <div className='target-desc-container'>
              <label>
                {"Date: " + month.startDate + " / " + month.endDate}
              </label>
              <label>
                {"Target: " +
                  month.target +
                  (month.isPercentage === true ? "%" : "")}
                <img
                  className="target-ico-kpiedit"
                  alt="arrow-up"
                  src={month.range === "Minimum" ? upArrow : downArrow}
                />
              </label>
              <label>{"Weight: " + month.weight}</label>
            </div>

          </div>
        ))}
      </div>

      <div className="selectionTab">
        <div className='target-mechanism-selection'>
          <div className='target-weight'>
            <label>{langFile.pages.KpiEdit.targetWeight}</label>
            <input
              type="number"
              id="targetWeight"
              autoComplete="on"
              min="0"
              max="100"
              maxLength="3"
              value={
                inputKpiType === "Critical"
                  ? 100
                  : inputKpiType === "Major"
                    ? inputWeight
                    : inputKpiType === "Minor"
                      ? 0
                      : 0
              }
              required="required"
              onChange={(event) => setInputWeight(event.target.value)}
              style={{
                pointerEvents:
                  inputKpiType === "Critical" || inputKpiType === "Minor"
                    ? "none"
                    : "auto",
              }}
            />
          </div>
          <div className='vertical-line'></div>
          <div className='target-type'>
            <label>{langFile.pages.KpiEdit.targetType}</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <input
                type="radio"
                id="percentage"
                name="targetType"
                value="percentage"
                checked={inputTargetType === "percentage"}
                onChange={(event) => setInputTargetType(event.target.value)}
              />
              <label htmlFor="percentage">
                {langFile.pages.KpiEdit.percentage}
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <input
                type="radio"
                id="Numeric"
                name="targetType"
                value="numeric"
                checked={inputTargetType === "numeric"}
                onChange={(event) => setInputTargetType(event.target.value)}
              />
              <label htmlFor="Numeric">{langFile.pages.KpiEdit.numeric}</label>
            </div>
          </div>
          <div className='vertical-line'></div>
          <div className='target-range'>
            <label>{langFile.pages.KpiEdit.targetRange}</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <input
                type="radio"
                id="Minimum"
                name="targetRange"
                value="Minimum"
                checked={inputRange === "Minimum"}
                onChange={(event) => setInputRange(event.target.value)}
              />
              <label htmlFor="Minimum">{langFile.pages.KpiEdit.minimum}</label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <input
                type="radio"
                id="Maximum"
                name="targetRange"
                value="Maximum"
                checked={inputRange === "Maximum"}
                onChange={(event) => setInputRange(event.target.value)}
              />
              <label htmlFor="Maximum">{langFile.pages.KpiEdit.maximum}</label>
            </div>
          </div>
        </div>


        {selectedEngine === "manuel" && inputTargetType === "percentage" ? (
          <div className='target-value-input-container'>
            <div className='vertical-line'></div>
            <label>{langFile.pages.KpiEdit.targetValue}</label>
            <label>{inputPercentageTarget + "%"}</label>
            <input
              placeholder="Enter percentage"
              type="range"
              max="100"
              min="0"
              step="1"
              value={inputPercentageTarget}
              id="percentageRange"
              onChange={(event) =>
                setInputPercentageTarget(event.target.value)
              }
            />
          </div>
        ) : selectedEngine === "manuel" && (
          <div className='target-value-input-container'>
            <div className='vertical-line'></div>
            <label>{langFile.pages.KpiEdit.targetValue}</label>
            <input
              placeholder="Enter number"
              type="number"
              id="numberRange"
              value={inputNumberTarget}
              onChange={(event) => setInputNumberTarget(event.target.value)}
              autoComplete="on"
              min={"0"}
            />
          </div>
        )}

      </div>
    </div>
  )
}
