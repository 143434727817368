import React, { useContext, useEffect, useState } from 'react'
import "./ForecastConfiguration.css"
import { KpiEditContext } from '../../contexts/KpiEditContext';

export default function ForecastConfiguration({ data, setData }) {
  const [arimaAiResult, setArimaAiResult] = useState();
  const [exponentialSmoothingAiResult, setExponentialSmoothingAiResult] = useState();
  const [prophetAiResult, setprophetAiResult] = useState();


  function handleTargetSelected(index) {
    let arr = [...targetsForRecommendation];
    const startDate = new Date(arr[index].item.startDate);
    const diffInMonths =
      (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
      (currentDate.getMonth() - startDate.getMonth());
    if (diffInMonths >= 0) {
      arr[index].selected = !arr[index].selected;
      setTargetsForRecommendation(arr);
    }
  }

  const {
    targetsForRecommendation,
    setTargetsForRecommendation,
    currentDate,
    selectionTargetsForConfiguration,
    setSelectionTargetsForConfiguration,
    median,
    setMedian,
    average,
    setAverage,
    kpiJson,
    beginDateForEngine
  } = useContext(KpiEditContext);

  useEffect(() => {
    //to calculate median and average
    const selectedItems = targetsForRecommendation.filter(
      (item) => item.selected
    );
    const values = selectedItems.map((item) => item.item.value);
    const sortedValues = [...values].sort((a, b) => a - b);
    const length = sortedValues.length;
    const median =
      length > 0 ?
        length % 2 === 0
          ? (sortedValues[length / 2 - 1] + sortedValues[length / 2]) / 2
          : sortedValues[Math.floor(length / 2)] : 0;
    setMedian(median);
    let total = 0;
    values.map((item) => (total += item));
    const average = values.length > 0 ? total / values.length : 0;
    setAverage(average);
  }, [targetsForRecommendation]);

  useEffect(() => {
    let Arr = [];
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //to create historical data for chart
    kpiJson[0].months.map((item) => {
      const startDate = new Date(item.startDate);
      const diffInMonths =
        (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
        (currentDate.getMonth() - startDate.getMonth());
      if (diffInMonths >= 0) {
        Arr.push({
          type: "historical data",
          date: item.startDate,
          value: item.value,
        });
        Arr.push({
          type: "historical target data",
          date: item.startDate,
          value: item.target,
        });
      } else if (diffInMonths === -1) {
        Arr.push({});
      }
    });
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //to create median, avg data for chart,
    const beginDateContImporvement = new Date(beginDateForEngine);
    const diffInMonthsForContImprovement = (currentDate.getFullYear() - beginDateContImporvement.getFullYear()) * 12 + (currentDate.getMonth() - beginDateContImporvement.getMonth());
    let valueForContImprovement = 0;
    targetsForRecommendation.map((item, index) => {
      const startDate = new Date(item.item.startDate);
      const diffInMonths = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth());
      if (diffInMonths === 0) {
        valueForContImprovement = item.item.target;
      }
      if (diffInMonths < 0) {
        Arr.push({
          type: "median",
          date: item.item.startDate,
          value: isNaN(median) ? 0 : median,

        });
        Arr.push({
          type: "average",
          date: item.item.startDate,
          value: isNaN(average) ? 0 : average,
        });
        Arr.push({
          type: "over average",
          date: item.item.startDate,
          value: isNaN(average * 1.2) ? 0 : average * 1.2,

        });
        Arr.push({
          type: "over median",
          date: item.item.startDate,
          value: isNaN(median * 1.2) ? 0 : median * 1.2,
        });
        if (diffInMonths <= diffInMonthsForContImprovement) {
          valueForContImprovement = valueForContImprovement * 1.2;
          Arr.push({
            type: "continuous improvement",
            date: item.item.startDate,
            value: valueForContImprovement,
          });
        } else {
          Arr.push({
            type: "continuous improvement",
            date: item.item.startDate,
            value: valueForContImprovement,
          });
        }
      }
    });
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    setData(Arr);

  }, [median, average, targetsForRecommendation, beginDateForEngine, kpiJson]);

  useEffect(() => {
    //to get ai(single_exponential_smoothing) data
    if (targetsForRecommendation.length !== 0) {
      let body = { values: [] };
      targetsForRecommendation.map((item) => {
        if (item.selected) {
          let obj = {
            date: item.item.startDate,
            value: parseInt(item.item.target),
            obtained: parseInt(item.item.value),
          };
          body.values.push(obj);
        }
      });
      let latestDateTargets = new Date(
        targetsForRecommendation[
          targetsForRecommendation.length - 1
        ].item.startDate
      );
      const diffInMonths =
        (latestDateTargets.getFullYear() - currentDate.getFullYear()) * 12 +
        (latestDateTargets.getMonth() - currentDate.getMonth());
      body["expectedMonthCount"] = diffInMonths;
      body["startMonthForExpectedMonth"] =
        currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1);
      body["modelType"] = "exponential_smoothing";
      if (body.values.length >= 3) {
        fetch(process.env.REACT_APP_BASE_URL_AI + process.env.REACT_APP_FORECAST, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            if (!data.error) {
              setExponentialSmoothingAiResult(data);
            }
            return data;
          })
          .catch((error) => {
            console.log(error.json());
          });
      }
    }
  }, [targetsForRecommendation]);

  useEffect(() => {
    //to get ai(ARIMA) data
    if (targetsForRecommendation.length >= 3) {
      let body = { values: [] };
      targetsForRecommendation.map((item) => {
        if (item.selected) {
          let obj = {
            date: item.item.startDate,
            value: parseInt(item.item.target),
            obtained: parseInt(item.item.value),
          };
          body.values.push(obj);
        }
      });
      let latestDateTargets = new Date(
        targetsForRecommendation[
          targetsForRecommendation.length - 1
        ].item.startDate
      );
      const diffInMonths =
        (latestDateTargets.getFullYear() - currentDate.getFullYear()) * 12 +
        (latestDateTargets.getMonth() - currentDate.getMonth());
      body["expectedMonthCount"] = diffInMonths;
      body["startMonthForExpectedMonth"] =
        currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1);
      body["modelType"] = "arima";
      if (body.values.length >= 3) {
        fetch(process.env.REACT_APP_BASE_URL_AI + process.env.REACT_APP_FORECAST, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            if (!data.error) {
              setArimaAiResult(data);
            }
            return data;
          })
          .catch((error) => {
            console.log(error.json());
          });
      }
    }
  }, [targetsForRecommendation]);

  useEffect(() => {
    //to get ai(prophet) data
    if (targetsForRecommendation.length !== 0) {
      let body = { values: [] };
      targetsForRecommendation.map((item) => {
        if (item.selected) {
          let obj = {
            date: item.item.startDate,
            value: parseInt(item.item.target),
            obtained: parseInt(item.item.value),
          };
          body.values.push(obj);
        }
      });
      let latestDateTargets = new Date(
        targetsForRecommendation[
          targetsForRecommendation.length - 1
        ].item.startDate
      );
      const diffInMonths =
        (latestDateTargets.getFullYear() - currentDate.getFullYear()) * 12 +
        (latestDateTargets.getMonth() - currentDate.getMonth());
      body["expectedMonthCount"] = diffInMonths;
      body["startMonthForExpectedMonth"] =
        currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1);
      body["modelType"] = "prophet";

      if (body.values.length >= 3) {
        fetch(process.env.REACT_APP_BASE_URL_AI + process.env.REACT_APP_FORECAST, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            if (!data.error) {
              setprophetAiResult(data);
            }
            return data;
          })
          .catch((error) => {
            console.log(error.json());
          });
      }

    }
  }, [targetsForRecommendation]);

  useEffect(() => {
    if (arimaAiResult) {
      let arr = [];
      for (let i = 0; i < arimaAiResult.forecastValues.length; i++) {
        let date = arimaAiResult.forecastValues[i].date.split("-");
        let type = "Arima AI";
        let value = arimaAiResult.forecastValues[i].value;
        let obj = { type: type, date: date[0] + "-" + date[1], value: value };
        arr.push(obj);
      }
      let tempData = [...data];
      for (let i = 0; i < arr.length; i++) {
        const newItem = arr[i];
        const existingItemIndex = tempData.findIndex(
          (item) => item.date === newItem.date
        );

        if (existingItemIndex !== -1) {
          // Eğer tarih zaten "Data" dizisinde bulunuyorsa, "value" değerini güncelleyelim
          // tempData[existingItemIndex].value = newItem.value;
          tempData.splice(existingItemIndex, 0, newItem);
        } else {
          // Eğer tarih "Data" dizisinde bulunmuyorsa, yeni bir öğe olarak ekleyelim
          // tempData.push(newItem);
        }
      }
      setData(tempData);
    }
  }, [arimaAiResult]);

  useEffect(() => {
    if (exponentialSmoothingAiResult) {
      let arr = [];
      for (let i = 0; i < exponentialSmoothingAiResult.forecastValues.length; i++) {
        let date = exponentialSmoothingAiResult.forecastValues[i].date.split("-");
        let type = "Exponential Smoothing AI";
        let value = exponentialSmoothingAiResult.forecastValues[i].value;
        let obj = { type: type, date: date[0] + "-" + date[1], value: value };
        arr.push(obj);
      }
      let tempData = [...data];
      for (let i = 0; i < arr.length; i++) {
        const newItem = arr[i];
        const existingItemIndex = tempData.findIndex(
          (item) => item.date === newItem.date
        );

        if (existingItemIndex !== -1) {
          // Eğer tarih zaten "Data" dizisinde bulunuyorsa, "value" değerini güncelleyelim
          // tempData[existingItemIndex].value = newItem.value;
          tempData.splice(existingItemIndex, 0, newItem);
        } else {
          // Eğer tarih "Data" dizisinde bulunmuyorsa, yeni bir öğe olarak ekleyelim
          // tempData.push(newItem);
        }
      }
      setData(tempData);
    }
  }, [exponentialSmoothingAiResult]);

  useEffect(() => {
    if (prophetAiResult) {
      let arr = [];
      for (let i = 0; i < prophetAiResult.forecastValues.length; i++) {
        let date = prophetAiResult.forecastValues[i].date.split("-");
        let type = "Prophet AI";
        let value = prophetAiResult.forecastValues[i].value;
        let obj = { type: type, date: date[0] + "-" + date[1], value: value };
        arr.push(obj);
      }
      let tempData = [...data];
      for (let i = 0; i < arr.length; i++) {
        const newItem = arr[i];
        const existingItemIndex = tempData.findIndex(
          (item) => item.date === newItem.date
        );

        if (existingItemIndex !== -1) {
          // Eğer tarih zaten "Data" dizisinde bulunuyorsa, "value" değerini güncelleyelim
          // tempData[existingItemIndex].value = newItem.value;
          tempData.splice(existingItemIndex, 0, newItem);
        } else {
          // Eğer tarih "Data" dizisinde bulunmuyorsa, yeni bir öğe olarak ekleyelim
          // tempData.push(newItem);
        }
      }
      setData(tempData);
    }
  }, [prophetAiResult]);


  useEffect(() => {
    let arr = [...targetsForRecommendation];
    if (selectionTargetsForConfiguration === "selectAll") {
      arr.map((target, index) => {
        const startDate = new Date(target.item.startDate);
        const diffInMonths =
          (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
          (currentDate.getMonth() - startDate.getMonth());
        if (diffInMonths >= 0) {
          target.selected = true;
        } else {
          target.selected = false;
        }
      });
      setTargetsForRecommendation(arr);
    } else if (selectionTargetsForConfiguration === "lastOneYear") {
      let Arr = [];
      targetsForRecommendation.map((item) => {
        const startDate = new Date(item.item.startDate);
        const diffInMonths =
          (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
          (currentDate.getMonth() - startDate.getMonth());
        if ((currentDate.getFullYear() - startDate.getFullYear()) === 0 && diffInMonths >= 0) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        Arr.push(item);
      });
      setTargetsForRecommendation(Arr);
    } else if (selectionTargetsForConfiguration === "lastSixMonth") {
      let Arr = [];
      targetsForRecommendation.map((item) => {
        const startDate = new Date(item.item.startDate);
        const diffInMonths =
          (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
          (currentDate.getMonth() - startDate.getMonth());
        if (diffInMonths < 6 && diffInMonths >= 0) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        Arr.push(item);
      });
      setTargetsForRecommendation(Arr);
    } else {
      let arr = [...targetsForRecommendation];
      arr.map((target, index) => {
        target.selected = false;
      });
      setTargetsForRecommendation(arr);
    }
  }, [selectionTargetsForConfiguration]);
  return (
    <div className='forecast-configuration-container'>
      <div className='auto-selection-tab'>
        <input
          type="radio"
          name="targetFilter"
          id="selectAll"
          value={"selectAll"}
          checked={selectionTargetsForConfiguration === "selectAll"}
          onChange={(e) => setSelectionTargetsForConfiguration(e.target.value)}
        />
        <label className={selectionTargetsForConfiguration === "selectAll" ? "selected" : ""} htmlFor="selectAll">Select All</label>
        <input
          type="radio"
          name="targetFilter"
          id="lastOneYear"
          value={"lastOneYear"}
          checked={selectionTargetsForConfiguration === "lastOneYear"}
          onChange={(e) => setSelectionTargetsForConfiguration(e.target.value)}
        />
        <label className={selectionTargetsForConfiguration === "lastOneYear" ? "selected" : ""} htmlFor="lastOneYear">Last 1 Year</label>
        <input
          type="radio"
          name="targetFilter"
          id="lastSixMonth"
          value={"lastSixMonth"}
          checked={selectionTargetsForConfiguration === "lastSixMonth"}
          onChange={(e) => setSelectionTargetsForConfiguration(e.target.value)}
        />
        <label className={selectionTargetsForConfiguration === "lastSixMonth" ? "selected" : ""} htmlFor="lastSixMonth">Last 6 Month</label>
        <input
          type="radio"
          name="targetFilter"
          id="none"
          value={"none"}
          checked={selectionTargetsForConfiguration === "none"}
          onChange={(e) => setSelectionTargetsForConfiguration(e.target.value)}
        />
        <label className={selectionTargetsForConfiguration === "none" ? "selected" : ""} htmlFor="none">None</label>
      </div>
      <div className='target-selection-tab'>
        <div className="data-list">
          {targetsForRecommendation.map((target, index) => (
            <div
              className={
                target.selected === true
                  ? "data-list-item active"
                  : "data-list-item"
              }
              key={index}
              onClick={() => handleTargetSelected(index)}
            >
              <label>
                {"Date: " + target.item.startDate + "/" + target.item.endDate}
              </label>
              <label>{"Value: " + target.item.value}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
