import React, { useState } from "react";

export const ImportTableContext = React.createContext();

const ImportTableContextProvider = (props) => {

    const [tableInitalData, setTableInitalData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [clickedCordinat, setClickedCordinat] = useState([-1, -1]);
    const [ids, setIds] = useState([])
    const [month, setMonth] = useState([]);
    const [messageIntoTable, setMessageIntoTable] = useState("Select Agreement")

    function ResetContext(){
        setTableInitalData([])
        setTableData([])
        setClickedCordinat([-1,-1])
        setIds([])
        setMonth([])
        setMessageIntoTable("Select Agreement")
    }

    return (
        <ImportTableContext.Provider value={{
            ResetContext,
            tableInitalData,
            setTableInitalData,
            tableData,
            setTableData,
            clickedCordinat,
            setClickedCordinat,
            ids,
            setIds,
            month,
            setMonth,
            messageIntoTable,
            setMessageIntoTable
        }}>
            {props.children}
        </ImportTableContext.Provider>
    )
}
export default ImportTableContextProvider;
