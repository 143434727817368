import React, { useContext } from 'react'
import "./Users.css"
import right_ico from "../../assets/right-arrow-2.png"
import { MainContext } from '../../contexts/MainContext';

export default function Users({ userList, department, setShowUsers }) {
    const { langFile } = useContext(MainContext);
    return (
        <div className='users-container'>
            <div className='table-information'>
                <label> {department + " "+ langFile.pages.Organization.userList}</label>
                <img onClick={()=>setShowUsers(false)} alt='close-ico' src={right_ico}></img>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>
                        {langFile.pages.Organization.name}
                        </th>
                        <th>
                        {langFile.pages.Organization.surname}
                        </th>
                        <th>
                        {langFile.pages.Organization.position}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {userList && userList.map((user,index) =>
                        <tr key={index}>
                            <td>
                                {user.firstname}
                            </td>
                            <td>
                            {user.lastname}
                            </td>
                            <td>
                            {user.positions[0]?.name}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>


        </div>
    )
}
