import React, { useContext } from "react";
import { MainContext } from "./MainContext";
import { LoginContext } from "./LoginContext";
import { KpiEditContext } from "./KpiEditContext";
import { KpisContext } from "./KpisContext";

export const UpdateKpiDateContext = React.createContext();

const UpdateKpiDateContextProvider = (props) => {
    const { token, user, roles } = useContext(LoginContext)
    const { AddAlert } = useContext(MainContext);
    const { rangeValues } = useContext(KpiEditContext)
    


    function TotalMonthNumber(start, end) {
        if (parseInt(end[0]) > parseInt(start[0])) {
            return (
                parseInt(end[1]) -
                parseInt(start[1]) +
                ((parseInt(end[0]) - parseInt(start[0])) * 12) + 1
            );
        } else if (parseInt(end[0]) === parseInt(start[0])) {
            if (parseInt(end[1]) > parseInt(start[1])) {
                return parseInt(end[1] - parseInt(start[1])) + 1;
            }
        }
    }

    function GetDate(startDate, mounthNumber) {
        const start = startDate.split("-");
        let yearCounter = parseInt(
            (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) / 12
        );
        let mounthCounter = parseInt(
            (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) % 12
        );
        start[1] = mounthCounter;
        start[0] = yearCounter;
        if (mounthCounter === 0) {
            start[1] += 12;
            start[0] -= 1;
        }
        if (start[1] < 10) {
            return String(start[0]) + "-0" + String(start[1]);
        } else {
            return String(start[0]) + "-" + String(start[1]);
        }
    }

    function GetDate_2(startDate, mounthNumber) {
        const start = startDate.split("-");
        let yearCounter = parseInt(
            (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) / 12
        );
        let mounthCounter = parseInt(
            (mounthNumber + parseInt(start[1]) + parseInt(start[0] * 12)) % 12
        );
        start[1] = mounthCounter;
        start[0] = yearCounter;
        if (mounthCounter === 0) {
            start[1] += 12;
            start[0] -= 1;
        }
        if (start[1] < 10) {
            return "01-0" + String(start[1]) + "-" + String(start[0]);
        } else {
            return "01-" + String(start[1]) + "-" + String(start[0]);
        }
    }



    async function CreateTarget(
        kpiGuid,
        targetTypeGuid,
        value,
        completedValue,
        weight,
        targetDate
    ) {
        if (roles.some(role => role === "KpiEdit")) {
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_CREATE_TARGET, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                },
                body: JSON.stringify({
                    accountId: user.id,
                    kpiId: kpiGuid,
                    targetTypeId: targetTypeGuid,
                    value: value,
                    completedValue: completedValue,
                    weight: weight,
                    targetDate: targetDate,
                    isPercentage: false,
                }),
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    throw res;
                })
                .then((data) => {
                    resolve(true)
                    return data;
                })
                .catch((error) => {
                    reject(error)
                    console.log(error.json());
                });
        });
    }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
    }
    async function DeleteTarget(
        targetId
    ) {
        if (roles.some(role => role === "KpiEdit")) {

        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_PURGE_TARGET_BY_ID, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                },
                body: JSON.stringify({
                    targetId: targetId
                }),
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    throw res;
                })
                .then((data) => {
                    resolve(true)
                    return data;
                })
                .catch((error) => {
                    reject(error)
                    console.log(error.json());
                });
        });
    }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
    }

    function Compare(existDateList, updatedDateList) {
        var onlyExist = [];
        var onlyUpdated = [];
        var common = [];

        existDateList.forEach(function (target) {
            if (updatedDateList.includes(target.date)) {
                common.push(target);
            } else {
                onlyExist.push(target);
            }
        });

        updatedDateList.forEach(function (date) {
            if (!existDateList.some(target => target.date === date)) {
                onlyUpdated.push(date);
            }
        });

        return { onlyExist, onlyUpdated, common };
    }
    async function UpdateKpiDate(startDate, endDate, kpi) {
        const totalMonthNumber = TotalMonthNumber(
            startDate.split("-"),
            endDate.split("-")
        );
        let existDateList = []
        let updatedDateList = []
        for (let i = 0; i < kpi.targets.length; i++) {
            existDateList.push({ id: kpi.targets[i].id, date: kpi.targets[i].referenceDate })
        }
        for (let i = 0; i < totalMonthNumber; i++) {
            updatedDateList.push(GetDate(startDate, i))
        }

        let compareResult = Compare(existDateList, updatedDateList)
        const promisesToAwait = [];
        for (let i = 0; i < compareResult.onlyUpdated.length; i++) {
            promisesToAwait.push(
                CreateTarget(
                    kpi.kpiId,
                    rangeValues["Minimum"],
                    0,
                    0,
                    kpi.priorityName === "Critical" ? 100 : 0,
                    GetDate_2(compareResult.onlyUpdated[i], 0)
                )
            );
        }

        for (let i = 0; i < compareResult.onlyExist.length; i++) {
            promisesToAwait.push(
                DeleteTarget(
                    compareResult.onlyExist[i].id
                )
            );
        }

        try {
            const responses = await Promise.all(promisesToAwait);
            await promisesToAwait.push(AddAlert("success", "Changing KPI Time Range is Successful"))
            

        } catch (error) {
            console.error(error);
            await AddAlert("error", "Changing KPI Time Range is Failed")
            
        }
    }

    return (
        <UpdateKpiDateContext.Provider value={{
            UpdateKpiDate,
            GetDate,
            GetDate_2
        }}>
            {props.children}
        </UpdateKpiDateContext.Provider>
    )
}

export default UpdateKpiDateContextProvider;