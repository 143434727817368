import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/plots";

export default function StackedAreaPlot(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!props.dataForRecommendation) {
      setData(props.data)
    }
  }, [props.data])
  const currentDate = new Date();
  useEffect(() => {//to combine the predicted data with the historical data line
    if (props.dataForRecommendation && props.data.length === 0) {
      setData([]);
    }
    else if (props.dataForRecommendation && props.data.length !== 0) {
      let formattedCurrentDate = currentDate.getFullYear() + (currentDate.getMonth() + 1 < 10 ? "-0" : "-") + (currentDate.getMonth() + 1);
      let lastValue = 0;
      let existingItemIndex = 0
      for (let i = 0; i < props.data.length; i++) {
        if ((props.data[i].type === "historical target data") && props.data[i].date === formattedCurrentDate) {
          lastValue = props.data[i].value;
          existingItemIndex = i
          break
        }
      }
      let temp = [...props.data];
      if (existingItemIndex !== 0) {
        temp.splice(existingItemIndex, 0, {
          type: "Arima AI",
          date: formattedCurrentDate,
          value: lastValue,
        });
        temp.splice(existingItemIndex, 0, {
          type: "Prophet AI",
          date: formattedCurrentDate,
          value: lastValue,
        });
        temp.splice(existingItemIndex, 0, {
          type: "Exponential Smoothing AI",
          date: formattedCurrentDate,
          value: lastValue,
        });
        temp.splice(existingItemIndex, 0, {
          type: "median",
          date: formattedCurrentDate,
          value: lastValue,
        });
        temp.splice(existingItemIndex, 0, {
          type: "average",
          date: formattedCurrentDate,
          value: lastValue,
        });
        temp.splice(existingItemIndex, 0, {
          type: "over average",
          date: formattedCurrentDate,
          value: lastValue,
        });
        temp.splice(existingItemIndex, 0, {
          type: "over median",
          date: formattedCurrentDate,
          value: lastValue,
        });
        temp.splice(existingItemIndex, 0, {
          type: "continuous improvement",
          date: formattedCurrentDate,
          value: lastValue,
        });
      }
      const data = temp.map((item) => ({
        ...item,
        value: Number(item?.value?.toFixed(6)),
      }));
      setData(data);
    }
  }, [props.data]);


  const config = {
    data,
    xField: "date",
    yField: "value",
    seriesField: "type",
    background: "#808080",
    //  yAxis: {
    //    label: {
    //      formatter: (v) =>
    //        `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    //    },
    //  },
    color: [
      "#1979C9",
      "#D62A0D",
      "#FAA219",
      "#008BF8",
      "#04E762",
      "#DC0073",
      "#F4ACB7",
      "#9EC5AB",
      "#01200F",
      "#89FC00",
      "#1C1C1C",
    ],
    lineStyle: ({ type }) => {
      if (
        type === "median" ||
        type === "average" ||
        type === "over average" ||
        type === "over median" ||
        type === "same from last year" ||
        type === "continuous improvement" ||
        type === "ai" ||
        type === "Arima AI" ||
        type === "Exponential Smoothing AI" ||
        type === "Prophet AI"
      ) {
        return {
          lineDash: [7, 7],
          opacity: 1,
          lineWidth: 2,
        };
      }
      return {
        opacity: 1,
        lineWidth: 3
      };
    },
  };


  return (
    <Line background="#808080" height={"80%"} width={"100%"} {...config} />
  );
}
