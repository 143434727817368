import React, { useContext} from "react";
import "../../styles/ProfileCardListComponentMyUsers/SmallProfileCard.css";
import Card from "./Card";
import { EditUserContext } from "../../contexts/EditUserContext";
import { SmallProfileCardContext } from "../../contexts/SmallProfileCardContext";
import PopUpAlert from "../../components/PopUpAlert/PopUpAlert";
import { LoginContext } from "../../contexts/LoginContext";
import { MainContext } from "../../contexts/MainContext";
import { useNavigate } from "react-router-dom";
import { MyUsersContext } from "../../contexts/MyUsersContext";

export default function SmallProfileCard({ searchUserText }) {

  const { myUsers, isDeletePopUp, setIsDeletePopUp } = useContext(SmallProfileCardContext);
  const { setToEditUser } = useContext(EditUserContext);
  const { token, user, roles } = useContext(LoginContext)
  const { setAlertList, AddAlert } = useContext(MainContext);
  const { GetAccounts } = useContext(MyUsersContext);
  const navigate = useNavigate();

  function deleteUser(body) {
    if (roles.some(role => role === "UserDelete")) {
    fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_DELETE_ACCOUNT, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + token,
        "TenantId": "slm",
      },
      body: JSON.stringify(body)
    })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
        else if (res.status === 401) {
          setAlertList([])
          localStorage.clear();
          navigate("/login");
        }
        throw res.json();
      })
      .then((data) => {
        if (data.result.statusCode === 200) {
          AddAlert("success", "Delete User is Successful")
          GetAccounts(user?.company?.id)
          return data;
        }
        else {
          AddAlert("error", "Delete User is Failed")
        }
      })
      .catch((error) => {
        AddAlert("error", "Delete User is Failed")
        console.log(error)
      });
    }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  return (
    <div className="small-profile-card-parent-my-user">
      <div className="parent-small-profile-card">
        {myUsers.filter(searchUser => {
          if (searchUserText === "") {
            return searchUser;
          }
          else if (searchUser?.firstname.toLowerCase().includes(searchUserText?.toLowerCase()) ||
            searchUser?.username.toLowerCase().includes(searchUserText?.toLowerCase())) {
            return searchUser;
          }
        }).map((user, index) => (
          <React.Fragment key={user.id}>
          {isDeletePopUp === user.id && <PopUpAlert key={"pop-up" + user.id} text={"Do you wanna delete " + user.firstname + " " + user.lastname + " ?"} func={deleteUser} setIsPopUp={setIsDeletePopUp} body={{ accountId: user.id, purge: true }} ></PopUpAlert>}
          <Card
            setToEditUser={setToEditUser}
            customer={user}
            online={true}
          ></Card>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
