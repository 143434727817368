import React from "react";
import "../styles/FailedSlaList.css";

export default function FailedSlaList() {
  return (
    <div className="parent-container-failedSlaList">
      <table className="container-failedSlaList">
        <thead>
          <tr>
            <th>
              <h1 className="header-1">NAME</h1>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr className="error">
            <td>Mobil Uygulama</td>
          </tr>
          <tr>
            <td>Bilgi Teknolojileri Altyapı</td>
          </tr>
          <tr>
            <td>İnsan Kaynakları İşlemleri </td>
          </tr>
          <tr className="error">
            <td>Lojistik</td>
          </tr>
          
         
        </tbody>
      </table>
    </div>
  );
}
