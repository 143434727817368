import React, { useContext, useEffect, useState } from "react";
import "../styles/Login.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo-3.png";
import pages_ico from "../assets/pages-ss.png";
import { MainContext } from "../contexts/MainContext";
import { LoginContext } from "../contexts/LoginContext";
import LangDropdown from "../components/LangDropdown";
import cordlynx_ico from "../assets/cordlynx.png"

export default function Login() {
  const { langFile } = useContext(MainContext);
  const { loginEmail,
    setLoginEmail,
    loginPassword,
    setLoginPassword,
    importLoader,
    setImportLoader,
    loginError,
    setLoginError,
    setToken,
    setRoles,
    setUser,
    SignInConfirm
   } = useContext(LoginContext)
  const navigate = useNavigate();

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      SignInConfirm();
    }
  }  

  return (
    <div className="login-parent">
      <div className="container">
        <label>{langFile.pages.Login.welcome}</label>
        <label>{langFile.pages.Login.loginInfo}</label>
        <div className="container-2">
          <img alt="pages_ico" src={pages_ico} className="login-pages-img" />
          <div className="form-container">
            <input
              className="login-input"
              type="text"
              placeholder={langFile.pages.Login.email}
              id="email"
              onChange={(event) => setLoginEmail(event.target.value)}
              onKeyDown={handleKeyPress}
              autoComplete="on"
            />
            <input
              className="login-input"
              type="password"
              placeholder={langFile.pages.Login.password}
              id="password"
              onChange={(event) => setLoginPassword(event.target.value)}
              onKeyDown={handleKeyPress}
              autoComplete="on"
            />
            {loginError === true ? (
              <label className="errorLabel">
                {langFile.pages.Login.loginError}
              </label>
            ) : null}
            <button
              onClick={SignInConfirm}
              style={{ pointerEvents: importLoader === true ? "none" : "all" }}
            >
              {importLoader === true ? (
                <div className="login-loader"></div>
              ) : (
                langFile.pages.Login.loginBtn
              )}
            </button>
            <button onClick={() => navigate('/forgotpassword')}>{langFile.pages.Login.forgotPass}</button>
          </div>
        </div>
        <div className="lang-logo-container">
          <img alt="pages_ico" src={logo} className="login-exp-logo" />
          <img alt="pages_ico" src={cordlynx_ico} className="login-exp-logo" />
          <div className="lang-container">
            <LangDropdown></LangDropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
