import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import "../styles/Services.css"
import { MainContext } from '../contexts/MainContext';
import { LoginContext } from '../contexts/LoginContext';
import { ServiceContext } from '../contexts/ServiceContext';
import PopUpAlert from "../components/PopUpAlert/PopUpAlert";
import logo from "../assets/services.png"
import edit_ico from "../assets/edit.png";
import plus_ico from "../assets/plus.png";
import delete_ico from "../assets/delete.png"
import SelectCompany from '../components/SelectCompany';
import Navigation from '../components/Navigation';
import ThemaMode from '../components/themaToggle/ThemaMode';

export default function Services() {
  const [searchServiceText, setSearchServiceText] = useState("");
  const navigate = useNavigate();
  const { langFile, setAlertList, AddAlert } = useContext(MainContext);
  const { services, setServices, isDeletePopUp, setIsDeletePopUp, setToEditService } = useContext(ServiceContext)
  const { token, user, roles } = useContext(LoginContext)
  const [paginationCounter, setPaginationCounter] = useState(50)

  function getAllService() {
    if (roles.some(role => role === "ServiceRead")) {

      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_SERVICE_GET_SERVICES + "?Deleted=false&Take=" + paginationCounter + "&Skip=0", {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          "Authorization": "Bearer " + token,
        }
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((data) => {
          setServices(data);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function deleteService(body) {
    if (roles.some(role => role === "ServiceDelete")) {

      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_DELETE_SERVICE, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(body)
      })
        .then((res) => {
          getAllService()
          if (res.ok && res.status === 200) {
            return res;
          }
          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res.json();
        })
        .then((data) => {
          if (data.status === 200) {
            AddAlert("success", "Delete Service is Successful")
            return data;
          }
          else {
            AddAlert("error", "Delete Service is Failed")
          }
        })
        .catch((error) => {
          AddAlert("error", "Delete Service is Failed")
          console.log(error)
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function handleClickToEditServiceButton(service) {
    setToEditService(service);
    navigate("/main/newservice")
  }

  useEffect(() => {
    getAllService()
    setToEditService(false)
  }, [paginationCounter])


  return (
    <div className='services-parent'>
      <div className='navigation-container'>
        <Navigation img={logo} text_1={langFile.pages.Services.services}
          text_2={langFile.pages.Services.servicesServices}></Navigation>
        <SelectCompany></SelectCompany>
        {roles.some(role => role === "ServiceCreate") &&
          <button className="add-service-button"
            onClick={() => { navigate("/main/newservice") }}
          >
            <img
              alt="add-service"
              className="add-service-img"
              src={plus_ico}
            ></img>
            {langFile.pages.NewService.newService}
          </button>
        }
        <input
          className="search-item"
          id="search"
          type="search"
          placeholder={
            langFile.pages.Services.searchService
          }
          onChange={(e) => setSearchServiceText(e.target.value)}
        />
      </div>


      <table className='container-services'>
        <thead >
          <tr>
            <th >
              {langFile.pages.Services.key}
            </th>
            <th >
              {langFile.pages.Services.name}
            </th>
            <th >
              {langFile.pages.Services.description}
            </th>
            <th >
              {langFile.pages.Services.platform}
            </th>
            <th>
              {langFile.pages.Services.serviceOwner}
            </th>
            <th >
              {langFile.pages.Services.serviceProcessManager}
            </th>
            <th >
              {langFile.pages.Services.businessOwner}
            </th>
            <th>
              {langFile.pages.Services.relatedTechnicalService}
            </th>
            <th>
              {langFile.pages.Services.customers}
            </th>
            <th>
              {langFile.pages.Services.supportCompany}
            </th>
            <th>
              {langFile.pages.Services.team}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {services.filter(({ service }) => {
            if (searchServiceText === "") {
              return service;
            }
            else if (service.name.toLocaleLowerCase().includes(searchServiceText.toLocaleLowerCase())) {
              return service;
            }
          }).map(({ service }, index) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td>{service.key}</td>
                  <td>{service.name}</td>
                  <td>{service.description}</td>
                  <td>{service.platform}</td>
                  <td>{service.serviceOwner}</td>
                  <td>{service.serviceProcessManager}</td>
                  <td>{service.businessOwner}</td>
                  <td>{service.relatedTechnicalService}</td>
                  <td>{service.customers.join(', ')}</td>
                  <td>{service.supportCompany}</td>
                  <td>{service.team}</td>
                  <td>
                    <>
                      {roles.some(role => role === "ServiceEdit") &&
                        <button className='edit-service-btn' onClick={() => handleClickToEditServiceButton(service)}>
                          <img className="icon" alt="point" src={edit_ico}></img>
                        </button>
                      }
                      {roles.some(role => role === "ServiceDelete") &&
                        <button className='edit-service-btn' onClick={() => setIsDeletePopUp(service.id)}>
                          <img className="icon" alt="point" src={delete_ico}></img>
                        </button>
                      }
                    </>
                  </td>
                  <td style={{ width: "0%" }}>
                    {isDeletePopUp === service.id && <PopUpAlert key={"pop-up" + index} text={"Do you wanna delete " + service.name + " ?"} func={deleteService} setIsPopUp={setIsDeletePopUp} body={{ guid: service.id }} ></PopUpAlert>}
                  </td>
                </tr>
              </React.Fragment>
            )
          })}
          <tr onClick={() => { setPaginationCounter(paginationCounter + 50) }} style={{ width: "180vw", position: "relative" }}>
            <td style={{ position: "sticky", left: "30vw" }}>LOAD MORE</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
