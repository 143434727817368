import React, { useContext, useEffect, useState } from 'react'
import "./EmailSenderMain.css"
import alert_ico from "../../assets/email.png";
import { LoginContext } from '../../contexts/LoginContext';
import { json, useNavigate } from 'react-router-dom';
import { MainContext } from '../../contexts/MainContext';
import Multiselect from 'multiselect-react-dropdown';
import { MailContext } from '../../contexts/MailContext';

export default function EmailSenderMain({ setState, mailTemplateId, subjectParameters, bodyParameters, cc = "", bcc = "" }) {
  const { token, user,roles } = useContext(LoginContext)
  const [users, setusers] = useState([])
  const navigate = useNavigate();
  const { setAlertList, AddAlert, AddMultiAlert } = useContext(MainContext);
  const [selectedUsers, setSelectedUsers] = useState([])
  const { SendEmail } = useContext(MailContext)

  useEffect(() => {
    if (user?.company?.id) {
      GetAccounts({
        companyId: user?.company?.id,
        pageIndex: 1,
        pageSize: 999,
        includeAccountType: true,
        includeCompany: true,
        includeOrganizations: true,
        includePositions: true,
      })
    }
  }, [user?.company?.id])

  function GetAccounts(body) {
    if (roles.some(role => role === "UserRead")) {
      fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_ACCOUNTS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "TenantId": "slm",
        },
        body: JSON.stringify(
          body
        ),
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          }

          else if (res.status === 401) {
            setAlertList([])
            localStorage.clear();
            navigate("/login");
          }
          throw res;
        })
        .then((data) => {
          setusers(data.accounts)
          return data;
        })
        .catch((error) => {
          setusers([]);
          console.log(error);
        });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  async function SendEmails() {
    const promisesToAwait = [];
    for (let i = 0; i < selectedUsers.length; i++) {
      promisesToAwait.push(SendEmail(
        {
          "mailTemplateId": mailTemplateId,
          "subjectParameters": subjectParameters,
          "bodyParameters": `[${selectedUsers[i].firstname}],[${selectedUsers[i].lastname}],${bodyParameters}`,
          "to": selectedUsers[i].email,
          "from": user.email,
          "cc": cc,
          "bcc": bcc
        }
      ))
    }
    const responses = await Promise.all(promisesToAwait);

    let alertList = [];
    for (let i = 0; i < responses.length; i++) {
      alertList.push({ status: responses[i][0], text: responses[i][1] })
    }
    AddMultiAlert(alertList)
  }

  function onSelect(selectedList, selectedItem) {
    setSelectedUsers(JSON.parse(JSON.stringify(selectedList)))
  }

  function onRemove(selectedList, removedItem) {
    setSelectedUsers(JSON.parse(JSON.stringify(selectedList)))
  }

  return (
    <div className='email-sender-parent'>
      <div className='email-sender-container'>
        <div className='ico-part'>
          <img alt='alert-ico' className='ico-part-img' src={alert_ico}></img>
        </div>
        <div className='select-users-part'>
          {/* <label className='pop-up-text'>
                        {"text"}
                    </label> */}
          <Multiselect
            options={users} // Options to display in the dropdown
            // selectedValues={} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue="username" // Property name to display in the dropdown options
          />
        </div>
        <div className='button-part'>
          <button style={{ width: selectedUsers.length === 0 ? "100%" : "45%" }} onClick={() => { setState(false) }}>
            Cancel
          </button>
          <button style={{ width: selectedUsers.length === 0 ? "0%" : "45%" }} onClick={() => { SendEmails() }}>
            Send
          </button>
        </div>
      </div>
    </div>
  )
}
