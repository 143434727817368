import React, { useContext, useEffect, useState } from "react";
import "../styles/MyUsers.css";
import SmallProfileCard from "../components/UsersComponent/SmallProfileCard";
import NewUser from "../components/NewUser";
import logo from "../assets/people.png";
import EditUser from "../components/EditUser";
import { MainContext } from "../contexts/MainContext";
import { MyUsersContext } from "../contexts/MyUsersContext";
import { NewUserContext } from "../contexts/NewUserContext";
import { EditUserContext } from "../contexts/EditUserContext";
import { SmallProfileCardContext } from "../contexts/SmallProfileCardContext";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import add_ico from "../assets/plus.png"
import SelectCompany from "../components/SelectCompany";
import Navigation from "../components/Navigation";

export default function MyUsers() {
  const navigate = useNavigate();
  const { langFile, setAlertList } = useContext(MainContext);
  const { getAccountTrigger: getAccountTriggerNewUser } = useContext(NewUserContext);
  const { getAccountTrigger: getAccountTriggerEditUser, toEditUser } = useContext(EditUserContext);
  const { roles, user } = useContext(LoginContext)
  const { GetAccounts, GetAllCompany } = useContext(MyUsersContext)
  const [searchUserText, setSearchUserText] = useState("");

  useEffect(() => {
      GetAllCompany()
  }, [roles]);

  useEffect(() => {
    if (user?.company?.id) {
      GetAccounts(user?.company?.id)
    }
  }, [getAccountTriggerEditUser, getAccountTriggerNewUser, user?.company?.id]);

  return (
    <div className="my-users-parent">
      <div className="navigation-container">
        <Navigation img={logo} text_1={langFile.pages.SmallProfileCardMyUsers.myUsers}
          text_2={langFile.pages.SmallProfileCardMyUsers.myUsers}></Navigation>
        <React.Fragment >
          <SelectCompany></SelectCompany>
            <button onClick={() => { navigate("/main/newcustomer") }} className="newCompany" >
              <img alt="newCompany" src={add_ico}>
              </img>
              {langFile.pages.NewCustomer.addNewCompany}
            </button>
        </React.Fragment>
        <input
          className="search-item"
          id="search"
          type="search"
          placeholder={
            langFile.pages.SmallProfileCardMyUsers.searchPlaceholder
          }
          onChange={(e) => setSearchUserText(e.target.value)}
        />
      </div>
      <div className="my-users-container">
        {(toEditUser === false && roles.some(role => role === "UserCreate")) ?
           <NewUser></NewUser>
          :
          (toEditUser.id === user.id || roles.some(role => role === "UserEdit")) &&
           <EditUser></EditUser> 
        }
        <SmallProfileCard searchUserText={searchUserText}></SmallProfileCard>
      </div>
    </div>
  );
}
