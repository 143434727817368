import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { MainContext } from '../contexts/MainContext';
import Navigation from '../components/Navigation';
import logo from "../assets/company-group.png"
import "../styles/Companies.css"
import CompaniesMain from '../components/Companies/CompaniesMain';
import { LoginContext } from "../contexts/LoginContext";
import SelectCompany from "../components/SelectCompany";

export default function Companies() {


    const [data, setData] = useState();
    const navigate = useNavigate;
    const { token, user, roles } = useContext(LoginContext)
    const { langFile, setAlertList, AddAlert } = useContext(MainContext);


    function GetCompanies() {
        if (roles.some(role => role === "CompanyGroupRead")) {

            fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY_GROUP, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token,
                    "TenantId": "slm",
                },
                body: JSON.stringify({
                    ownerCompanyId: user?.company?.id,
                    includeCompanies: true,
                    includeOwnerCompany: true,
                    pageNumber: 1,
                    pageSize: 999
                })
            }).then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res;
            }).then((data) => {
                setData(data.companyGroups);
                return data;

            }).catch((error) => {
                console.log(error);
            })
        }
        else {
            AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    useEffect(() => {
        GetCompanies()
    }, [user])


    return (
        <div className='companies-parent'>
            <div className="navigation-container">
                <Navigation img={logo} text_1={langFile.pages.Companies.companies}
                    text_2={langFile.pages.Companies.servicesCompanies}
                ></Navigation>
                <SelectCompany></SelectCompany>
            </div>
            <div className='companies-container'>
                <CompaniesMain GetCompanies={GetCompanies} companyGroups={data}></CompaniesMain>
            </div>
        </div>)
}