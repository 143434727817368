import React, { useContext } from 'react'
import CreateService from "../components/CreateService";
import { LoginContext } from '../contexts/LoginContext';
import { MainContext } from '../contexts/MainContext';
import "../styles/NewService.css";
import services_ico from "../assets/services.png"
import { ServiceContext } from '../contexts/ServiceContext';
import EditService from '../components/EditService';
import Navigation from '../components/Navigation';
export default function NewService() {
    const { langFile } = useContext(MainContext);
    const { toEditService } = useContext(ServiceContext);
    const {roles} = useContext(LoginContext)

    return (
        <div className='new-service-parent'>
             
            <div className="navigation-container">
                <Navigation img={services_ico} text_1={toEditService ?langFile.pages.NewService.editService : langFile.pages.NewService.newService}
                    text_2={langFile.pages.Services.services + " / " + langFile.pages.Services.services}></Navigation>
            </div>
            <div className="new-service-container">
                {(toEditService === false && roles.some(role => role === "ServiceCreate")) ?
                    <CreateService></CreateService>:
                    (toEditService !== false && roles.some(role => role === "ServiceEdit"))&&
                    <EditService></EditService>
                }
            </div>
        </div>
    )
}


