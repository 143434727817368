import "../styles/NewAgreement.css";
import { useEffect, useContext } from "react";
import Basket from "../components/Basket";
import logo2 from "../assets/chat.png";
import NewSlaMetaData from "../components/NewSlaMetaDataComponents/NewSlaMetaData.js";
import leftArrow from "../assets/arrow-left-3.png";
import rightArrow from "../assets/arrow-right-3.png";
import { MainContext } from "../contexts/MainContext";
import { NewAgreementContext } from "../contexts/NewAgreementContext";
import { LoginContext } from "../contexts/LoginContext";
import SelectCompany from "../components/SelectCompany"
import Navigation from "../components/Navigation";
import Chatbot from "../components/ChatbotComponents/Chatbot";
import { ChatbotContext } from "../contexts/ChatbotContext";

export default function NewAgreement() {
  const { langFile, AddAlert } = useContext(MainContext);
  const { token, roles } = useContext(LoginContext)
  const {
    metadataSide,
    setMetadataSide,
    basketSide,
    setBasketSide,
    metadataDone,
    setAgreementTypes,
    setTargetType,
    setPriority
  } = useContext(NewAgreementContext);

  const {
    setChatbotData,
  } = useContext(ChatbotContext);

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_SLA_TYPE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          take: 999,
          skip: 0
        }),
      })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setAgreementTypes(data.slaTypes)
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_TARGET_TYPE + "?Take=999&Skip=0",
      {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": "Bearer " + token
        },
      })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setTargetType(data)
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_PRIORITY_GET_PRIORITY+  "?Take=999&Skip=0",
      {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": "Bearer " + token
        },
      })
      .then((res) => {
        if (res.ok && res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setPriority(data)
      })
      .catch((err) => console.log(err));
  }, []);


  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır
      setChatbotData([])
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "93vh",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <div className='navigation-container'>
        <Navigation img={logo2} text_1={langFile.pages.Chatbot.chatbot} text_2={langFile.pages.Chatbot.toAddChatbot}></Navigation>
        <SelectCompany></SelectCompany>
      </div>
      <div className="chatbot-container">
        <NewSlaMetaData></NewSlaMetaData>
        <button
          className="arrow-button-chatbot"
          onClick={() => {
            setMetadataSide(!metadataSide);
          }}
        >
          <img
            alt="metadata"
            src={metadataSide === true ? leftArrow : rightArrow}
          ></img>
        </button>
        <div
          className={
            !metadataSide && !basketSide
              ? "box-cb-wide"
              : (!metadataSide && basketSide) ||
                (metadataSide && !basketSide)
                ? "box-cb-normal"
                : "box-cb"
          }
          style={{
            pointerEvents: (metadataDone && roles.some(role => role === "KpiCreate")) ? "auto" : "none",
            opacity: (metadataDone && roles.some(role => role === "KpiCreate"))   ? "1" : "0.5",
          }}
        >
          <Chatbot></Chatbot>
        </div>
        <button
          className="arrow-button-chatbot"
          onClick={() => {
            setBasketSide(!basketSide);
          }}
        >
          <img
            alt="metadata"
            src={basketSide === true ? rightArrow : leftArrow}
          ></img>
        </button>
        <Basket></Basket>
      </div>
    </div>
  );
}
